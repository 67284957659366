// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { isEmpty, isNull } from "../functions/general";
import "../styles/Dropdown.css";

const validKeys = ["Enter", " "];

/**
 * Structure used to define options in a dropdown input field
 * NOTE: Combine this with multivalue option
 * @param {string} text Displayed for user
 * @param {*} value actual value of ooption
 * @param {boolean} enabled whether the user can select this option */
export function DropdownOption(text, value, enabled) {
  return {
    text,
    value,
    enabled,
  };
}

/**
 * Generic dropdown component that allows the user to select a number of
 * options. Component works by passing in a list of strings as options, and
 * triggering a callback when the option is selected
 */
export default class Dropdown extends Component {
  static propTypes = {
    id: PropTypes.string, // ID of input used when triggering callbacks
    title: PropTypes.string, // tooltip shown when hovering over root DOM
    placeholder: PropTypes.string, // text to show when current value is empty
    disabled: PropTypes.bool, // disables all pointer events
    className: PropTypes.string, // extra class names for specific CSS styling
    children: PropTypes.node, // JSX objects to display under this component
    onChange: PropTypes.func, // callback triggered when value has changed
    tabIndex: PropTypes.string, // property for tabbing into component
    hold: PropTypes.bool, // Hold the dropdown open only if it should hold on choice
    reverse: PropTypes.bool, // display options above root DOM
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.string,
          enabled: PropTypes.bool,
        }),
      ])
    ), // list of options to display
  };

  constructor(props) {
    super(props);

    this.setOptionMapping(props);

    this.state = {
      open: false,
    };

    this.select = this.select.bind(this);
    this.close = this.close.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) this.setOptionMapping(this.props);
  }

  setOptionMapping(props) {
    this.optionMapping = {};
    props.options.forEach((option) => {
      let optionValue = option.value ? option.value : option;
      let optionText = option.text ? option.text : option;
      this.optionMapping[optionValue] = optionText;
    });
  }

  toggleOpen() {
    // If header is clicked, effectively toggle the open state
    if (this.state.open) {
      this.close();
      return;
    }

    this.setState({ open: true });
  }

  close() {
    if (!this.state.open) return;
    this.setState({ open: false });
  }

  select(event) {
    const { onChange, hold, value, id } = this.props;
    const option = event.target.id.replace(id + "-", "");

    if (option === value) return; // can't select the same option
    if (!hold) this.close();
    if (onChange) onChange({ target: { id, value: option } });
  }

  handleKey(event, option) {
    if (!validKeys.includes(event.key)) return;

    if (option === undefined) {
      this.toggleOpen();
    } else {
      this.select(option);
    }
  }

  render() {
    let { className, reverse, value, options, title, disabled, placeholder } = this.props;
    className = className === undefined || className === null ? "" : ` ${className}`;
    disabled = disabled === undefined || disabled === null ? false : disabled;
    const empty = isEmpty(_.get(this.optionMapping, value, null));
    if (isNull(placeholder) && empty) placeholder = "Select value...";

    return (
      <Fragment>
        {this.state.open && <div className="drop-clear" onMouseEnter={this.close} onFocus={this.close} />}

        <div title={title} disabled={disabled} className={`drop-down${this.state.open ? " open" : ""}${className}`}>
          <div tabIndex="0" role="navigation" className="r" onClick={this.toggleOpen} onKeyDown={this.handleKey}>
            <div className={`val${empty ? " empty" : ""}`}>{empty ? placeholder : this.optionMapping[value]}</div>
            <i className="fas fa-caret-down" />
          </div>

          {this.state.open && (
            <div className={`drop${reverse ? " reverse" : ""}`}>
              {options.map((option, index) => {
                const text = option.text ? option.text : option;
                const optionValue = option.value ? option.value : option;
                const active = optionValue === value;
                const enabled = option.enabled !== undefined ? option.enabled : true;

                return (
                  <div
                    id={`${this.props.id}-${optionValue}`}
                    key={index}
                    tabIndex="0"
                    className={`op ${!active ? "in" : ""}active`}
                    onClick={this.select}
                    onKeyDown={(event) => (enabled ? this.handleKey(event, text) : null)}
                    disabled={!enabled}
                  >
                    {text}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {this.state.open && <div className="" tabIndex="0" onFocus={this.close} />}
      </Fragment>
    );
  }
}
