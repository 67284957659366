var RECAPTCHA_SITE_ID='6LfQgbsZAAAAAB054UT8v2m2pl_ll_aG6EV4DPMm';
var IDENTITY_POOL_ID='us-east-1:a5abb9d8-493e-4a2c-a1c2-2829aa391cd5';
var REGION='us-east-1';
var APP_CLIENT_ID='6fmpsmpd4g2eg1o3ns397lfpik';
var USER_POOL_ID='us-east-1_hrv5qkJoJ';
var NAME='5f9qcch5tj';
var URL='https://api.auspexlabs.co';
// This code is property of Auspex Labs Inc. and is protected by Trade Secret.


const config = {
  apiGateway: {
    NAME: NAME,
    REGION: REGION,
    URL: URL,
  },
  cognito: {
    REGION: REGION,
    USER_POOL_ID: USER_POOL_ID,
    APP_CLIENT_ID: APP_CLIENT_ID,
    IDENTITY_POOL_ID: IDENTITY_POOL_ID,
  },
  recaptcha: RECAPTCHA_SITE_ID,
};

export default config;
