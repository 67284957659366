// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Fragment } from "react";

import { AddrClassNames, NodeClassification, HostTypeNames, HostType } from "../../../shared/enumerations";
import { hostComponents } from "../../../static/img/HostIcons";
import { properNoun } from "../../../shared/functions/formatting";
import { getHexagonPoints } from "../../../shared/functions/graphHelpers";

const RangeIconSizes = {
  endpoint: "small",
  address: "small",
  subnet: "medium",
  network: "large",
};

/**
 * A legend overlay displayed in the Graph
 * @param {String} title title of the legend
 */
export default class GraphLegend extends React.Component {
  renderClassifications(theme) {
    const class_colors = {
      [NodeClassification.EXTERNAL]: theme.highlight_1,
      [NodeClassification.MANAGED]: theme.primary_dark,
      [NodeClassification.UNMANAGED]: theme.highlight_6,
      [NodeClassification.UNCONFIGURED]: theme.tertiary,
      [NodeClassification.CLOUD_PROVIDER]: theme.highlight_9,
    };

    const types = Object.assign({}, NodeClassification);
    delete types.MISSING;

    return (
      <Fragment>
        {Object.values(types).map((entry, i) => {
          const y = 25 + i * 20;
          return (
            <Fragment key={`circ-${i}`}>
              <circle cx="10" cy={y} r="6" fill={class_colors[entry]}>
                <title>{AddrClassNames[entry]}</title>
              </circle>
              <text x="20" y={y + 3}>
                {AddrClassNames[entry]}
              </text>
            </Fragment>
          );
        })}
      </Fragment>
    );
  }

  renderHostTypes(types, columns) {
    const rows = [];
    Object.values(types).forEach((t, i) => {
      if (i % columns === 0) {
        rows.push([]);
      }
      let row = rows[rows.length - 1];
      row.push(t);
    });

    const ico_size = 21;
    const margin = 5;
    const col_w = 125;

    return (
      <Fragment>
        {rows.map((row, i) => {
          const y = 35 + i * (ico_size + 5);
          return (
            <Fragment key={`ico-row-${i}`}>
              {row.map((ico, c) => (
                <Fragment key={`ico-col-${c}`}>
                  {hostComponents(ico, {
                    height: `${ico_size}px`,
                    width: `${ico_size}px`,
                    x: 2 * margin + c * col_w,
                    y: y - ico_size / 2 - margin / 2,
                  })}
                  <text x={10 + ico_size + margin + c * col_w} y={y}>
                    {HostTypeNames[ico]}
                  </text>
                </Fragment>
              ))}
            </Fragment>
          );
        })}
      </Fragment>
    );
  }

  renderHost(theme, scale, x, y) {
    let hexPoints = getHexagonPoints(x, y, scale * theme[RangeIconSizes.endpoint]);
    let hex = "";
    hexPoints.forEach((point) => {
      hex += `${point.x} ${point.y},`;
    });
    hex = hex.substring(0, hex.length - 1);

    return (
      <Fragment>
        <title>Hexagon is an Endpoint</title>
        <polygon fill="grey" points={hex} />
        {/* TODO: Custom fill color isn't working for circles. */}
        {/* <polygon fill={theme.text} points={hex} /> */}
      </Fragment>
    );
  }

  renderRanges(theme) {
    const padding = 5;
    const scale = 1.5;

    return (
      <svg width="100">
        {Object.entries(RangeIconSizes).map((entry, i) => {
          let prev = 2 * padding;
          Object.values(RangeIconSizes).forEach((size, j) => {
            if (j < i) prev += scale * 2 * theme[size] + padding;
          });

          const range = properNoun(entry[0]);
          const radius = theme[entry[1]];
          const y = prev + 2 * radius + padding;
          const x = theme.large + 2 * padding;
          return (
            <svg key={`size-${entry[0]}`} width={130}>
              {i === 0 && (
                <text className="graph-legend-title" x="10" y="10">
                  Node Class:
                </text>
              )}
              {i === 0 ? (
                this.renderHost(theme, scale, x, y)
              ) : (
                <Fragment>
                  <title>
                    {properNoun(entry[1])} is a {range}
                  </title>
                  <circle cx={x} cy={y} r={scale * radius} />
                </Fragment>
              )}

              <text x={x + scale * radius + padding} y={`${y + (scale * radius) / 4}`}>
                {range}
              </text>
            </svg>
          );
        })}
      </svg>
    );
  }

  render() {
    const { theme, open } = this.props;

    const h = 130;
    const columns = 3;
    const types = Object.assign({}, HostType);
    delete types.unclassified;

    if (!open) {
      return null;
    } else {
      return (
        <div className="graph-legend-area">
          <svg id="graph-node-color-legend" height={h} width="100">
            {/* this is the legend for the node color, indicates type of node*/}
            <text className="graph-legend-title" x="10" y="10">
              Node Type:
            </text>
            {this.renderClassifications(theme)}
          </svg>
          <div id="graph-node-size-legend" height={h} width="90">
            {/* this is the legend for the node size */}
            {this.renderRanges(theme)}
          </div>
          <svg
            id="graph-host-legend"
            height={Math.ceil(Object.keys(types).length / columns + 2) * (5 + 21)}
            width={30 + 125 * columns}
          >
            <text className="graph-legend-title" x="10" y="10">
              Host Type:
            </text>
            {this.renderHostTypes(types, columns)}
          </svg>
        </div>
      );
    }
  }
}
