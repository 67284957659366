// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "../styles/SearchInput.css";
import { isNull } from "../functions/general";

const validKeys = ["Enter", " "];

/** Standard Search bar for filtering lists. Has no filtering capabilities,
 * instead only triggers filtering through submission */
export default class SearchInput extends Component {
  static propTypes = {
    id: PropTypes.string, // ID of input element
    onChange: PropTypes.func, // callback for when value of input changes, after sanitization
    onSubmit: PropTypes.func, // callback for submitting value
    onOptionSelected: PropTypes.func, // callback for when an option is clicked
    onInvalidCharacter: PropTypes.func, // callback for when an invalid character is input
    submitIcon: PropTypes.string, // icon override for submit button
    initialValue: PropTypes.string, // value to load when component is mounted
    sanitizeRegex: PropTypes.shape(RegExp), // Regex pattern for characters allowed in input
    emptyText: PropTypes.string, // string displayed when input is empty
    options: PropTypes.array, // options to display in a dropdown menu
    disabled: PropTypes.bool, // determines if component is disabled and doesn't accept input
    clearDisabled: PropTypes.bool, // determines if clear button is shown
    buttonVisible: PropTypes.bool, // determines if search button is visible even when input is empty
  };

  constructor(props) {
    super(props);

    let initialValue = isNull(props.initialValue) ? "" : props.initialValue;

    this.state = {
      value: initialValue,
    };

    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKey = this.handleKey.bind(this);
  }

  onClear() {
    const { onChange, onSubmit, id } = this.props;

    this.setState({ value: "" }, () => {
      if (onChange)
        onChange({
          target: {
            id,
            value: "",
          },
        });

      if (onSubmit) onSubmit("");
    });
  }

  onChange(event) {
    let { onChange, sanitizeRegex, onInvalidCharacter } = this.props;
    let newValue = event.target.value;
    let filtered = "";
    let invalidChars = [];

    if (sanitizeRegex) {
      try {
        for (let i = 0; i < newValue.length; i++) {
          let curChar = newValue.at(i);
          if (curChar.match(sanitizeRegex)) filtered += curChar;
          else invalidChars.push(curChar);
        }
      } catch (error) {
        console.error(error);
      }
    }

    // characters were filtered out from regex
    if (filtered.length < newValue) {
      event.target.value = filtered;

      if (onInvalidCharacter) onInvalidCharacter(invalidChars);

      return;
    }

    this.setState({ value: newValue }, () => {
      if (onChange) onChange(event);
    });
  }

  handleKey(event) {
    if (this.props.disabled) return;

    let submitDisabled = this.props.onSubmit && !this.props.onChange ? _.isEmpty(this.state.value) : false;
    if (submitDisabled) return;

    if (!validKeys.includes(event.key)) return;

    if (this.props.onSubmit) this.props.onSubmit(this.state.value);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.props.onSubmit && !this.props.disabled) this.props.onSubmit(this.state.value);
  }

  render() {
    let { id, emptyText, className, disabled, onChange, onSubmit, clearDisabled, submitIcon, buttonVisible } = this.props;
    let { value } = this.state;
    if (emptyText === undefined || emptyText === null) emptyText = "Search...";

    let classes = "search-bar";
    if (!_.isEmpty(className)) classes += " " + className;
    let noValue = _.isEmpty(value);
    let submitOnly = onSubmit && !onChange;
    let submitDisabled = submitOnly ? noValue : false;

    let submitIconClass = "fas fa-search";
    if (submitIcon) submitIconClass = submitIcon;
    let iconVisible = !isNull(buttonVisible) ? buttonVisible : !submitIcon || (submitIcon && !noValue);

    return (
      <div className={classes}>
        <input placeholder={emptyText} id={id} value={value} onChange={this.onChange} onKeyDown={this.handleKey}></input>

        <div className={`actions col-sm-1 search-ico ${noValue ? "empty" : "non-empty"} ${iconVisible ? "" : "icon-hidden"}`}>
          <div className="ico-body" onClick={this.handleSubmit} disabled={submitDisabled || disabled}>
            <i className={submitIconClass} />
          </div>

          {!noValue && !clearDisabled && (
            <div className="ico-body">
              <i className="clear fas fa-times" onClick={this.onClear} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
