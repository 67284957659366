// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

export const VIEW_REGION = "VIEW_REGION";
export const CLEAR_MAP = "CLEAR_REGION";

export const START_MAP_FETCH = "START_MAP_FETCH";
export const INIT_MAP_FETCH = "INIT_MAP_FETCH";
export const STOP_MAP_FETCH = "STOP_MAP_FETCH";
export const UPDATE_MAP = "UPDATE_MAP";
export const EMPTY_MAP_CHECK = "EMPTY_MAP_CHECK";
export const STORED_MAP = "STORED_MAP";
export const FINALIZE_MAP_FETCH = "FINALIZE_MAP_FETCH";
