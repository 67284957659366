// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import CacheManager from "../../../shared/cache";
import * as MapActions from "../reducers/actions";
import { devConfig } from "../../../shared/constants";

const cacheDisabled = global.gIsProd ? false : devConfig.cacheDisabled.graph;

class MapCache {
  constructor() {
    this.cache = new CacheManager();
    this.map_key = "geomap.map";
    this.cacheTime = 60 * 60 * 1000; // 1 hour
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  fetchRegionMap(lastFetchTime) {
    let response;
    if (global.gFromJSON) {
      if (lastFetchTime !== undefined) return undefined;

      // retrieve data via local file
      console.debug("MapCache::fetchRegionMap per config, getting global dummy data!");
      response = global.gDataJSON.mapData;
      if (response) {
        response = response.map;
      }

      return response;
    } else {
      return undefined;
    }
  }

  async getMap(lastFetchTime) {
    if (lastFetchTime !== undefined) {
      this.fetchRegionMap();
      return;
    }

    console.debug("MapCache::getMap");
    let map = null;
    const now = Date.now();
    let needToRetrieve = true;
    map = await this.cache.readData(this.map_key);
    if (map != null) {
      let lastUpdated = map.updatedAt;
      let isExpired = now - lastUpdated > this.cacheTime;

      if (isExpired) {
        await this.clearMap(false);
        map = null;
        console.debug(`GrapheCache::getMap map conditions failed, isExpired=${isExpired}`);
      }
    } else {
      console.debug("MapCache::map not found!");
      await this.clearMap(false);
    }

    if (needToRetrieve) {
      console.debug("MapCache::getMap nneds to retrieve!");
      let new_map = await this.fetchRegionMap();

      if (new_map === undefined) {
        if (map === null) map = {};
      } else if (map !== null) {
        new_map.updatedAt = now;
        console.debug("MapCache::getMap map updated, new map:", new_map);
        // this.dispatch(MapCache.storedMap())

        if (!cacheDisabled) this.cache.writeData(this.map_key, new_map);
      } else {
        console.debug("MapCache::getMap map not found; potentially invalid. Removing from local storage!");
        await this.clearMap();
      }
    } else {
      console.debug("MapCache::getMap map from cache; finalizing map fetch");
      this.dispatch(MapActions.stopMapFetch());
    }

    return map;
  }
}

const mapCache = new MapCache();
export default mapCache;
