// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withToastManager } from "react-toast-notifications";

import * as GeoMapActions from "../reducers/actions";

import MapArea from "../components/MapArea";
import ControlPanel from "../components/ControlPanel";
import MapLegend from "../components/MapLegend";
import { formatPageTitle } from "../../../shared/functions/formatting";
import "../styles/GeoMap.css";

class GeoMapView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastFetchTime: "",
    };
  }

  render() {
    return (
      <main>
        {formatPageTitle("Geographic Heat Map")}

        <MapArea />
        <ControlPanel />
        <MapLegend />
      </main>
    );
  }
}

// ====== Redux action and property mapping

/**
 * Retrieve information from store to be passed down React hierarchy
 */
const mapState = (state) => {
  const selector = state.geomap.selector;
  let infoGroups = [];

  return {
    selectedItem: selector.selectedItem,
    groups: infoGroups,
    user: state.user,
    last_request: state.geomap.fetch.last_request,
    iteration: state.geomap.fetch.iteration,
    fetcherStatus: state.geomap.fetch.status,
    isEmpty: state.geomap.fetch.isEmpty,
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(GeoMapActions, dispatch),
});

export default connect(mapState, mapDispatch)(withToastManager(GeoMapView));
