// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";

export default class MapLegend extends Component {
  render() {
    const { open } = this.props;

    if (!open) {
      return null;
    }

    return <div className="map-legend-area"></div>;
  }
}
