// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import CacheManager from "../../../shared/cache";
import { MyAPI, jsonpath } from "../../../shared/functions/general";
import { gateways } from "../../../shared/enumerations";
import { devConfig, ServerError } from "../../../shared/constants";
import _ from "lodash";

const cacheDisabled = global.gIsProd ? true : devConfig.cacheDisabled.monitoring;

class MonitorCache {
  constructor() {
    this.cache = new CacheManager();
    this.rootKey = "endpoints";
  }

  async getItem(userId, id) {
    let key = `${this.rootKey}.${id}`;

    let data = null;
    const now = Date.now();
    let needToRetrieve = true;

    let cacheResponse = await this.cache.readData(userId, key);
    if (cacheResponse != null) {
      console.debug("MonitorCache:: get Item: ", cacheResponse);
      let lastUpdated = cacheResponse.updatedAt;
      data = cacheResponse.data;
      needToRetrieve = now - lastUpdated >= this.cacheTime;
    } else {
      console.debug(`MonitorCache:: item with key ${key} not in cache `);
    }

    if (needToRetrieve) {
      let response;
      if (global.gFromJSON) {
        let path = `$..endpoints[?(@.id=='${id}')]`;
        let query_res = jsonpath.query(global.gEndpointMonitoring, path);
        data = query_res[0];
      } else {
        let url = `${gateways.endpointItem.replace(":id", id)}`;
        response = await MyAPI.get(url);

        const body = response.body;
        if (response.statusCode !== 200) throw new ServerError(response);
        data = Object.assign(body);
      }

      if (!cacheDisabled) this.cache.writeData(userId, key, data);
    }

    return {
      displayInfo: data,
      cached: !needToRetrieve,
    };
  }

  /** When an item is updated locally, save its new value to the cache */
  async updateItem(userId, endpointId, expression, pollType) {
    // Item should be in cache since this should only called immediately following a get
    let existingItem = await this.getItem(userId, endpointId);
    let newDisplayInfo = existingItem.displayInfo;

    _.set(newDisplayInfo, "Endpoint Info.Polling Group", expression);
    _.set(newDisplayInfo, "Endpoint Info.Monitoring Type", pollType);

    console.debug("MonitorCache::Updapted info: ", newDisplayInfo);

    return {
      displayInfo: newDisplayInfo,
      cached: existingItem.cached,
    };
  }
}

const monitorCache = new MonitorCache();
export default monitorCache;
