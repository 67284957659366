// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";
import { Navigate } from "react-router-dom";
import queryString from "query-string";

import { pages } from "../enumerations";

/**
 * Redirect user to an authenticated page if user has authenticated, Otherwise pass through
 * @since 0.3.1
 */
const UnauthenticatedRoute = ({ component: C, childProps }) => {
  const qs = queryString.parse(window.location.search);
  if (childProps.isAuthenticated) return <Navigate to={qs.redirect ? qs.redirect : pages.sysmap} replace />;
  return <C {...childProps} />;
};

export default UnauthenticatedRoute;
