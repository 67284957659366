// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
import { DateRangePicker } from "react-date-range";

export default class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);
  }

  onOpen() {
    if (this.state.open) return;
    this.setState({ open: true });
  }

  onClose() {
    if (!this.state.open) return;
    this.setState({ open: false });
  }

  render() {
    const { onChange, endDate, startDate, selTheme } = this.props;
    const openClass = this.state.open ? " open" : " closed";
    return (
      <div className="calendar-select">
        <div tabIndex="0" className="anchor calendar-sel-btn" onClick={this.state.open ? this.onClose : this.onOpen}>
          {this.props.rangeAlias}
          <i className={`fas fa-caret-down${openClass}`} />
        </div>

        <div className={`calendar${openClass}`}>
          <div className="anchor">
            <DateRangePicker
              ranges={[{ startDate, endDate, key: "selection" }]}
              onChange={onChange}
              rangeColors={[selTheme.primary_dark, selTheme.primary]}
              staticRanges={this.props.staticRanges}
            >
              <div className="heading">Date range</div>
            </DateRangePicker>
          </div>
        </div>
      </div>
    );
  }
}
