import React, { Component, Fragment } from "react";
import Tippy from "@tippyjs/react";
import queryString from "query-string";
import Modal from "react-modal";
import { Form, Col, FormGroup, FormControl } from "react-bootstrap";
import _ from "lodash";

import { MyAPI, isNull, requestErr, isEmpty, ValidationRule, ManagementResult } from "../../../shared/functions/general";
import { modalStyle } from "../../../shared/constants";
import {
  AccountProvider,
  AccountProviderNames,
  BillingNames,
  TemplateAvailability,
  TemplateOption,
  TemplateOptionNames,
  TemplateService,
  gateways,
} from "../../../shared/enumerations";
import Checkbox from "../../../shared/components/Checkbox";
import CopyField from "../../../shared/components/CopyField";
import Dropdown, { DropdownOption } from "../../../shared/components/Dropdown";
import LoaderButton from "../../../shared/components/LoaderButton";
import MultivalueDropdown, { MultivalueOption } from "../../../shared/components/MultivalueDropdown";
import WebWorker from "../../../shared/threading/WebWorker";
import Timer from "../../../shared/threading/timer";
import { ContextMenu, MenuItem } from "../../../shared/external/react-contextmenu";
import { handleContextMenuClick } from "../../../shared/functions/ctxMenu";
import "../styles/OrganizationTab.css";

const TOAST_HIDE_DELAY = 4; // value in seconds

const accountRules = {
  unique: ValidationRule(
    `Account must be unique.`,
    (value, ref) => _.filter(ref.props.accounts, (other) => other.id === value.id && other.provider === value.provider).length <= 1,
    "id"
  ),
  nameNotEmpty: ValidationRule(
    `Cannot be empty`,
    (value) => !isEmpty(value.name),
    "name",
    (_, ref) => ref.state.modifyingAccount
  ),
  nameMaxLength: ValidationRule(`Value is too long`, (value) => value.name.length < 255, "name"),
  // numberNotEmpty: ValidationRule(`Cannot be empty.`, (value) => !isEmpty(value.id), "id"),
  typeNotNull: ValidationRule(`Please select a value.`, (value) => !isNull(value.provider), "provider"),
  awsNumeric: ValidationRule(
    `Account must contain only 0-9.`,
    (value) => (value.provider !== AccountProvider.AWS ? true : !isNaN(value.id)),
    "id"
  ),
  awsLength: ValidationRule(
    `AWS Account number must be 12 digits.`,
    (value) => (value.provider !== AccountProvider.AWS ? true : value.id.length === 12),
    "id"
  ),
  // azureGuid: ValidationRule(
  //   `Azure Account number must be a 32-digit UID.`,
  //   (value) => value.provider !== AccountProvider.azure ? true : value.match(/^[A-Za-z0-9]$/) !== null,
  //   "id"
  // ),
};

const orgRules = {
  minLength: ValidationRule("Name cannot be empty.", (value) => value.name.length > 0, "name"),
  maxLength: ValidationRule("Name is too long.", (value) => value.name.length < 255, "name"),
};

// TODO: add other account providers here
const providerOptions = [AccountProvider.AWS, AccountProvider.PREM].map((provider) =>
  DropdownOption(AccountProviderNames[provider], provider)
);

const templateHint = {
  [TemplateOption.ALL]: "All available ingestion options",
  [TemplateOption.DNS]: "DNS Flow logs only",
  [TemplateOption.VPC]: "VPC Flow logs only",
  [TemplateOption.VNET]: "Virtual Network Flow logs only",
  [TemplateOption.NSG]: "Network Security Group logs only",
};

// Static mapping of available providers and templates that can be generated for that provider
const templateOptions = Object.fromEntries(
  Object.values(AccountProvider).map((provider) => {
    let result;

    try {
      let options = TemplateAvailability[provider];
      result = [
        provider,
        options.map((option) => {
          let safeOption = option === TemplateOption.VNET ? TemplateOption.VPC : option;
          return MultivalueOption(TemplateOptionNames[option], safeOption, undefined, _.get(templateHint, option, undefined));
        }),
      ];
    } catch {
      result = [provider, []];
    }

    return result;
  })
);

/** Render form errors generated using validation rules */
function renderErrors(prop, failures, classes) {
  if (!(prop in failures)) return null;

  let validationErrors = Object.values(failures[prop]).filter((failure) => !isNull(failure) && failure.length > 0);
  if (validationErrors.length === 0) return null;

  return (
    <div className={`error-area ${classes ? classes : ""}`}>
      {validationErrors.map((error) => (
        <li>{error}</li>
      ))}
    </div>
  );
}

class OrganizationTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Whether fetch was initiated by user
      refreshing: false,

      // waiting for response for fetching account info
      accountListLoading: false,
      // server responded with failure for fetching account info
      accountListFailed: false,
      // waiting for response for updating account info
      accountListSaving: false,
      // waiting for response from server for org info
      orgInfoLoading: false,
      // server responded with failure for fetching org info
      orgInfoFailed: false,
      // waiting for response for updating org info
      orgInfoSaving: false,
      // flag to show toast for saving org info or account info
      modificationSaveSuccess: 0,

      // link to marketplace to manage subscription to product
      marketplaceLink: null,

      // changes have been made to org info
      modifyingOrg: false,
      // the org info element currently be updated by user (focus / cursor)
      hoverElement: null,
      // the new value for org
      newOrgName: "",
      // errors produced by input validation (only org name)
      orgNameValidationErrors: [],

      // Modifying a selected account
      modifyingAccount: false,
      // vaildation failures for a adding/modifying account
      newAccountFails: {},
      // Value to assign new account name
      newAccountName: "",
      // Value to assign new account Cloud Provider
      newAccountProvider: null,
      // Cannot be modified
      newAccountId: "",
      // Whether Account Modal is showing
      accountModalOpen: false,
      // Whether modal for removing accounts is opened
      accountRemoveModalOpen: false,
      accountDeleteVerification: "",
      resultsModalOpen: false,
      // currently selected accounts
      selection: {},
      // The item to delete, triggered via CTX menu
      deleteSingleAccount: null,

      // Waiting for response for fetching template URL
      templateLoading: false,
      // Provider of requested deployment template
      templateProvider: null,
      // Deployment template type for changing data ingest options
      templateTypes: [],
      // Whether modal for downloading templates is opened
      templateModalOpen: false,
      templateResultModalOpen: false,
    };

    this.toastTimer = null;

    this.commitAddAccount = this.commitAddAccount.bind(this);
    this.commitUpdateAccount = this.commitUpdateAccount.bind(this);
    this.commitRemoveAccount = this.commitRemoveAccount.bind(this);
    this.commitUpdateOrganization = this.commitUpdateOrganization.bind(this);
    this.commitGenerateTemplate = this.commitGenerateTemplate.bind(this);
    this.openAddAccountModal = this.openAddAccountModal.bind(this);
    this.openRemoveAccountModal = this.openRemoveAccountModal.bind(this);
    this.openTemplateModal = this.openTemplateModal.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.fetchTemplate = this.fetchTemplate.bind(this);
    this.retryTemplate = this.retryTemplate.bind(this);
    this.onContextClick = this.onContextClick.bind(this);
    this.onHoverElement = this.onHoverElement.bind(this);
    this.onLeaveElement = this.onLeaveElement.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderItemContextMenu = this.renderItemContextMenu.bind(this);
    this.renderAddAccountModal = this.renderAddAccountModal.bind(this);
    this.renderDeleteAccountModal = this.renderDeleteAccountModal.bind(this);
    this.renderDeleteResultsModal = this.renderDeleteResultsModal.bind(this);
    this.renderTemplateModal = this.renderTemplateModal.bind(this);
    this.renderTemplateError = this.renderTemplateError.bind(this);
    this.renderTemplateResultsModal = this.renderTemplateResultsModal.bind(this);
    this.killToastTimer = this.killToastTimer.bind(this);
    this.toastTimerCallback = this.toastTimerCallback.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.killToastTimer();
  }

  fetchData(refresh = false) {
    const { organization, accounts, actions } = this.props;
    const newState = { refreshing: refresh };

    if (!organization || refresh) {
      newState.orgInfoLoading = true;
      newState.orgInfoFailed = false;
      MyAPI.get(gateways.orgItem)
        .then((response) => {
          console.debug("OrganizationTab::Org GET response:", response);

          if (response.statusCode !== 200) throw new Error(response.errorMessage);

          let marketplaceLink;
          switch (response.body.billing_type) {
            case AccountProvider.AWS:
              marketplaceLink = "https://us-east-1.console.aws.amazon.com/marketplace/home?region=us-east-1#/subscriptions";
              break;
            default:
              marketplaceLink = "";
          }

          this.setState({
            orgInfoFailed: false,
            orgInfoLoading: false,
            newOrgName: response.body.name,
            marketplaceLink,
          });
          actions.setOrganization(response.body);
        })
        .catch((error) => {
          this.setState({ orgInfoFailed: true, orgInfoLoading: false });
          console.error(error);
        });
    }

    if (_.get(accounts, "length", 0) <= 0 || refresh) {
      newState.accountListLoading = true;
      newState.accountListFailed = false;
      // actions.initManagementOperation({});
      MyAPI.get(gateways.accounts)
        .then((response) => {
          console.debug("OrganizationTab::Account list GET response:", response);
          if (response.statusCode !== 200) throw new Error(response.errorMessage);

          this.setState({
            accountListFailed: false,
            accountListLoading: false,
            refreshing: false,
          });
          actions.setAccounts(response.body.accounts);
        })
        .catch((error) => {
          this.setState({ accountListFailed: true, accountListLoading: false, refreshing: false });
          console.error(error);
        });
    }

    if (Object.keys(newState).length > 0) this.setState(newState);
  }

  fetchTemplate(templateType) {
    const { templateProvider } = this.state;
    const { actions, templateUrls } = this.props;

    if (
      templateProvider in templateUrls &&
      templateType in templateUrls[templateProvider] &&
      !isEmpty(templateUrls[templateProvider][templateType])
    ) {
      // template was already generated by user and saved in Redux store; no fetch needed
      console.debug("OrganizationTab::Url for ", templateType, " already generated.");
      actions.setTemplateUrl(templateType, templateUrls[templateProvider][templateType]);
    } else {
      let qs = { provider: templateProvider, type: templateType };
      let query = queryString.stringify(qs);
      let url = `${gateways.template}?${query}`;
      console.debug("OrganizationTab::Starting template generation. URL:", url);
      MyAPI.get(url)
        .then((response) => {
          console.debug("OrganizationTab::Template GET response:", response);
          if (response.statusCode !== 200) {
            console.error(response.errorMessage);
            throw new Error(response.statusCode);
          }

          actions.setTemplateUrl(templateType, templateProvider, response.body.customer_template_url);
        })
        .catch((error) => {
          actions.failAccountOperation(templateType, error);
        });
    }
  }

  killToastTimer() {
    if (isNull(this.toastTimer)) return;
    console.debug("OrganizationTab::toast timer killed");
    this.toastTimer.terminate();
    delete this.toastTimer;
  }

  toastTimerCallback() {
    this.killToastTimer();
    this.setState({ modificationSaveSuccess: false });
  }

  closeModal(event) {
    this.setState({ [event.target.id]: false });
  }

  handleChange(event) {
    const newState = {
      [event.target.id]: event.target.value,
    };

    if (["newOrgName"].includes(event.target.id))
      newState.modifyingOrg = event.target.value !== _.get(this.props.organization, "name");

    this.setState(newState);
  }

  onHoverElement(event) {
    this.setState({ hoverElement: event.target.id });
  }

  onLeaveElement() {
    this.setState({ hoverElement: null });
  }

  onSelect(account) {
    const selection = _.cloneDeep(this.state.selection);
    if (account.id in selection) delete selection[account.id];
    else selection[account.id] = account;
    this.setState({ selection });
  }

  onSelectAll() {
    const { accounts } = this.props;
    let newSelection = {};
    if (Object.keys(this.state.selection).length < accounts.length)
      accounts.forEach((account) => (newSelection[account.id] = account));

    this.setState({ selection: newSelection });
  }

  onContextClick(event, item, isNet) {
    this.setState({ ctxItem: { item, isNet } }, () => {
      const id = `ctx-${item.id}`;
      event.target.id = id;
      handleContextMenuClick(event, id, this.props);
    });
  }

  openAddAccountModal(_, account) {
    if (this.state.accountModalOpen) return;

    if (!isNull(account))
      this.setState({
        accountModalOpen: true,
        modifyingAccount: true,
        newAccountId: account.id,
        newAccountName: account.name,
        newAccountProvider: account.provider,
        newAccountFails: {},
      });
    else
      this.setState({
        accountModalOpen: true,
        newAccountId: "",
        newAccountName: "",
        newAccountProvider: null,
        newAccountFails: {},
      });
  }

  openRemoveAccountModal(_, account) {
    if (this.state.accountRemoveModalOpen) return;
    if (account === undefined) account = null;
    this.setState({
      accountDeleteVerification: "",
      accountRemoveModalOpen: true,
      deleteSingleAccount: account,
    });
  }

  openTemplateModal(event) {
    if (this.state.templateModalOpen) return;
    const provider = event.target.id.replace("template-", "");

    this.setState({
      templateModalOpen: true,
      templateProvider: provider,
      templateTypes: [],
    });
  }

  onRefresh() {
    if (this.state.refreshing) return;
    this.fetchData(true);
  }

  retryTemplate(event) {
    const templateType = event.target.id.replace("retry-", "");
    const newResults = _.cloneDeep(this.props.results);
    newResults[templateType] = _.merge(newResults[templateType], { active: true, failed: false });

    this.props.actions.initTemplateGeneration(newResults, this.state.templateProvider);
    this.fetchTemplate(templateType);
  }

  validateAddAccount() {
    const failures = {};
    let failCount = 0;
    const value = {
      id: this.state.newAccountId,
      provider: this.state.newAccountProvider,
      name: this.state.newAccountName,
    };

    Object.entries(accountRules).forEach(([key, rule]) => {
      let propertyFailures = failures[rule.prop];
      if (!propertyFailures) propertyFailures = {};

      let conditionMet = rule.conditional ? rule.conditional(value, this) : true;
      if (conditionMet) {
        propertyFailures[key] = !rule.resolver(value, this) ? rule.msg : "";
        failCount += propertyFailures[key].length;
        failures[rule.prop] = propertyFailures;
      }
    });

    return { failures, failed: failCount > 0 };
  }

  validateDeleteAccount() {
    return this.state.accountDeleteVerification === "delete";
  }

  validateOrganization() {
    const failures = [];
    const newOrg = { name: this.state.newOrgName };

    Object.entries(orgRules).forEach(([_, rule]) => {
      if (!rule.resolver(newOrg)) failures.push(rule.msg);
    });

    return failures;
  }

  async commitAddAccount() {
    const { failures, failed } = this.validateAddAccount();
    if (failed) {
      this.setState({ newAccountFails: failures });
      return;
    }

    this.setState({ accountListSaving: true, newAccountFails: {} });
    const newAccount = {
      name: this.state.newAccountName,
      provider: this.state.newAccountProvider,
    };

    if (newAccount.provider !== AccountProvider.PREM) newAccount.id = this.state.newAccountId;

    try {
      const response = await MyAPI.post(gateways.accounts, newAccount);

      console.debug("OrganizationTab::Account POST response:", response);
      if (response.statusCode !== 200) throw new Error(response.errorMessage);

      if (response.body.id) newAccount.id = response.body.id;
      if (isEmpty(newAccount.name)) newAccount.name = newAccount.id;

      this.props.actions.addAccount(newAccount);
      this.setState({ accountListSaving: false, accountModalOpen: false });
    } catch (error) {
      this.setState({ accountListSaving: false });
      console.error(error);
      this.props.toastManager.add(requestErr(error), {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: global.gToastTimeout,
      });
    }
  }

  async commitUpdateAccount() {
    // TODO: cannot apply if refreshing
    this.setState({ accountListSaving: true });
    const newAccount = {
      id: this.state.newAccountId,
      name: this.state.newAccountName,
      provider: this.state.newAccountProvider,
    };

    try {
      const response = await MyAPI.patch(gateways.accountItem.replace(":id", newAccount.id), { name: newAccount.name });

      console.debug("OrganizationTab::Account PATCH response:", response);
      if (response.statusCode !== 200) throw new Error(response.errorMessage);

      this.setState({ accountListSaving: false, accountModalOpen: false, modificationSaveSuccess: true });
      this.props.actions.updateAccount(newAccount);
    } catch (error) {
      this.setState({ accountListSaving: false });
      console.error(error);
      this.props.toastManager.add(requestErr(error), {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: global.gToastTimeout,
      });
    }
  }

  async commitRemoveAccount() {
    const { selection, deleteSingleAccount } = this.state;
    const { actions } = this.props;

    const accountList = !isNull(deleteSingleAccount) ? [deleteSingleAccount] : Object.values(selection);

    const results = {};
    accountList.forEach((account) => (results[account.id] = ManagementResult(isEmpty(account.name) ? account.id : account.name)));

    actions.initManagementOperation(results);

    const newState = { resultsModalOpen: true, accountRemoveModalOpen: false };
    if (!deleteSingleAccount) newState.selection = {};
    this.setState(newState);

    accountList.forEach((account) => {
      let url = gateways.accountItem.replace(":id", account.id);
      MyAPI.delete(url)
        .then((response) => {
          console.debug("Account DELETE response: ", response);
          if (response.statusCode === 200) actions.removeAccount(account.id);
          else actions.failAccountOperation(account.id);
        })
        .catch((error) => {
          console.error(error);
          actions.failAccountOperation(account.id);
        });
    });
  }

  async commitUpdateOrganization() {
    // TODO: cannot apply if refreshing

    if (this.state.orgInfoLoading || !this.state.modifyingOrg) return;
    let validationErrors = this.validateOrganization();
    if (validationErrors.length !== 0) {
      this.setState({ orgNameValidationErrors: validationErrors });
      return;
    }

    this.setState({ orgInfoSaving: true, orgNameValidationErrors: [] });
    const newOrganization = {
      name: this.state.newOrgName,
    };

    try {
      const response = await MyAPI.patch(gateways.orgItem, {
        name: newOrganization.name,
      });

      console.debug("OrganizationTab::Org PATCH response:", response);
      if (response.statusCode !== 200) throw new Error(response.errorMessage);

      this.setState({ orgInfoSaving: false, modifyingOrg: false, modificationSaveSuccess: true });
      this.props.actions.updateOrganization(newOrganization);

      this.killToastTimer();
      this.toastTimer = new WebWorker(Timer);
      this.toastTimer.addEventListener("message", this.toastTimerCallback, false);
      this.toastTimer.postMessage([TOAST_HIDE_DELAY]);
    } catch (error) {
      this.setState({ orgInfoSaving: false });
      console.error(error);
      this.props.toastManager.add(requestErr(error), {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: global.gToastTimeout,
      });
    }
  }

  async commitGenerateTemplate() {
    if (this.state.templateLoading) return;

    const { templateTypes } = this.state;
    const { actions } = this.props;
    this.setState({ templateModalOpen: false, templateResultModalOpen: true });

    const results = {};
    templateTypes.forEach((type) => (results[type] = ManagementResult(TemplateOptionNames[type])));
    actions.initTemplateGeneration(results, this.state.templateProvider);

    templateTypes.forEach(this.fetchTemplate);
  }

  renderAddAccountModal() {
    const { modifyingAccount, newAccountId, newAccountName, newAccountProvider, newAccountFails: failures } = this.state;
    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];

    return (
      <Modal isOpen={this.state.accountModalOpen} style={style}>
        <div className={`modal-pnl account-modal${modifyingAccount ? " modify-modal" : ""}`}>
          <div className="header">{modifyingAccount ? "Modify" : "Add"} Account</div>

          {!modifyingAccount && (
            <span className="info">Name is optional. If not provided, it will be assigned the account ID.</span>
          )}

          <Form horizontal="true" className="modal-form">
            {newAccountProvider !== AccountProvider.PREM && (
              <Fragment>
                <FormGroup controlId="newAccountId" className="modal-input">
                  <Col sm={4}>Account ID:</Col>
                  <Col sm={10}>
                    <FormControl
                      autoFocus
                      type="text"
                      value={newAccountId}
                      onChange={this.handleChange}
                      placeholder="ID"
                      disabled={modifyingAccount}
                    />
                  </Col>
                </FormGroup>

                {renderErrors("id", failures)}
              </Fragment>
            )}

            <FormGroup controlId="newAccountName" className={`modal-input${modifyingAccount ? " offset" : ""}`}>
              <Col sm={4}>Name:</Col>
              <Col sm={10}>
                <FormControl type="text" value={newAccountName} onChange={this.handleChange} placeholder="New Account" />
              </Col>
            </FormGroup>
            {renderErrors("name", failures)}

            <FormGroup controlId="newAccountProvider" style={{ display: "flex", alignItems: "center" }}>
              <Col componentclass="FormLabel" sm={4}>
                Provider:
              </Col>
              <Col sm={10}>
                {modifyingAccount ? (
                  AccountProviderNames[newAccountProvider]
                ) : (
                  <Fragment>
                    <FormControl
                      className="hidden"
                      value={newAccountProvider === null ? "" : newAccountProvider}
                      onChange={this.handleChange}
                    />
                    <Dropdown
                      id="newAccountProvider"
                      options={providerOptions}
                      onChange={this.handleChange}
                      value={newAccountProvider}
                    />
                    {renderErrors("provider", failures, "drodown-error")}
                  </Fragment>
                )}
              </Col>
            </FormGroup>
          </Form>

          <div className="footer">
            <div className="actions center">
              <div disabled={this.state.accountListSaving} className="btn quart" id="accountModalOpen" onClick={this.closeModal}>
                Cancel
              </div>
              <LoaderButton
                type="submit"
                text={modifyingAccount ? "Update" : "Create"}
                loadingText={modifyingAccount ? "Updating..." : "Creating..."}
                isLoading={this.state.accountListSaving}
                onClick={modifyingAccount ? this.commitUpdateAccount : this.commitAddAccount}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderDeleteAccountModal() {
    const { theme } = this.props;
    const { accountListLoading } = this.state;
    const valid = this.validateDeleteAccount();

    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = theme[modalStyle.content.backgroundColor];

    const commitButton = (
      <div className="btn" disabled={!valid || accountListLoading} type="submit" onClick={this.commitRemoveAccount}>
        Remove
      </div>
    );

    return (
      <Modal isOpen={this.state.accountRemoveModalOpen} style={style}>
        <div className="modal-pnl">
          <div className="header">Delete Confirmation</div>

          <span className="info">
            Are you sure you would like to delete the selected account(s)? Removing an account will prevent any new data from
            getting collected.
          </span>

          <span className="info">Please enter 'delete' below to confirm.</span>
          <Form>
            <FormGroup controlId="accountDeleteVerification">
              <FormControl
                autoFocus
                type="text"
                value={this.state.accountDeleteVerification}
                onChange={this.handleChange}
                placeholder="delete"
              />
            </FormGroup>
          </Form>

          <div className="footer">
            <div className="actions center">
              <div className="btn quart" id="accountRemoveModalOpen" onClick={this.closeModal}>
                Cancel
              </div>
              {accountListLoading ? (
                <Tippy
                  content="Please wait while accounts are loaded in."
                  animation="scale-subtle"
                  theme="material"
                  duration={global.gTTPDur}
                  delay={[global.gTTPShow, 0]}
                >
                  <div>{commitButton}</div>
                </Tippy>
              ) : (
                commitButton
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderDeleteResultsModal() {
    const { resultsModalOpen } = this.state;
    const { results, theme, operationActive } = this.props;

    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = theme[modalStyle.content.backgroundColor];

    return (
      <Modal isOpen={resultsModalOpen} style={style}>
        <div className="modal-pnl user-modal">
          <div className="header">Deleting Accounts</div>

          <Form horizontal="true" className="modal-form">
            {Object.entries(results).map(([key, result]) => {
              let resultClass = "fas fa-ellipsis-h";
              let backingClass = "backing far fa-circle";
              if (result.failed) {
                resultClass = "fas fa-times error";
                backingClass += " error";
              } else if (!result.active) {
                resultClass = "fas fa-check success";
                backingClass += " success";
              }

              return (
                <div className="result-entry" key={key}>
                  <div className="indicator">
                    <i className={backingClass} />
                    <i className={`icon ${resultClass}`} />
                  </div>
                  <span>{result.text}</span>
                </div>
              );
            })}
          </Form>

          <div className="footer">
            <div className="actions center">
              <LoaderButton
                disabled={operationActive}
                type="submit"
                id="resultsModalOpen"
                text="Close"
                className="quart"
                loadingText="Deleting..."
                isLoading={operationActive}
                onClick={this.closeModal}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderTemplateModal() {
    const style = _.cloneDeep(modalStyle);
    const { templateProvider, templateTypes } = this.state;
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];

    return (
      <Modal isOpen={this.state.templateModalOpen} style={style}>
        <div className="modal-pnl">
          <div className="header">Download {TemplateService[templateProvider]} Template</div>

          <span className="info">
            Generate one or more templates for the selected cloud provider to begin ingesting data into Observatory™.
          </span>

          <Form horizontal="true" className="modal-form">
            <FormGroup className="modal-input">
              <Col sm={4}>Templates:</Col>
              <Col sm={10}>
                <MultivalueDropdown
                  id="templateTypes"
                  value={templateTypes}
                  onChange={this.handleChange}
                  options={_.get(templateOptions, templateProvider, [
                    MultivalueOption("<i>No options available</i>", "none", false),
                  ])}
                />
              </Col>
            </FormGroup>
          </Form>

          <div className="footer">
            <div className="actions center">
              <div className="btn quart" id="templateModalOpen" onClick={this.closeModal}>
                Cancel
              </div>

              <div className="btn" disabled={templateTypes.length === 0} onClick={this.commitGenerateTemplate}>
                Generate
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderTemplateResultsModal() {
    const { results, theme, operationActive, templateUrls } = this.props;

    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = theme[modalStyle.content.backgroundColor];

    return (
      <Modal isOpen={this.state.templateResultModalOpen} style={style}>
        <div className="modal-pnl user-modal template-modal">
          <div className="header">Download {TemplateService[this.state.templateProvider]} Template</div>

          <Form horizontal="true" className="modal-form">
            {Object.entries(results).map(([templateType, result]) => {
              let url = _.get(templateUrls, `${this.state.templateProvider}.${templateType}`, undefined);

              return (
                <FormGroup key={templateType} className="modal-input result-entry">
                  <Col sm={9}>{result.text}</Col>
                  <Col className="template-url" sm={5}>
                    {result.active ? (
                      <Fragment>
                        <LoaderButton
                          disabled={result.active}
                          type="submit"
                          id="templateResultModalOpen"
                          text="Download"
                          className="quart"
                          loadingText="Loading..."
                          isLoading={result.active}
                        />
                        <i className="fas fa-times generate-spin" />
                      </Fragment>
                    ) : result.failed ? (
                      this.renderTemplateError(templateType, result)
                    ) : (
                      <Fragment>
                        <a className="btn" href={url}>
                          Download
                        </a>
                        <i className="fas fa-times generate-spin" />
                      </Fragment>
                    )}
                  </Col>
                </FormGroup>
              );
            })}
          </Form>

          <div className="footer">
            <div className="actions center">
              <div id="templateResultModalOpen" className="btn" onClick={this.closeModal}>
                {operationActive ? "Cancel" : "Close"}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderTemplateError(templateType, result) {
    let btnText = "Failed";
    let onClick = null;

    if (result.failed === 400) {
      btnText = "Retry";
      onClick = this.retryTemplate;
    } else if ([401, 403].includes(result.failed)) btnText = "Forbidden";

    return (
      <Fragment>
        <div id={`retry-${templateType}`} className="btn error" onClick={onClick} disabled={onClick === null}>
          {btnText}
        </div>
        <i className="fas fa-times error" />
      </Fragment>
    );
  }

  renderItemContextMenu(account) {
    const hoverEvent = (event) => event.preventDefault();

    return (
      <ContextMenu id={`ctx-${account.id}`}>
        <MenuItem
          tabIndex="0"
          onMouseMove={hoverEvent}
          onClick={() => {
            this.openAddAccountModal(account);
          }}
        >
          <i className="fas fa-edit" /> Modify
        </MenuItem>

        <MenuItem
          onClick={(event) => {
            this.openRemoveAccountModal(event, account);
          }}
          onMouseMove={hoverEvent}
          tabIndex="0"
        >
          <i className="fas fa-trash" />
          Remove
        </MenuItem>
      </ContextMenu>
    );
  }

  render() {
    let { accounts, organization } = this.props;
    const { selection, accountListLoading, accountListFailed, orgInfoLoading, orgInfoSaving, newOrgName } = this.state;

    if (isNull(organization)) organization = {};
    const orgNameErrors =
      this.state.orgNameValidationErrors.length > 0 ? <div className="error">{this.state.orgNameValidationErrors[0]}</div> : null;

    return (
      <Fragment>
        {this.renderAddAccountModal()}
        {this.renderDeleteAccountModal()}
        {this.renderDeleteResultsModal()}
        {this.renderTemplateModal()}
        {this.renderTemplateResultsModal()}

        <div className="title card">
          Organization Info
          <div className="help right">
            <div className={`success-toast${this.state.modificationSaveSuccess ? " shown" : ""}`}>Success!</div>

            {this.state.modifyingOrg && (
              <Fragment>
                {!orgInfoSaving && <div className="warning">Changes not saved</div>}

                <LoaderButton
                  text="Save"
                  loadingText="Saving..."
                  isLoading={orgInfoSaving}
                  onClick={this.commitUpdateOrganization}
                />
              </Fragment>
            )}

            {![AccountProvider.PREM, null, undefined].includes(organization.billing_type) && (
              <a className="btn" href={this.state.marketplaceLink} disabled={orgInfoLoading}>
                Manage Subscription
                <i className="fas fa-external-link-alt" />
              </a>
            )}
          </div>
        </div>

        <div className="org-info">
          <div className="subsection general">
            <div className="subheading">General</div>

            <div className="field">
              <label>Organization Name</label>
              <div className="value">
                {orgInfoLoading ? (
                  "..."
                ) : (
                  <Fragment>
                    {this.state.hoverElement === "newOrgName" ? (
                      <input
                        id="newOrgName"
                        value={newOrgName}
                        onChange={this.handleChange}
                        onMouseLeave={this.onLeaveElement}
                        placeholder="Enter text..."
                      >
                        {orgNameErrors}
                      </input>
                    ) : (
                      <div
                        id="newOrgName"
                        className={`input${newOrgName.length === 0 ? " empty" : ""}`}
                        onMouseEnter={this.onHoverElement}
                      >
                        {newOrgName.length === 0 ? "Enter text..." : newOrgName}
                        {orgNameErrors}
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </div>

            <div className="field">
              <label>Organization Key</label>
              <div className="value">{orgInfoLoading ? "..." : <CopyField value={organization.org_key} />}</div>
            </div>

            <div className="field">
              <label>Customer ID</label>
              <div className="value">{orgInfoLoading ? "..." : <CopyField value={organization.customer_id} />}</div>
            </div>

            <div className="field">
              <label>Billing Service</label>
              <div className="value">{orgInfoLoading ? "..." : BillingNames[organization.billing_type]}</div>
            </div>
          </div>

          <div className="subsection templates">
            <div className="subheading">Deployment Templates</div>

            <div className="field">
              <label>{TemplateService[AccountProvider.AWS]}</label>
              <div className="value">
                <div className="btn" id={`template-${AccountProvider.AWS}`} onClick={this.openTemplateModal}>
                  Download
                </div>
              </div>
            </div>

            <div className="field">
              <label>{TemplateService[AccountProvider.AZURE]}</label>
              <Tippy
                content="Azure is not yet available."
                animation="scale-subtle"
                theme="material"
                placement="top-end"
                duration={global.gTTPDur}
                delay={[global.gTTPShow, 0]}
              >
                <div className="value">
                  <div className="btn" id={`template-${AccountProvider.AZURE}`} disabled onClick={this.openTemplateModal}>
                    Download
                  </div>
                </div>
              </Tippy>
            </div>

            {/* <div className="field">
              <label>{TemplateService[AccountProvider.GCP]}</label>
              <div className="value">
                <div className="btn" id={`template-${AccountProvider.GCP}`} onClick={this.openTemplateModal}>
                  Download
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="card account-list" style={{ flexGrow: "2" }}>
          <div className="heading">
            <div className="header">Accounts</div>
            <div className="actions right">
              <Tippy
                content="Refresh list"
                animation="scale-subtle"
                theme="material"
                duration={global.gTTPDur}
                delay={[global.gTTPShow, 0]}
              >
                <div tabIndex="0" className="btn" style={{ backgroundColor: "transparent" }} onClick={this.onRefresh}>
                  <i className={`fas fa-sync-alt${accountListLoading ? " spin" : ""}`} />
                </div>
              </Tippy>

              <Tippy
                content="Configure new Account"
                animation="scale-subtle"
                theme="material"
                duration={global.gTTPDur}
                delay={[global.gTTPShow, 0]}
              >
                <div tabIndex="0" className="btn" onClick={this.openAddAccountModal}>
                  Add
                </div>
              </Tippy>

              <Tippy
                content={Object.keys(selection).length === 0 ? "Nothing is selected" : "Remove selected items"}
                animation="scale-subtle"
                theme="material"
                duration={global.gTTPDur}
                delay={[global.gTTPShow, 0]}
              >
                <div>
                  <div
                    tabIndex="0"
                    className="btn"
                    onClick={this.openRemoveAccountModal}
                    disabled={Object.keys(selection).length === 0}
                  >
                    Remove
                  </div>
                </div>
              </Tippy>
            </div>
          </div>

          <div className="body">
            {accountListLoading && !this.state.refreshing ? (
              <div className="jumbo">
                <div>Loading...</div>
                <div className="loading">
                  <i className="spin fas fa-sync-alt"></i>
                </div>
              </div>
            ) : (
              <Fragment>
                {accountListFailed ? (
                  <div className="jumbo error">
                    <div style={{ fontWeight: "600" }}>Error loading Accounts.</div>
                    <div className="btn" onClick={this.refresh}>
                      Retry
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    {accounts.length === 0 ? (
                      <div className="dash-table-empty">
                        <i>There are no accounts in your Organization.</i>
                      </div>
                    ) : (
                      <table className="dash-table">
                        <thead>
                          <tr>
                            <th width="20px">
                              <Checkbox
                                checked={accounts.length === 0 ? false : Object.keys(selection).length === accounts.length}
                                onChange={this.onSelectAll}
                              />
                            </th>
                            {/* <th width="20px"/> */}
                            <th width="30%">Name</th>
                            <th width="25%">Account ID</th>
                            <th width="25%">Provider</th>
                          </tr>
                        </thead>
                        <tbody>
                          {accounts.map((account) => (
                            <ListItem
                              account={account}
                              key={account.id}
                              selection={selection}
                              onSelect={this.onSelect}
                              onContextClick={this.onContextClick}
                              renderItemContextMenu={this.renderItemContextMenu}
                            />
                          ))}
                        </tbody>
                      </table>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

class ListItem extends Component {
  constructor(props) {
    super(props);

    this.onContextClick = this.onContextClick.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onContextClick(event) {
    const { onContextClick, account } = this.props;
    event.persist();
    onContextClick(event, account);
  }

  onSelect() {
    this.props.onSelect(this.props.account);
  }

  render() {
    const { account, selection, renderItemContextMenu } = this.props;
    const isSelected = account.id in selection;

    return (
      <Fragment>
        <tr id={`ctx-${account.id}`} onContextMenu={this.onContextClick}>
          <td>
            <Checkbox checked={isSelected} onChange={this.onSelect} />
          </td>
          <td>{isEmpty(account.name) ? <span style={{ fontStyle: "italic" }}>Unnamed</span> : account.name}</td>
          <td>{account.id}</td>
          <td className="ctx-ind">
            {AccountProviderNames[account.provider]}

            <div tabIndex="0" className="ctx-menu-btn" onClick={this.onContextClick}>
              <i className="fas fa-ellipsis-v" />
            </div>

            {renderItemContextMenu(account)}
          </td>
        </tr>
      </Fragment>
    );
  }
}

export default OrganizationTab;
