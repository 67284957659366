// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import * as actions from "./actionTypes";
import { initialState } from "../../../shared/constants";
import { listToObj } from "../../../shared/functions/general";

/**
 * @since 0.10.1
 * @param {*} state
 * @param {*} action
 */
export default function fetchReducer(state = initialState.threats, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case actions.SET_THREATS_LIST:
      let internal = action.internal.map((node) => {
        node.isInternal = true;
        return node;
      });

      newState.internal = listToObj(internal, "uid");
      newState.external = listToObj(action.external, "uid");

      newState.risky_addrs = action.risky_addrs;
      newState.total_addrs = action.total_addrs;

      if (action.callback) action.callback();
      return newState;
    default:
      return state;
  }
}
