// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import * as actions from "./actionTypes";
import { initialState } from "../../../shared/constants";
import _ from "lodash";

/**
 * Handles actions regarding the change of selected items
 * @since 0.2.2
 * @param {*} state
 * @param {*} action
 */
export default function selectorReducer(state = initialState.sysmap.selector, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case actions.VIEW_NETWORK:
      newState.selectedNetwork = action.network;
      newState.expandedItems = [];

      let hierarchy = [];
      if (!_.isEmpty(action.network)) {
        hierarchy = [{ name: "...", id: null }];
        if (action.network.name) hierarchy.push({ name: action.network.name, id: action.network.id });
        else hierarchy[0].name = <i>Loading...</i>;
      }

      newState.hierarchy = hierarchy;
      return newState;
    case actions.INSPECT_ITEM:
      newState.selectedItem = {
        data: action.data,
        isNode: action.isNode,
        source: action.source,
      };
      return newState;
    case actions.EXPAND_ITEM:
      newState.expandedItems = state.expandedItems.concat(action.network);
      return newState;
    case actions.INIT_NET_FETCH:
      newState.selectedNetwork.display_info = action.display_info;
      newState.hierarchy = action.hierarchy;
      return newState;
    case actions.UPDATE_HIERARCHY:
      newState.hierarchy = action.hierarchy;
      return newState;
    case actions.STORED_GRAPH:
      newState.current_graph_network_id = action.network_id;
      return newState;
    case actions.SET_FILTER:
      newState.filter = action.filter;
      return newState;
    default:
      return state;
  }
}
