// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

/** Names of cookies used by the site */
export const site_cookies = {
  // Temporary cookie used for recovering from a signup failure
  newUser: "new_user",
  local: "Local storage",
  curUser: "current_user",
  // Cache the accounts to allow user to filter on existing faster
  accounts: "org_accounts",
  session: "session",
  graph_prefs: "graph_prefs",
  graph_opts: "graph_opts",
  graph_filter: "graph_filter",
};

export const pages = {
  landing: "/",
  login: "/login",
  reset: "/login/reset",
  signup: "/signup",
  sysmap: "/systemmap",
  geomap: "/geomap",
  threats: "/threats",
  monitor: "/monitoring",
  configuration: "/configuration",
  billing: "/billing",
  overview: "/overview",
  registration: "/subscribe",
};

/** Names of defined API gateway resources */
export const gateways = {
  checkKey: "/check-key",
  registration: "/subscribe",

  orgItem: "/org",
  consoleData: "/org/billing",
  reports: "/org/report",
  threats: "/org/threats",
  template: "/org/template",
  accounts: "/accounts",
  accountItem: "/accounts/:id",
  users: "/users",
  userItem: "/users/:id",
  userResend: "/users/:id/resend",
  endpoints: "/endpoints",
  endpointItem: "/endpoints/:id",
  network: "/networks",
  networkItem: "/networks/:id",
  subnet: "/subnets",
  subnetItem: "/subnets/:id",
  addressItem: "/addresses/:id",
  sessionItem: "/sessions/:id",
  hostItem: "/hosts/:id",
  netFetch: "/system-map/:id",
  riskItem: "/risk/:id",
};

export const OpStatus = {
  exists: 1,
  bad_ip: 2,
  error: 3,
  success: 4,
  fail: 5,
  invalid: 6,
  not_found: 7,
};

export const FetchStatus = {
  ACTIVE: 1,
  INACTIVE: 0,
  FAILED: -1,
  NOT_FOUND: -2,
};

export const ClientTheme = {
  1: "Night",
  2: "Light",

  // Color blind themes
  3: "Deuteranopia",
  4: "Tritanopia",
  5: "Monochromacy",
};

export const MinAlertLevel = {
  1: "High",
  2: "Medium",
  3: "Low",
};

export const ReportingSchedule = {
  1: "Never",
  2: "Weekly",
  3: "Daily",
};

export const UserStatus = {
  unconfirmed: "1",
  confirmed: "2",
};

export const UserGroups = {
  Admin: "0",
  Operator: "1",
  Ungrouped: "2",
};

export const UserGroupNames = {
  [UserGroups.Admin]: "Admin",
  [UserGroups.Operator]: "Operator",
  [UserGroups.Ungrouped]: "Ungrouped",
};

export const AccountProvider = {
  AWS: "aws",
  AZURE: "az",
  PREM: "op",
  // GCP: "gcp"
};

/** Human readable distinguisher for billing source */
export const BillingNames = {
  [AccountProvider.AWS]: "AWS Marketplace",
  [AccountProvider.AZURE]: "Azure Marketplace",
  [AccountProvider.PREM]: "Contract",
  // [AccountProvider.GCP]: "Google Cloud Marketplace",
};

export const AccountProviderNames = {
  [AccountProvider.AWS]: "Amazon Web Services",
  [AccountProvider.AZURE]: "Microsoft Azure",
  [AccountProvider.PREM]: "On Premsises",
  // [AccountProvider.GCP]: "Google Cloud Platform",
};

/**
 * Type of template combinations
 */
export const TemplateOption = {
  ALL: "full",
  VPC: "cloud",
  VNET: "vcloud",
  NSG: "sg",
  DNS: "dns",
};

export const TemplateOptionNames = {
  [TemplateOption.ALL]: "All Ingestion",
  [TemplateOption.VPC]: "VPC Flow Logs",
  [TemplateOption.VNET]: "VNet Flow Logs",
  [TemplateOption.NSG]: "NSG Flow Logs",
  [TemplateOption.DNS]: "DNS",
};

export const TemplateService = {
  [AccountProvider.AWS]: "AWS CloudFormation",
  [AccountProvider.AZURE]: "Azure Resource Manager",
  // [AccountProvider.GCP]: "Google Deployment Manager",
};

export const TemplateAvailability = {
  [AccountProvider.AWS]: [TemplateOption.ALL, TemplateOption.VPC, TemplateOption.DNS],
  [AccountProvider.AZURE]: [TemplateOption.ALL, TemplateOption.VNET, TemplateOption.NSG],
};

/** Enumerated values for node classifications */
export const NodeClassification = {
  MISSING: 0,
  MANAGED: 2,
  UNMANAGED: 1,
  CLOUD_PROVIDER: 5,
  EXTERNAL: 3,
  UNCONFIGURED: 4,
};

export const AddrClassNames = {
  [NodeClassification.MANAGED]: "Managed",
  [NodeClassification.UNMANAGED]: "Unmanaged",
  [NodeClassification.CLOUD_PROVIDER]: "Cloud Provider",
  [NodeClassification.EXTERNAL]: "External",
  [NodeClassification.UNCONFIGURED]: "Unconfigured",
  [NodeClassification.MISSING]: "Unknown",
};

/** Enumerated values for the size of the node */
export const NodeRange = {
  ORG: "organization",
  NETWORK: "network",
  SUBNET: "subnet",
  ADDRESS: "address",
  FLOW: "session",
  MISSING: "missing",
  HOST: "host",
};

/** Convert range to a comparable size */
export const RangeToSize = {
  [NodeRange.NETWORK]: 3,
  [NodeRange.SUBNET]: 2,
  [NodeRange.ADDRESS]: 1,
  [NodeRange.HOST]: 1,
};

export const ASC = "asc";
export const DESC = "desc";

/** See api\components\constants.py  */
export const HostType = {
  unclassified: 1000,
  dir_server: 110,
  ntp_server: 100,
  web_server: 90,
  database_server: 80,
  dns: 70,
  bgp_server: 60,
  windows_server: 50,
  mail_server: 40,
  ftp_server: 30,
  remote_desktop: 20,
  telnet_server: 10,
  tftp_server: -10,
  irc_server: -20,
  tor_server: -30,
  pen_tester: -40,
};

export const HostTypeNames = {
  [HostType.pen_tester]: "Penetration Tester ",
  [HostType.unclassified]: "Unclassified",
  [HostType.dns]: "DNS Server",
  [HostType.dir_server]: "Directory Server",
  [HostType.ftp_server]: "File Server",
  [HostType.ntp_server]: "NTP Server",
  [HostType.web_server]: "Web Server",
  [HostType.remote_desktop]: "Remote Desktop",
  [HostType.telnet_server]: "Telnet Server",
  [HostType.mail_server]: "Mail Server",
  [HostType.windows_server]: "Windows Server",
  [HostType.database_server]: "Database Server",
  [HostType.tftp_server]: "TFTP Server",
  [HostType.bgp_server]: "BGP Server",
  [HostType.irc_server]: "IRC Server",
  [HostType.tor_server]: "TOR Server",
};

export const RiskSource = {
  none: "-1",
  rejected_flow: "0",
  cluster: "1",
  pii: "2",
  unmanaged_addr: "4",
  osint: "5",
  centrality: "7",
  link_prediction: "8",
  tri_count: "9",
  label_propagation: "10",
  louvain: "11",
  graph_neural_net: "12",
  missing_link: "13",
  community_migration: "14",
  geopolitical: "15",
};

export const RiskSourceNames = {
  [RiskSource.none]: "Baseline",
  [RiskSource.rejected_flow]: "Rejected Connection",
  [RiskSource.cluster]: "Community Deviation",
  [RiskSource.pii]: "Personally Identifiable Information",
  [RiskSource.unmanaged_addr]: "Unmanaged",
  [RiskSource.centrality]: "Centrality Anomaly Detection",
  [RiskSource.link_prediction]: "Graph Link Prediction",
  [RiskSource.tri_count]: "Triangle Count Coefficient",
  [RiskSource.label_propagation]: "Label Propagation",
  [RiskSource.louvain]: "Louvain Modularity",
  [RiskSource.graph_neural_net]: "Graph Neural Network Model",
  [RiskSource.missing_link]: "Missing Link Prediction",
  [RiskSource.geopolitical]: "Geopolitical",
  [RiskSource.osint]: "Open Source Intel",
};

export const RiskLevel = {
  NONE: 0,
  CAT1: 1,
  CAT2: 4,
  CAT3: 6,
  CAT4: 8,
};

/**
 * Distinct source types of ingested data and pricing categories;
 * Values are strings to be compatible with DynamoDB Map keys
 */
export const Telemetry = {
  DEFAULT: "0",
  API: "1",
  DNS: "2",
  VPC: "3",
  NETFLOW: "5",
  ERROR: "6",
  PACKETBEAT: "7",
  INSIGHTS: "11",
  CLOUDTRAIL: "12",
  ICMP: "13",
  PCAP: "14",
};

export const TELEMETRY_NAMES = {
  [Telemetry.API]: "API Calls",
  [Telemetry.DNS]: "VPC DNS Logs",
  [Telemetry.VPC]: "VPC Flow Logs",
  [Telemetry.NETFLOW]: "NetFlow",
  [Telemetry.ERROR]: "Error",
  [Telemetry.PACKETBEAT]: "Packetbeat",
  [Telemetry.INSIGHTS]: "Insights",
  [Telemetry.CLOUDTRAIL]: "Cloudtrail",
  [Telemetry.ICMP]: "ICMP",
  [Telemetry.PCAP]: "PCAP",
};
