// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import Tippy from "@tippyjs/react";
import { Scrollbars } from "react-custom-scrollbars-2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import invert from "invert-color";
import _ from "lodash";

import Chart, { chartTypes } from "../../../shared/components/Chart";
import { renderThumb } from "../../../shared/functions/general";
import { basicRiskColor } from "../../../shared/functions/color";

/**
 * Contains a Key Value display of an inspected item
 * @since 0.2.2
 */
export default class InfoItem extends Component {
  constructor(props) {
    super(props);

    this.onShowRiskResetModal = this.onShowRiskResetModal.bind(this);
  }

  renderChartItem(name, value, rowClass, uuid, theme) {
    let secondary = null;
    let options = undefined;
    let colorSet = undefined;
    const type = value.graph.type;
    const { groupName } = this.props;
    delete value.graph.type;

    try {
      switch (type) {
        case chartTypes.line:
          rowClass = "chart";
          if (["Activity", "Availability"].includes(groupName)) {
            let timeUnit = "minute";
            colorSet = [this.props.theme.primary, this.props.theme.highlight_8];
            if (groupName === "Availability") {
              timeUnit = "hour";
              colorSet[0] = this.props.theme.highlight_7;
            }

            // sizeOverride = {height: 45, width: 200}
            options = {
              responsive: true,
              legend: { display: false },
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    type: "time",
                    time: { unit: timeUnit },
                    gridLines: { display: true },
                    ticks: {
                      suggestedMin: 0,
                      suggestedMax: 1,
                    },
                  },
                ],
                yAxes: [
                  {
                    type: "linear",
                    gridLines: { display: true, color: theme.highlight_9 },
                    ticks: {
                      suggestedMin: 0,
                      suggestedMax: 1,
                    },
                  },
                ],
              },
            };

            secondary = (
              <TableRow className="units">
                <TableCell className="hidden-item" />
                <TableCell className="full-item">
                  <Table size="small">
                    <TableBody></TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            );
          }
          break;
        case chartTypes.doughnut:
          rowClass = "piechart";
          name = "";
          let legendKeys = [];
          _.get(value, "graph.datasets", []).forEach((set) => {
            let keysSubset = _.map(_.get(set, "points", []), "x");
            legendKeys.push(...keysSubset);
          });

          if (legendKeys !== undefined) {
            const half = Math.ceil(legendKeys.length / 2);
            const col1 = legendKeys.slice(0, half);
            const col2 = legendKeys.slice(half, legendKeys.length);
            secondary = (
              <TableRow>
                <TableCell className="hidden-item" />
                <TableCell className="legend full-item">
                  <Table size="small">
                    <TableBody>
                      {col1.map((item, index) => (
                        <TableRow key={index} className="legend-row">
                          <TableCell className="legend-item">
                            <div
                              className="color-box"
                              style={{
                                backgroundColor: theme.pieChartColors[index],
                              }}
                            />
                            {item}
                          </TableCell>

                          {index < col2.length ? (
                            <TableCell className="legend-item">
                              <div
                                className="color-box"
                                style={{
                                  backgroundColor: theme.pieChartColors[index + half],
                                }}
                              />
                              {col2[index]}
                            </TableCell>
                          ) : (
                            <TableCell className="hidden-item" />
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            );
          }
          break;
        default:
          break;
      }
    } catch (e) {
      // Data was improperly formatted.
      console.error(e);
    }

    value = <Chart name={name} type={type} data={value.graph} uuid={uuid} theme={theme} options={options} colorSet={colorSet} />;

    return { name, value, rowClass, secondary };
  }

  renderTable(name, value, rowClass, theme) {
    rowClass = "table";
    const table = _.cloneDeep(value.table);
    const title = name;
    const tooltip = value.tooltip;
    let headers = table.shift();

    if (headers === undefined) {
      console.debug("We shouldn't be here...?");
      return <div />;
    }

    // need to render status display differently
    let isPortTable = headers[0].toLowerCase() === "status";

    name = "";
    value = (
      <Scrollbars
        id={`${title}-flow-list`}
        autoHideDuration={1000}
        renderThumbHorizontal={(_) => {
          return <div />;
        }}
        renderThumbVertical={(obj) => {
          return renderThumb(obj, theme);
        }}
        style={{
          minHeight: `${Math.min((table.length + 1) * 33 + 47, 250)}px`,
        }}
      >
        <Tippy content={tooltip} animation="scale-subtle" theme="material" duration={global.gTTPDur} delay={[global.gTTPShow, 0]}>
          <div className="flow-title">{title}</div>
        </Tippy>

        <Table size="small" className="flow-table">
          <TableHead>
            <TableRow>
              {headers.map((header, i) => (
                <TableCell key={i} align="center">
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {table.map((row, i) => (
              <TableRow key={i}>
                {row.map((item, j) => {
                  if (j === row.length - 1)
                    return (
                      <TableCell key={j} align="right">
                        {item}
                      </TableCell>
                    );
                  if (j === 0 && isPortTable) {
                    let bgColor;
                    switch (item.toLowerCase()) {
                      case "reject":
                        bgColor = theme.highlight_5;
                        break;
                      case "accept":
                        bgColor = theme.highlight_7;
                        break;
                      default:
                        bgColor = theme.text;
                    }

                    return (
                      <TableCell key={j}>
                        <div
                          className="status-box"
                          style={{
                            backgroundColor: bgColor,
                            color: invert(bgColor, true),
                          }}
                        >
                          {item}
                        </div>
                      </TableCell>
                    );
                  }
                  return <TableCell key={j}>{item}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbars>
    );

    return { name, value, rowClass };
  }

  onShowRiskResetModal(event) {
    let { selectedItem, onShowRiskReset } = this.props;
    if (onShowRiskReset) {
      selectedItem.ip = selectedItem.name;
      onShowRiskReset(event, selectedItem);
    }
  }

  render() {
    let { value, name, uuid, theme, selectedItem } = this.props;
    let secondary;
    if (value === undefined) {
      return <div />;
    }
    let rowClass = "key-pair"; // ClassName used for styling a specific type of graph
    let hasTable = false;
    let res;

    /* 
      Loads a graph canvas
      Should be structured as such:
      value = {
        "graph": {
          // data in format specified by ChartJS
        }
      }
    */
    if (value.graph !== undefined) {
      res = this.renderChartItem(name, value, rowClass, uuid, theme);
    } else if (value.table !== undefined) {
      res = this.renderTable(name, value, rowClass, theme);
      hasTable = true;
    }

    if (res !== undefined) {
      value = res.value;
      name = res.name;
      rowClass = res.rowClass;
      secondary = res.secondary ? res.secondary : null;
    } else if (typeof value === "object" && Object.keys(value).length === 0) {
      // Input is not a known configuration
      value = "";
    }

    let riskStyle = null;
    if (name && typeof name === "string" && name.toLowerCase() === "risk") {
      let risk = _.cloneDeep(value);
      //const color = riskGradient(theme, risk);
      let color = basicRiskColor(risk);
      const text = invert(color, false);
      riskStyle = { backgroundColor: color, color: text };

      value = <div className="risk">{risk}</div>;
    }
    let entry = selectedItem ? (
      <span>
        {value}
        <div className="btn risk-reset" onClick={this.onShowRiskResetModal} style={riskStyle} title="Reset Risk">
          <i className="fas fa-undo" />
        </div>
      </span>
    ) : (
      value
    );

    return (
      <Fragment>
        <TableRow className={rowClass}>
          <TableCell className={!hasTable ? "field-item" : "hidden-item"}>{name}</TableCell>
          {!hasTable ? (
            <Fragment>
              {selectedItem && selectedItem.resettable ? (
                <TableCell style={riskStyle}>{entry}</TableCell>
              ) : (
                <TableCell style={riskStyle}>{value}</TableCell>
              )}
            </Fragment>
          ) : (
            <TableCell className="full-item">{value}</TableCell>
          )}
        </TableRow>
        {secondary}
      </Fragment>
    );
  }
}
