// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import * as actions from "./actionTypes";

/**
 * Adds a list of billing metrics to the store
 * @param {*} metrics
 * @since 0.4.1
 */
export const addMetrics = (metrics) => ({
  metrics,
  type: actions.ADD_METRICS,
});

/** Adds a list of users; Operation overwrites existing list instead of appends */
export const addUsers = (users) => ({
  users,
  type: actions.ADD_USERS,
});

/** Modifies a single user. Operation expected to be asynchronous */
export const modifyUser = (user) => ({
  user,
  type: actions.MODIFY_USER,
});

/** Deletes a single user. Operation expected to be asynchronous */
export const deleteUser = (userName) => ({
  userName,
  type: actions.DELETE_USER,
});

/**
 * Initialize results asynchronous modification and deletions. This is combined with
 * operations from different Console tabs as no two console tabs can be active at the same
 * time, nor can be navigated away from without refreshing the Redux store
 * */
export const initManagementOperation = (results) => ({
  results,
  type: actions.INIT_MANAGE_OPERATION,
});

export const failUserOp = (userName) => ({
  userName,
  type: actions.FAIL_USER_OPERATION,
});

/** Get list of accounts */
export const setAccounts = (accounts) => ({
  type: actions.SET_ACCOUNT_LIST,
  accounts,
});

export const addAccount = (account) => ({
  type: actions.ADD_ACCOUNT,
  account,
});

export const updateAccount = (account) => ({
  type: actions.UPDATE_ACCOUNT,
  account,
});

export const removeAccount = (account_id) => ({
  type: actions.REMOVE_ACCOUNT,
  account_id,
});

/** TODO: Combne this with other management operations */
export const failAccountOperation = (account_id, error) => ({
  type: actions.FAIL_ACCOUNT_OPERATION,
  account_id,
  error,
});

export const setOrganization = (organization) => ({
  type: actions.GET_ORGANIZATION,
  organization,
});

export const updateOrganization = (organization) => ({
  type: actions.UPDATE_ORGANIZATION,
  organization,
});

export const initTemplateGeneration = (results, provider) => ({
  type: actions.INIT_TEMPLATE_GENERATION,
  provider,
  results,
});

export const setTemplateUrl = (templateType, provider, url) => ({
  type: actions.SET_TEMPLATE_URL,
  templateType,
  provider,
  url,
});
