// This code is property of Auspex Labs Inc. and is protected by Trade Secret.
import _ from "lodash";

import { initialState } from "../../../shared/constants";
import { ASC } from "../../../shared/enumerations";
import { isOperationActive } from "../../../shared/functions/general";
import * as actions from "./actionTypes";

export default function reducer(state = initialState.settings, action) {
  const newState = _.cloneDeep(state);

  switch (action.type) {
    case actions.ADD_METRICS:
      newState.billing = action.metrics;
      // const org_key = newState.billing.accounts[0];
      // newState.billing.accounts[0] = global.gDefaultAccount;
      // newState.billing.org_key = org_key;
      break;

    case actions.INIT_MANAGE_OPERATION:
      newState.results = action.results;
      newState.operationActive = true;
      break;

    case actions.FAIL_USER_OPERATION:
      if (action.userName)
        newState.results[action.userName] = _.merge(state.results[action.userName], { active: false, failed: true });
      newState.operationActive = isOperationActive(newState.results);
      break;

    case actions.ADD_USERS:
      newState.users = _.orderBy(action.users, ["email"], [ASC]);
      newState.operationActive = false;
      break;

    case actions.MODIFY_USER:
      for (let i = 0; i < state.users.length; i++) {
        if (state.users[i].user_name === action.user.user_name) {
          newState.users[i] = _.merge(state.users[i], action.user);
          newState.results[action.user.user_name] = _.merge(state.results[action.user.user_name], { active: false, failed: false });
          break;
        }
      }

      newState.operationActive = isOperationActive(newState.results);
      break;

    case actions.DELETE_USER:
      for (let i = 0; i < state.users.length; i++) {
        if (state.users[i].user_name === action.userName) {
          newState.users.splice(i, 1);
          newState.results[action.userName] = _.merge(state.results[action.userName], { active: false, failed: false });
          break;
        }
      }

      newState.operationActive = isOperationActive(newState.results);
      break;

    case actions.SET_ACCOUNT_LIST:
      newState.accounts = _.orderBy(action.accounts, ["name", "id"], [ASC]);
      break;

    case actions.ADD_ACCOUNT:
      newState.accounts.push(action.account);
      newState.accounts = _.orderBy(newState.accounts, ["name", "id"], [ASC]);
      break;

    case actions.UPDATE_ACCOUNT:
      newState.accounts = newState.accounts.map((account) => (account.id === action.account.id ? action.account : account));
      break;

    case actions.REMOVE_ACCOUNT:
      for (let i = 0; i < state.accounts.length; i++) {
        if (state.accounts[i].id === action.account_id) {
          newState.accounts.splice(i, 1);
          newState.results[action.account_id] = _.merge(state.results[action.account_id], { active: false, failed: false });
          break;
        }
      }

      newState.operationActive = isOperationActive(newState.results);
      break;

    case actions.FAIL_ACCOUNT_OPERATION:
      if (action.account_id)
        newState.results[action.account_id] = _.merge(state.results[action.account_id], { active: false, failed: true });
      newState.operationActive = isOperationActive(newState.results);
      break;

    case actions.GET_ORGANIZATION:
      newState.organization = action.organization;
      break;

    case actions.UPDATE_ORGANIZATION:
      Object.entries(action.organization).forEach((entry) => {
        const [key, value] = entry;
        newState.organization[key] = value;
      });
      break;

    case actions.INIT_TEMPLATE_GENERATION:
      newState.results = action.results;
      newState.operationActive = true;
      if (!(action.provider in state.templateUrls)) newState.templateUrls[action.provider] = {};
      break;

    case actions.SET_TEMPLATE_URL:
      newState.templateUrls[action.provider][action.templateType] = action.url;
      newState.results[action.templateType] = _.merge(state.results[action.templateType], { active: false, failed: false });
      newState.operationActive = isOperationActive(newState.results);
      break;

    default:
      return state;
  }

  return newState;
}
