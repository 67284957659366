// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";

/* Use BrowserRouter as router. This uses the 
browser’s History API to create real URLs. */
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import { Amplify } from "@aws-amplify/core";
import { Provider } from "react-redux";
import Modal from "react-modal";
import { CookiesProvider } from "react-cookie";
import { ToastProvider, DefaultToastContainer } from "react-toast-notifications";

// Local resources
import App from "./shared/components/App";
import "./shared/styles/index.css";
import "./shared/styles/Credentials.css";
import { config, devConfig } from "./shared/constants";
import configureStore, { configureLogger } from "./configureStore";
import graphCache from "./pages/sysmap/graphcache"; // the singleton for the app

if (!global.gIsProd && devConfig.customLogging) configureLogger();

// get the configured redux store (reducers are imported there)
// start the preloaded state as undefined
const store = configureStore(undefined);
graphCache.setDispatch(store.dispatch);
// Set the root for all Modals globally
Modal.setAppElement("#root");

// TODO: (DevOps 313) 'default' is deprecated
Amplify.configure({
  Auth: {
    /* Set to true because we want our users to be signed in 
    before they can interact with our app.*/
    mandatorySignIn: true,

    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: config.apiGateway.NAME,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

const MyCustomToast = ({ children, ...props }) => (
  <div style={{ zIndex: 10, position: "absolute" }}>
    <DefaultToastContainer {...props}>{children}</DefaultToastContainer>
  </div>
);

/* Use the Router to render the App component. This will allow us 
to create the routes necessary inside the App component.*/
ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <ToastProvider components={{ ToastContainer: MyCustomToast }}>
        <Router>
          <App graphcache={graphCache} />
        </Router>
      </ToastProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById("root")
);
