// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { renderThumb } from "../../../shared/functions/general";

import InfoGroup from "./InfoGroup";
import "../styles/InfoList.css";
import _ from "lodash";

/**
 * List of InfoGroups containing information useful to the user of the
 * selected object.
 * Primarily used for the currently selected network, node, or edge
 * @since 0.2.2
 */
function InfoList({
  open,
  openInfoPane,
  closeInfoPane,
  canOpenInfoPane,
  theme,
  inspecting,
  groups,
  actions,
  activeItem,
  onShowRiskReset,
}) {
  const scrollRef = useRef();
  let scrollEnabled = true; // NOTE: need to use size detectors to determine if scrollbar is enabled...

  let selectedItem = activeItem && activeItem.resettable ? activeItem : null;

  return (
    <div className={`info-list ${scrollEnabled ? " overflow" : ""}${!open ? " collapsed" : ""}`}>
      <div className="anchor">
        <div className={`toggle${!canOpenInfoPane ? " tog-hide" : ""}`} onClick={open ? closeInfoPane : openInfoPane}>
          <i className={`fas fa-angle-left${open ? " face-right" : ""}`} />
        </div>
      </div>
      {inspecting ? (
        <div className="full">
          <i className="fas fa-sync spin" />
        </div>
      ) : (
        <Scrollbars
          ref={scrollRef}
          renderThumbHorizontal={(_) => {
            return <div />;
          }}
          renderThumbVertical={(obj) => {
            return renderThumb(obj, theme);
          }}
          id="info-list"
          autoHideDuration={1000}
        >
          {groups.map((group) => {
            let hasRisk;
            try {
              hasRisk = _.get(group, "name", "").toLowerCase().includes("threat");
            } catch {
              hasRisk = false;
            }

            return (
              <InfoGroup
                selectedItem={hasRisk ? selectedItem : null}
                onShowRiskReset={onShowRiskReset}
                key={group.name}
                groupName={group.name}
                infos={group.infos}
                theme={theme}
                {...actions}
              />
            );
          })}
        </Scrollbars>
      )}
    </div>
  );
}

export default InfoList;
