// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";
import { Navigate } from "react-router-dom";
import { pages } from "../enumerations";

/**
 * Page route limited to users properly authenticated through AWS Amplify/Cognito
 * @since 0.3.1
 */
const AuthenticatedRoute = ({ component: C, childProps }) =>
  childProps.isAuthenticated ? (
    <C {...childProps} />
  ) : (
    <Navigate to={`${pages.login}?redirect=${childProps.location.pathname}${childProps.location.search}`} />
  );

export default AuthenticatedRoute;
