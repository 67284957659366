// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

export const ADD_METRICS = "ADD_METRICS";

export const ADD_USERS = "ADD_USER";
export const MODIFY_USER = "MODIFY_USER";
export const DELETE_USER = "DELETE_USER";
export const INIT_MANAGE_OPERATION = "INIT_MANAGE_OPERATION";
export const FAIL_USER_OPERATION = "FAIL_USER_OPERATION";

export const SET_ACCOUNT_LIST = "SET_ACCOUNT_LIST";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const REMOVE_ACCOUNT = "REMOVE_ACCOUNT";
export const FAIL_ACCOUNT_OPERATION = "FAIL_ACCOUNT_OPERATION";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";

export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";

export const INIT_TEMPLATE_GENERATION = "INIT_TEMPLATE_GENERATION";
export const SET_TEMPLATE_URL = "";
export const FAIL_TEMPLATE_GENERATION = "FAIL_TEMPLATE_GENERATION";
