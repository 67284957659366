// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";

import "../styles/Home.css";

export default class Home extends Component {
  renderLander() {
    return (
      <Fragment>
        <div className={`landing-splash${this.props.className ? " " + this.props.className : ""}`} />
        <div className="landing">{this.props.children}</div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="home">{!this.props.isAuthenticated && this.renderLander()}</div>
        <div className="padding" />
      </Fragment>
    );
  }
}
