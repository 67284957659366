// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";
import Tippy from "@tippyjs/react";

import "../styles/WorkspaceButton.css";

/**
 * A graph button to be displayed in the Graph Area
 * above the graph
 * @since 0.2.2
 * @param {JSX} text The inner text of the button
 * @param {String} tooltip Text to display on hover
 * @param {Boolean} disabled Whether the button is active or not
 * @param {Function} onClick The click callback
 * @param {Boolean} demo Whether the feature is available during demo
 */
export default class WorkspaceButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    if (!this.props.disabled) this.props.onClick(event);
  }

  render() {
    const { text, tooltip, disabled, demo, children, className } = this.props;

    let classes = "";
    if (className !== undefined && typeof className === "string") classes = className;
    if (classes.length > 1) classes = " " + classes;

    return (
      <div className={`workspace-btn${classes}`}>
        <Tippy
          content={demo ? global.gDemoMsg : tooltip}
          animation="scale-subtle"
          theme="material"
          duration={global.gTTPDur}
          delay={[global.gTTPShow, 0]}
          offset={[0, -8]}
          placement="left"
        >
          <div>
            <div className="grp-btn" onClick={this.handleClick} disabled={disabled} data-demo={demo}>
              {text}
            </div>
          </div>
        </Tippy>

        {children}
      </div>
    );
  }
}
