// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

/**
 * Creates a Worker process for use in threading
 */
export default class WebWorker {
  constructor(worker) {
    const code = worker.toString();
    const blob = new Blob(["(" + code + ")()"]);
    return new Worker(URL.createObjectURL(blob));
  }
}
