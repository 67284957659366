// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import Modal from "react-modal";
import Tippy from "@tippyjs/react";
import { withToastManager } from "react-toast-notifications";
import { ContextMenu, MenuItem } from "../../../shared/external/react-contextmenu";
import { Form, Col, FormGroup, FormControl } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import _ from "lodash";

import * as MonitoringActions from "../reducers/actions";

import MonitorEntry from "../components/MonitorEntry";
import Chart, { chartTypes } from "../../../shared/components/Chart";
import { ASC, DESC, pages, gateways, NodeRange } from "../../../shared/enumerations";
import Dropdown from "../../../shared/components/Dropdown";
import LoaderButton from "../../../shared/components/LoaderButton";
import SortItem from "../../../shared/components/SortItem";
import SearchInput from "../../../shared/components/SearchInput";
import InfoList from "../../sysmap/components/InfoList";
import { modalStyle, ServerError } from "../../../shared/constants";
import { handleContextMenuClick } from "../../../shared/functions/ctxMenu";
import { decompress, formatPageTitle, getEndpointDisplayName } from "../../../shared/functions/formatting";
import { getItemGroups, MyAPI, requestErr, renderThumb, isNull } from "../../../shared/functions/general";
import "../styles/MonitoringView.css";
import monitorCache from "../components/MonitoringCache";
import { saturateColor } from "../../../shared/functions/color";

// This is temporary until the Consistently Down table is properly formatted
const DISABLE_TABLE = true;

const PollType = {
  icmp: "icmp",
};

/** Types of monitoring enabled on backend */
const pollTypeOptions = [PollType.icmp];

/** List of preset cron expressions */
const ExpressionPreset = {
  oneMin: { name: "Every minute", expr: "* * ? * * *" },
  fiveMin: { name: "Every 5 minutes", expr: "*/5 * ? * * *" },
  tenMin: { name: "Every 10 minutes", expr: "*/10 * ? * * *" },
  thirtyMin: { name: "Every 30 minutes", expr: "*/30 * ? * * *" },
  hourly: { name: "Hourly", expr: "0 * ? * * *" },
  daily: { name: "Daily", expr: "0 0 ? * * *" },
  // Disable custom expressions until implemented
  // custom: { name: "Cron Expression", expr: null },
};
const expressionOptions = [];
const presetFromName = {}; // for use grabbing expression from dropdown to send to backend
const presetFromExpression = {}; // for use populating dropdown from already set value
Object.values(ExpressionPreset).forEach((preset) => {
  expressionOptions.push(preset.name);
  presetFromName[preset.name] = preset.expr;
  presetFromExpression[preset.expr] = preset.name;
});

class MonitoringView extends Component {
  constructor(props) {
    super(props);
    let filter = this.loadFilter();

    const date = new Date();
    date.setDate(date.getDate() + 1);
    const last = date.toISOString();
    date.setDate(date.getDate() - 8);
    const first = date.toISOString();

    this.state = {
      // The state of main data fetch process
      loading: {
        active: false,
        failure: false,
      },
      inspecting: false,

      // Visibility of info pane
      infoPaneOpen: false,

      // Endpoint list sorting
      sortDir: ASC,
      sortOrder: "downtime",

      // Filter applied using search bar
      filter: filter,
      searchResults: [],

      // Set axis date range to 30 days for history graphs
      dateRange: {
        first,
        last,
      },

      // Operation in process
      loadOp: false,

      // Show Add/Modify Endpoint Modal
      addOpen: false,
      modifying: "",

      removeOpen: null,
      removeVerify: "",
      removing: false,

      // Add/Modify Modal inputs
      nodeTarget: null,
      nodePollExpression: null,
      nodePollPreset: null,
      nodePollType: PollType.icmp,
      previousExpression: "",
      previousPollType: "",
      createErr: "",
    };

    this.closeInfoPane = this.closeInfoPane.bind(this);
    this.openInfoPane = this.openInfoPane.bind(this);
    this.selectEntry = this.selectEntry.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.renderEndpointContext = this.renderEndpointContext.bind(this);
    this.onShowContext = this.onShowContext.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.refresh = this.refresh.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.commitCreateEndpoint = this.commitCreateEndpoint.bind(this);
    this.commitDeleteEndpoint = this.commitDeleteEndpoint.bind(this);
    this.showEndpointModal = this.showEndpointModal.bind(this);
    this.showRemoveModal = this.showRemoveModal.bind(this);
    this.onToggleEnabled = this.onToggleEnabled.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const { actions, toastManager } = this.props;
    const { sortOrder, filter, loading } = this.state;

    if (loading.active) return;

    this.setState({ loading: { active: true, failed: false } });
    requestEndpointList((endpointData) => {
      if (endpointData === null) {
        // There was an error trying to request the list.
        this.setState({
          loading: { active: false, failed: true },
        });
      } else {
        this.setState({
          loading: { active: false, failed: false },
        });

        let newList = _.orderBy(endpointData.endpoints, [sortOrder], [DESC]);
        endpointData.endpoints = newList;
        actions.updateEndpointSummary(endpointData);
        this.applyFilter(filter, endpointData.endpoints);
      }
    }, toastManager);
  }

  loadFilter() {
    // TODO: Not sure what this code was originally meant to do
    if (!_.isEmpty(_.get(this.state, "filter"))) return this.state.filter;

    console.debug("MonitoringView::Loading filter");
    let qs = queryString.parse(window.location.search);
    let filter = qs.search;

    if (_.isEmpty(filter)) filter = "";

    return filter;
  }

  handleSort(order, reverse) {
    order = order.toLowerCase();
    let flipped = false;

    let direction = ASC;
    // Flip direction if Order is the same, else set dir to ASC
    if (this.state.sortOrder === order) {
      direction = this.state.sortDir === ASC ? DESC : ASC;
      flipped = true;
    }

    if (reverse && !flipped) direction = direction === ASC ? DESC : ASC;

    let newSearch = _.orderBy(this.state.searchResults, [order], direction);
    this.setState({
      sortOrder: order,
      sortDir: direction,
      searchResults: newSearch,
    });

    let oldList = this.props.endpoints;
    let newList = _.orderBy(oldList, [order], [direction]);
    this.props.actions.updateEndpointSummary({ endpoints: newList });
  }

  /**
   * Generic handler for hiding an open modal
   */
  closeModal(event) {
    this.setState({ [event.target.id]: event.target.id === "removeOpen" ? null : false });
  }

  closeInfoPane() {
    this.setState({ infoPaneOpen: false });
  }

  openInfoPane() {
    let canOpen = this.canOpenInfoPane(true);
    if (!canOpen) return;
    this.setState({ infoPaneOpen: true });
  }

  canOpenInfoPane() {
    return !(this.state.loading.active || this.state.loading.failed);
  }

  async selectEntry(item) {
    if (item !== null) {
      this.setState({ inspecting: true }, () => {
        this.openInfoPane();
      });

      const { actions, toastManager } = this.props;

      let endpointData = null;
      try {
        endpointData = await monitorCache.getItem(this.props.userId, item.id);
      } catch (error) {
        console.error(error);
        this.closeInfoPane();
        toastManager.add(requestErr(error), {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });
      }

      this.setState({ inspecting: false });

      console.debug("MonitoringView::inspected endpoint: ", endpointData);
      if (endpointData !== null) {
        actions.inspectEndpoint({
          id: item.id,
          display_info: endpointData.displayInfo,
        });
      }
    } else {
      this.props.actions.inspectEndpoint({});
    }
  }

  showRemoveModal(event, endpoint) {
    event.stopPropagation();
    this.setState({ removeOpen: endpoint, removeVerify: "", removing: false });
  }

  showEndpointModal(event, endpoint) {
    event.preventDefault();
    event.stopPropagation();

    const { toastManager } = this.props;

    if (isNull(endpoint)) {
      this.setState({
        nodePollExpression: "",
        nodePollPreset: "",
        nodePollType: PollType.icmp,
        previousPollType: "",
        previousExpression: "",
        nodeTarget: "",
        addOpen: true,
        modifying: "",
      });
    } else {
      let target = endpoint.range === NodeRange.ADDRESS ? endpoint.ip_address : endpoint.name;
      let expression = _.get(endpoint, "rule", "");
      let pollType = _.get(endpoint, "monitor", null);

      this.setState({
        // if this information is written in Dgraph, then this would not be necessary
        // nodePollExpression: expression,
        // // TODO: nodePollPreset: Object.keys(presetFromExpression).includes(expression) ? presetFromExpression[expression] : ExpressionPreset.custom.name,
        // nodePollPreset: Object.keys(presetFromExpression).includes(expression) ? presetFromExpression[expression] : ExpressionPreset.daily.name,
        // nodePollType: _.get(endpoint, "monitor", PollType.icmp),

        // These will be loaded after a fetch
        nodePollExpression: "",
        nodePollPreset: "",
        nodePollType: "",
        previousExpression: "",
        previousPollType: "",

        nodeTarget: target,
        addOpen: true,
        modifying: endpoint.id,
        inspecting: true,
      });

      monitorCache
        .getItem(this.props.userId, endpoint.id)
        .then((endpointData) => {
          if (!isNull(endpointData)) {
            console.debug("MonitoringView::inspected endpoint: ", endpointData);
            expression = _.get(endpointData, "displayInfo.Endpoint Info.Polling Group", null);
            pollType = _.get(endpointData, "displayInfo.Endpoint Info.Monitoring Type", null);
            let preset = "";

            if (isNull(expression)) expression = "";
            else {
              // TODO: preset = Object.keys(presetFromExpression).includes(expression) ? presetFromExpression[expression] : ExpressionPreset.custom.name,
              preset = Object.keys(presetFromExpression).includes(expression)
                ? presetFromExpression[expression]
                : ExpressionPreset.daily.name;
            }

            this.setState({
              inspecting: false,
              nodePollExpression: expression,
              nodePollPreset: preset,
              nodePollType: pollType,
              previousExpression: expression,
              previousPollType: pollType,
            });
          } else {
            throw new ServerError({ statusCode: 500, body: { message: "Internal server error" } });
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({ inspecting: false });
          toastManager.add(requestErr(error), {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: global.gToastTimeout,
          });
        });
    }
  }

  commitDeleteEndpoint() {
    const { removeOpen } = this.state;
    const { toastManager, endpoints, actions } = this.props;
    this.setState({ removing: true });

    let url = gateways.endpointItem.replace(":id", removeOpen.id);
    MyAPI.delete(url)
      .then((response) => {
        console.debug("MonitoringView::Endpoint DELETE response: ", response);
        if (response.statusCode === 200) {
          let newList = _.filter(endpoints, (endpoint) => endpoint.id !== removeOpen.id);
          actions.updateEndpointSummary({ newList });

          this.setState({ removeOpen: null, removing: false });
          toastManager.add("Successfully removed endpoint!", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: global.gToastTimeout,
          });
        } else {
          throw new ServerError(response);
        }
      })
      .catch((error) => {
        this.setState({ removing: false });
        toastManager.add(requestErr(error), {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });
      });
  }

  async commitCreateEndpoint() {
    const { modifying, nodePollExpression, nodePollType, nodeTarget } = this.state;
    const { toastManager } = this.props;
    this.setState({ loadOp: true });

    try {
      let url = modifying ? gateways.endpointItem.replace(":id", modifying) : gateways.endpoints;
      let func = modifying ? MyAPI.patch : MyAPI.post;
      let requestBody = {
        expression: nodePollExpression,
        monitor: nodePollType,
      };

      if (!modifying) requestBody.target = nodeTarget;
      let response = await func(url, requestBody);

      console.debug(`MonitoringView:: Endpoint ${modifying ? "PATCH" : "POST"} response:"`, response);
      if (response.statusCode === 200) {
        this.setState({ addOpen: false, loadOp: false });

        if (modifying) {
          monitorCache.updateItem(this.props.userId, modifying, nodePollExpression, nodePollType).catch((error) => {
            console.error(error);
          });
        } else this.refresh();
      } else {
        this.setState({ loadOp: false });

        // TODO: Either show which value is malformed or tell user server is broken
        let err_message = response.statusCode === 400 ? "Arguments are invalid." : "An unexpected error occurred.";

        toastManager.add(err_message, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });
      }
    } catch (error) {
      toastManager.add(requestErr(error), {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: global.gToastTimeout,
      });
    }
  }

  /**
   * Generic callback for storing the input value when its component
   * has been updated.
   * @param {*} event The event that was triggered
   */
  handleChange = (event) => {
    const newState = {
      [event.target.id]: event.target.value,
    };

    // if (event.target.id === "nodePollPreset" && event.target.value !== ExpressionPreset.custom.name)
    if (event.target.id === "nodePollPreset") newState.nodePollExpression = presetFromName[event.target.value];
    this.setState(newState);
  };

  updateFilter(newFilter) {
    if (newFilter.toLowerCase() === this.state.filter.toLowerCase()) return;

    this.applyFilter(newFilter);
  }

  applyFilter(newFilter, loaded) {
    let { endpoints } = this.props;

    // `loaded` should be passed when data is newly populated, and should be passed in order to immediately
    // apply filter ahead of Redux state update
    if (loaded) endpoints = loaded;
    if (_.isNull(endpoints)) return;

    if (_.isEmpty(newFilter)) {
      this.setState({ filter: "", searchResults: [] });
      this.props.navigate(pages.monitor);
      return;
    }

    console.debug("MonitoringView::Applying filter:", newFilter);

    newFilter = newFilter.toLowerCase().trim();

    let newList = [];
    endpoints.forEach((endpoint) => {
      const entry = {
        id: endpoint.id.toLowerCase(),
        name: isNull(endpoint.name) ? "" : `${endpoint.name}`.toLowerCase(),
        ip_address: isNull(endpoint.ip_address) ? "" : `${endpoint.ip_address}`.toLowerCase(),
        assigned_name: isNull(endpoint.assigned_name) ? "" : `${endpoint.assigned_name}`.toLowerCase(),
      };

      if (
        entry.name.includes(newFilter) ||
        // || entry.id.includes(newFilter)
        entry.ip_address.includes(newFilter) ||
        entry.assigned_name.includes(newFilter)
      )
        newList.push(endpoint);
    });

    newList = _.orderBy(newList, [this.state.sortOrder], [this.state.sortDir]);
    this.setState({ filter: newFilter, searchResults: newList });

    let qs = { search: newFilter };
    this.props.navigate(`${pages.monitor}?${queryString.stringify(qs)}`);
  }

  validateCreateForm() {
    const {
      modifying,
      inspecting,
      nodePollExpression,
      nodePollType,
      nodePollPreset,
      nodeTarget,
      previousExpression,
      previousPollType,
    } = this.state;

    if (modifying && inspecting) return true;

    // Modification cannot be made if nothing has changed
    if (modifying && previousPollType === nodePollType && previousExpression === nodePollExpression) return false;

    if (
      nodePollExpression === null ||
      nodePollExpression === "" ||
      nodePollPreset === null ||
      nodeTarget === null ||
      nodeTarget === "" ||
      isNull(nodePollType)
    )
      return false;

    return true;
  }

  renderRemoveModal() {
    const { removeVerify, removeOpen } = this.state;
    const validForm = removeVerify !== "delete";
    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];
    let isOpen = !isNull(removeOpen);
    if (!isOpen) return null;

    return (
      <Modal isOpen={true} style={style}>
        <div className="modal-pnl endpoint-modal">
          <div className="header">Remove {getEndpointDisplayName(removeOpen)}</div>
          <span className="info">
            Please verify that you would like to delete the following endpoint by entering "delete" in the space below.
          </span>

          <Form>
            <FormGroup controlId="removeVerify" className="modal-input">
              <FormControl
                autoFocus
                className="w-50"
                type="text"
                value={removeVerify}
                onChange={this.handleChange}
                placeholder="delete"
              />
            </FormGroup>
          </Form>

          <div className="footer">
            <div className="actions center">
              <div disabled={this.state.removing} className="btn quart" id="removeOpen" onClick={this.closeModal}>
                Cancel
              </div>
              <LoaderButton
                disabled={validForm}
                type="submit"
                text="Delete"
                loadingText="Deleting..."
                isLoading={this.state.removing}
                onClick={this.commitDeleteEndpoint}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderEndpointModal() {
    if (!this.state.addOpen) return null;

    const { modifying, inspecting, nodePollExpression, nodePollPreset, nodePollType, nodeTarget } = this.state;
    const validForm = !this.validateCreateForm();
    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];

    const updatingPoll = inspecting && _.isEmpty(modifying);
    const staticOption = "Loading...";

    return (
      <Modal isOpen={true} style={style}>
        <div className="modal-pnl endpoint-modal">
          <div className="header">{modifying ? "Modify" : "Define"} Endpoint</div>

          {updatingPoll && (
            <span>
              <i className="spin fas fa-sync" />
              Fetching current configuration...
            </span>
          )}

          <Form horizontal="true" className="modal-form">
            <FormGroup controlId="nodeTarget" className="modal-input">
              <Col sm={5}>Target:</Col>
              <Col sm={10}>
                <FormControl
                  autoFocus
                  type="text"
                  disabled={!_.isEmpty(modifying)}
                  value={nodeTarget}
                  onChange={this.handleChange}
                  placeholder="0.0.0.0 or host.name.com"
                />
              </Col>
            </FormGroup>

            <FormGroup controlId="nodePollType" className="modal-input">
              <Col componentclass="FormLabel" sm={5}>
                Poll Type:
              </Col>
              <Col sm={10}>
                <FormControl className="hidden" value={nodePollType} onChange={this.handleChange} />
                <Dropdown
                  id="nodePollType"
                  disabled={updatingPoll}
                  title={updatingPoll ? "Currently fetching data. Please wait." : undefined}
                  options={updatingPoll ? [staticOption] : pollTypeOptions}
                  onChange={updatingPoll ? null : this.handleChange}
                  value={updatingPoll ? staticOption : nodePollType}
                />
              </Col>
            </FormGroup>

            <FormGroup controlId="nodePollPreset" className="modal-input">
              <Col componentclass="FormLabel" sm={5}>
                Schedule:
              </Col>
              <Col sm={10}>
                <FormControl className="hidden" value={nodePollPreset} onChange={this.handleChange} />
                <Dropdown
                  disabled={updatingPoll}
                  id="nodePollPreset"
                  title={updatingPoll ? "Currently fetching data. Please wait." : undefined}
                  options={updatingPoll ? [staticOption] : expressionOptions}
                  onChange={updatingPoll ? null : this.handleChange}
                  value={updatingPoll ? staticOption : nodePollPreset}
                />
              </Col>
            </FormGroup>

            {/* disabled={nodePollPreset !== ExpressionPreset.custom.name} */}
            <FormGroup controlId="nodePollExpression" className="modal-input">
              <Col sm={5}>Cron Expression:</Col>
              <Col sm={10}>
                <FormControl
                  autoFocus
                  type="text"
                  disabled={updatingPoll || nodePollPreset !== "Cron Expression"}
                  value={nodePollExpression}
                  onChange={this.handleChange}
                  placeholder="MIN HR DAY MO WK YR"
                />
              </Col>
            </FormGroup>

            {this.state.createErr.length > 0 && (
              <div className="modal-error">
                <span className="err">{this.state.createErr}</span>
              </div>
            )}
          </Form>

          <div className="footer">
            <div className="actions center">
              <div disabled={this.state.loadOp} className="btn quart" id="addOpen" onClick={this.closeModal}>
                Cancel
              </div>
              <LoaderButton
                disabled={validForm}
                type="submit"
                text={modifying ? "Modify" : "Create"}
                loadingText={modifying ? "Modifying..." : "Creating..."}
                isLoading={this.state.loadOp}
                onClick={this.commitCreateEndpoint}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  onToggleEnabled(event, ...params) {
    event.stopPropagation();

    const { endpoint, enabled: newState } = params[0];
    const { actions, endpoints, toastManager } = this.props;
    actions.toggleEndpointState(endpoint.id);

    let url = gateways.endpointItem.replace(":id", endpoint.id);

    MyAPI.patch(url, { enabled: newState })
      .then((response) => {
        console.debug("MonitoringView::PATCH toggle state response:", response);
        actions.finishEndpointStateToggle(endpoint.id);
        if (response.statusCode === 200) {
          for (let i = 0; i < endpoints.length; i++) {
            if (endpoints[i].id === endpoint.id) {
              endpoint.enabled = newState;
              break;
            }
          }
          actions.updateEndpointSummary({ endpoints });
        } else {
          throw new ServerError(response);
        }
      })
      .catch((error) => {
        actions.finishEndpointStateToggle(endpoint.id);
        toastManager.add(requestErr(error), {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });
      });
  }

  onShowContext(endpoint, event) {
    console.debug("MonitoringView::context click: ", endpoint.id);
    const id = `endpoint-${endpoint.id}`;
    event.target.id = id;
    handleContextMenuClick(event, id, this.props);
  }

  contextHoverEvent = (event) => event.preventDefault();

  renderEndpointContext(endpoint) {
    if (isNull(endpoint)) return null;

    let toggling = this.props.togglingEndpoints.includes(endpoint.id); // for invoking modifications; target is reserved
    // let tooltip = `${endpoint.enabled ? "Pause" : "Resume"} monitoring for this endpoint`;

    return (
      <ContextMenu id={`ctx-endpoint-${endpoint.id}`}>
        <MenuItem tabIndex="0" data={endpoint} onClick={this.viewItem} onMouseMove={this.contextHoverEvent}>
          <i className="fas fas-project-diagram" /> View in Map
        </MenuItem>

        {/* For some reason, Tippy believes the tooltip is undefined */}
        {/* <Tippy
          content={tooltip}
          animation="scale-subtle"
          theme="material"
          duration={global.gTTPDur}
          delay={[global.gTTPShow, 0]}
        > */}
        <MenuItem
          data={{ endpoint, enabled: !endpoint.enabled }}
          disabled={toggling}
          tabIndex="0"
          onClick={this.onToggleEnabled}
          onMouseMove={this.contextHoverEvent}
        >
          <i className={`fas fa-${endpoint.enabled ? "pause" : "play"}`} />
          {endpoint.enabled ? "Paus" : "Resum"}
          {toggling ? "ing..." : "e"}
        </MenuItem>
        {/* </Tippy> */}

        <MenuItem data={endpoint} onMouseMove={this.contextHoverEvent} tabIndex="0" onClick={this.showEndpointModal}>
          <i className="fas fa-cog" /> Configure
        </MenuItem>

        <MenuItem onClick={this.showRemoveModal} tabIndex="0" data={endpoint} onMouseMove={this.contextHoverEvent}>
          <i className="fas fa-trash" /> Remove
        </MenuItem>
      </ContextMenu>
    );
  }

  /**
   * Reroute the page to the System map for the selected item
   */
  viewItem(event, endpoint) {
    event.stopPropagation();
    const qs = queryString.stringify({ network: endpoint.id });
    this.props.navigate(pages.sysmap + "?" + qs);
  }

  renderEndpoints() {
    const { theme, endpoints } = this.props;
    const { loading, filter, searchResults } = this.state;

    const endpointList = _.isEmpty(filter) ? endpoints : searchResults;

    return (
      <div className="right-col">
        <div className="card hosts">
          <div className="heading">
            <div className="header">Endpoints</div>
            <div className="actions right">
              <SearchInput initialValue={this.state.filter} onSubmit={this.updateFilter} />

              <Tippy
                content="Refresh list"
                animation="scale-subtle"
                theme="material"
                duration={global.gTTPDur}
                delay={[global.gTTPShow, 0]}
              >
                <div className="btn settings" onClick={this.refresh}>
                  <i className={`${loading.active ? "spin " : ""}fas fa-sync`} />
                </div>
              </Tippy>

              <Tippy
                content="Add Endpoint"
                animation="scale-subtle"
                theme="material"
                duration={global.gTTPDur}
                delay={[global.gTTPShow, 0]}
              >
                <div className="btn settings" onClick={this.showEndpointModal}>
                  <i className="fas fa-plus-circle" />
                </div>
              </Tippy>
            </div>
          </div>

          <div className="body">
            <div className="menu-sorting">
              <div className="right">
                <span>Sort:</span>
                <SortItem handleSort={this.handleSort} filter="Downtime" order={this.state.sortOrder} reverse /> |
                <SortItem handleSort={this.handleSort} filter="IP Address" order={this.state.sortOrder} /> |
                <SortItem handleSort={this.handleSort} filter="Name" order={this.state.sortOrder} />
              </div>
            </div>

            <Scrollbars
              renderThumbHorizontal={(_) => {
                return <div />;
              }}
              renderThumbVertical={(obj) => {
                return renderThumb(obj, theme);
              }}
              id="endpoint-list"
              autoHideDuration={1000}
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <div className="grid">
                {endpointList.length === 0 ? (
                  <Fragment>
                    {loading.active ? (
                      <div className="load-msg">
                        {/* <i className="fas fa-sync spin"></i> */}
                        <i>Loading data...</i>
                      </div>
                    ) : (
                      <Fragment>
                        {loading.failed ? (
                          <div className="error">Could not load endpoint list. Please try again later.</div>
                        ) : (
                          <div className="empty">
                            <i>
                              {!_.isEmpty(filter)
                                ? "No endpoints match search."
                                : "No endpoints have been configured for monitoring."}
                            </i>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {endpointList.map((endpoint) => (
                      <MonitorEntry
                        item={endpoint}
                        togglingEndpoints={this.props.togglingEndpoints}
                        selected={endpoint.id === this.props.selectedEntry.id}
                        highlighted={endpoint.id === _.get(this.state.highlightedEndpoint, "id", "")}
                        theme={this.props.theme}
                        renderContext={this.renderEndpointContext}
                        onShowContext={this.onShowContext}
                        selectEntry={this.selectEntry}
                        key={endpoint.id}
                      />
                    ))}
                  </Fragment>
                )}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }

  renderHistory() {
    const { history } = this.props;
    const { first, last } = this.state.dateRange;

    let mainDataset = history.datasets && history.datasets.length > 0 ? history.datasets[0].points : [];

    const options = {
      title: {
        display: true,
        position: "left",
        text: "Percent Devices Available",
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: "day",
            },
            gridLines: {
              display: true,
            },
            ticks: {
              min: first,
              max: last,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
              max: 100,
              step: 20,
            },
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
    };

    return (
      <div className="card history">
        <div className="heading">
          <div className="header">History</div>
        </div>

        <div className="body">
          <Chart
            name="Availability"
            type={chartTypes.line}
            data={{ data: { points: mainDataset } }}
            uuid="endpoint-availability"
            theme={this.props.theme}
            colorSet={[this.props.theme.highlight_7]}
            options={options}
            yAxisUnits="%"
          />
        </div>
      </div>
    );
  }

  renderTopOffenders() {
    const { loading, dateRange } = this.state;
    const { topOutages, outageInfo, theme } = this.props;
    const options = {
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: "day",
            },
            gridLines: {
              display: true,
            },
            ticks: {
              min: dateRange.first,
              max: dateRange.last,
              callback: () => null,
            },
          },
        ],
        yAxes: [
          {
            gridLines: { display: true },
            ticks: {
              callback: () => null,
            },
          },
        ],
      },
      responsive: true,
      legend: { display: false },
      maintainAspectRatio: false,
    };

    return (
      <div className="card outage">
        <div className="heading">
          <div className="header">Consistently Down</div>
        </div>

        <div className="body">
          {topOutages.length === 0 ? (
            <Fragment>
              {loading.active ? (
                <div className="dash-table-empty">
                  <i>Loading data...</i>
                </div>
              ) : (
                <div className="dash-table-empty">
                  <i>Nothing to show.</i>
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {DISABLE_TABLE ? (
                <div className="top-outages">
                  {topOutages.map((item) => {
                    let color = item.enabled
                      ? item.active
                        ? theme.highlight_7
                        : theme.highlight_5
                      : saturateColor(theme.highlight_7, 0);
                    return (
                      <div key={item.id} className="item">
                        <div className="indicator" style={{ backgroundColor: color }} />
                        <p>{getEndpointDisplayName(item)}</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <table className="dash-table top-outages">
                  <thead>
                    <tr>
                      <th width="20%">Active</th>
                      <th width="40%">Name</th>
                      <th width="40%">History</th>
                    </tr>
                  </thead>

                  <tbody>
                    {topOutages.map((item) => {
                      let color = item.enabled
                        ? item.active
                          ? theme.highlight_7
                          : theme.highlight_5
                        : saturateColor(theme.highlight_7, 0);
                      let graph = outageInfo[item.id];

                      return (
                        <tr key={item.id} className="item">
                          <td>
                            <div className="indicator" style={{ backgroundColor: color }} />
                          </td>
                          <td>
                            <p>{getEndpointDisplayName(item)}</p>
                          </td>
                          <td>
                            {graph ? (
                              <Fragment>
                                {graph === "error" ? (
                                  <i>Failed to load</i>
                                ) : (
                                  <Chart
                                    name="Availability"
                                    type={chartTypes.line}
                                    data={graph}
                                    uuid="endpoint-outage"
                                    theme={theme}
                                    colorSet={[theme.highlight_5]}
                                    options={options}
                                    yAxisUnits="%"
                                  />
                                )}
                              </Fragment>
                            ) : (
                              <i className="spin fas fa-sync"></i>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { infoPaneOpen, inspecting, loading } = this.state;
    const { selectedEntry, downPercent, theme } = this.props;
    const canOpen = !_.isEmpty(selectedEntry);

    return (
      <main className="monitoring">
        {formatPageTitle("Endpoint Monitoring")}

        {this.renderEndpointModal()}
        {this.renderRemoveModal()}

        <InfoList
          open={infoPaneOpen}
          canOpenInfoPane={canOpen}
          openInfoPane={this.openInfoPane}
          closeInfoPane={this.closeInfoPane}
          inspecting={inspecting}
          groups={this.props.groups}
          theme={this.props.theme}
          uuid={`endpoint-history-${selectedEntry.id}`}
          {...this.props.actions}
        />

        <div className="page">
          <div className="title card">
            Endpoint Monitoring
            <Tippy
              animation="scale-subtle"
              theme="material"
              duration={global.gTTPDur}
              delay={[global.gTTPShow, 0]}
              content={global.gDemoMsg}
            >
              <div
                className="btn quart right"
                style={{
                  filter: "saturate(0) brightness(var(--disabled))",
                  cursor: "default",
                }}
              >
                Generate Report
              </div>
            </Tippy>
          </div>
          <div className="dashboard">
            <div className="left-col">
              <div
                className="overview spark"
                style={{
                  borderBottomColor: downPercent >= 55 ? theme.highlight_5 : theme.highlight_7,
                }}
              >
                <div className="field">
                  <div className="head">Down Endpoints</div>
                  <div className="metric">{loading.failed ? "-" : loading.active ? "Loading..." : `${downPercent}%`}</div>
                </div>
              </div>

              {this.renderTopOffenders()}

              {this.renderHistory()}
            </div>

            {this.renderEndpoints()}
          </div>
        </div>
      </main>
    );
  }
}

/**
 * Requests the list of endpoints associated with the currently authenticated user
 * from database.
 * @param {function name(params) {}} callback The function called when the request is successful
 * @param {ToastManager} toaster The ToastManager object to push errors to the screen
 */
export async function requestEndpointList(callback, toaster) {
  try {
    let endpointData = null;

    if (global.gFromJSON) {
      endpointData = global.gEndpointMonitoring;
      let endpointList = Object.values(endpointData.endpoints);
      endpointData.endpoints = endpointList;
      console.debug("MonitoringView::Endpoint Data JSON:\n", endpointData);
      callback(endpointData === undefined ? null : endpointData);
    } else {
      const response = await MyAPI.get(gateways.endpoints);

      console.debug("MonitoringView::Endpoint Data response:\n", response);
      if (response.statusCode !== 200) throw new ServerError(response);
      decompress(response.body, (endpointData) => {
        callback(endpointData === undefined ? null : endpointData);
      });
    }
  } catch (error) {
    toaster.add(requestErr(error), {
      appearance: "error",
      autoDismiss: true,
      autoDismissTimeout: global.gToastTimeout,
    });

    callback(null);
  }
}

// ====== Redux action and property mapping

/**
 * Retrieve information from store to be passed down React hierarchy
 */
const mapProps = (state) => {
  let infoGroups =
    state.monitoring.selectedEntry.length !== 0
      ? getItemGroups(state.monitoring.selectedEntry)
      : [
          {
            name: "Endpoint Info",
            infos: [{ name: <i>Nothing is selected.</i>, value: "" }],
          },
        ];

  return {
    groups: infoGroups,
    user: state.user,
    ...state.monitoring,
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(MonitoringActions, dispatch),
});

export default connect(mapProps, mapDispatch)(withToastManager(MonitoringView));
