// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Fragment } from "react";
import queryString from "query-string";
import "tippy.js/dist/backdrop.css";
import { connect } from "react-redux";
import { ContextMenu, MenuItem } from "../../../shared/external/react-contextmenu";
import * as d3 from "d3";
import _ from "lodash";

import { MyAPI, requestErr, listToObj, reduxDispatchDelay, authorize } from "../../../shared/functions/general";
import ResponsiveForceGraph from "./ResponsiveForceGraph";
import { tabs } from "../../settings/containers/ConsoleView";
import { hostIcons } from "../../../static/img/HostIcons";
import graphCache from "../graphcache";
import { blendColor, basicRiskColor } from "../../../shared/functions/color";
import { handleContextMenuClick } from "../../../shared/functions/ctxMenu";
import { decompress, getNodeLabel, stripDate } from "../../../shared/functions/formatting";
import { NodeRange, NodeClassification, pages, RiskLevel, site_cookies, gateways, FetchStatus } from "../../../shared/enumerations";
import {
  transformToD3,
  toggleSyncLock,
  drawHexagon,
  makeValidD3Graph,
  d3EdgeLink,
  filterGraph,
} from "../../../shared/functions/graphHelpers";
import WebWorker from "../../../shared/threading/WebWorker";
import timer from "../../../shared/threading/timer";
import Dropdown from "../../../shared/components/Dropdown";
import { defaultFilter, evaluatePresetRange, saveFilterToQuery } from "./FilterBar";
import { FilterKey, FilterOperation } from "./FilterOption";

// Number of seconds to wait for D3 to cool before unlocking frozen nodes
const UNFREEZE_INTERVAL = 10;
const freezeDisabled = true;

const particlesDisabled = true;

// Size of graph to limit physics simulation settings
const SIZE_LIMIT = 500;

// The duration in milliseconds graph animations like recentering and zooming
// should typically last
const INTERPOLATION_TIME = 300;

// The Hex value for hover fading blend
const HOVER_ALPHA = "f0";
// Percentage to blend main color to hover blend color (theme based)
const HOVER_BLEND = 0.1;

function getLinkName(link, nodes) {
  let first_name, last_name;
  nodes.forEach((node) => {
    if (node.id === link.source.id) first_name = node.ip;
    else if (node.id === link.target.id) last_name = node.ip;
  });
  return `the connection between ${first_name} and ${last_name};`;
}

/**
 * Contains the graph canvas
 * @since 0.2.2
 */
class GraphArea extends React.Component {
  constructor(props) {
    super(props);

    this.graphcache = graphCache; // get the singleton for the app
    this.freezeTimer = null;

    const def_opts = {
      d3VelocityDecay: 0.28,
      d3AlphaDecay: 0.055,
      d3AlphaMin: 0.002,
      dagMode: null,
      dagLevelDistance: 25,
      charge_distanceMax: 2000,
      charge_strength: -50,
      link_distance: 35,
      forceX: 0.1,
      forceY: 0.125, // offset helps with perpetual dynamo forces
      // collision_it: 5, // not currently exposed by module
    };

    let opts;
    if (global.gisProd) {
      opts = def_opts;
    } else {
      let res = this.props.cookies.get(site_cookies.graph_opts);
      opts = res ? res : def_opts;
      if (!res) this.props.cookies.set(site_cookies.graph_opts, JSON.stringify(opts), global.gCookieSettings);
    }

    this.state = {
      d3GraphData: {
        nodes: [],
        links: [],
      },
      d3GraphUpdated: Date.now(),
      d3graphneighbors: [],
      d3link: null,
      d3HoverNode: null,
      d3GraphOptions: opts,
      d3GraphOptions_toggled: false,
      d3LimitSim: false,

      // filter as was applied to current graph (evaluated datetime)
      appliedFilter: {},

      isolating: false,
      polling: false,
      frozen: false,

      // node that was right clicked
      ctxNode: null,
      ctxEdge: null,

      // Whether the screen is being panned
      panning: false,
    };

    this.d3graph_zoom_level = 1;
    // Used in conjunction with react-sizeme to force rerenders
    this.window_size = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.fgRef = React.createRef(); // this is the ref to the d3 force graph
    this.initializedForces = false;
    this.isolateNode = this.isolateNode.bind(this);
    this.filterExisting = this.filterExisting.bind(this);
    this.manageNode = this.manageNode.bind(this);
    this.requestNetworkInfo = this.requestNetworkInfo.bind(this);
    this.startFreezeTimer = this.startFreezeTimer.bind(this);
    this.killFreezeTimer = this.killFreezeTimer.bind(this);
    this.unfreezeGraph = this.unfreezeGraph.bind(this);
    this.clickNode = this.clickNode.bind(this);
    this.contextClickNode = this.contextClickNode.bind(this);
    this.contextClickEdge = this.contextClickEdge.bind(this);
    this.clickEdge = this.clickEdge.bind(this);
    this.clickBackground = this.clickBackground.bind(this);
    this.mergeGraph = this.mergeGraph.bind(this);
    this.finalizeFetch = this.finalizeFetch.bind(this);
    this.d3DrawNode = this.d3DrawNode.bind(this);
    this.d3LinkColor = this.d3LinkColor.bind(this);
    this.d3LinkDash = this.d3LinkDash.bind(this);
    this.d3LinkWidth = this.d3LinkWidth.bind(this);
    this.onNodeHover = this.onNodeHover.bind(this);
    this.onLinkHover = this.onLinkHover.bind(this);
    this.onZoomEnd = this.onZoomEnd.bind(this);
    this.onDragEvent = this.onDragEvent.bind(this);
    this.addGraphOptionControl = this.renderGraphOptions.bind(this);
    this.toggleGraphOptionsDisplay = this.toggleGraphOptionsDisplay.bind(this);
  }

  componentDidMount() {
    // Cannot use setState here as connecting to the Redux store will cause
    // setState to be called on an unmounted component
    this.setAuth().then((isAuth) => (this.isAuth = isAuth));

    this.window_size.width = window.innerWidth;
    this.window_size.height = window.innerHeight;

    // this is to be able to have a ref to this component from system map view,
    // without getting function components canot be given refs
    // https://github.com/reactjs/reactjs.org/issues/2120
    const { childRef } = this.props;
    childRef(this);

    // Load filter and request graph if one is selected
    let qs = queryString.parse(window.location.search);
    let networkID = qs.network;
    let filter = this.props.loadFilter(true, !networkID);

    // Only keep filter in query string if, when network list is open, it is not the default filter
    // The querystring filter should ideally be empty when no network is selected
    if (!qs.network && _.isEqual(filter, defaultFilter)) filter = {};

    if (networkID) {
      // add top level nodes to the network
      this.props.actions.viewNetwork({ id: networkID });
      // let preserve = networkID === current_graph_network_id;
      this.requestNetworkInfo(networkID, this.props.toastManager, false, true, undefined, filter);
    } else {
      this.props.openNetList();
    }
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  shouldComponentUpdate(nP, nextState) {
    return (
      nP.networkID !== this.props.networkID ||
      this.props.iteration !== nP.iteration ||
      this.props.selectedItem !== nP.selectedItem ||
      this.state.d3GraphUpdated !== nextState.d3GraphUpdated ||
      this.state.ctxNode !== nextState.ctxNode ||
      this.state.d3GraphOptions_toggled !== nextState.d3GraphOptions_toggled ||
      this.window_size.width !== window.innerWidth ||
      this.window_size.height !== window.innerHeight ||
      this.state.panning !== nextState.panning ||
      this.state.d3LimitSim !== nextState.d3LimitSim ||
      (!this.initializedForces && this.fgRef && this.fgRef.current)
    );
  }

  /**
   * Begins fetch of currently selected network, only if
   * anything is selected
   */
  async componentDidUpdate(_, prevState) {
    // console.debug("GraphArea::updated");
    // const { actions } = this.props;
    // const { stopNetFetch } = actions;

    this.window_size.width = window.innerWidth;
    this.window_size.height = window.innerHeight;

    // let emptyNow = networkID === undefined || networkID === -1;
    // let emptyBefore = prevP.networkID === undefined || prevP.networkID === -1;

    // fgref.current has been assigned, so force settings may now be applied
    if (!this.initializedForces && this.fgRef && this.fgRef.current) {
      this.initD3Force();
    }

    // reheat simulation for toggling open graph option debug window
    if (prevState.d3GraphOptions_toggled !== this.state.d3GraphOptions_toggled) {
      if (this.fgRef && this.fgRef.current && this.state.d3GraphOptions.dagMode == null) {
        this.fgRef.current.d3ReheatSimulation();
      }
    }
  }

  async setAuth() {
    return authorize(this.props.authGroups);
  }

  mergeGraph(newGraph, preserve, clear = false) {
    const { d3GraphData } = this.state;
    const d3DictGraph = {
      nodes: listToObj(d3GraphData.nodes, "id"),
      links: listToObj(d3GraphData.links, "id"),
    };
    console.debug("GraphArea::New graph received: ", newGraph);

    newGraph.nodes = listToObj(newGraph.nodes);
    newGraph.edges = listToObj(newGraph.edges);
    let newD3Graph = transformToD3(newGraph);

    let resultGraph;

    if (clear) {
      resultGraph = newD3Graph;
    } else {
      resultGraph = _.merge(d3DictGraph, newD3Graph);

      if (!preserve) {
        let intersection = { nodes: {}, links: {} };
        // Preserve location of nodes in new isolated graph, but remove old nodes not in new
        Object.keys(d3DictGraph.nodes).forEach((uid) => {
          if (uid in newD3Graph.nodes) intersection.nodes[uid] = resultGraph.nodes[uid];
        });
        Object.keys(d3DictGraph.links).forEach((uid) => {
          if (uid in newD3Graph.links) intersection.links[uid] = resultGraph.links[uid];
        });

        resultGraph = intersection;
      }
    }

    let valid_graph = makeValidD3Graph(resultGraph);
    this.setGraphData(valid_graph, !clear);
  }

  // On finalize message callback, check empty graph
  async finalizeFetch() {
    await reduxDispatchDelay();
    let { d3GraphData: graph } = this.state;

    // d3 graph has nodes and links
    // console.log("Empty check on graph::", graph)
    const isEmpty = _.isEmpty(graph.nodes) && _.isEmpty(graph.links);
    this.props.actions.emptyCheck(isEmpty);
    if (isEmpty) {
      this.setGraphData({});
    } else {
      if (this.state.frozen || this.state.polling) {
        if (!freezeDisabled) this.startFreezeTimer();
        this.setState({ polling: false });
      }

      // Start the timer to poll for new updates
      this.props.startPollTimer();
    }
  }

  unfreezeGraph() {
    this.killFreezeTimer();
    console.debug("GraphArea::freeze timer unfreezing nodes");
    toggleSyncLock(this.state.d3GraphData, false);
  }

  startFreezeTimer() {
    console.debug("GraphArea::freeze Timer started");
    // Ensure no timer is already running
    this.killFreezeTimer();

    this.freezeTimer = new WebWorker(timer);
    this.freezeTimer.onmessage = this.unfreezeGraph;
    this.freezeTimer.postMessage([UNFREEZE_INTERVAL]);
  }

  /** Halt the execution of the poll timer to prevent polling */
  killFreezeTimer() {
    if (!this.freezeTimer) return;
    console.debug("GraphArea::freeze timer killed");
    this.freezeTimer.terminate();
    delete this.freezeTimer;
  }

  /** Determine if a node can be context clicked with enough options to display */
  contextDetails(node) {
    const { networkID } = this.props;
    let unmanageable = !this.isAuth;
    let nonfocusable = false;
    let isInternal = ![NodeClassification.CLOUD_PROVIDER, NodeClassification.EXTERNAL].includes(node.classification);

    if ([NodeRange.ORG, NodeRange.HOST, NodeRange.ADDRESS].includes(node.range)) {
      unmanageable = true;
    }

    if ([NodeRange.ORG].includes(node.range)) {
      nonfocusable = true;
    }

    if (node.range === NodeRange.ADDRESS) {
      nonfocusable = [NodeClassification.MISSING].includes(node.classification);
    }

    let identical = networkID === node.id;

    return {
      hasContext: !unmanageable || (!identical && !nonfocusable),
      unmanageable,
      identical,
      nonfocusable,
      isInternal: isInternal && this.isAuth && node.risk > 0,
    };
  }

  renderEdgeContext(edge) {
    return (
      <ContextMenu id="ctx-graph">
        <MenuItem className="heading">
          <span>{edge.name}</span>
        </MenuItem>
        <MenuItem data={edge} tabIndex="0" onClick={this.props.onShowResetRiskModal}>
          <i className="fas fa-undo" />
          Reset Risk
        </MenuItem>
      </ContextMenu>
    );
  }

  /**
   * Render the context menu for when a node is context clicked
   * @since 0.5.0
   */
  renderContext() {
    const { ctxNode: node, ctxEdge } = this.state;
    if (ctxEdge) return this.renderEdgeContext(ctxEdge);
    if (!node || node.range === NodeRange.MISSING) return <div />;

    const name = node.range === NodeRange.ADDRESS ? node.ip : node.name;
    const { isInternal, unmanageable, identical, nonfocusable } = this.contextDetails(node);

    return (
      <ContextMenu id="ctx-graph">
        <MenuItem className="heading">
          <span>{name}</span>
        </MenuItem>
        <MenuItem divider />

        {!identical && !nonfocusable && (
          <MenuItem data={node} onClick={this.isolateNode}>
            <i className="fas fa-search" />
            Isolate
          </MenuItem>
        )}

        {isInternal && (
          <MenuItem data={node} tabIndex="0" onClick={this.props.onShowResetRiskModal}>
            <i className="fas fa-undo" />
            Reset Risk
          </MenuItem>
        )}

        {!unmanageable && (
          <MenuItem data={node} onClick={this.manageNode}>
            <i className="fas fa-cog" />
            Manage
          </MenuItem>
        )}
      </ContextMenu>
    );
  }

  /** Saves the graph simulations to cookies for easy reloading during development */
  saveGraphOptions(opts) {
    console.debug("GraphArea::Saving graph options");
    this.props.cookies.set(site_cookies.graph_opts, JSON.stringify(opts), global.gCookieSettings);
  }

  toggleGraphOptionsDisplay() {
    this.setState({ d3GraphOptions_toggled: !this.state.open });
  }

  // Adds graph option widget to display
  renderGraphOptions() {
    const { d3GraphOptions_toggled: open, d3GraphOptions } = this.state;

    const values = {
      d3VelocityDecay: { name: "Vel Decay" },
      d3AlphaDecay: { name: "Alpha Decay" },
      d3AlphaMin: { name: "Alpha Min" },
      dagMode: {
        name: "DAG Mode",
        options: ["", "td", "rl", "zout", "zin", "radialout", "radialin"],
      },
      dagLevelDistance: { name: "DAG Level Distance" },
      charge_distanceMax: {
        name: "Charge Distance Max",
        func: (x) => this.fgRef.current.d3Force("charge").distanceMax(x),
      },
      charge_strength: {
        name: "Charge Strength",
        func: (x) => this.fgRef.current.d3Force("charge").strength(x),
      },
      link_distance: {
        name: "Link Distance",
        func: (x) => this.fgRef.current.d3Force("link").distance(x),
      },
      forceX: { name: "Force X", apply: true },
      forceY: { name: "Force Y", apply: true },
      // collision_it: {name: "Collision Iterations"},
    };

    const onChangeCallback = (op, x, applicator, apply) => {
      if (this.fgRef.current && d3GraphOptions[op] !== x) {
        let opts = { ...d3GraphOptions };
        opts[op] = x === "" ? null : x;
        if (applicator !== undefined) applicator(x);

        this.saveGraphOptions(opts);
        this.setState(
          {
            d3GraphOptions_toggled: !open,
            d3GraphOptions: opts,
          },
          () => {
            if (apply) this.applyForceSettings();
          }
        );
      }
    };

    return (
      <div className={`graph-options${open ? " open" : ""}`}>
        {Object.entries(values).map(([op, val]) => {
          return (
            <div key={op} className="op">
              <label>{val.name}</label>
              {val.options !== undefined ? (
                <Dropdown
                  options={val.options}
                  value={d3GraphOptions[op]}
                  onChange={(event) => {
                    onChangeCallback(op, event.target.value, val.func, val.apply);
                  }}
                />
              ) : (
                <input
                  value={d3GraphOptions[op]}
                  onChange={(event) => {
                    onChangeCallback(op, event.target.value, val.func, val.apply);
                  }}
                />
              )}
            </div>
          );
        })}
        <div className="btn" onClick={this.toggleGraphOptionsDisplay}>
          {open ? "Close" : "Open"} Widget
        </div>
      </div>
    );
  }

  render() {
    const { networkID, fetching } = this.props;
    const renderGraph = networkID !== -1 && !_.isEmpty(this.state.d3GraphData.nodes);

    // the d3-graph uses color for hover that needs to refresh, this is in mem, so by not showing the graph
    // we force the color memory to refresh or we end up with __indexColor null errors
    d3.select("#d3-graph");
    return (
      <Fragment>
        {renderGraph ? ( //don't show or we need one node and we want it to redraw anyway
          <div id="d3-graph" onClick={this.clickBackground}>
            {this.renderContext()}

            <ResponsiveForceGraph
              virtualRef={this.fgRef} // Cannot set `ref` directly on function Components, so we must virtualize it
              graphData={this.state.d3GraphData}
              linkColor={this.d3LinkColor}
              linkWidth={this.d3LinkWidth}
              nodeLabel={(node) => {
                if (node.range === NodeRange.MISSING) {
                  return fetching ? "Loading data..." : "Node is missing.";
                }
                if (node.range === NodeRange.ADDRESS) return node.ip;
                return node.name;
              }}
              nodeCanvasObject={this.d3DrawNode}
              nodeRelSize={this.state.d3GraphOptions.node_rel_size} //ration of node circle area per val unit, default 4, smallest node is 20/4
              onNodeDragEnd={(node) => {
                // fix nodes after dragging
                // console.debug("GraphArea::onDragEnd: node:", node)
                node.fx = node.x;
                node.fy = node.y;
                node.fz = node.z;
              }}
              linkDirectionalParticles={particlesDisabled ? 0 : 2}
              linkDirectionalParticleWidth={particlesDisabled ? 0 : 2}
              d3VelocityDecay={this.state.d3GraphOptions.d3VelocityDecay}
              d3AlphaDecay={this.state.d3GraphOptions.d3AlphaDecay}
              d3AlphaMin={this.state.d3LimitSim ? this.state.d3GraphOptions.d3AlphaMin : 0}
              onNodeClick={this.clickNode}
              onNodeRightClick={this.contextClickNode}
              onLinkRightClick={this.contextClickLink}
              onLinkClick={this.clickEdge}
              onNodeHover={this.onNodeHover}
              onLinkHover={this.onLinkHover}
              onZoom={(k, x, y) => this.onDragEvent(true, k, x, y)}
              onZoomEnd={(k, x, y) => this.onDragEvent(false, k, x, y)}
              // Allows DAG modes on graphs with loops, though this is not advised
              // onDagError={() => {}}
              dagMode={this.state.d3GraphOptions.dagMode}
              dagLevelDistance={this.state.d3GraphOptions.dagLevelDistance}
              autoPauseRedraw={!this.state.d3LimitSim}
            />
          </div>
        ) : null}

        {!global.gIsProd && !global.gOptionsDisabled && this.renderGraphOptions()}
      </Fragment>
    );
  }

  /**
   * only used in d3 REACT_FORCE graph mode
   * @param {*} node
   * @param {*} ctx
   * @param {*} globalscale
   * @param {*} isshadowcontext
   */
  d3DrawNode(node, ctx, globalscale, isshadowcontext) {
    const { theme, selectedItem } = this.props;
    const { d3graphneighbors } = this.state;
    ctx.fillStyle = theme.tertiary;
    const { classification, range, risk, x, y, id, host_type } = node;
    let { name } = node;
    const hoverActive = !_.isEmpty(d3graphneighbors);

    // node size
    let radius;
    switch (range) {
      case NodeRange.ORG:
        radius = 2 * theme.medium;
        name = "Organization";
        break;
      case NodeRange.NETWORK:
        radius = theme.large;
        break;
      case NodeRange.SUBNET:
        radius = theme.medium;
        break;
      default:
        radius = theme.small;
    }

    let icon = null;
    if (host_type !== undefined && host_type !== null && Object.keys(hostIcons).includes(host_type.toString())) {
      icon = hostIcons[host_type.toString()];
    }

    // the selected node should be a bit larger
    if (selectedItem && selectedItem.data.id === id) {
      ctx.beginPath();
      ctx.arc(node.x, node.y, radius * 1.4, 0, 2 * Math.PI, false);
      ctx.fillStyle = theme.text + "b9";
      ctx.fill();
    }

    // node color
    let node_color;
    if (classification === NodeClassification.EXTERNAL) {
      // d3 react component will override color
      ctx.fillStyle = node_color = theme.highlight_1;
    } else if (classification === NodeClassification.MANAGED) {
      ctx.fillStyle = node_color = theme.primary_dark;
    } else if (classification === NodeClassification.UNMANAGED) {
      ctx.fillStyle = node_color = theme.highlight_6;
    } else if (classification === NodeClassification.MISSING) {
      ctx.fillStyle = node_color = theme.text;
    } else if (classification === NodeClassification.CLOUD_PROVIDER) {
      ctx.fillStyle = node_color = theme.highlight_9;
    } else {
      ctx.fillStyle = node_color = theme.tertiary;
    }

    let isHighlighted = false;
    if (hoverActive) {
      // we have some neighbors
      if (d3graphneighbors.includes(id)) {
        radius *= 1.4;
        isHighlighted = true;
      } else {
        // darken node color
        ctx.fillStyle = blendColor(HOVER_BLEND, node_color, theme.secondary_dark) + HOVER_ALPHA;
      }
    }

    // Node shape
    if (range === NodeRange.HOST) {
      drawHexagon(ctx, x, y, radius);
    } else {
      // make circle
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
      ctx.fill();
    }

    // risk ring
    if (risk && risk >= RiskLevel.CAT2) {
      let riskColor = basicRiskColor(risk);
      // darken ring if not highlighted and other nodes are
      if (!isHighlighted && hoverActive) riskColor = blendColor(HOVER_BLEND, riskColor, theme.secondary_dark) + HOVER_ALPHA;

      ctx.strokeStyle = riskColor;
      ctx.lineWidth = 2;
      ctx.stroke(); // circle border
    }

    // host type icon overlay
    if (icon) {
      // icon padding in pixels, X% of diameter
      const px = 0.3 * 2 * radius;
      let s = (radius * 2 - px) / icon.width;

      // TODO: create composite image in temporary canvas to use source instead
      // of source svg file, as there are limitations on the drawing capabilities of canvas
      // ctx.globalCompositeOperation = "destination-out";
      // ctx.fillStyle = theme.text
      //Recorded as work item 137 in DevOps
      ctx.drawImage(icon, x - (s * icon.width) / 2, y - (s * icon.height) / 2, s * icon.width, s * icon.height);
    }

    // label for large objects and internal address
    const label = getNodeLabel(node);

    if (label !== undefined && name !== undefined && !_.isEmpty(name)) {
      const s = 6;
      let x = node.x - ((s / 2) * name.length) / 2;
      let y = node.y + s / 2.0;
      ctx.font = `${s}px Montserrat`;
      ctx.strokeStyle = node_color;
      ctx.lineWidth = 1;
      ctx.strokeText(name, x, y);

      ctx.fillStyle = theme.text;
      ctx.fillText(name, x, y);
    }
  }

  /**
   * Used for customizing the link color
   */
  d3LinkColor(link) {
    const { theme, selectedItem } = this.props;
    const { risk, source, target } = link;
    const { d3graphneighbors, d3link, d3HoverNode } = this.state;
    let linkColor = theme.tertiary + "80";

    // color by selection
    if (selectedItem && selectedItem.data.id === link.id) {
      linkColor = theme.text;
    }

    if (!_.isEmpty(d3graphneighbors)) {
      //we have some neighbors
      if (d3link !== null) {
        linkColor = d3link === link ? theme.text : theme.tertiary + "50";
      } else {
        if (
          d3graphneighbors.includes(source.id) &&
          d3graphneighbors.includes(target.id) &&
          (source.id === d3HoverNode || target.id === d3HoverNode)
        ) {
          linkColor = theme.text;
        } else {
          linkColor = theme.tertiary + "50";
        }
      }
    } else if (d3link !== null) {
      linkColor = d3link === link ? theme.text : linkColor;
    }

    // color by topological
    if (link.topological) {
      linkColor = blendColor(0.75, linkColor, theme.primary + "40");
    }

    // color by risk
    if (risk && risk > RiskLevel.CAT1) {
      linkColor = basicRiskColor(risk);
    }

    /* const riskColor = riskGradient(theme, risk, true);
      try {
        linkColor = blendColor(0.8, linkColor, riskColor);
      } catch (err) {
        console.error(err);
      }*/

    return linkColor;
  }

  /** Dashed line configuration for D3 */
  d3LinkDash(link) {
    return link.topological ? [8, 2] : [];
  }

  d3LinkWidth(link) {
    const { selectedItem } = this.props;
    let width = 1;

    if (selectedItem && selectedItem.data.id === link.id) width += 1;
    if (this.state.d3link === link) width += 2;

    return width;
  }

  recenterGraph() {
    console.debug("GraphArea::recenterGraph");
    this.fgRef.current.centerAt(0, 0, INTERPOLATION_TIME);
  }
  zoomGraph(inc_or_dec) {
    const { d3graph_zoom_level } = this;

    console.debug("GraphArea::zoomGraph ", inc_or_dec);
    if (d3graph_zoom_level >= 0) {
      let new_zoom_level = d3graph_zoom_level + inc_or_dec * 0.1; //scale it
      this.fgRef.current.zoom(new_zoom_level, 3);
      //this.setState({d3_graph_zoom_level: new_zoom_level }) handled onZoomEnd
    } else {
      this.fgRef.current.zoom(1, 3);
    }
  }

  onZoomEnd({ k, x, y }) {
    // console.debug("Zoom ends ", k,x,y)
    // on initial graph load this gets set;
    // moved from setState as update could occur during existing state transition
    this.d3graph_zoom_level = k;
  }

  // ============ Event Handlers ============

  clearCTXNode() {
    this.setState({ ctxNode: null, ctxEdge: null });
  }

  async clickNode(node) {
    if (this.state.panning) return;

    //console.debug("GraphArea::clickNode ", nodeInfo)
    this.clearCTXNode();

    if (node) {
      console.debug("GraphArea::clickNode D3 |", node);
    }

    if (node && node.id) {
      if ([NodeRange.MISSING, NodeRange.ORG].includes(node.range)) return;

      let name;
      switch (node.range) {
        case NodeRange.ADDRESS:
          name = node.ip;
          break;
        default:
          name = node.name;
      }

      let resettable =
        ![NodeClassification.CLOUD_PROVIDER, NodeClassification.EXTERNAL].includes(node.classification) &&
        this.isAuth &&
        node.risk > 0;
      this.inspectGraphItem(node.id, node.range, {}, resettable, name);
    }
  }

  /**
   * Event fired when right clicked a node
   * @param {*} node
   * @param {Event} event
   */
  contextClickNode(node, event) {
    if (this.state.panning) return;

    // console.debug('CTX Node: ', node, '\nEvt: ', event)
    // Cannot show context menu if no item can be shown
    if (!this.contextDetails(node).hasContext) return;

    this.setState({ ctxNode: node });
    const id = "graph";
    event.target.id = id;
    handleContextMenuClick(event, id, this.props);
  }

  contextClickEdge(edge, event) {
    if (this.state.panning);
    if (edge.topological) return;

    let ctxEdge = Object.assign({}, edge);
    ctxEdge.name = getLinkName(edge, this.state.d3GraphData.nodes);
    ctxEdge.range = NodeRange.FLOW;

    this.setState({ ctxEdge });
    const id = "graph";
    event.target.id = id;
    handleContextMenuClick(event, id, this.props);
  }

  /**
   * Event fired when left clicked an Edge
   * @param {*} edge
   */
  clickEdge(edge) {
    if (this.state.panning) return;

    console.debug("GraphArea::clickEdge, ", edge);
    this.clearCTXNode();

    if (edge.topological) return;

    let args = {
      first_address: edge.source.id,
      second_address: edge.target.id,
    };

    let name = getLinkName(edge, this.state.d3GraphData.nodes);
    let resettable = this.isAuth && edge.risk > 0;
    this.inspectGraphItem(edge.id, NodeRange.FLOW, args, resettable, name);
  }

  /** Event fired when background of graph is clicked */
  clickBackground() {
    if (this.state.panning) return;

    this.clearCTXNode();
    const { actions, selectedItem } = this.props;
    // only clear the selection if there was something actually selected
    if (selectedItem && selectedItem.data && selectedItem.data.id) {
      actions.closeInfoPane();
      actions.inspectItem([], false, null);
    }
  }

  onNodeHover(node, prevNode) {
    // console.debug("GraphArea::hoverNode ", node)
    if (node !== null && node.range === NodeRange.ORG) return;

    // node is the new node the mouse is over, or it is null if it is not on a node
    if (node !== prevNode && node != null) {
      let neighbors = this.getNeighbors(node);
      this.setState({ d3graphneighbors: neighbors, d3HoverNode: node.id });
      //console.debug("GraphArea::Node neighbors ", neighbors)
    } else if (node == null) {
      this.setState({ d3graphneighbors: [], d3HoverNode: null });
    }
    //else if node === prevNode we should leave neighbors alone
  }

  onLinkHover(link, prevLink) {
    if (this.state.panning) return;

    if (link !== prevLink && link != null) {
      this.setState({
        d3link: link,
        d3graphneighbors: [link.source.id, link.target.id],
      });
    } else if (link == null) {
      this.setState({ d3graphneighbors: [], d3link: null });
    }
  }

  getNeighbors(node) {
    const { d3GraphData } = this.state;
    return d3GraphData.links.reduce(
      function (neighbors, link) {
        if (link.target.id === node.id) {
          neighbors.push(link.source.id);
        } else if (link.source.id === node.id) {
          neighbors.push(link.target.id);
        }
        return neighbors;
      },
      [node.id]
    );
  }

  onDragEvent(panning, k, x, y) {
    // console.debug("GraphArea::OnDrag/Zoom event: ", panning)

    if (!panning) this.onZoomEnd(k, x, y);

    this.setState({ panning });
  }

  /**
   * Initialize global properties in the React-Force-Graph
   */
  initD3Force() {
    const { d3GraphOptions: options } = this.state;
    this.fgRef.current.d3Force("center").x(0).y(0).z(0);
    this.fgRef.current.d3Force("charge").distanceMax(options.charge_distanceMax);
    this.fgRef.current.d3Force("charge").strength(options.charge_strength);
    this.fgRef.current.d3Force("link").distance(options.link_distance);
    this.applyForceSettings();

    // do not want to create a state update here
    this.initializedForces = true;
  }

  applyForceSettings() {
    // this.fgRef.d3Force('collision', d3.forceCollide(node => Math.sqrt(100 / (node.level + 1)) * this.state.d3GraphOptions.node_rel_size))
    this.fgRef.current.d3Force(
      "collision",
      d3.forceCollide((node) => node.radius)
    );
    // .iterations(this.state.d3GraphOptions.collision_it)
    this.fgRef.current.d3Force("forceX", d3.forceX().strength(this.state.d3GraphOptions.forceX).x(0.5));
    this.fgRef.current.d3Force("forceY", d3.forceY().strength(this.state.d3GraphOptions.forceY).y(0.5));
  }

  /**
   * Requests the graph information from the database of the current graph
   * @param {String} uuid The globally unique ID of the network
   * @param {*} toaster Reference to Toast manager object for pushing error messages to user
   * @param {boolean} preserve Preserve all nodes in merge. If false, only nodes in new graph will be saved
   * @param {boolean} clear Old graph refers to different network; all nodes will be considered new regardless
   * @param {boolean} isPoll Optional; Whether the request is a polling operation
   * @param {filter} preloadedFilter The filter preloaded upon mounting to fetch in earlier cycle
   * @since 0.0.5
   * */
  async requestNetworkInfo(uuid, toaster, preserve, clear, isPoll, preloadedFilter) {
    const { actions } = this.props;

    let networkChanged = this.props.networkID !== uuid;
    // without cloneDeep, applying the date range will affect all other use cases of the filter (not good!)
    let filter = preloadedFilter !== undefined ? _.cloneDeep(preloadedFilter) : _.cloneDeep(this.props.filter);
    if (_.isEmpty(filter)) filter = _.cloneDeep(this.props.loadFilter()); // This should load the default filter
    else if (networkChanged) {
      saveFilterToQuery(filter, this.props.navigate, this.props.cookies, true);
    }

    if (!isPoll) {
      filter[FilterKey.updated].value = evaluatePresetRange(filter[FilterKey.updated].value);
    } else {
      let now = Date.now();
      const { d3GraphUpdated } = this.state;
      // Ensure that the range option is fully ignored for polling
      filter[FilterKey.updated] = {
        value: Math.round(Math.abs(now - d3GraphUpdated) / 60000), // in minutes
      };
    }

    if (!freezeDisabled && !this.state.frozen) {
      const { d3GraphData } = this.state;
      const isEmpty = _.isEmpty(d3GraphData.nodes) && _.isEmpty(d3GraphData.links);
      // No need to unfreeze a graph that was previously empty
      if (!isEmpty) {
        toggleSyncLock(d3GraphData, true);
      }
    }

    // format filter object in preparation to send as HTTP payload
    let isAbsolute = filter[FilterKey.updated].op === FilterOperation.between;
    let payloadFilter = {
      ...filter,
      [FilterKey.updated]: isAbsolute
        ? {
            absolute: true,
            value: stripDate(filter[FilterKey.updated].value),
            secondary: stripDate(filter[FilterKey.updated].secondary),
          }
        : {
            value: filter[FilterKey.updated].value,
          },
    };

    this.setState({ polling: isPoll, appliedFilter: payloadFilter });
    actions.startNetFetch();

    let response;
    if (global.gFromJSON) {
      response = global.gDataJSON[uuid];
      if (response) {
        console.debug("GraphCache::getNetworkGraph mimicking socket gen events so that refresh statuses set");
        actions.initNetFetch(response.hierarchy, response.display_info);
        actions.stopNetFetch();
        let root_graph = response.graph; // the net call only returns the graph portion

        // in order to apply filter, need to mock the input graph as already coming from D3, not from JSON
        root_graph.nodes = listToObj(root_graph.nodes);
        root_graph.edges = listToObj(root_graph.edges);
        root_graph = d3EdgeLink(transformToD3(root_graph));
        let d3GraphData = {
          nodes: Object.values(root_graph.nodes),
          links: Object.values(root_graph.links),
        };

        // apply current filter to static graph
        response.graph = filterGraph(payloadFilter, d3GraphData).to_cache;
      } else {
        // Network does not exist locally
        response = {
          hierarchy: [{ id: uuid, name: "Not Found" }],
          display_info: {},
        };

        actions.initNetFetch(response.hierarchy, response.display_info);
        actions.storedGraph(uuid);
        actions.stopNetFetch();
      }

      this.mergeGraph(response.graph, preserve, clear);
    } else {
      try {
        Object.keys(payloadFilter).forEach((key) => (payloadFilter[key] = JSON.stringify(payloadFilter[key])));
        const query = queryString.stringify(payloadFilter);
        const url = `${gateways.netFetch.replace(":id", uuid)}?${query}`;

        response = await MyAPI.get(url);
        console.debug("Net fetch response: ", response);

        // NOTE: When VTL mapping is updated to match proxy integration, we will be able to pull the statusCode from the response
        // if (response.errorMessage !== undefined) throw new Error(response.errorMessage);

        if (response.statusCode !== 200) {
          let errorMessage = "Internal Server Error";
          let code = response.statusCode >= 400 && response.statusCode < 500 ? FetchStatus.NOT_FOUND : FetchStatus.FAILED;
          if (_.get(response, "body.message")) errorMessage = response.body.message;
          actions.stopNetFetch(errorMessage, code);
        } else if (typeof response.body === "string" || response.body instanceof String) {
          decompress(response.body, (body) => {
            actions.initNetFetch(body.hierarchy, null);
            this.mergeGraph(body.graph, preserve, clear);
            actions.stopNetFetch();
          });
        }
      } catch (error) {
        // console.error(error)
        toaster.add(requestErr(error), {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });
      }
    }
  }

  /**
   * Load data for the item given the specified parameters
   * @param {string} id The id of the object to inspect
   * @param {number} type The type of object to inspect; NodeRange
   * @param {*} args Additional information needed to grab information for flows
   * @param {string} name Name of item to display in modals
   * @param {boolean} resettable Whether the risk can be reset for this item
   */
  inspectGraphItem(id, type, args, resettable, name) {
    const { actions, updateInspection, toastManager, userId, filter } = this.props;
    updateInspection(true);

    graphCache
      .getItem(userId, id, type, args, filter)
      .then((response) => {
        actions.inspectItem({ id, display_info: response.display_info }, type !== NodeRange.FLOW, {
          id,
          resettable,
          range: type,
          name,
        });
      })
      .catch((error) => {
        console.error(error);
        toastManager.add(requestErr(error), {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });
      })
      .finally(() => {
        updateInspection(false);
      });
  }

  /**
   * Manage the settings for a particular node
   * @param {Event} _ event passed from context menu
   * @param {*} node
   */
  manageNode(event, node) {
    event.stopPropagation();
    if ([NodeRange.NETWORK, NodeRange.SUBNET].includes(node.range)) {
      // go to the management console with the item preselected
      const query = queryString.stringify({ tab: tabs.network, item: node.id });
      this.props.navigate(pages.configuration + "?" + query);
    } else {
      // We don't have a case yet where Addresses can be managed
    }
  }

  isolateNode(event, node) {
    event.stopPropagation();
    console.debug("GraphArea::Isolating node: ", node.id);
    this.setState({ isolating: true });

    // set the history before process finishes for safety
    let qs = queryString.parse(window.location.search);
    qs.network = node.id;
    const query = queryString.stringify(qs);
    console.debug("GraphArea::isolateNode setting qs");
    this.props.navigate(pages.sysmap + "?" + query);

    const { toastManager, actions } = this.props;
    actions.viewNetwork({ id: node.id, name: getNodeLabel(node) });
    this.requestNetworkInfo(node.id, toastManager, false, false);
  }

  filterExisting(filter) {
    const d3GraphData = _.cloneDeep(this.state.d3GraphData);
    this.setState({ isolating: true });
    let qs = queryString.parse(window.location.search);

    // create a bound callback to request new data
    let requestCallback = () => {
      const { toastManager } = this.props;
      this.requestNetworkInfo(qs.network, toastManager, false, false, undefined, filter);
    };

    requestCallback = requestCallback.bind(this);

    // freeze current graph to prevent jittering
    if (!freezeDisabled) {
      toggleSyncLock(d3GraphData, true);
      requestCallback(d3GraphData);
    } else {
      requestCallback();
    }
  }

  /** generic method that figures out what mode we are in
   * D3 and will set the data appropriately
   */
  async setGraphData(graph, merge = true) {
    const { d3GraphData } = this.state;

    if (graph === null || graph === undefined || _.isEmpty(graph)) {
      if (
        (d3GraphData !== undefined || d3GraphData !== undefined) &&
        d3GraphData.hasOwnProperty("nodes") &&
        !_.isEmpty(d3GraphData.nodes)
      ) {
        // reset, this is different graph for different network
        console.debug("GraphArea::setGraphData reset d3GraphData to empty");
        this.setState({
          d3GraphData: { nodes: [], links: [] },
          d3graphneighbors: [],
          isolating: false,
        });
      }
      return;
    }

    // console.debug("GraphArea::setGraphData setting graph ", _.cloneDeep(graph) ,
    // "\nD3 internal: ", _.cloneDeep(d3GraphData))
    this.setD3Data(graph, merge);
  }

  setD3Data(graph) {
    let newD3Graph = transformToD3(graph);

    let newGraph = {
      nodes: Object.values(newD3Graph.nodes),
      links: Object.values(newD3Graph.links),
    };

    this.setState(
      {
        d3GraphData: newGraph,
        d3GraphUpdated: Date.now(),
        isolating: false,
        d3LimitSim: newGraph.nodes.length > SIZE_LIMIT,
      },
      this.finalizeFetch
    );
  }
}

export default connect(null, null)(GraphArea);
