// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import { callIfExists, store } from "../external/react-contextmenu/helpers";

/**
 * The following methods are pulled from react-contextmenu/actions.js.
 * The module itself is no longer being maintained.
 */
export const MENU_SHOW = "REACT_CONTEXTMENU_SHOW";
export const MENU_HIDE = "REACT_CONTEXTMENU_HIDE";

export function handleContextMenuClick(event, id, props, elem) {
  if (props.disable) return;
  // if (props.disableIfShiftIsPressed && event.shiftKey) return;

  event.preventDefault();
  event.stopPropagation();

  let x = event.clientX || (event.touches && event.touches[0].pageX);
  let y = event.clientY || (event.touches && event.touches[0].pageY);

  if (props.posX) {
    x -= props.posX;
  }
  if (props.posY) {
    y -= props.posY;
  }

  hideMenu();

  let data = callIfExists(props.collect, props);
  let showMenuConfig = {
    position: { x, y },
    target: elem,
    id: id.startsWith("ctx-") ? id : `ctx-${id}`,
  };
  if (data && typeof data.then === "function") {
    // it's a promise
    data.then((resp) => {
      showMenuConfig.data = Object.assign({}, resp, {
        target: event.target,
      });
      showMenu(showMenuConfig);
    });
  } else {
    showMenuConfig.data = Object.assign({}, data, {
      target: event.target,
    });
    showMenu(showMenuConfig);
  }
}

export function dispatchGlobalEvent(eventName, opts, target = window) {
  // Compatibale with IE
  // @see http://stackoverflow.com/questions/26596123/internet-explorer-9-10-11-event-constructor-doesnt-work
  let event;

  if (typeof window.CustomEvent === "function") {
    event = new window.CustomEvent(eventName, { detail: opts });
  } else {
    event = document.createEvent("CustomEvent");
    event.initCustomEvent(eventName, false, true, opts);
  }

  if (target) {
    target.dispatchEvent(event);
    Object.assign(store, opts);
  }
}

export function showMenu(opts = {}, target) {
  dispatchGlobalEvent(MENU_SHOW, Object.assign(opts, { type: MENU_SHOW }), target);
}

export function hideMenu(opts = {}, target) {
  dispatchGlobalEvent(MENU_HIDE, Object.assign(opts, { type: MENU_HIDE }), target);
}
