// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";

import splash from "../../static/img/404.svg";
import "../styles/NotFound.css";

const NotFound = ({ childProps }) => (
  <React.Fragment>
    <div className="not-found-bg" />
    <div className="NotFound">
      <div className="body">
        <img className="splash" src={splash} alt="Error 404" />
        <h3>You're lost. Let's fix that.</h3>
        <div
          className="btn quart"
          onClick={() => {
            childProps.navigate(-1);
          }}
        >
          Back
        </div>
      </div>
    </div>
  </React.Fragment>
);
export default NotFound;
