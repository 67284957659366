// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

export const SET_NETWORK_LIST = "SET_NETWORK_LIST";
export const REMOVE_NETWORK = "REMOVE_NETWORK";
export const FAIL_NET_MANAGE_OPERATION = "FAIL_NET_MANAGE_OPERATION";
export const INIT_NET_MANAGE_OPERATION = "INIT_NET_MANAGE_OPERATION";
export const CLEAR_NETWORKS = "CLEAR_NETWORKS";
export const VIEW_NETWORK = "VIEW_NETWORK";
export const UPDATE_NETWORK = "UPDATE_NETWORK";
export const INSPECT_ITEM = "INSPECT_GRAPH_ITEM";
export const EXPAND_ITEM = "EXPAND_GRAPH_ITEM";

export const UPDATE_HIERARCHY = "UPDATE_HIERARCHY";
export const SET_FILTER = "SET_NETWORK_FILTER";

export const START_NET_FETCH = "START_NET_FETCH";
export const INIT_NET_FETCH = "INIT_NET_FETCH";
export const PAUSE_NET_FETCH = "PAUSE_NET_FETCH";
export const STOP_NET_FETCH = "STOP_NET_FETCH";
export const EMPTY_CHECK = "EMPTY_NET_CHECK";
export const STORED_GRAPH = "STORED_GRAPH";

export const SET_THREATS_LIST = "SET_THREATS_LIST";
