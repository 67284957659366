// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
import queryString from "query-string";

import { FetchStatus, pages } from "../../../shared/enumerations";

/**
 * A object containing information on a network
 * contained within the Directory component
 *
 * @since 0.2.2
 */
export default class DirectoryItem extends Component {
  /**
   * Selects a network to view in the graph
   */
  select = async () => {
    const { last, network, actions, killPollTimer, fetcherStatus, requestNetworkInfo, toastManager } = this.props;

    // Cannot view an already selected network
    if (last || network.id === -1 || fetcherStatus === FetchStatus.ACTIVE) return;

    killPollTimer();
    let qs = queryString.parse(window.location.search);
    qs.network = network.id;
    const query = queryString.stringify(qs);
    actions.viewNetwork({ id: network.id }, true);
    console.debug("DirectoryItem::select setting qs");
    this.props.navigate(`${pages.sysmap}?${query}`);
    requestNetworkInfo(network.id, toastManager, true, false);
  };

  render() {
    const { network, last } = this.props;
    return (
      <div className={`net-item${last ? " selected" : ""}`} key={network.id ? network.id : network.name} onClick={this.select}>
        {!last && <div className="backing" />}
        {network.name}
        <div className={`right-arrow${last ? " selected" : ""}`}></div>
      </div>
    );
  }
}
