// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import * as actions from "./actionTypes";

/**
 * Changes the user's theme preference
 * @since 0.2.2
 * @param {*} theme The ID for the theme requested
 */
export const changeTheme = (theme) => ({
  type: actions.CHANGE_THEME,
  theme: theme,
});

/**
 * Adds the user object to the redux store, so that it doesn't need to be pulled from
 * other means
 * @param {*} user
 */
export const setUser = (user) => ({
  type: actions.CACHE_USER,
  user,
});

/**
 * Set the dispatch function of the Redux store. The primary use of this
 * is to pass it down to the WebSocket so that it may update the store
 * @param {function} dispatch
 * @param {WebSocket} socket
 */
export const setDispatch = (dispatch, socket) => ({
  type: actions.SET_DISPATCHER,
  dispatch,
  socket,
});
