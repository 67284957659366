// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";
import Helmet from "react-helmet";
import { Buffer } from "buffer";
import pako from "pako";
import { addMilliseconds } from "date-fns";
import _ from "lodash";

import { app_name } from "../constants";
import { NodeClassification, NodeRange } from "../enumerations";

/**
 * Formats the given number into currency string
 * @param {number} num
 */
export function formatCurrency(num) {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(num);
}

/**
 * Format a Date object to human readable string
 * @param {Date} date The date to format
 */
export function formatDate(date, dash = false, includeTime = false) {
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  let yyyy = date.getFullYear();
  const s = dash ? "-" : "/";
  let result = `${mm}${s}${dd}${s}${yyyy}`;

  if (includeTime) {
    let hr = String(date.getHours()).padStart(2, "0");
    let mn = String(date.getMinutes()).padStart(2, "0");
    let sc = String(date.getSeconds()).padStart(2, "0");
    result += ` ${hr}:${mn}:${sc} UTC`;
  }

  return result;
}

/**
 * Translate ISO 8601 date from javascript version to Python version
 *
 * TODO: This can probably be replaced by date-fns methods
 * @param {*} date Expected to be in local time. Accepts strings and Date object.
 * @returns Timestamp in UTC time
 * */
export function isoDate(date, includeTime = true) {
  let dateString = new Date(date);
  const offset = dateString.getTimezoneOffset();
  dateString = addMilliseconds(dateString.getTime(), -offset * 60 * 1000);
  let timestamp = dateString.toISOString().replace("Z", "");
  if (!includeTime) {
    timestamp = timestamp.substring(0, timestamp.indexOf("T"));
  }

  return timestamp;
}

/**
 * @param {Date} date
 * @returns UTC ISO 8601 without milliseconds, seconds, or timezone. Used for filter values
 */
export function stripDate(date) {
  let month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // month starts at 0
  let day = date.getUTCDate().toString().padStart(2, "0");
  let hours = date.getUTCHours().toString().padStart(2, "0");
  let minutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${date.getUTCFullYear()}-${month}-${day}T${hours}:${minutes}`;
}

/**
 * Formats a string into a proper noun, capitalizing the first letter
 * @param {string} str A string formatted as "string · string · ..."
 */
export function properNoun(str) {
  const except = ["of", "a", "the", "by", "in", "on", "with", "for"];
  const words = str.split(" ");
  let res = "";
  words.forEach((s, index) => {
    if (s) {
      const skip = index === 0 ? true : !except.includes(s);
      if (s !== "" && skip) res += s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase() + " ";
    }
  });
  return res.trim();
}

/**
 * Formats the phone number into a specific standard
 * @param {string} p The 10-digit phone number, typically preceeded by +1
 */
export function formatPhone(p) {
  p = p.trim();
  if (!p.startsWith("+1")) p = "+1" + p;
  if (!p.startsWith("+")) p = "+" + p;
  return `${p.substr(0, 2)} (${p.substr(2, 3)})-${p.substr(5, 3)}-${p.substr(8)}`;
}

export function formatPageTitle(page_name) {
  return (
    <Helmet>
      <title>
        {page_name} | {app_name}
      </title>
    </Helmet>
  );
}

/**
 * Formats numbers to the nearest 3rd order of magnitude
 * e.g. 1000 => 1K
 * @param {number} value
 * Source: https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 */
export function formatMetric(value) {
  if (value < 1000) return `${value}`;

  let i = -1;
  let units = ["K", "M", "B", "T"];
  do {
    value = value / 1000;
    i++;
  } while (value > 1000);

  let result = Math.max(value, 0.1).toFixed(1);
  // max places = 3
  result = result >= 100 ? result.toFixed(0) : result;
  return `${result} ${units[i]}`;
}

/**
 * Round a float to a displayable string, removing trailing 0's and the decimal where possible
 * @param {number} places
 * @returns {string}
 */
export function pythonicRound(value, places = 2) {
  let s = value.toFixed(places);
  return s.replace(/\.?0+$/, "");
}

/** Determines which value should be displayed for a host entry.
  Assigned name > hostname > IP Address
*/
export function getEndpointDisplayName(host) {
  if (host.assigned_name) return host.assigned_name;
  if (host.name !== null && host.name !== undefined) return host.name;
  return host.ip_address;
}

/**
 * Decompress a GZIP compressed portion of the payload
 */
export function decompress(bytestring, callback) {
  let json;

  try {
    let bits = bytestring.substr(2, bytestring.length - 3);
    let b = new Buffer.from(bits, "base64");

    let json_string = pako.inflate(b, { to: "string" });
    json = JSON.parse(json_string);
  } catch (error) {
    console.error(error);
    json = undefined;
  }

  console.debug("Decompressed message: ", json);

  if (callback) callback(json);
}

export function formatChartDate(value) {
  //Create the new date to get the full format and then get the timezone offset so there are no translation errors
  //between UTC and the local timezone.
  let dateString = new Date(value);
  const offset = dateString.getTimezoneOffset();
  dateString = new Date(dateString.getTime() + offset * 60 * 1000);
  let fixedDate = dateString.toDateString().slice(4, value.length);

  return fixedDate;
}

/**
 * Formats graph data for use in Recharts graphs.
 * Input: {x_units, y_units, datasets: [{label: "label1", points: [{x: "xval", y: "yval"}]}]}
 * Output: {xUnits, yUnits, points: [{x: xval, label1: yval, label2: yval}]}
 *
 * Warning: Cannot handle multiple Y values at same X value in single dataset.
 * Higher index Y values will overwrite lower ones
 */
export function formatRechartsData(chart) {
  const normalized = {};
  const dataKeys = [];

  chart.datasets.forEach((set, i) => {
    let dataKey = _.get(set, "label", "").toLowerCase();
    if (i > 0 && !dataKey) {
      console.warn("functions::Found a dataset with no label at index: ", i);
      dataKeys.push("");
    } else {
      dataKeys.push(dataKey);
    }

    // X values shared between datasets will be merged to contain multiple Y values
    // Y values will inherit the `label` as the key
    let values = _.reduce(
      set.points,
      (points, { x, y }) => {
        if (chart.x_units === "time") x = formatChartDate(x);
        return { ...points, [x]: { x: x, [dataKey]: y } };
      },
      {}
    );
    // Preserve existing points
    _.merge(normalized, values);
  });

  return {
    xUnits: chart.x_units,
    yUnits: chart.y_units,
    dataKeys, // For use in Legends
    points: Object.values(normalized),
  };
}

export function formatChartJSData({ x_units, y_units, datasets }) {
  return {
    xAxisUnits: x_units,
    yAxisUnits: y_units,
    datasets,
  };
}

export function formatChartLabel(value, units) {
  if (units === undefined || units === null) units = "";
  return units === "$" ? `$${value}` : `${value}${units}`;
}

export function getNodeLabel(node) {
  let label = node.name;
  if (node.range === NodeRange.ORG) label = "Organization";
  else if (node.range === NodeRange.ADDRESS && node.classification !== NodeClassification.EXTERNAL) label = node.ip;
  else if (node.range === NodeRange.HOST) {
    // disable label for endpoint nodes
    label = undefined;
  }

  return label;
}
