// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import { FormText, FormGroup, FormControl } from "react-bootstrap";
import { Auth } from "@aws-amplify/auth";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";

import { pages, site_cookies, gateways } from "../../../shared/enumerations";
import { genericError } from "../../../shared/constants";
import Home from "./Home";
import Password from "../../../shared/components/Password";
import LoaderButton from "../../../shared/components/LoaderButton";
import { formatPageTitle } from "../../../shared/functions/formatting";
import { MyAPI } from "../../../shared/functions/general";
import Tippy from "@tippyjs/react";

const passLen = 8;
const passMax = 99;

/** Set of rules dictating how a password is restricted */
export const passRules = {
  minimum: {
    msg: `Minimum ${passLen} characters`,
    resolver: (pass) => pass.length >= passLen,
  },
  maximum: {
    msg: `Maximum ${passMax + 1} characters`,
    resolver: (pass) => pass.length <= passMax,
  },
  piechart: {
    msg: `Special characters (!@#$%^&*_-=+( ){ }[ ]~\`;:' ",.<>?| / \\)`,
    // eslint-disable-next-line
    resolver: (pass) => pass.match(/[!@#$%^&*(){}~`;:<>?,.'"_\-\=\+|\[\]\\\/]/gm) !== null,
  },
  lowercase: {
    msg: `Lower case characters (a-z)`,
    resolver: (pass) => pass.match(/[a-z]/gm) !== null,
  },
  uppercase: {
    msg: `Upper case characters (A-Z)`,
    resolver: (pass) => pass.match(/[A-Z]/gm) !== null,
  },
  numeric: {
    msg: `Numbers (0-9)`,
    resolver: (pass) => pass.match(/[0-9]/gm) !== null,
  },
  match: {
    msg: `passwords do not match`,
    resolver: (pass, sec) => pass === sec,
  },
};

export const processPassword = (password, confirmPassword) => {
  const fails = [];
  Object.values(passRules).forEach((rule) => {
    if (!rule.resolver(password, confirmPassword)) fails.push(rule.msg);
  });

  return fails;
};

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      confirmationCode: "",
      organization: "",
      newUser: null,
      usrErrMsg: "",
      showPass: false,

      passErrMsg: "",
      confErrmsg: "",
      orgErrMsg: "",
      password: "",
      confirmPassword: "",
      fails: [],
    };
  }

  componentDidMount() {
    /**
     * If user was trying to signup but they refreshed the page,
     * we can salvage the state by loading from the cookies
     */
    const newUser = this.props.cookies.get(site_cookies.newUser);
    if (newUser) {
      const hash = newUser.hash;
      delete newUser.hash;
      this.setState({ newUser, email: newUser.user.username, password: hash });
    }
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      processPassword(this.state.password, this.state.confirmPassword).length === 0 &&
      this.state.organization.length > 0
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handlePassChange = (event) => {
    this.handleChange(event, (password, confirmPassword) => {
      this.setState({ fails: processPassword(password, confirmPassword) });
    });
  };

  handleOrgChange = (event) => {
    event.target.id = "organization";
    this.handleChange(event);
  };

  handleChange = (event, callback) => {
    this.setState(
      {
        [event.target.id]: event.target.value,
      },
      () => {
        if (callback) callback(this.state.password, this.state.confirmPassword);
      }
    );
  };

  async check_Key() {
    try {
      const response = await MyAPI.pre_post(gateways.checkKey, {
        key: this.state.organization,
      });

      return response.valid;
    } catch (error) {
      console.error(error);
      return -1;
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true, passErrMsg: "", orgErrMsg: "" });

    const valid_key = this.check_Key();
    if (valid_key === -1) return;
    if (valid_key)
      try {
        const newUser = await Auth.signUp({
          username: this.state.email,
          password: this.state.password,
          attributes: {
            email: this.state.email,
            "custom:org_key": this.state.organization,
          },
        });
        this.setState({
          newUser,
          passErrMsg: "",
          isLoading: false,
        });

        // console.debug('Cached User:\n',newUser)

        /** Here we temporarily save the user data to a cookie
         * So we can retrieve it if anything goes wrong
         */
        newUser.hash = this.state.password;
        this.props.cookies.set(site_cookies.newUser, JSON.stringify(newUser), {
          path: pages.signup,
          sameSite: "strict",
          maxAge: 300,
        });
      } catch (e) {
        if (e.code === "InvalidPasswordException") {
          this.setState({
            passErrMsg: e.message.substr(e.message.indexOf(": ") + 2) + ".",
          });
        } else if (e.code === "UsernameExistsException") {
          this.setState({
            usrErrMsg: e.message.substr(e.message.indexOf(": ") + 1) + ".",
          });
        } else {
          this.props.toastManager.add(genericError, {
            appearance: "error",
          });
          console.error(e);
        }

        this.setState({ isLoading: false });
      }
    else {
      this.setState({ orgErrMsg: "This is not a valid key" });
    }
  };

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      const user = await Auth.signIn(this.state.email, this.state.password);

      this.props.cookies.remove(site_cookies);
      this.props.authCallback(true, user.signInUserSession, pages.sysmap);
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  };

  toggleShowPass(_, value) {
    this.setState({ showPass: value });
  }

  renderConfirmationForm() {
    return (
      <Fragment>
        <FormGroup controlId="confirmationCode">
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
            placeholder="Confirmation Code"
          />
          <FormText>Please check your email for the code.</FormText>
        </FormGroup>
        <LoaderButton
          className="quart btn-lg btn-block"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </Fragment>
    );
  }

  renderForm() {
    return (
      <Fragment>
        <input type="hidden" value="prayer" />
        <FormGroup controlId="email">
          {this.state.usrErrMsg.length > 0 && <div className="error">{this.state.usrErrMsg}</div>}
          <FormControl
            autoFocus
            type="email"
            autoComplete="email"
            value={this.state.email}
            onChange={this.handleChange}
            placeholder="Email"
          />
        </FormGroup>

        <FormGroup controlId="organization">
          <Tippy
            content="This is a 32-character code sent to you by your System Administrator."
            animation="scale-subtle"
            theme="material"
            duration={global.gTTPDur}
            delay={[global.gTTPShow, 0]}
          >
            <input
              // id = 'organization'
              className="form-control"
              type="text"
              autoComplete="false"
              onChange={this.handleOrgChange}
              value={this.state.organization}
              placeholder="Organization Key"
            />
          </Tippy>
        </FormGroup>

        <FormGroup controlId="password">
          {this.state.passErrMsg.length > 0 && <div className="error">{this.state.passErrMsg}</div>}

          {this.state.fails.length > 0 && (
            <ul className="error">
              <p>Password Restrictions:</p>
              {this.state.fails.map((message, i) => (
                <li className="err" key={i}>
                  {message}
                </li>
              ))}
            </ul>
          )}

          <Password
            value={this.state.password}
            onChange={this.handlePassChange}
            autoComplete="password"
            onToggle={(_, value) => this.toggleShowPass(_, value)}
            placeholder="Password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword">
          <Password
            value={this.state.confirmPassword}
            onChange={this.handlePassChange}
            autoComplete="password"
            placeholder="Confirm Password"
            visible={this.state.showPass}
            hideToggle
          />
        </FormGroup>
        <LoaderButton
          className="quart btn-lg btn-block"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Signup"
          loadingText="Signing up…"
        />
      </Fragment>
    );
  }

  render() {
    const notUser = this.state.newUser === null;
    const handler = notUser ? this.handleSubmit : this.handleConfirmationSubmit;
    return (
      <Home isAuthenticated={this.props.isAuthenticated} navigate={this.props.navigate}>
        {formatPageTitle("Signup")}

        <div className="Signup body">
          <form onSubmit={handler}>
            <h3>Signup</h3>
            {notUser ? this.renderForm() : this.renderConfirmationForm()}

            <div className="sep-h" />
            <div className="options">
              <Link to={pages.login}>Returning User</Link>
            </div>
          </form>
        </div>
      </Home>
    );
  }
}
export default withToastManager(Signup);
