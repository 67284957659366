// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import * as actions from "./actionTypes";
import { initialState } from "../../../shared/constants";
import _ from "lodash";

export default function monitorReducer(state = initialState.monitoring, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case actions.UPDATE_MONITORING_SUMMARY:
      newState.endpoints = action.data.endpoints;

      // populated when data orginates from backend
      if (action.data.top_outages !== undefined) {
        newState.topOutages = action.data.top_outages;
        newState.history = action.data.history;

        let downCount = _.reduce(action.data.endpoints, (sum, host) => sum + (!host.active ? 1 : 0), 0);
        newState.downPercent =
          action.data.endpoints.length === 0 ? 0 : Math.round((100 * downCount) / action.data.endpoints.length);
      }

      return newState;
    case actions.INSPECT_ENDPOINT:
      newState.selectedEntry = action.endpoint;
      return newState;
    case actions.TOGGLE_ENDPOINT_STATE:
      newState.togglingEndpoints = [action.endpointId, ...state.togglingEndpoints];
      return newState;
    case actions.FINISH_ENDPOINT_STATE_TOGGLE:
      for (let i = 0; i < state.togglingEndpoints.length; i++) {
        if (state.togglingEndpoints[i] === action.endpointId) {
          newState.togglingEndpoints.splice(i, 1);
          break;
        }
      }
      return newState;
    default:
      return state;
  }
}
