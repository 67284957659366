// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import _ from "lodash";

import * as actions from "./actionTypes";
import { initialState } from "../../../shared/constants";
import { FetchStatus } from "../../../shared/enumerations";
import { isNull } from "../../../shared/functions/general";

/**
 * Reducer for handling graph fetch process
 * @since 0.6.1
 * @param {*} state
 * @param {*} action
 */
export default function fetchReducer(state = initialState.sysmap.fetch, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case actions.VIEW_NETWORK:
      if (_.isEmpty(action.network)) {
        newState.iteration = 0;
      }

      newState.preserve = action.preserve ? action.preserve : false;
      return newState;
    case actions.START_NET_FETCH:
      newState.status = FetchStatus.ACTIVE;
      return newState;
    case actions.INIT_NET_FETCH:
      newState.iteration = 0;
      newState.isEmpty = false;
      newState.chunk_history = {};
      if (action.hierarchy && action.hierarchy.length > 0) {
        newState.hierarchy = action.hierarchy;
      }
      return newState;
    case actions.STOP_NET_FETCH:
      newState.iteration = 0;
      if (action.error !== undefined && action.error !== null) {
        newState.error = action.error;
        newState.status = !isNull(action.errorCode) ? action.errorCode : FetchStatus.FAILED;
      } else {
        newState.error = null;
        newState.status = FetchStatus.INACTIVE;
      }

      return newState;
    case actions.EMPTY_CHECK:
      newState.isEmpty = action.isEmpty;
      return newState;
    default:
      return state;
  }
}
