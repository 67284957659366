// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
// import queryString from 'query-string'
import { ContextMenu, MenuItem } from "../../../shared/external/react-contextmenu";
// import _ from 'lodash'

// import {
//   requestErr,
//   blendColor,
//   riskGradient,
//   authorize
// } from '../../../shared/functions/static'
// import { RiskLevel, site_cookies } from '../../../shared/constants'

import mapCache from "../map_cache";

export default class MapArea extends Component {
  constructor(props) {
    super(props);

    this.mapCache = mapCache;

    this.state = {
      ctx_region: null,
      region_data: null,
    };
  }

  async mergeMap(new_map, iteration) {
    const dictMap = {};
    console.debug("MapArea::New map received: ", new_map);
    this.mapCache.mergeMap(new_map, dictMap, iteration).then((merged_map) => {
      this.setMapData(merged_map);
    });
  }

  componentDidMount() {
    // this.mapCache.getMap()
  }

  renderContext() {
    const { ctx_region: region } = this.state;

    if (!region) return null;

    return (
      <ContextMenu id="ctx-map">
        <MenuItem className="heading">
          <span>{region.nam}</span>
        </MenuItem>
      </ContextMenu>
    );
  }

  render() {
    return <div></div>;
  }
}
