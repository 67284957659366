// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
import Slider from "@mui/material/Slider";
import PropTypes from "prop-types";
import { subMinutes, differenceInMinutes, formatDistance } from "date-fns";
import _ from "lodash";

import { isNull } from "../../../shared/functions/general";
import { pythonicRound, stripDate } from "../../../shared/functions/formatting";
import { MAX_TIME_OFFSET } from "../../../shared/constants";

const SCALE = 60;
const RANGE_MARKS = []; // currently, values in minutes (step by day)
for (let index = 0; index <= MAX_TIME_OFFSET + 1; index += 1440) {
  const day = pythonicRound(index / 1440);
  RANGE_MARKS.push({
    value: -index,
    label: index === 0 ? "Now" : `${day} d`,
  });
}

/**
 * Converts a set of date times to offsets in minutes from the reference date
 * @param {Array} date Array of values expressed in date
 * @param {*} referenceDate
 * @returns {number} Negative number representing offset in past
 */
function convertDateToOffset(date, referenceDate) {
  if (isNull(date)) return 0;

  let diff = differenceInMinutes(referenceDate, date) / 60;
  let value = -Math.abs(diff);

  return isNaN(value) ? 0 : value;
}

/**
 * @param {number} offset
 * @param {Date} referenceDate
 * @param {Date} originalDate
 * @returns {Date}
 */
function convertOffsetToDate(offset, referenceDate, originalDate) {
  let newDate = subMinutes(referenceDate, Math.abs(offset * SCALE));
  return isNaN(newDate.getTime()) ? (isNull(originalDate) ? referenceDate : originalDate) : newDate;
}

/** Minimum distance between both points in slider */
export const minDistance = 1;

// TODO: This component expects values to be in Date only.
export default class FilterSlider extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      values: [-10, 0],
    };

    this.onChange = this.onChange.bind(this);
    this.valueText = this.valueText.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { filter, field, referenceDate, range } = props;

    return {
      ...state,
      values: [
        convertDateToOffset(_.get(filter, `${field}.value`), referenceDate),
        convertDateToOffset(_.get(filter, `${field}.secondary`), referenceDate),
      ],
      minOffset: convertDateToOffset(range.max, referenceDate),
      maxOffset: convertDateToOffset(range.min, referenceDate),
    };
  }

  onChange(_event, newValues, activeThumb) {
    const { onChange, field, filter, referenceDate } = this.props;
    const { values, minOffset, maxOffset } = this.state;

    if (!onChange) return;
    if (!Array.isArray(newValues)) return;

    if (activeThumb === 0) {
      newValues = [Math.max(Math.min(newValues[0], values[1] - minDistance), minOffset), values[1]];
    } else {
      newValues = [values[0], Math.min(Math.max(newValues[1], values[0] + minDistance), maxOffset)];
    }

    let originalDates = [_.get(filter, `${field}.value`), _.get(filter, `${field}.secondary`)];
    const newDates = newValues.map((val, index) => convertOffsetToDate(val, referenceDate, originalDates[index]));
    onChange(field, newDates[0], undefined, newDates[1]);
  }

  valueText(offset) {
    const { referenceDate } = this.props;
    let date = convertOffsetToDate(offset, referenceDate);
    let value;
    if (isNull(date)) return offset;

    try {
      value = formatDistance(referenceDate, date);
    } catch {
      value = `${stripDate(date).replace("T", " ")} UTC`;
    }

    return value;
  }

  render() {
    return (
      <div className="filter-op vertical sliders">
        <Slider
          value={this.state.values}
          onChange={this.onChange}
          valueLabelDisplay="auto"
          getAriaValueText={this.valueText}
          valueLabelFormat={this.valueText}
          max={this.state.maxOffset}
          min={this.state.minOffset}
          step={1}
          disableSwap
        />

        <Slider
          className="slider-marks"
          defaultValue={0}
          step={SCALE}
          marks={RANGE_MARKS}
          max={this.state.maxOffset * SCALE}
          min={this.state.minOffset * SCALE - 1}
          valueLabelDisplay="off"
        />
      </div>
    );
  }
}
