// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Tippy from "@tippyjs/react";
import _ from "lodash";
import queryString from "query-string";
import { withToastManager } from "react-toast-notifications";
import { createStaticRanges } from "react-date-range/dist/defaultRanges";
import { addDays, addYears, startOfMonth, endOfMonth, addMonths, startOfWeek, endOfWeek, startOfYear, endOfYear } from "date-fns";

import * as ConsoleActions from "../reducers/actions";

import { BarChart, AreaChart, Area, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import Calendar from "../components/Calendar";

import { TELEMETRY_NAMES, gateways, site_cookies } from "../../../shared/enumerations";

import {
  /*formatCurrency, */ formatDate,
  formatPageTitle,
  formatRechartsData,
  isoDate,
} from "../../../shared/functions/formatting";
import { evaluateGradient } from "../../../shared/functions/color";
import Dropdown from "../../../shared/components/Dropdown";
import ResponsivePage from "../../../shared/containers/ResponsivePage";
import "../../../shared/styles/Dashboard.css";
import { MyAPI, requestErr, authorize, cookie_settings } from "../../../shared/functions/general";
import { ServerError } from "../../../shared/constants";

/** Default metrics to show when data is loading */
const defaultPrices = {
  "API Calls": { unit: "calls" }, // Why is this calls when the others are 'record'?
  Error: { unit: "record" },
  "VPC Flow Logs": { unit: "record" },
  Packetbeat: { unit: "record" },
  ICMP: { unit: "record" },
  // 'CloudTrail': { unit: 'record' },
  // 'NetFlow': { unit: 'record' },
  // 'sFlow': { unit: 'record' },
};

const LOADING_ACCOUNT = "...";
const DEFAULT_ACCOUNT = "All";

// Logic pulled from the react-date-picker
// Used to create custom static ranges
const DATE_RANGES = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),
};

/** Names for preset ranges */
const DATE_ALIASES = {
  thisMonth: {
    label: "This Month",
    range: () => ({
      startDate: DATE_RANGES.startOfMonth,
      endDate: DATE_RANGES.endOfMonth,
    }),
  },
  lastMonth: {
    label: "Last Month",
    range: () => ({
      startDate: DATE_RANGES.startOfLastMonth,
      endDate: DATE_RANGES.endOfLastMonth,
    }),
  },
  thisWeek: {
    label: "This Week",
    range: () => ({
      startDate: DATE_RANGES.startOfWeek,
      endDate: DATE_RANGES.endOfWeek,
    }),
  },
  lastWeek: {
    label: "Last Week",
    range: () => ({
      startDate: DATE_RANGES.startOfLastWeek,
      endDate: DATE_RANGES.endOfLastWeek,
    }),
  },
};

const staticRanges = createStaticRanges(Object.values(DATE_ALIASES));

/**
 * Tab Component for displaying the cost info. Contains an interactable bar
 * graph with transaction metrics related to app usage.
 * @since 0.4.1
 */
class BillingView extends Component {
  constructor(props) {
    super(props);

    const { start, end } = this.initDateRange();

    this.state = {
      // If the billing information is being refreshed
      refreshing: false,
      selectedAccount: DEFAULT_ACCOUNT,

      // fetch status for billing data (not accounts)
      loading: {
        active: false,
        failed: false,
      },

      // Range for billing metrics; must be Date object
      startDate: start,
      endDate: end,
      // The text value for the range of billing information to display
      rangeAlias: DATE_ALIASES.thisMonth.label,
      // saved to cookies instead of store for faster loading to filter by account
      accounts: undefined,

      // fetch status for accounts
      accountListFailed: false,
      accountListLoading: false,

      // initialization flag for billing so that refreshes don't display empty while active
      billingInit: false,
    };

    this.onChangeDate = this.onChangeDate.bind(this);
    this.renderPage = this.renderPage.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  /**
   * Initializes the date range using todays date to
   * the first and last day of the month
   */
  initDateRange() {
    let date = new Date();
    const res = {
      start: new Date(date.getFullYear(), date.getMonth(), 1),
      end: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    };

    // console.debug('AutoRange: ', res)
    return res;
  }

  componentDidMount() {
    const { authGroups, cookies, actions } = this.props;

    const isAuthorized = authorize(authGroups);
    this.setState({
      accounts: cookies.get(site_cookies.accounts),
      authorized: isAuthorized,
    });

    if (!isAuthorized) return;

    if (_.get(this.props.accounts, "length", 0) <= 0) {
      MyAPI.get(gateways.accounts)
        .then((response) => {
          console.debug("BillingView::Account list GET response:", response);
          if (response.statusCode !== 200) throw new Error(response.errorMessage);

          const accounts = response.body.accounts;
          this.setState({
            accountListFailed: false,
            accountListLoading: false,
            accounts: accounts.map((account) => account.id),
          });
          this.props.cookies.set(site_cookies.accounts, JSON.stringify(accounts), cookie_settings(3600 * 24 * 7));
          actions.setAccounts(accounts);
        })
        .catch((error) => {
          this.setState({ accountListFailed: true, accountListLoading: false });
          console.error(error);
        });
    }
    this.requestCostMetrics();

    if (this.state.loading.failed) return;
  }

  shouldComponentUpdate(nP, nS) {
    if (nP.billing !== undefined && this.state.billing === undefined) return true;
    let bill = false;
    if (nP.billing !== undefined)
      bill =
        this.props.billing.metrics !== nP.billing.metrics ||
        this.props.billing.logs !== nP.billing.logs ||
        this.props.billing.report !== nP.billing.report;
    return (
      this.state.refreshing !== nS.refreshing ||
      bill ||
      !_.isEqual(this.props.theme, nP.theme) ||
      this.state.startDate !== nS.startDate ||
      this.state.endDate !== nS.endDate
    );
  }

  /**
   * Retrieve cost metrics of the given organization
   */
  async requestCostMetrics(account_id = undefined) {
    this.setState({ loading: { active: true, failed: false } });
    try {
      let metrics;
      if (global.gFromJSON) {
        metrics = global.gBillingMetrics.body;
        console.debug("BillingView::configuration JSON data:", metrics);
      } else {
        const args = {
          startDate: isoDate(this.state.startDate, false),
          endDate: isoDate(this.state.endDate, false),
        };
        if (account_id !== undefined) {
          const { org_key } = this.props.billing;
          args.account_id = account_id === global.gDefaultAccount ? org_key : account_id;
        }

        let qs = queryString.stringify(args);
        let url = `${gateways.consoleData}?${qs}`;
        const response = await MyAPI.get(url);

        console.debug("BillingView::console fetch response:", response);
        metrics = response.body;
        if (parseInt(response.statusCode) !== 200) throw new ServerError(response);
      }

      this.setState({
        loading: { active: false, failed: false },
        billingInit: true,
      });
      this.props.actions.addMetrics(metrics);
    } catch (err) {
      this.setState({ loading: { active: false, failed: true } });
      this.props.toastManager.add(requestErr(err), {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: global.gToastTimeout,
      });
    }
  }

  onChangeDate(ranges) {
    this.setState(
      {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      },
      () => {
        let label = null;
        staticRanges.forEach((range) => {
          if (range.isSelected(ranges.selection)) {
            label = range.label;
          }
        });

        if (label === null) {
          label = `${formatDate(ranges.selection.startDate)} - 
          ${formatDate(ranges.selection.endDate)}`;
        }

        this.setState({ rangeAlias: label });
        this.requestCostMetrics();
      }
    );
  }

  refresh() {
    // Can't fetch while already fetching
    if (this.state.refreshing || this.state.loading.active) return;

    const comp = this;
    this.setState({ refreshing: true }, () => {
      let { selectedAccount } = comp.state;
      let account_id = selectedAccount !== LOADING_ACCOUNT && selectedAccount !== DEFAULT_ACCOUNT ? selectedAccount : undefined;
      comp.requestCostMetrics(account_id);
      comp.setState({ refreshing: false });
    });
  }

  handleAccountChange(event) {
    this.setState({ selectedAccount: event.target.value, refreshing: true }, () => {
      const { selectedAccount } = this.state;
      this.requestCostMetrics(selectedAccount !== DEFAULT_ACCOUNT ? selectedAccount : undefined);
      this.setState({ refreshing: false });
    });
  }

  /**
   * Take two values and determine the percent change and the
   * color associated with it from a gradient
   * @param {number} current The current value
   * @param {number} previous The last value
   */
  percChange(current, previous) {
    const { theme } = this.props;

    // Define cost gradient
    const gradient = {
      stops: [
        { color: theme.highlight_4, value: 0 },
        { color: theme.highlight_4, value: 0.1 },
        { color: theme.highlight_1, value: 0.35 },
        { color: theme.primary, value: 0.68 },
        { color: theme.primary_dark, value: 0.91 },
        { color: theme.primary_dark, value: 1 },
      ],
    };

    const range = 20;
    const value = previous === 0 ? 0 : ((current - previous) / Math.abs(previous)) * 100;

    return {
      value: Math.round(value),
      color: evaluateGradient(gradient, value, range, -range),
    };
  }

  /**
   * Splits a currency by the main denominations and its decimal. The
   * Decimal place is padded with 0s.
   * @param {number} val The value
   * @returns {dict} Formatted like {main: XXX <string>, decimal: XX <string>}
   */
  formatSplitCurrency = (val) => {
    val = Math.round(val * 100) / 100;
    let s = `${val}`;
    let m, d;
    if (!s.includes(".")) {
      m = `${val}`;
      d = "00";
    } else {
      const v = `${val}`.split(".");
      m = v[0];
      d = v[1].length === 1 ? "0" + v[1] : `${v[1]}`;
    }

    return { main: m, decimal: d };
  };

  //not used, handled in formatting.js
  /*formatChartDate(value){
  let dateString = new Date(value);
  let fixedDate = dateString.toDateString().slice(4, value.length);
console.log(fixedDate);
  return fixedDate;
}*/

  /**
   * Formats graph data for use in Recharts graphs.
   * Input: {x_units, y_units, datasets: [{label: "label1", points: [{x: "xval", y: "yval"}]}]}
   * Output: {xUnits, yUnits, points: [{x: xval, label1: yval, label2: yval}]}
   */
  formatRechartsData(chart) {
    const normalized = {};
    const dataKeys = [];

    chart.datasets.forEach((set, i) => {
      let dataKey = _.get(set, "label", "").toLowerCase();

      if (i > 0 && !dataKey) {
        console.warn("functions::Found a dataset with no label at index: ", i);
        dataKeys.push("");
      } else {
        dataKeys.push(dataKey);
      }

      // X values shared between datasets will be merged to contain multiple Y values
      // Y values will inherit the `label` as the key
      let values = _.reduce(
        set.points,
        (sum, { x, y }) => {
          if (chart.x_units === "time") x = this.formatChartDate(x);
          return { ...sum, [x]: { x: x, [dataKey]: y } };
        },
        {}
      );

      // Preserve existing points
      _.merge(normalized, values);
    });

    return {
      xUnits: chart.x_units,
      yUnits: chart.y_units,
      dataKeys, // For use in Legends
      points: Object.values(normalized),
    };
  }

  /** Render the metrics within specified range */
  renderCosts() {
    const accounts = this.props.accounts.length > 0 ? this.props.accounts : this.state.accounts;
    const { startDate, endDate } = this.state;
    let { metrics, prices } = this.props.billing;
    const data = formatRechartsData(metrics);
    const dateActions = {
      //Review below for future implementation (calendar buttons)
      // next: this.nextRange,
      // previous: this.previousRange,
      onChange: this.onChangeDate,
    };

    const empty = data.points.length === 0 || this.state.loading.failed;
    prices = global.gFromJSON ? defaultPrices : prices;

    const noAccts = accounts === undefined;
    const accountOpts = noAccts ? [LOADING_ACCOUNT] : [DEFAULT_ACCOUNT, ...accounts];
    return (
      <Fragment>
        <div className="overall-costs card">
          <div className="heading">
            <div className="header">Overall Costs</div>

            <div className="actions right">
              <div className="sub-header">Account:</div>
              <Dropdown
                value={noAccts ? LOADING_ACCOUNT : this.state.selectedAccount}
                options={accountOpts}
                onChange={(event) => {
                  this.handleAccountChange(event);
                }}
                id="selectedAccount"
                disabled={noAccts || this.state.refreshing}
              />

              <Calendar
                selTheme={this.props.theme}
                startDate={startDate}
                endDate={endDate}
                rangeAlias={this.state.rangeAlias}
                staticRanges={staticRanges}
                {...dateActions}
              />

              <div tabIndex="0" className="btn refresh" onClick={this.refresh}>
                <i className={`fas fa-sync${this.state.refreshing ? " spin" : ""}`} />
              </div>
            </div>
          </div>

          <div className="body">{this.renderCostsChart(data, empty)}</div>
        </div>

        <div className={`telemetry card${this.state.telemetryOpen ? "" : " minimized"}`}>
          <div className="heading">
            <div className="header">Cost Breakdown</div>
          </div>

          <div className="body">
            <div className="breakdown">
              <p>Total monthly costs are calculated by applying a rate to the total number of records received.</p>
              <p>To the right is a breakdown of the rate applied per service.</p>
            </div>

            <table className="dash-table">
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(this.state.loading.active ? defaultPrices : prices).map(([type, val]) => (
                  <tr key={type}>
                    <td>{type}</td>
                    <td>
                      ${this.state.loading.active ? "--.--" : val.rate} / {val.unit}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    );
  }

  renderServices() {
    const { services } = this.props.billing;
    const serviceData = formatRechartsData(services);
    const empty = services.datasets === undefined || services.datasets.length === 0 || this.state.loading.active;
    return (
      <div className="card service-cost">
        <div className="heading">
          <div className="header">Cost by Service</div>
        </div>

        <div className="body">
          <div className="body">{this.renderRechartServices(serviceData, empty)}</div>
        </div>
      </div>
    );
  }

  renderRechartServices(serviceData, empty) {
    const { theme } = this.props;

    return (
      <Fragment>
        {empty || this.state.loading.active ? (
          <div className="overlay" style={{ height: 200 }}>
            <div className="cd">{this.state.loading.active ? "LOADING DATA" : "NO DATA"}</div>
          </div>
        ) : (
          <div className="graph-container" style={{ height: 200 }}>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                align="left"
                height={400}
                data={serviceData.points}
                margin={{
                  top: 10,
                  right: 5,
                  left: 0,
                  bottom: 0,
                }}
              >
                <Tooltip
                  content={<this.CustomTooltipGeneric />}
                  cursor={false}
                  wrapperStyle={{ backgroundColor: "#000000" }}
                  contentStyle={{ backgroundColor: theme.backgroundColor }}
                />
                <Legend
                  iconType="rect"
                  align="left"
                  //todo: add onClick method for toggling visiblity

                  // this formatter should be defined as a function so as to not redefine it on each render
                  formatter={(value) => (
                    <span className="text-color-class" style={{ color: theme.text, fontSize: "1rem" }}>
                      {value}
                    </span>
                  )}
                />
                <XAxis dataKey="x" angle="-45" height={25} style={{ fontSize: ".8rem" }} interval={0} tickLine={false} />
                <YAxis
                  interval={0}
                  style={{ fontSize: "1rem" }}
                  axisLine={false}
                  tickLine={false}
                  width={25}
                  tickFormatter={(value) => `$${value}`}
                />
                <Tooltip />

                {serviceData.dataKeys.map((dataKey, i) => (
                  <Area
                    type="monotone"
                    key={dataKey}
                    dataKey={dataKey}
                    name={_.get(TELEMETRY_NAMES, dataKey, dataKey)}
                    stroke={theme.billingChartColors[i]}
                    fill={theme.billingChartColors[i] + "40"}
                  />
                ))}
              </AreaChart>
            </ResponsiveContainer>
          </div>
        )}
      </Fragment>
    );
  }

  // NOTE: This looks like it can be defined outside of the BillingView class
  CustomTooltipGeneric({ payload, label, active }) {
    if (active) {
      return (
        <Tippy
          content="Cost and Estimates"
          animation="scale-subtle"
          theme="material"
          duration={global.gTTPDur}
          delay={[global.gTTPShow, 0]}
        >
          <div className="custom-tooltip">
            <p className="intro">
              {`${label}`}

              {payload.map((entry, i) => {
                let value = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(entry.value);
                return (
                  <Fragment key={i}>
                    <br />
                    {`${entry.name}: ${value}`}
                  </Fragment>
                );
              })}
            </p>
          </div>
        </Tippy>
      );
    }

    return null;
  }

  renderCostsChart(chart, empty) {
    // Todo more formatting on graph
    const { theme } = this.props;

    return (
      <Fragment>
        {empty || this.state.loading.active ? (
          <div className="overlay" style={{ height: 210 }}>
            <div className="cd">{this.state.loading.active ? "LOADING DATA" : "NO DATA"}</div>
          </div>
        ) : (
          <div className="graph-container" style={{ height: 210 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={1500}
                height={210}
                data={chart.points}
                barGap={0}
                margin={{
                  top: 5,
                  right: 5,
                  left: 0,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="x" angle="-30" height={25} style={{ fontSize: "1rem" }} interval={0} tickLine={false} />
                <YAxis
                  interval={0}
                  style={{ fontSize: "1rem" }}
                  axisLine={false}
                  tickLine={false}
                  width={30}
                  tickFormatter={(value) => `$${value}`}
                />
                <Tooltip
                  content={<this.CustomTooltipGeneric />}
                  cursor={false}
                  wrapperStyle={{ backgroundColor: "#000000" }}
                  contentStyle={{ backgroundColor: theme.backgroundColor }}
                />
                <Legend
                  formatter={(value) => (
                    <span className="text-color-class" style={{ color: theme.text }}>
                      {value}
                    </span>
                  )}
                />

                <Bar name="Costs" dataKey="costs" fill={theme.highlight_10 + "40"} stroke={theme.highlight_10} />

                <Bar name="Estimated" dataKey="estimated" fill={theme.highlight_4 + "40"} stroke={theme.highlight_4} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </Fragment>
    );
  }

  renderPage() {
    let { report } = this.props.billing;
    const { billingInit, loading } = this.state;

    const curPerc = this.percChange(!billingInit ? 0 : report.current, !billingInit ? 0 : report.previous);
    const curSplit = !billingInit ? { main: "--", decimal: "--" } : this.formatSplitCurrency(report.current);
    const estSplit = !billingInit ? { main: "--", decimal: "--" } : this.formatSplitCurrency(report.est_current);
    const selSplit = loading.active ? { main: "--", decimal: "--" } : this.formatSplitCurrency(report.selected);

    return (
      <Fragment>
        <div className="metrics">
          <div className="current spark" style={{ borderBottomColor: curPerc.color }}>
            <div className="field">
              <div className="head">Current Month Costs</div>
              <div className="metric">
                ${curSplit.main}
                <span>{curSplit.decimal}</span>
              </div>
            </div>
            <div className="token" style={{ backgroundColor: curPerc.color }}>
              <div className="bed">
                <div className="rate">
                  {curPerc.value === 0 ? "-" : <i className={`fas fa-arrow-${curPerc.value > 0 ? "up" : "down"}`} />}
                </div>
                <div className="perc">{`${curPerc.value}`.replace("-", "")}%</div>
              </div>
            </div>
          </div>

          <div className="estimated">
            <div className="field">
              <div className="head">Estimated Month Costs</div>
              <div className="metric">
                ${estSplit.main}
                <span>{estSplit.decimal}</span>
              </div>
            </div>
          </div>
          <Tippy
            content="The total cost for the selected date range"
            animation="scale-subtle"
            theme="material"
            duration={global.gTTPDur}
            delay={[global.gTTPShow, 0]}
          >
            <div className="due">
              <div className="field">
                <div className="head">Selected Total</div>
                <div className="metric">
                  ${selSplit.main}
                  <span>{selSplit.decimal}</span>
                </div>
              </div>
            </div>
          </Tippy>
        </div>

        <div className="billing columns">
          <div className="full">{this.renderCosts()}</div>

          <div className="third">{this.renderServices()}</div>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <main className="console">
        {formatPageTitle("Billing")}
        <ResponsivePage renderPage={this.renderPage} />
      </main>
    );
  }
}

// ====== Redux action and property mapping

const mapState = (state) => {
  return {
    billing: state.settings.billing,
    accounts: state.settings.accounts.map((account) => account.id),
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(ConsoleActions, dispatch),
});

export default connect(mapState, mapDispatch)(withToastManager(BillingView));
