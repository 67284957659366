// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import invert from "invert-color";
import queryString from "query-string";
import Tippy from "@tippyjs/react";
import _ from "lodash";

import { tabs } from "../../settings/containers/ConsoleView";
import { riskGradient } from "../../../shared/functions/color";
import SortItem from "../../../shared/components/SortItem";
import { pages, ASC, DESC } from "../../../shared/enumerations";
import SearchInput from "../../../shared/components/SearchInput";

import "../styles/NetworkList.css";

const renderSubnets = true;

export default class NetworkList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      sortOrder: null,
      sortDir: ASC,
    };

    this.handleNetClick = this.handleNetClick.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.gotoNode = this.gotoNode.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  /** Handler for clicking a network */
  async handleNetClick(id, name) {
    const { actions, closeNetList, toastManager } = this.props;

    closeNetList();

    let qs = queryString.parse(window.location.search);
    qs.network = id;
    const query = queryString.stringify(qs);
    console.debug("NetworkList::handleNetClick setting qs");
    this.props.navigate(`${pages.sysmap}?${query}`);
    actions.viewNetwork({ name, id });
    this.props.requestNetworkInfo(id, toastManager, false, true);
  }

  handleSort(filter, reverse) {
    filter = filter.toLowerCase();
    let flipped = false;

    let direction = ASC;
    // Flip direction if Order is the same, else set dir to ASC
    if (this.state.sortOrder === filter) {
      direction = this.state.sortDir === ASC ? DESC : ASC;
      flipped = true;
    }

    if (reverse && !flipped) direction = direction === ASC ? DESC : ASC;

    this.setState({
      sortOrder: filter,
      sortDir: direction,
    });

    // Remove org level entry from set
    let oldList = this.props.networks;
    const org = oldList[global.gOrgLevelUID];
    delete oldList[global.gOrgLevelUID];

    let newList = _.orderBy(oldList, [filter], [direction]);
    newList = {
      [global.gOrgLevelUID]: org,
      ...newList,
    };

    this.props.actions.setNetworkList(newList);
  }

  // Go directly to a node not listed in list
  gotoNode(search) {
    if (search.length === 0) return;

    this.handleNetClick(search, search);
  }

  refresh() {
    if (this.props.refreshing) return;
    this.props.refreshList();
  }

  render() {
    const { networks, theme, open, refreshing } = this.props;
    const organizationEntry = networks[global.gOrgLevelUID];

    const sorting = (
      <div className="right">
        <span style={{ marginRight: "15px" }}>Sort:</span>
        <SortItem handleSort={this.handleSort} filter="Name" order={this.state.sortOrder} /> |
        {/* <SortItem handleSort={this.handleSort} filter="Risk" order={this.state.sortOrder} reverse={true} /> | */}
        <SortItem handleSort={this.handleSort} filter="CIDR" order={this.state.sortOrder} />
      </div>
    );

    return (
      <div className={`net-stats${!open ? " closed" : ""}`}>
        <div className="header">
          Networks
          <div
            className="btn"
            onClick={() => {
              const tab = queryString.stringify({ tab: tabs.net });
              this.props.navigate(pages.configuration + "#" + tab);
            }}
          >
            +
          </div>
        </div>

        <div className="menu-search">
          <Tippy
            content="Enter the hostname, IP address, or UID of a node your Organization owns."
            animation="scale-subtle"
            theme="material"
            duration={global.gTTPDur}
            delay={[global.gTTPShow, 0]}
          >
            <p>Find Node:</p>
          </Tippy>
          <SearchInput
            clearDisabled
            initialValue={this.state.search}
            onSubmit={this.gotoNode}
            submitIcon="fas fa-arrow-right"
            sanitizeRegex={/^[\w\-.]$/gm}
          />

          <Tippy
            content="Refresh network list"
            animation="scale-subtle"
            theme="material"
            duration={global.gTTPDur}
            delay={[global.gTTPShow, 0]}
          >
            <div className="btn" onClick={this.refresh}>
              <i
                className={`fas fa-sync-alt${refreshing || this.props.initializing ? " spin" : ""}`}
                style={{ marginLeft: "8px" }}
              />
            </div>
          </Tippy>
        </div>

        {/* TODO: Sorting buttons - clean up view/UI 
        Recorded as work item 136 in DevOps*/}
        <div className="menu-sorting">
          <div>{sorting}</div>
        </div>

        <div className="list">
          {(refreshing || this.props.initializing) && Object.entries(networks).length === 0 ? (
            <div className="net initializer">
              <div className="info">
                <i>Loading Networks...</i>
              </div>
            </div>
          ) : (
            <Fragment>
              {organizationEntry && (
                <ListItem key={organizationEntry.id} theme={theme} network={organizationEntry} onClick={this.handleNetClick} />
              )}
              {Object.entries(networks).map(([_, network]) => {
                if (network.id === global.gOrgLevelUID) return null;
                return <ListItem key={network.id} theme={theme} network={network} onClick={this.handleNetClick} />;
              })}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

class ListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.toggleChevron = this.toggleChevron.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  toggleChevron(e) {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  }

  handleClick() {
    this.props.onClick(this.props.network.id, this.props.network.name);
  }

  render() {
    const { network, theme, onClick } = this.props;
    const { open } = this.state;
    const org_level = network.id === global.gOrgLevelUID;

    const color = org_level ? theme.primary_dark : riskGradient(theme, network.risk);

    let text = invert(color, false);
    text = "#fff";
    const item = (
      <div>
        <div className="net" key={network.id} style={{ borderBottomColor: color }}>
          <div className="risk" style={{ backgroundColor: color, color: text }}>
            {/* NOTE: Aggregated network/subnet risk not implemented */}
            {/* {org_level ? <i className="fas fa-globe" /> : network.risk} */}
            {org_level ? <i className="fas fa-globe" /> : null}
          </div>

          <div className="info" onClick={this.handleClick}>
            {network.name}
            <span className="right">{org_level ? "" : `${network.cidr}`}</span>
          </div>
          {network.subnets.length > 0 && renderSubnets && (
            <div className={`indicator${open ? " open" : ""}`} onClick={this.toggleChevron}>
              <i className="fas fa-chevron-down" />
            </div>
          )}
        </div>
        {network.subnets.length > 0 && (
          <div
            key={`net-sub-${network.id}`}
            style={{
              height: open ? `${40 * network.subnets.length}px` : "0px",
            }}
            className={`subnet-list${open ? " open" : ""}`}
          >
            {renderSubnets &&
              network.subnets.map((subnet) => {
                const c = riskGradient(theme, subnet.risk);
                return (
                  <div
                    className="net sub"
                    key={`sub-${subnet.id}`}
                    style={{ borderBottomColor: c }}
                    onClick={() => onClick(subnet.id, subnet.name)}
                  >
                    <div className="risk" style={{ backgroundColor: c, color: text }}>
                      {/* {subnet.risk} */}
                    </div>

                    <div className="info">
                      {subnet.name}
                      <span className="right">{subnet.cidr}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );

    return network.id === global.gOrgLevelUID ? (
      <Tippy
        content="View all networks within your organization"
        animation="scale-subtle"
        theme="material"
        duration={global.gTTPDur}
        delay={[global.gTTPShow, 0]}
      >
        {item}
      </Tippy>
    ) : (
      item
    );
  }
}
