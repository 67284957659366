// This code is property of Auspex Labs Inc. and is protected by Trade Secret.
import _ from "lodash";

import * as actions from "./actionTypes";
import { isOperationActive } from "../../../shared/functions/general";
import { initialState } from "../../../shared/constants";

/**
 * Reducer for handling the list of networks
 * @since 0.2.2
 * @param {*} state
 * @param {*} action
 */
export default function networksReducer(state = initialState.sysmap.networks, action) {
  let newState = _.cloneDeep(state);
  switch (action.type) {
    case actions.SET_NETWORK_LIST:
      if (Array.isArray(action.netList)) {
        let newList = {};
        action.netList.forEach((net) => (newList[net.id] = net));
        newState.networkList = newList;
      } else newState.networkList = action.netList;
      return newState;
    case actions.REMOVE_NETWORK:
      if (_.isNil(action.parentId)) {
        delete newState.networkList[action.id];
      } else {
        // Remove subnet
        let parentNetwork = newState.networkList[action.parentId];
        parentNetwork.subnets = _.filter(parentNetwork.subnets, (subnet) => subnet.id !== action.id);
      }
      newState.results[action.id] = _.merge(state.results[action.id], {
        active: false,
      });

      newState.savingActive = isOperationActive(newState.results);
      return newState;
    case actions.CLEAR_NETWORKS:
      newState.networkList = {};
      return newState;
    case actions.INIT_NET_MANAGE_OPERATION:
      newState.results = action.results;
      newState.savingActive = true;
      return newState;
    case actions.FAIL_NET_MANAGE_OPERATION:
      if (action.id)
        newState.results[action.id] = _.merge(state.results[action.id], {
          active: false,
          failed: true,
        });

      newState.savingActive = isOperationActive(newState.results);
      return newState;
    case actions.UPDATE_NETWORK:
      newState.networkList = state.networkList.map((network, index) => {
        if (index === action.index) {
          return action.data;
        } else {
          return network;
        }
      });

      return newState;
    default:
      return state;
  }
}
