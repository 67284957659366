// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import * as actions from "./actionTypes";

export const viewRegion = (region) => ({ type: actions.VIEW_REGION, region });

export const clearMap = () => ({ type: actions.CLEAR_MAP });

export const startMapFetch = () => ({ type: actions.START_MAP_FETCH });

export const initMapFetch = (requestID) => ({
  type: actions.INIT_MAP_FETCH,
  requestID,
});

export const stopMapFetch = () => ({ type: actions.STOP_MAP_FETCH });

export const updateMap = (map, request_id, chunk_id, map_callback) => ({
  type: actions.UPDATE_MAP,
  map,
  request_id,
  chunk_id,
  map_callback,
});

export const emptyCheck = (isEmpty) => ({
  type: actions.EMPTY_MAP_CHECK,
  isEmpty,
});

export const storedMap = () => ({ type: actions.STORED_MAP });

export const finalizeMapFetch = (request_id, chunk_id, map_empty_callback) => ({
  type: actions.FINALIZE_MAP_FETCH,
  request_id,
  chunk_id,
  map_empty_callback,
});
