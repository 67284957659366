// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import { Auth } from "@aws-amplify/auth";
import { Link } from "react-router-dom";
import { FormGroup, FormControl } from "react-bootstrap";
import { withToastManager } from "react-toast-notifications";
import queryString from "query-string";
import Modal from "react-modal";
import _ from "lodash";

import Home from "./Home";
import { processPassword } from "./Signup";
import { pages } from "../../../shared/enumerations";
import { genericError, modalStyle } from "../../../shared/constants";
import LoaderButton from "../../../shared/components/LoaderButton";
import Password from "../../../shared/components/Password";
import { formatPageTitle } from "../../../shared/functions/formatting";

class ResetPasswordView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: null,
      email: "",
      password: "",
      codeSent: false,
      confirmed: false,
      confirmPassword: "",
      isConfirming: false,
      isSendingCode: false,

      modalOpen: false,
      modalTitle: "",
      modalMsg: "",

      errMessage: "",
      passErrMsg: "",
      showPass: false,
      fails: [],
    };

    this.closeModal = this.closeModal.bind(this);
    this.toggleShowPass = this.toggleShowPass.bind(this);
  }

  componentDidMount() {
    let qs = queryString.parse(window.location.search);
    if (qs.u && qs.i && this.validateCode(qs.i)) {
      try {
        this.setState({ code: qs.i, codeSent: true, email: atob(qs.u) });
      } catch (error) {
        console.error(error);
      }
    }
  }

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  validateCode(code) {
    return code.length === 6;
  }

  validateResetForm() {
    return this.state.code.length > 0 && processPassword(this.state.password, this.state.confirmPassword).length === 0;
  }

  toggleShowPass(_, value) {
    this.setState({ showPass: value });
  }

  handlePassChange = (event) => {
    this.handleChange(event, (password, confirmPassword) => {
      this.setState({ fails: processPassword(password, confirmPassword) });
    });
  };

  handleChange = (event, callback) => {
    this.setState(
      {
        [event.target.id]: event.target.value,
      },
      () => {
        if (callback) callback(this.state.password, this.state.confirmPassword);
      }
    );
  };

  closeModal() {
    this.setState({ modalMsg: "", modalOpen: false, modalTitle: "" });
  }

  handleSendCode = async (event) => {
    event.preventDefault();

    this.setState({ isSendingCode: true });

    try {
      await Auth.forgotPassword(this.state.email);
      this.setState({ codeSent: true, errMessage: "", isSendingCode: false });
    } catch (e) {
      if (e.code === "UserNotFoundException") {
        this.setState({ isSendingCode: false, errMessage: e.message });
      } else if (e.code === "NotAuthorizedException") {
        this.setState({
          isSendingCode: false,
          modalOpen: true,
          modalTitle: "User not verified",
          modalMsg:
            "Your password cannot be reset because your account has not " +
            "yet been verified. Please check your email for your current temporary password or " +
            "contact an administrator to obtain a new temporary password.",
        });
      } else {
        console.error(e);

        this.props.toastManager.add(genericError, {
          appearance: "error",
        });
        this.setState({ isSendingCode: false });
      }
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isConfirming: true });

    try {
      await Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.password);
      this.setState({ confirmed: true, confErrMsg: "" });
    } catch (e) {
      console.error(e);
      this.setState({ isConfirming: false });
      if (e.code === "ExpiredCodeException") {
        this.props.navigate(pages.reset);

        this.setState({
          code: null,
          codeSent: false,
          modalOpen: true,
          modalTitle: "ExpiredCode",
          modalMsg: e.message,
        });
      } else if (e.code === "") {
        this.setState({ confErrMsg: e.message });
      } else {
        this.props.toastManager.add(genericError, {
          appearance: "error",
        });
      }
    }
  };

  renderPasswordForm() {
    const { fails, passErrMsg } = this.state;
    return (
      <Fragment>
        <h3>Reset Password</h3>
        <p>Please enter a new password.</p>
        <FormGroup controlId="password">
          {passErrMsg.length > 0 && <div className="error">{passErrMsg}</div>}

          {fails.length > 0 && (
            <ul className="error">
              <p>Password Restrictions:</p>
              {fails.map((message, i) => (
                <li className="err" key={i}>
                  {message}
                </li>
              ))}
            </ul>
          )}
          <Password
            value={this.state.password}
            onChange={this.handlePassChange}
            onToggle={this.toggleShowPass}
            placeholder="New Password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword">
          <Password
            onChange={this.handlePassChange}
            value={this.state.confirmPassword}
            visible={this.state.showPass}
            placeholder="Confirm Password"
            hideToggle
          />
        </FormGroup>
        <LoaderButton
          type="submit"
          text="Confirm"
          loadingText="Confirm…"
          className="quart btn-lg btn-block"
          isLoading={this.state.isConfirming}
          disabled={!this.validateResetForm()}
        />

        <div className="sep-h" />
        <div className="options">
          <Link to={pages.signup}>
            <span>New User?</span>
          </Link>
        </div>
      </Fragment>
    );
  }

  renderRequestCodeForm() {
    return (
      <Fragment>
        <h3>Reset Password</h3>
        <p>Please enter the email you use to sign in. You will receive a confirmation email with a code provided.</p>
        <FormGroup bsSize="large" controlId="email">
          {this.state.errMessage.length > 0 && <div className="error">{this.state.errMessage}</div>}
          <FormControl autoFocus type="email" value={this.state.email} onChange={this.handleChange} placeholder="Email" />
        </FormGroup>
        <LoaderButton
          type="submit"
          loadingText="Sending…"
          text="Send Confirmation"
          className="btn-lg btn-block"
          isLoading={this.state.isSendingCode}
          disabled={!this.validateCodeForm()}
        />

        <div className="sep-h" />
        <div className="options">
          <Link to={pages.login}>Login</Link>
          <span>|</span>
          <Link to={pages.signup}>
            <span>New User?</span>
          </Link>
        </div>
      </Fragment>
    );
  }

  renderConfirmationForm() {
    return (
      <Fragment>
        <h3>Reset Password</h3>
        <FormGroup bsSize="large" controlId="code">
          <p>Please check your email for the confirmation code.</p>

          <LoaderButton
            type="submit"
            loadingText="Resending…"
            text="Resend Confirmation"
            className="btn-lg btn-block"
            isLoading={this.state.isSendingCode}
            disabled={!this.validateCodeForm()}
          />
        </FormGroup>
      </Fragment>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="success">
        <i className="fas fa-check" />
        <p>Your password has been reset.</p>
        <p>
          <Link to={pages.login}>Click here to login with your new credentials.</Link>
        </p>
      </div>
    );
  }

  render() {
    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];

    return (
      <Home isAuthenticated={this.props.isAuthenticated} navigate={this.props.navigate} className="ResetPassword">
        {formatPageTitle("Reset Password")}

        <Modal isOpen={this.state.modalOpen} style={style}>
          <div className="modal-pnl">
            <div className="header">{this.state.modalTitle}</div>

            <span className="info">{this.state.modalMsg}</span>

            <div className="footer">
              <div className="btn" onClick={this.closeModal}>
                OK
              </div>
            </div>
          </div>
        </Modal>

        <div className="body">
          {this.state.code === null ? (
            this.state.codeSent ? (
              <form onSubmit={this.handleSendCode}>{this.renderConfirmationForm()}</form>
            ) : (
              <form onSubmit={this.handleSendCode}>{this.renderRequestCodeForm()}</form>
            )
          ) : !this.state.confirmed ? (
            <form onSubmit={this.handleSubmit}>{this.renderPasswordForm()}</form>
          ) : (
            this.renderSuccessMessage()
          )}
        </div>
      </Home>
    );
  }
}

export default withToastManager(ResetPasswordView);
