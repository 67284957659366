// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
import _ from "lodash";
import { formatDate } from "../../../shared/functions/formatting";
import { UserGroupNames, UserGroups } from "../../../shared/enumerations";
import Checkbox from "../../../shared/components/Checkbox";

/**
 * @param {string} timestamp
 */
function readableDate(timestamp) {
  if (timestamp === "Never") return timestamp;

  timestamp += "Z";
  return formatDate(new Date(timestamp), false, true);
}

/** Because a user can belong to multiple groups at once in Cognito,
 * we want to only display the group with the highest level of permissions.
 */
function minGroup(groups) {
  if (groups.length === 0) return UserGroupNames[UserGroups.Ungrouped];

  groups.map((group) => parseInt(group));
  let minimum = Math.min(groups);

  return UserGroupNames[`${minimum}`];
}

export default class UserItem extends Component {
  constructor(props) {
    super(props);
    this.select = this.select.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleContextClick = this.handleContextClick.bind(this);
  }

  shouldComponentUpdate(nP, nS) {
    return (
      // nP.name !== this.props.Name ||
      nP.selection !== this.props.selection || !_.isEqual(nP.user, this.props.user)
    );
  }

  /**
   * Toggling wether this object is selected or not
   */
  select(id, callback) {
    const { selection, actions } = this.props;
    const isSelected = selection.includes(id);
    if (isSelected) {
      actions.removeUserFromSelection(id, callback);
    } else {
      actions.addUserToSelection(id);
    }
  }

  handleClick = () => this.select(this.props.user.user_name);

  handleContextClick = (event) => {
    event.persist();
    this.props.onContextClick(event, this.props.user.user_name);
  };

  render() {
    const { user, selection, renderUserContextMenu } = this.props;
    const isSelected = selection.includes(user.user_name);

    const checkbox = <Checkbox checked={isSelected} onChange={this.handleClick} />;

    const groups = minGroup(user.groups === undefined || user.groups === null ? [] : user.groups);

    return (
      <tr id={`ctx-${user.user_name}`} onContextMenu={this.handleContextClick}>
        <td>{checkbox}</td>
        <td onClick={this.handleClick}>
          {user.first_name} {user.last_name}
        </td>
        <td onClick={this.handleClick}>{user.email}</td>
        <td onClick={this.handleClick}>{groups}</td>
        {/* <td>{user.disabled ? "Disabled" : "Enabled"}</td> */}
        <td className="ctx-ind" onClick={this.handleClick}>
          {readableDate(user.last_login)}

          <div tabIndex="0" className="ctx-menu-btn" onClick={this.handleContextClick}>
            <i className="fas fa-ellipsis-v" />
          </div>

          {renderUserContextMenu(user)}
        </td>
      </tr>
    );
  }
}
