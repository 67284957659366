// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import { saturateColor } from "../../../shared/functions/color";

import { getEndpointDisplayName } from "../../../shared/functions/formatting";

export default class MonitorEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      highlighted: false,
    };
  }

  toggleHighlighted(newState) {
    this.setState({ highlighted: newState });
  }

  render() {
    let { highlighted } = this.state;
    let { item, theme, selected, selectEntry, onShowContext, renderContext, togglingEndpoints } = this.props;
    if (!item) return null;

    let toggling = togglingEndpoints.includes(item.id);

    let color = item.enabled ? (item.active ? theme.highlight_7 : theme.highlight_5) : saturateColor(theme.highlight_7, 0);

    const menu_button = (
      <Fragment>
        <div tabIndex="0" className="ctx-menu-btn" onClick={(event) => onShowContext(item, event)}>
          <i className="fas fa-ellipsis-v" />
        </div>

        {renderContext(item)}
      </Fragment>
    );

    return (
      <div
        key={item.id}
        className={`item ${selected ? " active" : ""}`}
        onClick={() => selectEntry(item)}
        onMouseEnter={() => this.toggleHighlighted(true)}
        onMouseLeave={() => this.toggleHighlighted(false)}
        onContextMenu={(event) => onShowContext(item, event)}
      >
        <div className="indicator" style={{ backgroundColor: color }}>
          {toggling && <i className="toggling spin fas fa-sync" />}
        </div>
        <p>{getEndpointDisplayName(item)}</p>
        {highlighted && menu_button}
      </div>
    );
  }
}
