// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";

import { pages } from "../enumerations";
import "rc-tabs/assets/index.css";
import "../styles/SidePanel.css";
import monitoringIcon from "../../static/img/monitoring.svg";
import { authorize } from "../functions/general";

// Main navigation panel
// contains links to other Applications
class SidePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.closeNav = this.closeNav.bind(this);
    this.openNav = this.openNav.bind(this);
  }

  shouldComponentUpdate(nP, nS) {
    return nS.isOpen !== this.state.isOpen || nP.href !== this.props.href;
  }

  render() {
    const { href, authGroups } = this.props;
    const isAdmin = authorize(authGroups);

    return (
      <div
        id="mySidepanel"
        className={`sidepanel${this.state.isOpen ? " open" : ""}`}
        onMouseEnter={this.openNav}
        onMouseLeave={this.closeNav}
      >
        <SidePanelButton link={pages.sysmap} faClass="fas fa-project-diagram" href={href} text="System Map" />
        <SidePanelButton link={pages.monitor} svg={monitoringIcon} href={href} text="Monitoring" />
        <SidePanelButton link={pages.threats} faClass="fa fa-exclamation-circle" href={href} text="Threats" />

        <div className={`bottom${this.state.isOpen ? " open" : ""}`}>
          {isAdmin && <SidePanelButton link={pages.billing} faClass="fas fa-chart-line" href={href} text="Billing" />}
          <SidePanelButton link={pages.configuration} faClass="fa fa-cog" text="Configuration" href={href} />
        </div>
      </div>
      /*Removed items from sidepanel:
      <SidePanelButton link="" faClass="fa fa-home" text="Dashboard" href={href} demo />
      <SidePanelButton link={pages.geomap} faClass="fa fa-globe-americas" text="Heat Map" href={href} demo />
      */
    );
  }

  /**
   * Opens the side bar, only if it is closed
   * @since 0.3.1
   */
  closeNav() {
    if (!this.state.isOpen) return;
    this.setState({ isOpen: false });
  }

  /**
   * Opens the side bar, only if it is open
   * @since 0.3.1
   */
  openNav = () => {
    if (this.state.isOpen) return;
    this.setState({ isOpen: true });
  };
}

export default SidePanel;

/**
 * A button in the sidepanel
 * @param {string} text The text of the button
 * @param {string} faClass The font-awesome className of the button's icon
 * @param {string} link The hyperlink the button links to
 * @param {boolean} demo Whether the subsection is available during the demo
 * @param {*} actions The operations the button performs when focus is gained/lost
 */
const SidePanelButton = ({ text, faClass, link, demo, href, svg }) => {
  demo = demo ? global.gDemo : demo;
  const empty = demo || link.length === 0;
  const active = empty ? false : href.includes(link);

  let body = (
    <div className={`btn-0${active ? " active" : ""}`}>
      {svg !== undefined && <img src={svg} alt="monitoring.svg" />}
      {faClass !== undefined && <i className={faClass} aria-hidden="true" />}
      <p>{text}</p>
    </div>
  );

  if (demo)
    body = (
      <Tippy
        content={global.gDemoMsg}
        animation="scale-subtle"
        theme="material"
        duration={global.gTTPDur}
        delay={[global.gTTPShow, 0]}
        offset={[0, -8]}
      >
        <div>
          <div data-demo="true">{body}</div>
        </div>
      </Tippy>
    );

  if (empty) return body;

  return (
    <Link to={link} className="link">
      {body}
    </Link>
  );
};
