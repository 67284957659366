// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";
import ForceGraph2D from "react-force-graph-2d";
import { SizeMe } from "react-sizeme";

const ResponsiveForceGraph = (props) => {
  return (
    <SizeMe monitorHeight>
      {({ size }) => (
        // Wrapper required to avoid flex drift from SizeMe-injected DOM
        <div style={{ position: "relative", height: "100%" }}>
          <div style={{ position: "absolute", width: "100%", height: size.height }}>
            <ForceGraph2D ref={props.virtualRef} width={size.width} height={size.height} {...props} />
          </div>
        </div>
      )}
    </SizeMe>
  );
};

export default ResponsiveForceGraph;
