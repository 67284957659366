// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";
import { Navigate } from "react-router-dom";
import { pages } from "../enumerations";
import { authorize } from "../functions/general";

/**
 * Page route limited to users with the proper level of authorization. (e.g. only admins can view admin controls)
 */
const AuthorizedRoute = ({ component: C, childProps, minCreds }) => {
  const isAuthorized = authorize(childProps.authGroups, minCreds);
  return isAuthorized ? <C {...childProps} /> : <Navigate to={`${pages.sysmap}`} />;
};

export default AuthorizedRoute;
