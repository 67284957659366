// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import queryString from "query-string";
import { Scrollbars } from "react-custom-scrollbars-2";
import Modal from "react-modal";
import Tippy from "@tippyjs/react";
import { ContextMenu, MenuItem } from "../../../shared/external/react-contextmenu";
import { Form, Col, FormGroup, FormControl } from "react-bootstrap";
import _ from "lodash";

import NetItem from "./NetItem";
import Checkbox from "../../../shared/components/Checkbox";
import Dropdown from "../../../shared/components/Dropdown";
import ResponsiveTableHeader from "../../../shared/components/ResponsiveTableHeader";
import LoaderButton from "../../../shared/components/LoaderButton";
import { MyAPI, requestErr, copyText, renderThumb, isOperationActive } from "../../../shared/functions/general";
import { gateways, NodeRange, pages } from "../../../shared/enumerations";
import { modalStyle } from "../../../shared/constants";
import { handleContextMenuClick } from "../../../shared/functions/ctxMenu";
import { requestNetworkList } from "../../sysmap/containers/SystemMapView";
import { defaultFilter, formatFilterQuery } from "../../sysmap/components/FilterBar";

/**
 * Displays the network tab of the customer console
 * @since 0.4.1
 */
export default class NetworkTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selection: {},

      // verification string for deleting networks
      verification: "",

      // Visibility state of verification modal for deleting networks
      deleteOpen: false,

      // The item to delete, triggered via CTX menu
      deleteSingle: null,

      // Visibility state of network creation modal
      createOpen: false,

      // The ID of the object to modify
      modifying: null,

      // Error message for creating networks/subnet
      createErr: "",

      // CIDR string for creating networks
      cidr: "",

      // A user-friendly name for a newly created network
      name: "",

      // Whether the user is creating a subnet or a network
      netType: null,

      // Status of the currently sent loader button
      loadOp: false,
      refreshing: false,
      // Fetch status
      loading: {
        active: false,
        failed: false,
      },

      // table height as defined by responsive table component
      netTableHeight: 60,

      // When Subnets must be deleted first, delete these
      networksToDelete: null,

      // Results state controls for async processes
      resultsModalOpen: false,
      results: {},
      resultModalTitle: null,
    };

    // bind methods
    this.onSelect = this.onSelect.bind(this);
    this.addToSelection = this.addToSelection.bind(this);
    this.removeFromSelection = this.removeFromSelection.bind(this);
    this.deleteCallback = this.deleteCallback.bind(this);
    this.deleteFailCallback = this.deleteFailCallback.bind(this);
    this.deleteNetworks = this.deleteNetworks.bind(this);

    this.requestNetworks = this.requestNetworks.bind(this);
    this.showNetworkCreation = this.showNetworkCreation.bind(this);
    this.showRemoveModal = this.showRemoveModal.bind(this);
    this.onClickShowRemoveModal = this.onClickShowRemoveModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onContextClick = this.onContextClick.bind(this);
    this.commitCreateNetwork = this.commitCreateNetwork.bind(this);
    this.commitDelete = this.commitDelete.bind(this);
    this.renderItemContextMenu = this.renderItemContextMenu.bind(this);

    this.refresh = this.refresh.bind(this);
    this.updateHeaderHeight = this.updateHeaderHeight.bind(this);
  }

  componentDidMount() {
    if (Object.entries(this.props.networks).length === 0) {
      this.requestNetworks();
    } else {
      this.setState({ loading: { active: false, failed: false }, refreshing: false });
    }
  }

  requestNetworks(refreshing = false) {
    this.setState({ loading: { active: true, failed: false }, refreshing });
    const view = this;

    requestNetworkList((networkList) => {
      if (networkList === null) {
        // request failed and no networks were retrieved
        this.setState({ loading: { active: false, failed: true }, refreshing: false });
      } else {
        view.props.actions.setNetworkList(networkList);
        this.setState({ loading: { active: false, failed: false }, refreshing: false });
      }
    }, this.props.toastManager);
  }

  componentDidUpdate(prevProps) {
    const { results } = this.props;
    const { networksToDelete } = this.state;
    if (!results !== prevProps.results && networksToDelete) {
      if (isOperationActive(results) === Object.values(networksToDelete).length) {
        this.setState({ networksToDelete: null });
        this.deleteNetworks(networksToDelete);
      }
    }
  }

  refresh = () => {
    if (this.state.loading.active) return;
    this.deselectAll();
    this.requestNetworks(true);
  };

  deleteCallback(response, item) {
    const { actions } = this.props;
    console.debug(`${item.subnets ? "Network" : "Subnet"} DELETE response:`, response);
    if (response.statusCode === 200) actions.removeNetwork(item.id, item.parentId);
    else actions.failNetworkManagementOp(item.id);
  }

  deleteFailCallback(error, item) {
    const { actions } = this.props;
    console.error(error);
    actions.failNetworkManagementOp(item.id);
  }

  onSelect() {
    if (Object.keys(this.state.selection).length !== getNetSize(this.props.networks)) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }

  /**
   * Selects all networks in the list
   * @since 0.4.1
   */
  selectAll() {
    const selection = new Set();
    Object.values(this.props.networks).forEach((net) => {
      if (net.id !== global.gOrgLevelUID) selection[net.id] = net;
      if (net.subnets)
        net.subnets.forEach((sub) => {
          selection[sub.id] = sub;
        });
    });

    this.setState({ selection: selection });
  }

  /**
   * Deselects all networks in the networks
   * @since 0.4.1
   */
  deselectAll() {
    this.setState({ selection: {} });
  }

  /**
   * Adds the network at the given index to the list of selected networks
   * @param {number} id
   * @since 0.4.1
   */
  addToSelection(item) {
    const selection = _.cloneDeep(this.state.selection);
    selection[item.id] = item;
    this.setState({ selection: selection });
  }

  /**
   * Removes the network at the given index from the selection
   * @param {*} id
   * @param {function} callback The function to callback when the item has been removed
   * @since 0.4.1
   */
  removeFromSelection(item, callback) {
    const selection = _.cloneDeep(this.state.selection);
    delete selection[item.id];
    this.setState({ selection: selection }, callback);
  }

  /**
   * Show the network removal modal
   * @param {string} item The single item to delete. Default is null
   */
  showRemoveModal(item = null) {
    this.setState({ deleteOpen: true, verification: "", deleteSingle: item });
  }

  onClickShowRemoveModal() {
    this.showRemoveModal(null);
  }

  /**
   * Removes a list of networks from the system. This is a destructive operation,
   * so we must have extensive validation for handling this operation
   * @since 0.4.1
   */
  async commitDelete() {
    const { deleteSingle } = this.state;
    const { actions } = this.props;
    let selected = deleteSingle === null ? this.state.selection : { [deleteSingle.id]: deleteSingle };

    let networks = [],
      subnets = [];
    let results = {};
    let selection = Object.values(selected);
    selection.forEach((item) => {
      results[item.id] = { text: item.name, active: true, failed: false };

      if (item.subnets) networks.push(item);
      else subnets.push(item);
    });

    actions.initNetworkManagementOperation(results);
    const newState = selected.length === 1 ? { deleteSingle: null } : {};
    const resultModalTitle = "Remove Item Confirmation";
    this.setState({
      selected: {},
      resultsModalOpen: true,
      deleteOpen: false,
      resultModalTitle,
      ...newState,
    });

    subnets.forEach((item) => {
      let url = gateways.subnetItem.replace(":id", item.id);
      MyAPI.delete(url)
        .then((response) => this.deleteCallback(response, item))
        .catch((error) => this.deleteFailCallback(error, item));
    });

    // Ensure that all subnets are deleted first before networks
    if (subnets.length > 0) {
      this.setState({ networksToDelete: networks });
    } else {
      this.deleteNetworks(networks);
    }
  }

  deleteNetworks(networks) {
    networks.forEach((item) => {
      let url = gateways.networkItem.replace(":id", item.id);
      MyAPI.delete(url)
        .then((response) => this.deleteCallback(response, item))
        .catch((error) => this.deleteFailCallback(error, item));
    });
  }

  /**
   * Shows the network creation modal
   */
  showNetworkCreation() {
    this.setState({
      createOpen: true,
      name: "",
      cidr: "",
      netType: null,
      modifying: null,
      createErr: "",
    });
  }

  /**
   * Generic handler for hiding an open modal
   */
  closeModal(event) {
    this.setState({ [event.target.id]: false });
  }

  /**
   * Generic callback for storing the input value when its component
   * has been updated.
   * @param {*} event The event that was triggered
   */
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  /**
   * Basic input validation for creating a network. Returns true only
   * if the input is valid.
   * @since 0.4.1
   */
  validateCreateForm() {
    /*TODO: Validate name to 250 displayable characters*/
    //Recorded as work item 121 in DevOps
    const { cidr, /*name,*/ netType } = this.state;

    if (
      cidr === "" ||
      // name === '' ||
      netType === null
    )
      return false;

    return true;
  }

  /**
   * Verify that the user has confirmed they indeed want to remove a list
   * of networks from being managed. Returns true only if valid
   * @since 0.4.1
   */
  validateDeleteForm() {
    // NOTE: Should this be more complex?
    return this.state.verification === "delete";
  }

  /**
   * Sends a POST request for when a new network is created
   * @since 0.2.2
   */
  async commitCreateNetwork() {
    this.setState({ loadOp: true, createErr: "" });

    const netName = this.state.name.length > 0 ? this.state.name : this.state.cidr;
    const { cidr, netType, modifying } = this.state;

    try {
      let url, func;
      if (modifying) {
        url = netType.toLowerCase() === NodeRange.NETWORK ? gateways.networkItem : gateways.subnetItem;
        url = url.replace(":id", modifying);
        func = MyAPI.patch;
      } else {
        url = netType.toLowerCase() === NodeRange.NETWORK ? gateways.network : gateways.subnet;
        func = MyAPI.post;
      }

      let response = await func(url, { name: netName, cidr });
      console.debug(`${netType} ${modifying ? "PATCH" : "POST"} ${netType.toLocaleLowerCase()} response: `, response);

      let responseBody = response.body;
      if (response.statusCode === 200) {
        // close the window if successful, otherwise post a toast
        this.setState({ loadOp: false, createOpen: false });
        this.props.toastManager.add(responseBody.message, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });

        this.props.actions.requestNetworks();
      } else {
        //TODO: Show a OK modal displaying error.
        //Recorded as work item 122 in DevOps
        const errMessage = _.get(responseBody, "message", "An unexpected error occurred.");
        this.setState({ loadOp: false, createErr: errMessage });
      }
    } catch (err) {
      this.setState({ loadOp: false });
      this.props.toastManager.add(requestErr(err), {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: global.gToastTimeout,
      });
    }
  }

  /**
   * Show the modal for modifying the selected item
   * @param {*} item The item to modify
   * @param {*} isNetwork Whether the items is a Subnet or a Network
   */
  openModifyNetwork(item, isNetwork) {
    this.setState({
      createOpen: true,
      name: item.name,
      cidr: item.cidr,
      netType: isNetwork ? "Network" : "Subnet",
      modifying: item.id,
      createErr: "",
    });
  }

  /**
   * Reroute the page to the System map for the selected item
   * @param {*} item
   */
  viewItem(item) {
    const query = formatFilterQuery(defaultFilter);
    query.network = item.id;

    const qs = queryString.stringify({ network: item.id });
    this.props.navigate(`${pages.sysmap}?${qs}`);
    this.props.actions.viewNetwork({ id: item.id, name: item.name });
  }

  /**
   * Copy the link to the System map for the selected item
   * @param {*} item The item to view using the generated link
   */
  copyLink(item) {
    const net = queryString.stringify({ network: item.id });
    const path = window.location.origin + pages.sysmap + "?" + net;

    copyText(path);
  }

  onContextClick(event, item, isNet) {
    this.setState({ ctxItem: { item, isNet } }, () => {
      const id = `ctx-${item.id}`;
      event.target.id = id;
      handleContextMenuClick(event, id, this.props);
    });
  }

  updateHeaderHeight(size) {
    if (this.state.netTableHeight === size.height) return;
    this.setState({ netTableHeight: size.height });
  }

  /**
   * To ensure the table has the same column width despite having only the
   * body be scrollable, the header is drawn twice. The first is shown to user,
   * while the second is hidden and used only for positioning
   * @param {*} hidden
   */
  renderTHead(hidden) {
    let l = getNetSize(this.props.networks);
    // The extra fragment layer is added so that
    const head = (
      <Fragment>
        <thead className={hidden ? "secondary" : ""}>
          <tr>
            <th width="20px">
              <Checkbox checked={l === 0 ? false : Object.keys(this.state.selection).length === l} onChange={this.onSelect} />
            </th>
            {/* <th width="20px"/> */}
            <th width="20%">Name</th>
            <th width="12%">Status</th>
            <th width="15%">CIDR Range</th>
            <th width="calc(38% - 20px)">Tags</th>
          </tr>
        </thead>
      </Fragment>
    );

    return <Fragment>{!hidden ? <ResponsiveTableHeader head={head} onSize={this.updateHeaderHeight} /> : head}</Fragment>;
  }

  /**Renders the network Creation window */
  renderCreationModal() {
    const modifying = this.state.modifying !== null;
    const validForm = !this.validateCreateForm();
    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];

    return (
      <Modal isOpen={this.state.createOpen} style={style}>
        <div className="modal-pnl network-modal">
          <div className="header">{modifying ? "Modify" : "Define New"} Network/Subnet</div>

          <span className="info">
            Please enter a name for the new network or subnet and define the address range it occupies.
            {modifying &&
              " Please note that you cannot change this object to a Network or Subnet with a range that already exists."}
          </span>

          <Form horizontal="true" className="modal-form">
            <FormGroup controlId="name" className="modal-input">
              <Col sm={2}>Name:</Col>
              <Col sm={10}>
                <FormControl autoFocus type="text" value={this.state.name} onChange={this.handleChange} placeholder="New Network" />
              </Col>
            </FormGroup>

            <FormGroup controlId="cidr" className="modal-input">
              <Col sm={2}>CIDR Range:</Col>
              <Col sm={10}>
                <FormControl value={this.state.cidr} onChange={this.handleChange} placeholder="X.X.X.X/XX" />
              </Col>
            </FormGroup>

            {!modifying && (
              <FormGroup controlId="netType" style={{ display: "flex", alignItems: "center" }}>
                <Col componentclass="FormLabel" sm={2}>
                  Type:
                </Col>
                <Col sm={10}>
                  <FormControl
                    className="hidden"
                    value={this.state.netType === null ? "" : this.state.netType}
                    onChange={this.handleChange}
                  />
                  <Dropdown
                    id="netType"
                    options={["Network", "Subnet"]}
                    onChange={this.handleChange}
                    value={this.state.netType}
                    placeholder="Select Type..."
                  />
                </Col>
              </FormGroup>
            )}

            {this.state.createErr.length > 0 && (
              <div className="modal-error">
                <span className="err">{this.state.createErr}</span>
              </div>
            )}
          </Form>

          <div className="footer">
            <div className="actions center">
              <div disabled={this.state.loadOp} className="btn quart" id="createOpen" onClick={this.closeModal}>
                Cancel
              </div>
              <LoaderButton
                disabled={validForm}
                type="submit"
                text={modifying ? "Update" : "Create"}
                loadingText={modifying ? "Updating..." : "Creating..."}
                isLoading={this.state.loadOp}
                onClick={this.commitCreateNetwork}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  /** Renders the removal confirmation window */
  renderRemovalModal() {
    const validD = !this.validateDeleteForm();
    const action = "delete";
    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];

    return (
      <Modal isOpen={this.state.deleteOpen} style={style}>
        <div className="modal-pnl">
          <div className="header">Remove Confirmation</div>

          <span className="info">
            Please confirm by entering '{`${action}`}' below. Note that you cannot remove networks that have subnets without first
            removing all of their subnets.
          </span>
          <Form onSubmit={this.commitDelete}>
            <FormGroup controlId="verification">
              <FormControl
                autoFocus
                type="text"
                value={this.state.verification}
                onChange={this.handleChange}
                placeholder="delete"
              />
            </FormGroup>
          </Form>

          <div className="footer">
            <div className="actions center">
              <div className="btn quart" id="deleteOpen" onClick={this.closeModal}>
                Cancel
              </div>
              <div className="btn" disabled={validD} type="submit" onClick={this.commitDelete}>
                Remove
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderResultsModal() {
    const { resultsModalOpen, resultModalTitle } = this.state;
    const { results } = this.props;

    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];

    let active = 0;
    Object.values(results).forEach((entry) => {
      if (entry.active) active += 1;
    });

    return (
      <Modal isOpen={resultsModalOpen} style={style}>
        <div className="modal-pnl user-modal">
          <div className="header">{resultModalTitle}</div>

          <Form horizontal="true" className="modal-form">
            {Object.entries(results).map(([key, result]) => {
              let resultClass = "fas fa-ellipsis-h";
              let backingClass = "backing far fa-circle";
              if (result.failed) {
                resultClass = "fas fa-times error";
                backingClass += " error";
              } else if (!result.active) {
                resultClass = "fas fa-check success";
                backingClass += " success";
              }

              return (
                <div className="result-entry" key={key}>
                  <div className="indicator">
                    <i className={backingClass} />
                    <i className={`icon ${resultClass}`} />
                  </div>
                  <span>{result.text}</span>
                </div>
              );
            })}
          </Form>

          <div className="footer">
            <div className="actions center">
              <LoaderButton
                disabled={active > 0}
                type="submit"
                id="resultsModalOpen"
                text="Close"
                className="quart"
                loadingText="Saving..."
                isLoading={active > 0}
                onClick={this.closeModal}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  /**
   * Render the Context menu for the given table entry;
   * NOTE: This is inefficient since it renders an instance for every item, however
   * it is necessary for the context menu DOM to exist so that it can be made visible
   */
  renderItemContextMenu(item, parentId) {
    const isNet = _.isNil(parentId);
    const cantRemove = isNet && item.subnets.length > 0;
    const hoverEvent = (event) => event.preventDefault();

    const mnRemove = (
      <MenuItem
        onClick={() => {
          this.showRemoveModal({ parentId, ...item });
        }}
        disabled={cantRemove}
        onMouseMove={hoverEvent}
        tabIndex={cantRemove ? "-1" : "0"}
      >
        <i className="fas fa-trash" />
        Remove
      </MenuItem>
    );

    return (
      <ContextMenu id={`ctx-${item.id}`}>
        <MenuItem tabIndex="0" data={item} onClick={this.viewItem}>
          <i className="fas fas-project-diagram" /> View in Map
        </MenuItem>

        <MenuItem disabled onMouseMove={hoverEvent} data={item} onClick={this.copyLink}>
          <i className="fas fa-copy" /> Copy Map link
        </MenuItem>

        <MenuItem onMouseMove={hoverEvent} className="header">
          <p>Actions</p>
        </MenuItem>
        <MenuItem divider />

        <MenuItem
          tabIndex="0"
          onMouseMove={hoverEvent}
          onClick={() => {
            this.openModifyNetwork(item, isNet);
          }}
        >
          <i className="fas fa-edit" /> Modify
        </MenuItem>

        {cantRemove ? (
          <div className="react-contextmenu-item wrapper--disabled" title="Can not remove a network with subnets.">
            {mnRemove}
          </div>
        ) : (
          mnRemove
        )}
      </ContextMenu>
    );
  }

  render() {
    const itemActions = {
      addToSelection: this.addToSelection,
      removeFromSelection: this.removeFromSelection,
    };

    const { networks, theme, pageSize } = this.props;
    const { selection, netTableHeight } = this.state;
    const netEntries = Object.entries(networks);
    const h = pageSize === undefined ? window.innerHeight : pageSize.height;
    const padding = 50;
    const minHeight = h - padding - 127 - netTableHeight;

    return (
      <Fragment>
        {/* Network Creation window */}
        {this.renderCreationModal()}

        {/* Removal confirmation window */}
        {this.renderRemovalModal()}
        {this.renderResultsModal()}

        <div className="card network-list" style={{ flexGrow: "2" }}>
          {/* Network management buttons */}
          <div className="heading">
            <div className="header">Network List</div>
            <div className="actions right">
              <Tippy
                content="Refresh list"
                animation="scale-subtle"
                theme="material"
                duration={global.gTTPDur}
                delay={[global.gTTPShow, 0]}
              >
                <div tabIndex="0" className="btn" style={{ backgroundColor: "transparent" }} onClick={this.refresh}>
                  <i className={`fas fa-sync-alt`} />
                </div>
              </Tippy>

              <Tippy
                content="Create new Network/Subnet"
                animation="scale-subtle"
                theme="material"
                duration={global.gTTPDur}
                delay={[global.gTTPShow, 0]}
              >
                <div tabIndex="0" className="btn" onClick={this.showNetworkCreation}>
                  Add
                </div>
              </Tippy>

              <Tippy
                content={Object.keys(this.state.selection).length === 0 ? "Nothing is selected" : "Remove selected items"}
                animation="scale-subtle"
                theme="material"
                duration={global.gTTPDur}
                delay={[global.gTTPShow, 0]}
              >
                <div>
                  <div
                    tabIndex="0"
                    className="btn"
                    onClick={this.onClickShowRemoveModal}
                    disabled={Object.keys(this.state.selection).length === 0}
                  >
                    Remove
                  </div>
                </div>
              </Tippy>
            </div>
          </div>

          <div className="body">
            <span>The following is a list of networks defined on your environment.</span>
            {this.state.loading.active && !this.state.refreshing ? (
              <div className="jumbo">
                <div>Loading Network data...</div>
                <div className="loading">
                  <i className="spin fas fa-sync-alt"></i>
                </div>
              </div>
            ) : (
              <Fragment>
                {!this.state.loading.failed ? (
                  <Fragment>
                    <table className="dash-table">
                      {this.renderTHead(false)}
                      <tbody />
                    </table>

                    <Scrollbars
                      renderThumbHorizontal={(_) => {
                        return <div />;
                      }}
                      renderThumbVertical={(obj) => {
                        return renderThumb(obj, theme);
                      }}
                      id="network-list"
                      autoHideDuration={1000}
                      style={{ minHeight: `${minHeight}px` }}
                      // autoHide
                    >
                      {netEntries.length === 0 ? (
                        <div className="dash-table-empty">
                          <i>There are no networks currently setup.</i>
                        </div>
                      ) : (
                        <table className="dash-table">
                          {/* Used for column sizing of body */}
                          {this.renderTHead(true)}
                          <tbody>
                            {netEntries.map(([_, network]) => {
                              if (network.id === global.gOrgLevelUID) return null;

                              return (
                                <NetItem
                                  network={network}
                                  key={network.id}
                                  selection={selection}
                                  actions={itemActions}
                                  onContextClick={this.onContextClick}
                                  renderItemContextMenu={this.renderItemContextMenu}
                                />
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </Scrollbars>
                  </Fragment>
                ) : (
                  <div className="jumbo error">
                    <div style={{ fontWeight: "600" }}>Error loading Networks.</div>
                    <div className="btn" onClick={this.refresh}>
                      Retry
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

/** get the accurate size of the network list, including all subnets */
function getNetSize(networks) {
  let l = Object.entries(networks).length;
  Object.values(networks).forEach((network) => {
    if (network.subnets) l += network.subnets.length;
    if (network.id === global.gOrgLevelUID) l -= 1;
  });

  return l;
}
