import React, { Component, Fragment } from "react";
import { PropTypes } from "prop-types";
import Tippy from "@tippyjs/react";
import { copyText, isNull } from "../functions/general";

import WebWorker from "../threading/WebWorker";
import Timer from "../threading/timer";
import "../styles/CopyField.css";

const TOOLTIP_HIDE_DELAY = 2; // value in seconds

/** Copies a value to the clipboard */
class CopyField extends Component {
  static propTypes = {
    value: PropTypes.string, // value to copy
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.hideTimer = null;

    this.copyValue = this.copyValue.bind(this);
    this.hideTimerCallback = this.hideTimerCallback.bind(this);
    this.killHideTimer = this.killHideTimer.bind(this);
  }

  componentWillUnmount() {
    this.killHideTimer();
  }

  copyValue() {
    copyText(this.props.value);
    this.setState({ visible: true });
    this.killHideTimer();

    this.hideTimer = new WebWorker(Timer);
    this.hideTimer.addEventListener("message", this.hideTimerCallback, false);
    this.hideTimer.postMessage([TOOLTIP_HIDE_DELAY]);
  }

  killHideTimer() {
    if (isNull(this.hideTimer)) return;
    console.debug("CopyText::hide timer killed");
    this.hideTimer.terminate();
    delete this.hideTimer;
  }

  hideTimerCallback() {
    this.killHideTimer();
    this.setState({ visible: false });
  }

  render() {
    return (
      <Fragment>
        <Tippy
          content="Copied!"
          animation="shift-away-subtle"
          trigger="manual"
          theme="material"
          duration={global.gTTPDur}
          visible={this.state.visible}
          onClickOutside={this.hideTimerCallback}
          delay={[0, 0]}
        >
          <div className="copy-field" onClick={this.copyValue}>
            {this.props.value}
            <i className="far fa-copy" />
          </div>
        </Tippy>
      </Fragment>
    );
  }
}

export default CopyField;
