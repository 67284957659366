import React from "react";

/**
 * Sorting indicator for non tabular lists
 * @param {string} filter Text displayed for sort option
 * @param {string} filterKey Proeprty name to use when sorting. If undefined, defaults to snake case equivalent of "filter"
 * @param {Function} handleSort callback triggered to begin sort
 * @param {string} order The direction to sort (ASC, DESC)
 * @param {boolean} reverse Whether to reverse the sort order (using prop "order")
 * @returns
 */
const SortItem = ({ filter, filterKey, handleSort, order, reverse }) => {
  const active = order === filter.toLowerCase();
  const classes = `${active ? "" : "in"}active sort-item`;
  if (reverse === undefined) reverse = false;

  const key = filterKey !== undefined ? filterKey : filter.replaceAll(" ", "_");

  return (
    <span
      className={classes}
      onClick={() => {
        handleSort(key, reverse);
      }}
    >
      {filter}
    </span>
  );
};

export default SortItem;
