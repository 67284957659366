// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import Tippy from "@tippyjs/react";
import queryString from "query-string";
import { addMinutes, parseISO, subMinutes } from "date-fns";
import _ from "lodash";

import FilterOption, { FilterKey, FilterOperation, FilterOperationNames, ValueType } from "./FilterOption";
import FilterSlider from "./FilterSlider";
import { pages, site_cookies } from "../../../shared/enumerations";
import { stripDate } from "../../../shared/functions/formatting";
import { MAX_TIME_OFFSET, maxRisk } from "../../../shared/constants";
import { cookie_settings, isNull, isNumeric, isValidIsoDate } from "../../../shared/functions/general";
import "../styles/FilterBar.css";

export const timeRangePresets = {
  m_1: "10 m",
  m_2: "30 m",
  h_1: "1 hr",
  h_2: "2 hr",
  h_3: "4 hr",
  h_4: "8 hr",
  h_5: "12 hr",
  d_1: "1 d",
  d_2: "2 d",
  d_3: "4 d",
  d_4: "7 d",

  // currently, users cannot set a custom relative time filter
  // custom: "Custom",
};

/**
 * Generates offset values based on the value of the time range preset.
 * Each resolver produces a value in minutes
 * Example: {"2 hr": 120}
 * */
let timePresetResolvers = Object.fromEntries(
  Object.values(timeRangePresets).map((key) => {
    let d = key.split(" ");

    let m,
      x = parseInt(d[0]);
    switch (d[1]) {
      case "hr":
        m = 60;
        break;
      case "d":
        m = 24 * 60;
        break;
      default:
        m = 1;
    }

    return [key, x * m];
  })
);

/** Filters that need an operation */
const opFilters = [FilterKey.risk, FilterKey.updated];

export const defaultFilter = {
  [FilterKey.updated]: { value: timeRangePresets.m_1, op: FilterOperation.gte },
  [FilterKey.risk]: { value: 0, op: FilterOperation.gte },
};

let riskOperations = [FilterOperation.gt, FilterOperation.gte, FilterOperation.lte, FilterOperation.lt];
riskOperations = riskOperations.map((op) => FilterOperationNames[op]);

const geoRanges = {
  any: "Any",
  with: "Has Data",
  none: "No Data",
};

// default values to exclude from filter definition
const ignoreDefaults = {
  geo: "any",
};

/**
 * Evaluate the last updated field based on the filter
 */
export function evaluatePresetRange(range) {
  let timeValue = _.get(timePresetResolvers, range, undefined);

  if (timeValue === undefined) {
    // Value is not a preset and should be treated as an ISO 8601 date
    timeValue = range;
  }

  return timeValue;
}

/** Apply filter object updates back into querystring */
export function saveFilterToQuery(filter, navigate, cookies, save_cookie = true) {
  const qs = queryString.parse(window.location.search);
  const flat = formatFilterQuery(filter);
  const merged = _.merge(qs, flat);

  // remove items that were deleted in new filter
  if (Object.keys(flat).length < Object.keys(merged).length) {
    Object.keys(merged).forEach((key) => {
      // don't want to remove other querystring values, like network id
      if (!(key in flat) && key in FilterKey) {
        delete merged[key];
        if (opFilters.includes(key)) delete merged[key + "_o"];
      }
    });
  }

  const query = queryString.stringify(merged);
  console.debug("FilterBar::saveFilterToQuery setting qs");
  navigate(pages.sysmap + "?" + query);

  if (save_cookie && cookies) {
    cookies.set(site_cookies.graph_filter, JSON.stringify(filter), cookie_settings(3600));
  }
}

/**
 * Convert a filter object to a valid query string
 * @param {*} filter
 */
export function formatFilterQuery(filter) {
  let qs = {};
  const isAbsolute = _.get(filter, `${FilterKey.updated}.op`) === FilterOperation.between;

  Object.keys(filter).forEach((key) => {
    let isDate = [FilterKey.updated].includes(key) && isAbsolute;

    qs[key] = isDate ? stripDate(filter[key].value) : filter[key].value;
    if (opFilters.includes(key)) {
      qs[key + "_o"] = filter[key].op;
    }

    if ("secondary" in filter[key]) {
      qs[key + "_s"] = isDate ? stripDate(filter[key].secondary) : filter[key].secondary;
    }
  });

  return qs;
}

/** Extract graph filter from querystring */
export function extractFilter(qs) {
  let filter = {};

  // We only want filters we can recognize; anything else the user inputs is garbage
  Object.values(FilterKey).forEach((key) => {
    if (qs[key] === undefined) return;

    let value = isNumeric(qs[key]) ? parseFloat(qs[key]) : qs[key];
    filter[key] = { value, op: FilterOperation.gte };

    if (opFilters.includes(key)) {
      let opKey = `${key}_o`;
      if (opKey in qs && Object.values(FilterOperation).includes(qs[opKey])) {
        filter[key].op = qs[opKey];
      }
    }

    let secondaryKey = `${key}_s`;
    if (qs[secondaryKey] !== undefined) {
      let secondaryValue = isNumeric(qs[secondaryKey]) ? parseFloat(qs[secondaryKey]) : qs[secondaryKey];
      filter[key].secondary = secondaryValue;
    }
  });

  return filter;
}

const timeTooltip = "Items with communications within time range. Internal nodes are not excluded.";

/**
 * Validates that the filter extracted from the query string contains valid values
 * @param {*} filter Standard filter object, un-evaluated. Modified by function.
 * @param {*} now Reference Date; no timestamp can exceed this value
 */
export function validateFilter(filter, now) {
  let was_valid = true;

  // absolute will be a preset range. currently, this is the only option as custom is only for absolute
  if (FilterKey.updated in filter) {
    if ([FilterOperation.gte, FilterOperation.gt, FilterOperation.lte, FilterOperation.lt].includes(filter[FilterKey.updated].op)) {
      if (!Object.values(timeRangePresets).includes(filter[FilterKey.updated].value)) {
        filter[FilterKey.updated].op = defaultFilter[FilterKey.updated].op;
        was_valid = false;
      }
    } else if (filter[FilterKey.updated].op === FilterOperation.between) {
      let maxDate = subMinutes(now, MAX_TIME_OFFSET);
      let startDate, endDate;

      // can't parse date, so must use default created by preset minute offset
      if (!isValidIsoDate(filter[FilterKey.updated].value)) {
        filter[FilterKey.updated].value = startDate = subMinutes(now, evaluatePresetRange(defaultFilter[FilterKey.updated].value));
        was_valid = false;
      } else {
        // Assume time string is UTC. All other time zones are ignored.
        // Filter string is replaced by Date for easier calculations down the pipeline
        filter[FilterKey.updated].value = startDate = parseISO(filter[FilterKey.updated].value + "+00:00");
      }

      // TO date validation; Range must have 2 valid dates, not 1
      if (!isNull(filter[FilterKey.updated].secondary)) {
        if (!isValidIsoDate(filter[FilterKey.updated].secondary)) {
          filter[FilterKey.updated].secondary = endDate = new Date(now);
          was_valid = false;
        } else {
          filter[FilterKey.updated].secondary = endDate = parseISO(filter[FilterKey.updated].secondary + "+00:00");

          if (endDate > now) {
            filter[FilterKey.updated].secondary = endDate = new Date(now);
            was_valid = false;

            // This check is inclusive since end date must be after start date
          } else if (endDate <= maxDate) {
            filter[FilterKey.updated].secondary = endDate = addMinutes(maxDate, 1);
          }
        }
      } else {
        filter[FilterKey.updated].secondary = endDate = new Date(now);
        was_valid = false;
      }

      if (startDate > endDate) {
        filter[FilterKey.updated].value = startDate = subMinutes(endDate, 1);
        was_valid = false;
      }

      if (startDate < maxDate) {
        filter[FilterKey.updated].value = startDate = addMinutes(maxDate, 1);
        was_valid = false;
      } else if (startDate > now) {
        filter[FilterKey.updated].value = startDate = new Date(now.toISOString());
        was_valid = false;
      }
    } else {
      filter[FilterKey.updated] = _.cloneDeep(defaultFilter[FilterKey.updated]);
      was_valid = false;
    }
  }

  if (FilterKey.risk in filter) {
    if (!isNumeric(filter[FilterKey.risk].value)) {
      filter[FilterKey.risk].value = defaultFilter[FilterKey.risk].value;
      was_valid = false;
    } else if (filter[FilterKey.risk].value < 0 || filter[FilterKey.risk].value > maxRisk) {
      filter[FilterKey.risk].value = defaultFilter[FilterKey.risk].value;
      was_valid = false;
    }

    if (!riskOperations.includes(FilterOperationNames[filter[FilterKey.risk].op])) {
      filter[FilterKey.risk].op = defaultFilter[FilterKey.risk].op;
      was_valid = false;
    }
  }

  if (FilterKey.geo in filter && !(filter[FilterKey.geo].value in geoRanges)) {
    delete filter[FilterKey.geo];
    was_valid = false;
  }

  return was_valid;
}

export default class FilterBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      failedValues: {},
      tempFilter: _.cloneDeep(this.props.filter),
    };

    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.toggleRelativeTime = this.toggleRelativeTime.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidUpdate(prevProps) {
    this.props.setReferenceDate();

    // Filter updated by another component
    // If filter was cleared, don't clear temp filter to allow modifying before graph is selected
    if (!_.isEqual(prevProps.filter, this.props.filter) && !_.isEmpty(this.props.filter)) {
      // cloneDeep is used so FilterBar only modifies its own version
      this.setState({ tempFilter: _.cloneDeep(this.props.filter) });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(nextProps.filter, this.props.filter) ||
      !_.isEqual(nextState.tempFilter, this.state.tempFilter) ||
      nextState.fixedValues !== this.state.failedValues
    );
  }

  onChangeFilter(field, value, operation, secondary) {
    let { tempFilter } = this.state;

    if (value !== undefined) {
      if (ignoreDefaults[field] !== undefined && ignoreDefaults[field] === value) delete tempFilter[field];
      else {
        if (tempFilter[field] === undefined) {
          tempFilter[field] = { value };
        } else tempFilter[field].value = value;
      }
    }

    if (secondary !== undefined) {
      if (secondary === null) delete tempFilter[field].secondary;
      else tempFilter[field].secondary = secondary;
    }

    if (operation) tempFilter[field].op = operation;
    this.setState({ tempFilter });
  }

  /**
   * User switches between absolute and relative
   * NOTE: This logic is more quality of life for users; quickly swapping beteen the two options won't result in loss of work
   */
  toggleRelativeTime() {
    const { parent } = this.props;
    const { tempFilter } = this.state;
    const toRange = _.get(tempFilter, `${FilterKey.updated}.op`) === FilterOperation.gte;
    const newOperation = toRange ? FilterOperation.between : FilterOperation.gte;
    let newSecondary = null;
    let newValue;

    if (toRange) {
      let offset = evaluatePresetRange(_.get(tempFilter, `${FilterKey.updated}.value`));
      newValue = subMinutes(parent.referenceDate, offset);
      // secondary has no reference, so it should simply be the current time
      newSecondary = new Date(parent.referenceDate);
    } else {
      // find the time range preset with the nearest value to current time filter
      let nearest,
        lastDiff = null;
      let previousDate = parseISO(_.get(tempFilter, `${FilterKey.updated}.value`, defaultFilter[FilterKey.updated].value));
      let oldValue = Math.abs(parent.referenceDate - previousDate) / 60000; // the offset in minutes the previous date represented from current time
      let choices = Object.entries(timePresetResolvers);

      for (let index = 0; index < choices.length; index++) {
        const choice = choices[index][1];
        const diff = Math.abs(choice - oldValue);

        if (lastDiff === null || diff < lastDiff) {
          lastDiff = diff;
          nearest = choices[index][0];
        } else if (lastDiff !== null && diff > lastDiff) {
          // The time ranges should be defined from smallest to largest, so if the difference increases we have left the minima
          break;
        }
      }

      newValue = nearest;
    }

    this.onChangeFilter(FilterKey.updated, newValue, newOperation, newSecondary);
  }

  /**
   * Show/hide errors produced by child component validators.
   * This is primarily triggered by MUI components and follows its callback signature
   * */
  onError(id, failed) {
    const failedValues = _.cloneDeep(this.state.failedValues);

    if (failed && !(id in failedValues)) {
      failedValues[id] = true;
    } else if (!failed && id in failedValues) {
      delete failedValues[id];
    } else {
      return;
    }

    this.setState({ failedValues });
  }

  applyFilter() {
    const { applyFilter } = this.props;
    if (applyFilter) applyFilter(this.state.tempFilter);
  }

  render() {
    const { open, filter, parent } = this.props;
    const { tempFilter } = this.state;
    let operation = _.get(tempFilter, `${FilterKey.updated}.op`);
    const isRelative = operation !== FilterOperation.between;
    const maxDateTime = subMinutes(parent.referenceDate, MAX_TIME_OFFSET);

    if (tempFilter === undefined) return null;

    const sameFilter = _.isEqual(tempFilter, filter);
    const valid = Object.keys(this.state.failedValues).length === 0;

    return (
      <div className={`filter-bar${open ? " open" : ""}`}>
        <div className="btn" disabled={sameFilter || !valid} onClick={this.applyFilter}>
          Apply
        </div>
        {/* <FilterOption
          field={FilterKey.geo}
          className="geo"
          name="Geolocation"
          tooltip="Filter flag based on geolocation data collected on address."
          filter={tempFilter}
          optionNames={Object.values(geoRanges)}
          options={geoRanges}
          defaultValue={"any"}
          onChange={this.onChangeFilter}
        /> */}
        <div className="sep-v" />

        {isRelative ? (
          <FilterOption
            field={FilterKey.updated}
            name="Since"
            tooltip={timeTooltip}
            filter={tempFilter}
            optionNames={Object.keys(timePresetResolvers)}
            onChange={this.onChangeFilter}
          />
        ) : (
          <Fragment>
            <FilterOption
              className="vertical"
              field={FilterKey.updated}
              name="To"
              filter={tempFilter}
              range={{ min: parent.referenceDate, max: _.get(tempFilter, `${FilterKey.updated}.value`, parent.referenceDate) }}
              onChange={this.onChangeFilter}
              onError={this.onError}
              defaultValue={parent.referenceDate}
              type={ValueType.date}
              secondary
            />

            <div className="sep-v" />
            <FilterSlider
              filter={tempFilter}
              field={FilterKey.updated}
              onChange={this.onChangeFilter}
              range={{ min: parent.referenceDate, max: maxDateTime }}
              referenceDate={parent.referenceDate}
            />
            <div className="sep-v" />

            <FilterOption
              className="vertical"
              field={FilterKey.updated}
              name="From"
              filter={tempFilter}
              range={{ min: _.get(tempFilter, `${FilterKey.updated}.secondary`, parent.referenceDate), max: maxDateTime }}
              onChange={this.onChangeFilter}
              onError={this.onError}
              type={ValueType.date}
              defaultValue={this.refereneDate}
            />
          </Fragment>
        )}

        <div className="time vertical">
          <Tippy
            content="Time filter with pecific range"
            animation="scale-subtle"
            theme="material"
            duration={global.gTTPDur}
            delay={[global.gTTPShow, 0]}
          >
            <div className="btn" disabled={!isRelative} onClick={this.toggleRelativeTime}>
              Absolute
            </div>
          </Tippy>

          <Tippy
            content="Time filter with current dates"
            animation="scale-subtle"
            theme="material"
            duration={global.gTTPDur}
            delay={[global.gTTPShow, 0]}
          >
            <div className="btn" disabled={isRelative} onClick={this.toggleRelativeTime}>
              Relative
            </div>
          </Tippy>
        </div>

        {/* <div className="sep-v" />
        <FilterOption
          field={FilterKey.risk}
          name="Risk"
          filter={tempFilter}
          operations={riskOperations}
          range={{ min: 0, max: maxRisk }}
          onChange={this.onChangeFilter}
        /> */}
      </div>
    );
  }
}
