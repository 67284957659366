import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";

import "../styles/MultivalueDropdown.css";
import Tippy from "@tippyjs/react";
import { isEmpty } from "../functions/general";

/**
 * @param {string} text Displayed for user
 * @param {*} value actual value of ooption
 * @param {boolean} enabled whether the user can select this option
 * @param {string} tooltip a hint to show the user when cursor hovers over option
 */
export const MultivalueOption = (text, value, enabled, tooltip) => ({
  text,
  value,
  enabled,
  tooltip,
});

class MultivalueDropdown extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // ID of the component used when triggering callbacks
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
        enabled: PropTypes.bool,
        tooltip: PropTypes.string,
      })
    ), // list of options to display
    disabled: PropTypes.bool, // displays all pointer events
    className: PropTypes.string, // extra classes for CSS styling
    tabIndex: PropTypes.string, // property for tabbing into the component
    onChange: PropTypes.func, // callback triggered when option selected
  };

  constructor(props) {
    super(props);

    this.optionMapping = {};
    props.options.forEach((option) => {
      let optionValue = option.value ? option.value : option;
      let optionText = option.text ? option.text : option;
      this.optionMapping[optionValue] = optionText;
    });

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { onChange, value, id } = this.props;
    const option = event.target.value;

    if (option === value) return; // can't select the same option
    if (onChange) onChange({ target: { id, value: option } });
  }

  render() {
    const { id, value, options, disabled, className } = this.props;

    return (
      <Select
        // labelId="demo-multiple-name-label"
        id={id}
        className={className}
        disabled={disabled}
        multiple
        value={value}
        onChange={this.handleChange}
        // input={<OutlinedInput />}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Select templates...</em>;
          }

          return selected.map((i) => _.get(this.optionMapping, i, "")).join(", ");
        }}
      >
        {options.map((option) => {
          const text = option.text ? option.text : option;
          const optionValue = option.value ? option.value : option;
          const enabled = option.enabled !== undefined ? option.enabled : true;

          const item = !isEmpty(option.tooltip) ? (
            <Tippy content={option.tooltip} animation="scale-subtle" theme="material" duration={global.gTTPDur} delay={[300, 0]}>
              <span>{text}</span>
            </Tippy>
          ) : (
            text
          );

          return (
            <MenuItem id={`${id}-${optionValue}`} key={optionValue} value={optionValue} disabled={!enabled}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    );
  }
}

export default MultivalueDropdown;
