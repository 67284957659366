// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import { Auth } from "@aws-amplify/auth";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withToastManager } from "react-toast-notifications";
import { withIdleTimer } from "react-idle-timer";
import { withCookies } from "react-cookie";
import _ from "lodash";

import { pages, site_cookies, ClientTheme, gateways } from "../enumerations";
import { themes, devConfig } from "../constants";
import { cookie_settings, MyAPI, withRouter } from "../functions/general";
import * as UserActions from "../reducers/actions";
import NavBar from "./NavBar";
import SidePanel from "../containers/SidePanel";
import Routes from "../routing/Routes";
import { ReactComponent as AlignableLogo } from "../../static/img/alignable.svg";
import "../styles/App.css";

// Tippy Styling
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import "tippy.js/animations/scale-subtle.css";

// range picker styling
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

/*
 * See react-idle-timer docs
 * https://idletimer.dev/docs/api/with-idle-timer
 */
class IdleTimerComponent extends Component {
  render() {
    return null;
  }
}

const IdleTimer = withIdleTimer(IdleTimerComponent);

/*
 * To make our login information persist we need to store and load it
 * from the browser session. Instead of using Cookies or Local Storage
 * we use AWS Amplify that does this for us automatically and we just
 * need to read from it and load it into our application state.
 * Amplify gives us a way to get the current user session using the
 * Auth.currentSession() method. It returns a promise that resolves to
 * the session object (if there is one).
 *
 * We are going to load this when our app loads, with the function
 * componentDidMount. Since Auth.currentSession() returns a promise,
 * it means that we need to ensure that the rest of our app is only
 * ready to go after this has been loaded.
 */
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      timeout: 1000 * 60 * 15, // 15 min
      isTimedOut: false,
      authGroups: null,
      userId: null,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  _onAction() {
    this.setState({ isTimedOut: false });
  }

  _onActive() {
    console.debug("App::User has become active.");
    this.setState({ isTimedOut: false });
  }

  _onIdle() {
    const { isTimedOut } = this.state;
    if (isTimedOut) {
      //TODO: log user out if they have timed out
      //Recorded as work item 132 in DevOps
      // this.props.navigate('/')
    } else {
      // this.setState({showModal: true})
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  /**
   * All this does is load the current session. If it loads, then it
   * updates the isAuthenticating flag once the process is complete.
   * The Auth.currentSession() method throws an error "No current user" if
   * nobody is currently logged in.
   * @since 0.3.1
   */
  async componentDidMount() {
    try {
      const session = (global.gIsProd ? false : devConfig.localAuth) ? devConfig.localSession : await Auth.currentSession();
      this.authCallback(true, session);
    } catch (e) {
      if (e !== "No current user") {
        console.error(e);
      }
    }

    /* This initializes the isAuthenticated flag in the App’s state. */
    this.setState({ isAuthenticating: false });
  }

  /**
   * And calling authCallback updates the isAuthenticated flag in
   * the App's state.
   * @since 0.3.1
   * @param { * } authenticated
   * @param {*} session
   */
  authCallback = (authenticated, session, redirect) => {
    const { setUser, cookies } = this.props;

    let newState = { isAuthenticated: authenticated };
    if (!authenticated) {
      newState.authGroups = null;
      newState.userId = null;
    }
    this.setState(newState);

    // Store as cookie for faster loading, but update later with current info
    const cachedUser = cookies.get(site_cookies.curUser);
    if (cachedUser !== undefined) setUser(cachedUser);

    if (authenticated) {
      const authGroups = session.idToken.payload["cognito:groups"];
      cookies.set(site_cookies.session, JSON.stringify(authGroups), cookie_settings(300));
      const userId = session.idToken.payload.sub;
      this.setState({ authGroups, userId: userId });

      // TODO: Only redirect if not controlled by AuthenticatedRoute (e.g. Login)
      // if (redirect) this.props.navigate(redirect);

      let url = gateways.userItem.replace(":id", userId);

      MyAPI.get(url)
        .then((response) => {
          console.debug("App::Get user response: ", response);

          if (response.statusCode !== 200) {
            console.error(response.message);
          } else {
            const current_user = response.body;
            setUser(current_user);
            cookies.set(site_cookies.curUser, current_user, cookie_settings(300));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  /**
   * If we didn't use this method below, we would only be removing the user
   * session from our app's state. But when the user refreshed the page, they
   * would be loading the user session from the browser local storage (using
   * Amplify), in effect logging them back in.
   * @since 0.3.1
   */
  handleLogout = async () => {
    const { setUser, cookies, changeTheme } = this.props;

    changeTheme(ClientTheme[1]);
    await Auth.signOut();

    this.authCallback(false);
    setUser(null);
    cookies.remove(site_cookies.curUser);

    /* This redirects us back to the login page once the user logs out. */
    this.props.navigate(pages.login);
  };

  render() {
    const { theme } = this.props;
    const selTheme = _.merge(themes.Root, themes[theme]);

    const childProps = {
      navigate: this.props.navigate,
      location: this.props.location,
      isAuthenticated: this.state.isAuthenticated,
      authCallback: this.authCallback,
      authGroups: this.state.authGroups,
      isTimedOut: this.state.isTimedOut,
      cookies: this.props.cookies,
      userId: this.state.userId,
      theme: selTheme,
      workerAvailable: Boolean(window.Worker),
    };

    const showNav = this.state.isAuthenticated;

    return (
      !this.state.isAuthenticating && (
        <Fragment>
          <NavBar handleLogout={this.handleLogout} {...childProps} />

          {this.state.isAuthenticated && (
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={250}
              timeout={this.state.timeout}
            />
          )}

          {showNav && <SidePanel href={window.location.href} authGroups={this.state.authGroups} />}
          <Routes childProps={childProps} />

          {!showNav && (
            <footer>
              <div className="separator" />
              <div className="social-row">
                <a className="social" href="https://twitter.com/Auspex_Labs" title="Twitter">
                  <i className="fab fa-twitter" />
                </a>
                <a className="social" href="https://www.linkedin.com/company/auspex-labs/" title="LinkedIn">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a className="social" href="https://www.facebook.com/AuspexLabs/" title="Facebook">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="social" href="https://www.alignable.com/ashburn-va/auspex-labs-inc" title="alignable">
                  <AlignableLogo />
                </a>
              </div>
              <div className="actions">
                <Link to={pages.login} className="">
                  Home
                </Link>
                <a href="https://www.auspex-labs.com/about-auspex">About</a>
                <a href="https://www.auspex-labs.com/code-of-ethics">Code of Ethics</a>
                <a href="https://www.auspex-labs.com/products">Products</a>
                <a href="https://www.auspex-labs.com/services">Services</a>
                <a href="https://www.auspex-labs.com/contact">Contact</a>
                <a href="https://www.auspex-labs.com/terms">Terms and Conditions</a>
                <a href="https://www.auspex-labs.com/privacy">Privacy Policy</a>
              </div>
              <table>
                <tr>
                  <td className="copyright">Copyright 2019-2023 Auspex Labs Inc.</td>
                  <td className="attribution" align="right">
                    This product includes GeoLite2 data created by MaxMind, available from{" "}
                    <a href="http://www.maxmind.com">http://www.maxmind.com</a>.
                  </td>
                </tr>
              </table>
              <div> </div>
            </footer>
          )}
        </Fragment>
      )
    );
  }
}

const mapDispatch = (dispatch) => {
  const actions = bindActionCreators(UserActions, dispatch);
  return { ...actions };
};

const mapState = (state) => ({
  dispatch: state.dispatch,
  theme: state.user.preferences.theme,
});

export default connect(mapState, mapDispatch)(withToastManager(withRouter(withCookies(App))));
