// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import { withToastManager } from "react-toast-notifications";
import _ from "lodash";

import * as SysmapActions from "../reducers/actions";

import GraphMessage from "../components/GraphMessage";
import GraphArea from "../components/GraphArea";
import GraphLegend from "../components/GraphLegend";
import StatusBar from "../components/StatusBar";
import FilterBar, { extractFilter, defaultFilter, saveFilterToQuery, validateFilter } from "../components/FilterBar";
import Directory from "../components/Directory";
import InfoList from "../components/InfoList";
import NetworkList from "../components/NetworkList";
import WorkspaceButton from "../components/WorkspaceButton";
import { FetchStatus, gateways, NodeRange, pages, site_cookies } from "../../../shared/enumerations";
import { formatPageTitle } from "../../../shared/functions/formatting";
import { MyAPI, requestErr, isEmpty, cookie_settings, normalizeGroups, getItemGroups } from "../../../shared/functions/general";
import WebWorker from "../../../shared/threading/WebWorker";
import Timer from "../../../shared/threading/timer";
import "../styles/SystemMap.css";
import { devConfig, modalStyle, ServerError } from "../../../shared/constants";
import Modal from "react-modal";
import LoaderButton from "../../../shared/components/LoaderButton";
import graphCache from "../graphcache";

// Limit the range by whic the user can zoom the graph
export const zoomRange = {
  min: 0.1,
  max: 10,
};

// the name of a network that does not exist
const NONEXISTENT_NETWORK = "Not Found";

// for dev debugging, disable auto refresh
const AUTO_REFRESH = false;

// Preferences on how the graph screen should behave
const DEFAULT_PREFERENCES = {
  autoSync: global.gIsProd ? false : AUTO_REFRESH,
  displayGraphLegend: true,
};

// The number of seconds to wait before begining autosync
const POLL_INTERVAL = global.gIsProd ? 60 : devConfig.pollInterval;

/**
 * Generate a date for use in filter that snapshots in time. Once saved, this should
 * prevent validations from being done out of sync with the current time
 * */
export function getReferenceDate() {
  let now = new Date();
  now.setMilliseconds(0);
  now.setSeconds(0);

  return now;
}

/**
 * Container for the System Map View. Connects all child components to store.
 * @since 0.2.2
 */
class SystemMapView extends Component {
  constructor(props) {
    super(props);
    let res = this.props.cookies.get(site_cookies.graph_prefs);
    let graphPrefs = res ? res : null;
    if (graphPrefs === null) graphPrefs = DEFAULT_PREFERENCES;
    if (!res) this.props.cookies.set(site_cookies.graph_prefs, JSON.stringify(graphPrefs), cookie_settings(3600 * 24 * 365));

    this.state = {
      // The state of the loader
      netLoader: {
        active: false,
        failure: false,
      },

      // information panel visiblities
      netListOpen: false,
      infoPaneOpen: false,
      filterOpen: false,

      // Whether the graph data is manually being refreshed
      refreshing: false,
      resetOpen: null,

      resetting: false,

      // Whether the user is currently fetching info for info panel
      inspecting: false,

      // message to be displayed in status bar
      statusMessage: "",

      currentZoom: 1,
      displayGraphLegend: graphPrefs.displayGraphLegend,

      // Whether to auto sync graph area
      autoSync: graphPrefs.autoSync,
    };

    this.referenceDate = getReferenceDate();

    this.refresh = this.refresh.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.closeNetList = this.closeNetList.bind(this);
    this.openNetList = this.openNetList.bind(this);
    this.closeInfoPane = this.closeInfoPane.bind(this);
    this.clearNet = this.clearNet.bind(this);
    this.openInfoPane = this.openInfoPane.bind(this);
    this.updateLoading = this.updateLoading.bind(this);
    this.updateInspection = this.updateInspection.bind(this);
    this.toggleGraphLegend = this.toggleGraphLegend.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.loadFilter = this.loadFilter.bind(this);
    this.recenter = this.recenter.bind(this);
    this.pollTimerCallback = this.pollTimerCallback.bind(this);
    this.startPollTimer = this.startPollTimer.bind(this);
    this.killPollTimer = this.killPollTimer.bind(this);
    this.toggleAutoSync = this.toggleAutoSync.bind(this);
    this.toggleFilterOpen = this.toggleFilterOpen.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onShowResetRiskModal = this.onShowResetRiskModal.bind(this);
    this.commitResetRisk = this.commitResetRisk.bind(this);
    this.setReferenceDate = this.setReferenceDate.bind(this);
  }

  /**
   * Triggered when the Component is first loaded into
   * the page.
   */
  componentDidMount() {
    const sysmap = this;

    // Retrieve list of networks
    if (Object.entries(this.props.networks).length === 0) {
      this.setState({ netLoader: { active: true, failed: false } });
      requestNetworkList((networkList) => {
        if (networkList === null) {
          // There was an error trying to request the list.
          this.setState({
            netLoader: { active: false, failed: true },
          });
        } else {
          this.setState({
            netLoader: { active: false, failed: false },
          });

          sysmap.props.actions.setNetworkList(networkList);

          // No network was selected
          if (isEmpty(this.props.selectedNetwork)) {
            if (networkList.length > 1) {
              let single = networkList.length === 2;
              let n = 0;
              if (single) {
                for (let j = 1; j < networkList.length; j++) {
                  if (networkList[j].id !== global.gOrgLevelUID) {
                    n = j;
                    break;
                  }
                }
              }

              // If there are subnets to view, show the network list pane
              if (single) single = networkList[n].subnets.length === 0;
              if (single) {
                // We only have one network, so select it as the first one!
                let qs = queryString.parse(window.location.search);
                qs.network = networkList[n].id;
                const query = queryString.stringify(qs);
                this.props.navigate(pages.sysmap + "?" + query);
                console.debug("SystemMap::Fetch list callback; setting qs");
                this.props.actions.viewNetwork({ id: qs.network, name: networkList[n].name });
                this.graphAreaRef.requestNetworkInfo(qs.network, this.props.toastManager, false, true);
              }
            }
          }
        }
      }, this.props.toastManager);
    }
  }

  componentDidUpdate(prevP) {
    if (prevP.fetcherStatus === "active" && this.props.fetcherStatus === "failed")
      this.props.toastManager.add("An unexpected server error occurred. (500)", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: global.gToastTimeout,
      });

    const { selectedNetwork } = this.props;
    let emptyNow = isEmpty(selectedNetwork),
      emptyBefore = isEmpty(prevP.selectedNetwork);
    // user has gone back to the network list
    if (emptyNow && !emptyBefore) {
      // this.setState({ filter: {}, tempFilter: {} });
    }

    // restart polling timer if user is no longer idle, but only if there is a graph to fetch
    const nonexistent = this.props.hierarchy.length > 0 ? this.props.hierarchy[0].name === NONEXISTENT_NETWORK : false;
    if (prevP.isTimedOut && !this.props.isTimedOut && this.props.networkID !== -1 && !nonexistent) {
      this.startPollTimer();
    }

    // Close the info pane if it should no longer be open
    if (this.state.infoPaneOpen && !this.canOpenInfoPane()) this.closeInfoPane();
  }

  componentWillUnmount() {
    this.killPollTimer();
  }

  closeNetList() {
    this.setState({ netListOpen: false });
  }

  openNetList() {
    // Show the network selection overlay
    this.props.actions.viewNetwork({});
    this.props.actions.stopNetFetch();
    this.props.actions.updateHierarchy([]); //clear so that ribbon clears
    this.setState({ netListOpen: true });
  }

  closeInfoPane() {
    this.setState({ infoPaneOpen: false });
  }

  openInfoPane() {
    if (!this.canOpenInfoPane(true)) return;
    this.setState({ infoPaneOpen: true });
  }

  canOpenInfoPane() {
    const fetching = this.props.fetcherStatus === FetchStatus.ACTIVE;
    const nonexistent = this.props.hierarchy.length > 0 ? this.props.hierarchy[0].name === NONEXISTENT_NETWORK : false;
    const noGroups = this.props.groups.length === 0 && !this.state.inspecting;
    const noNetworks = this.props.networkID === -1 || Object.keys(this.props.networks).length === 0;
    return !(noNetworks || fetching || nonexistent || this.props.isEmpty || this.state.netLoader.failed || noGroups);
  }

  toggleGraphLegend() {
    const { selectedNetwork } = this.props;
    if (!isEmpty(selectedNetwork)) {
      this.setState({ displayGraphLegend: !this.state.displayGraphLegend }, this.savePreferences);
    }
  }

  savePreferences() {
    const graphPrefs = {
      autoSync: this.state.autoSync,
      displayGraphLegend: this.state.displayGraphLegend,
    };

    this.props.cookies.set(site_cookies.graph_prefs, JSON.stringify(graphPrefs), cookie_settings(3600 * 24 * 365));
  }

  toggleAutoSync(autoSync, _, id) {
    this.setState({ autoSync }, () => {
      this.savePreferences();

      if (autoSync) {
        const nonexistent = this.props.hierarchy.length > 0 ? this.props.hierarchy[0].name === NONEXISTENT_NETWORK : false;
        const noNetworks = this.props.networkID === -1 || Object.keys(this.props.networks).length === 0;

        if (nonexistent || noNetworks) return;

        this.startPollTimer();
      } else {
        this.killPollTimer();
      }
    });
  }

  pollTimerCallback() {
    console.debug("SystemMap::poll timer starting network fetch");
    this.killPollTimer();

    // start fetch process
    const { toastManager, networkID } = this.props;
    this.graphAreaRef.requestNetworkInfo(networkID, toastManager, true, false, true);
  }

  startPollTimer() {
    // When initial fetch is finished, we should still set the time it completed
    // for future reference
    this.setState({
      refreshing: false,
    });

    if (!this.state.autoSync || !this.props.workerAvailable) return;
    console.debug("SystemMap::poll Timer started");
    // Ensure no timer is already running
    this.killPollTimer();

    this.pollTimer = new WebWorker(Timer);
    this.pollTimer.addEventListener("message", this.pollTimerCallback, false);
    this.pollTimer.postMessage([POLL_INTERVAL]);
  }

  /**
   * Halt the execution of the poll timer to prevent polling
   */
  killPollTimer() {
    if (this.pollTimer === undefined) return;
    console.debug("SystemMap::poll timer killed");
    this.pollTimer.terminate();
    delete this.pollTimer;
  }

  /**
   * Set the network loading state from child components.
   * This is primarily called from the GraphArea when it requests a new network
   */
  updateLoading(netLoader) {
    this.setState({ netLoader, refreshing: false });
  }

  /**
   * Set the inspection loading state from child components.
   * @param {bool} active
   */
  updateInspection(active) {
    if (active) {
      this.setState({ inspecting: true }, () => {
        this.openInfoPane();
      });
    } else this.setState({ inspecting: false });
  }

  /**
   * Refreshes the visualization of the currently selected network.
   * This cannot be called if no network is currently selected
   * @since 0.3.1
   */
  async refresh() {
    const { actions, selectedNetwork, fetcherStatus } = this.props;
    const fetching = fetcherStatus === FetchStatus.ACTIVE;

    if (fetching) {
      // stop the fetch
      this.setState({ refreshing: false }, () => {
        actions.stopNetFetch();
      });
    } else if (!isEmpty(selectedNetwork)) {
      this.killPollTimer();
      actions.startNetFetch();
      this.setState({ refreshing: true });

      const { toastManager, networkID } = this.props;
      this.graphAreaRef.requestNetworkInfo(networkID, toastManager, true, false, true);
    }
  }

  /**
   * Refreshes the current list of networks by resending the request
   * through the gateway
   * @since 0.3.1
   */
  refreshList() {
    const sysmap = this;
    this.setState({
      refreshing: true,
      netLoader: { active: true, failed: true },
    });
    requestNetworkList((networkList) => {
      if (networkList === null) {
        // There was an error trying to request the list.
        this.setState({
          refreshing: false,
          netLoader: { active: false, failed: true },
        });
      } else {
        // clear the network list
        // this happens only on the callback to prevent fetch errors
        // from triggering an empty list

        sysmap.props.actions.setNetworkList(networkList);
        this.setState({
          netLoader: { active: false, failed: false },
          refreshing: false,
        });
      }
    }, this.props.toastManager);
  }

  zoomIn() {
    this.graphAreaRef.zoomGraph(1);
  }

  zoomOut() {
    this.graphAreaRef.zoomGraph(-1);
  }

  /**
   * Set the current zoom to the specified value
   * @param {number} value
   */
  setZoom(value) {}

  recenter() {
    //graphAreaRef is good with the workaround https://github.com/reactjs/reactjs.org/issues/2120
    console.debug("SystemMapView::recenter");
    this.graphAreaRef.recenterGraph();
  }

  toggleFilterOpen() {
    this.setState({ filterOpen: !this.state.filterOpen });
  }

  /** Load the filter into the component either from the querystring or from cookies */
  loadFilter(apply = false, ignoreQueryUpdate = false) {
    console.debug("SystemMapView::Loading filter");
    let qs = queryString.parse(window.location.search);
    let filter = extractFilter(qs);
    const { cookies, navigate } = this.props;

    if (_.isEmpty(filter)) {
      let res = cookies.get(site_cookies.graph_filter);
      if (res) {
        // Filter could now be invalid, despite being loaded from cookies
        validateFilter(res, this.referenceDate);
        filter = res;
      } else {
        filter = defaultFilter;
      }

      if (!ignoreQueryUpdate && !isEmpty(qs.network)) saveFilterToQuery(filter, navigate, cookies, res === undefined);
    } else {
      const valid = validateFilter(filter, this.referenceDate);
      if (!valid) saveFilterToQuery(filter, navigate, cookies, true);
    }

    if (apply) this.props.actions.setFilter(filter);
    return filter;
  }

  // Non render triggering function to establish single timestamp for use in filtering
  setReferenceDate() {
    this.referenceDate = getReferenceDate();
  }

  applyFilter(newFilter) {
    const { cookies, navigate, filter, actions } = this.props;

    // Don't apply the same filter more than once
    if (_.isEqual(filter, newFilter)) return;

    this.killPollTimer();
    newFilter = _.cloneDeep(newFilter);
    saveFilterToQuery(newFilter, navigate, cookies);
    actions.setFilter(newFilter);

    // Fetch new graph with updated filter
    if (!isEmpty(this.props.selectedNetwork)) {
      this.setState({ refreshing: true }, () => {
        this.graphAreaRef.filterExisting(newFilter);
      });
    }
  }

  onShowResetRiskModal(event, node) {
    console.debug("SystemMapView::Reset Node risk: ", node);
    event.stopPropagation();
    event.preventDefault();

    let name;
    switch (node.range) {
      case NodeRange.FLOW:
        name = node.name;
        break;
      default:
        name = node.ip;
    }

    this.setState({ resetOpen: { id: node.id, name } });
  }

  closeModal(event) {
    this.setState({ [event.target.id]: event.target.id === "resetOpen" ? null : false });
  }

  /**
   * Clear the network view of the current list, if
   * there is more than one network available
   */
  clearNet() {
    console.debug("Directory::clearNet setting qs");
    this.props.navigate(pages.sysmap);

    this.killPollTimer();

    if (this.graphAreaRef) {
      this.graphAreaRef.killFreezeTimer();
      this.graphAreaRef.setGraphData({});
    }

    this.openNetList();
  }

  /** Reset risk for given node; refresh threats summary to reflect change */
  commitResetRisk() {
    const { toastManager } = this.props;
    const { resetOpen: node } = this.state;
    this.setState({ resetting: true });

    let url = gateways.riskItem.replace(":id", node.id);
    MyAPI.get(url)
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({ resetting: false, resetOpen: null });

          this.props.toastManager.add(`Successfully reset risk for ${node.ip_address}`, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: global.gToastTimeout,
          });

          // TODO: updating local information will be much easier once display info is preprocessed
          this.props.actions.inspectItem([], false, null);
          graphCache.invalidateItem(this.props.userId, node.id);
          this.refresh();
        } else {
          throw new ServerError(response);
        }
      })
      .catch((error) => {
        this.setState({ resetting: false });
        toastManager.add(requestErr(error), {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });
      });
  }

  renderRiskResetModal() {
    const { resetOpen, resetting } = this.state;
    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];
    if (!resetOpen) return null;

    return (
      <Modal isOpen={true} style={style}>
        <div className="modal-pnl">
          <div className="header">Reset Risk</div>
          <span className="info">
            Reset the risk for {resetOpen.name}? All risk collected for this node up to this point will be considered as benign.
          </span>

          <div className="footer">
            <div className="actions center">
              <div className="btn quart" id="resetOpen" onClick={this.closeModal}>
                Cancel
              </div>
              <LoaderButton
                type="submit"
                text="Reset"
                loadingText="Resetting..."
                isLoading={resetting}
                onClick={this.commitResetRisk}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { fetcherStatus, hierarchy, selectedNetwork, networks, networkID, isEmpty: emptyGraph } = this.props;
    const nonexistent = hierarchy.length > 0 ? hierarchy[0].name === NONEXISTENT_NETWORK : false;
    const { infoPaneOpen, netLoader } = this.state;
    const canOpen = this.canOpenInfoPane();
    const selectedGraph = !isEmpty(selectedNetwork);

    let isLimited = false;
    let networkFetch = () => null;
    if (this.graphAreaRef !== undefined) {
      isLimited = this.graphAreaRef.state.d3LimitSim;
      networkFetch = this.graphAreaRef.requestNetworkInfo;
    }

    const filterBar = (
      <FilterBar
        parent={this}
        setReferenceDate={this.setReferenceDate}
        open={this.state.filterOpen}
        valid={this.state.netListOpen}
        applyFilter={this.applyFilter}
        filter={this.props.filter}
        actions={this.props.actions}
      />
    );

    // Overlay message
    let msg = null;
    if (netLoader.failed) {
      msg = (
        <GraphMessage message={`Network list unavailable.`}>
          <div className="btn" onClick={this.refreshList}>
            Try again
          </div>
        </GraphMessage>
      );
    } else if (nonexistent) {
      msg = <GraphMessage message={`This network does not exist.`} />;
    } else if (networkID !== -1) {
      if (fetcherStatus === FetchStatus.FAILED) {
        msg = (
          <GraphMessage message="There was a problem loading the graph.">
            <div className="btn" onClick={this.refresh}>
              Retry
            </div>
          </GraphMessage>
        );
      } else if (fetcherStatus === FetchStatus.NOT_FOUND) {
        msg = (
          <GraphMessage message="This network does not exist.">
            <div className="btn" onClick={this.clearNet}>
              Back
            </div>
          </GraphMessage>
        );
      } else if (emptyGraph && fetcherStatus !== FetchStatus.ACTIVE) {
        msg = <GraphMessage message="This network currently has no data." />;
      }
    } else if (Object.keys(networks).length === 0) {
      // TODO: Now that we can search directly to an owned node from the network list,
      // it is possible orgs can have addresses but no networks. The logic below should account for this.
      msg = (
        <GraphMessage message="There are no networks.">
          <div className="btn" onClick={this.refreshList}>
            Refresh
          </div>
        </GraphMessage>
      );
    }

    // TODO : <GraphMessage message="Select a network from the panel on the left."/>
    // related to a larger effort of making the UI as user friendly as possible including first-time users.
    // Recorded as work item 135 in DevOps

    return (
      <main className="sysmap">
        {formatPageTitle("System Map")}
        {this.renderRiskResetModal()}

        {/* {netLoader.active && <LoaderOverlay text="Fetching Network Data..." />} */}

        <Fragment>
          <InfoList
            open={infoPaneOpen}
            canOpenInfoPane={canOpen}
            openInfoPane={this.openInfoPane}
            closeInfoPane={this.closeInfoPane}
            inspecting={this.state.inspecting}
            groups={this.props.groups}
            theme={this.props.theme}
            activeItem={this.props.selectedItem.source}
            onShowRiskReset={this.onShowResetRiskModal}
            {...this.props.actions} // why are we deconstructing this object?
          />

          <NetworkList
            open={this.state.netListOpen}
            current_graph_network_id={this.props.current_graph_network_id}
            requestNetworkInfo={networkFetch}
            closeNetList={this.closeNetList}
            refreshList={this.refreshList}
            initializing={netLoader.active}
            refreshing={this.state.refreshing && fetcherStatus === FetchStatus.ACTIVE}
            networks={networks}
            filter={this.props.filter}
            theme={this.props.theme}
            history={this.props.history}
            actions={this.props.actions}
            toastManager={this.props.toastManager}
            killPollTimer={this.killPollTimer}
            navigate={this.props.navigate}
          />

          <div id="activeWorkspace" className={`workspace${!infoPaneOpen ? " expanded" : ""}`}>
            {!netLoader.active && msg}
            <GraphArea
              childRef={(ref) => (this.graphAreaRef = ref)} // set element for reference use, see https://github.com/reactjs/reactjs.org/issues/2120
              theme={this.props.theme}
              networkID={networkID}
              onShowResetRiskModal={this.onShowResetRiskModal}
              filter={this.props.filter}
              actions={{
                openInfoPane: this.openInfoPane,
                closeInfoPane: this.closeInfoPane,
                ...this.props.actions,
              }}
              toastManager={this.props.toastManager}
              iteration={this.props.iteration}
              current_graph_network_id={this.props.current_graph_network_id}
              selectedItem={this.props.selectedItem}
              updateInspection={this.updateInspection}
              loadFilter={this.loadFilter}
              hierarchy={this.props.hierarchy}
              fetching={fetcherStatus === FetchStatus.ACTIVE}
              startPollTimer={this.startPollTimer}
              killPollTimer={this.killPollTimer}
              cookies={this.props.cookies}
              history={this.props.history}
              authGroups={this.props.authGroups}
              navigate={this.props.navigate}
              userId={this.props.userId}
              openNetList={this.openNetList}
            />

            <GraphLegend theme={this.props.theme} open={this.state.displayGraphLegend} />

            {/* Graph Control Buttons */}
            <div className="buttons">
              {/* Toggle Legend */}
              <WorkspaceButton
                text={<i className="fas fa-info-circle" />}
                tooltip={"Toggle Legend"}
                onClick={this.toggleGraphLegend}
                disabled={!selectedGraph}
              />

              {/* Refresh Current Data */}
              <WorkspaceButton
                text={<i className={`fas fa-sync-alt${fetcherStatus === FetchStatus.ACTIVE ? " spin" : ""}`} />}
                tooltip={
                  fetcherStatus === FetchStatus.ACTIVE
                    ? this.state.refreshing
                      ? "Refreshing... Click to stop."
                      : "Expanding... Click to stop."
                    : "Refresh Graph Data"
                }
                onClick={this.refresh}
                disabled={!selectedGraph}
              />

              {/* Center network to GraphArea */}
              <WorkspaceButton
                text={<i className="fas fa-crosshairs" />}
                disabled={!selectedGraph}
                tooltip={"Recenter Graph"}
                onClick={this.recenter}
                {...this.props.actions}
              />

              {/* Filter network graph */}
              <WorkspaceButton
                childRef={(ref) => (this.filterButtonRef = ref)}
                className="anchor"
                text={<i className={`fas fa-${this.state.filterOpen ? "times-circle" : "history"}`}> </i>}
                // disabled={!selectedGraph}
                tooltip={this.state.filterOpen ? "Close Filter" : "Filter Graph"}
                onClick={this.toggleFilterOpen}
                {...this.props.actions}
              >
                {selectedGraph && filterBar}
              </WorkspaceButton>
            </div>

            <Directory
              hierarchy={this.props.hierarchy}
              networks={networks}
              organization={this.props.user.organization}
              selectedNetwork={this.props.selectedNetwork}
              actions={this.props.actions}
              killPollTimer={this.killPollTimer}
              toastManager={this.props.toastManager}
              fetcherStatus={fetcherStatus}
              requestNetworkInfo={this.graphAreaRef ? this.graphAreaRef.requestNetworkInfo : () => null}
              clearNet={this.clearNet}
              navigate={this.props.navigate}
            />

            <StatusBar
              theme={this.props.theme}
              zoomIn={() => {
                this.zoomIn();
              }}
              zoomOut={() => {
                this.zoomOut();
              }}
              message={this.state.statusMessage}
              collapsed={!infoPaneOpen}
              autoSync={this.state.autoSync}
              toggleAutoSync={this.toggleAutoSync}
              isLimited={isLimited}
              workerAvailable={this.props.workerAvailable}
            />
          </div>

          {!selectedGraph && <div className="floating-filter">{filterBar}</div>}
        </Fragment>
      </main>
    );
  }
}

/**
 * Determines what information to show if no network is selected
 * @since 0.3.1
 * @param {*} state The data to be in the format of the network object as
 * recieved from the API
 */
const getNetGroups = (state) => {
  let groups = [
    {
      name: "Network Info",
      infos: [],
    },
  ];

  if (isEmpty(state.sysmap.selector.selectedNetwork)) {
    // Base group for no selected network
    groups[0].infos.push({ name: <i>Nothing is selected.</i>, value: "" });
  } else {
    let network = state.sysmap.selector.selectedNetwork;
    if (network.display_info === undefined) groups[0].infos.push({ name: <i>Loading...</i>, value: "" });
    else groups = normalizeGroups(network.display_info);
  }

  return groups;
};

/**
 * Requests the list of networks associated with the
 * currently authenticated user from the database. The list is formatted specifically to be
 * put into InfoGroups at for the info panel
 * @param {Function} callback The function called when the request is successful
 * @param {ToastManager} toaster The ToastManager object to push errors to the screen
 * @since 0.3.1
 */
export async function requestNetworkList(callback, toaster) {
  try {
    let netList = [];

    // Load networks from a static file
    if (global.gFromJSON) {
      netList = global.gNetListJSON.networks;
      console.debug("Network List JSON:\n", netList);
    } else {
      const response = await MyAPI.get(gateways.network);

      console.debug("Network List Response:\n", response);
      let body = response.body;
      if (response.statusCode !== 200) throw new Error(`${body.message} status code ${response.statusCode}`);
      netList = body.networks;
    }

    // Add organization level entry
    netList = [{ name: "Organization", id: global.gOrgLevelUID, subnets: [] }, ...netList];

    callback(netList === undefined ? null : netList);
  } catch (error) {
    toaster.add(requestErr(error), {
      appearance: "error",
      autoDismiss: true,
      autoDismissTimeout: global.gToastTimeout,
    });

    callback(null);
  }
}

// ====== Redux action and property mapping

/**
 * Retrieve information from store to be passed down React hierarchy
 */
const mapState = (state) => {
  const selector = state.sysmap.selector;

  // Get the ID from the index of the selection
  let nID = !isEmpty(selector.selectedNetwork) ? selector.selectedNetwork.id : -1;

  // If no item in the graph is selected, then show the network info
  let infoGroups =
    selector.selectedItem.data.length !== 0
      ? getItemGroups(selector.selectedItem.data)
      : isEmpty(selector.selectedNetwork)
      ? getNetGroups(state)
      : getItemGroups(selector.selectedNetwork);

  return {
    hierarchy: selector.hierarchy,
    networks: state.sysmap.networks.networkList,
    selectedNetwork: selector.selectedNetwork,
    selectedItem: selector.selectedItem,
    networkID: nID,
    groups: infoGroups,
    user: state.user,
    iteration: state.sysmap.fetch.iteration,
    fetcherStatus: state.sysmap.fetch.status,
    isEmpty: state.sysmap.fetch.isEmpty,
    current_graph_network_id: state.sysmap.selector.current_graph_network_id,
    chunks: state.sysmap.fetch.chunk_history,
    filter: selector.filter,
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(SysmapActions, dispatch),
});

export default connect(mapState, mapDispatch)(withToastManager(SystemMapView));
