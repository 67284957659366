// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import InfoItem from "./InfoItem";
import _ from "lodash";

/**
 * A group of information to be displayed in the info pane
 * @since 0.3.1
 * @param {*} infos The list of individual info elements
 * @param {*} groupName The name of the group
 * @param {number} theme The index of the user's preferred theme
 */
const InfoGroup = ({ infos, groupName, theme, actions, selectedItem, onShowRiskReset }) => {
  return (
    <div className="info-group">
      <div className="info-header">{groupName}</div>
      <Table>
        <TableBody>
          {infos.map((info, index) => {
            let hasRisk;
            try {
              hasRisk = _.get(info, "name", "").toLowerCase().includes("risk");
            } catch {
              hasRisk = false;
            }

            return (
              <InfoItem
                name={info.name}
                key={index}
                value={_.cloneDeep(info.value)}
                theme={theme}
                uuid={`info-group-${info.name}-${index}`}
                {...actions}
                groupName={groupName}
                selectedItem={selectedItem && hasRisk ? selectedItem : null}
                onShowRiskReset={onShowRiskReset}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default InfoGroup;
