// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

const Timer = () => {
  // The following only works properly if the eslint comment is inline. May need to revisit this;
  // Other attempts at using anything other than self have resulted in either too many thread responses or
  // none at all.

  // prettier-ignore
  self.addEventListener("message", e => { // eslint-disable-line no-restricted-globals
    console.debug("Timer: Message received from main script:", e);

    // Wait X seconds
    setTimeout(() => {
      console.debug("Timer: Timeout completed.");
      postMessage("timerFinish");
    }, e.data[0] * 1000);
  });
};

export default Timer;
