// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import _ from "lodash";

import { FilterKey, FilterOperation } from "../../pages/sysmap/components/FilterOption";
import { NodeRange, NodeClassification } from "../enumerations";

/**
 * Converts the provided graph from format
 * {
 *    "nodes": {"0x1": {data:{"id":"0x1", ip: "192.168.1.1"}}},
 *    "edges": {"0x2 {data: {"id": "0x2", source:"0x1", target: "0x3"}}}
 * }
 * to
 * {
 *    nodes:{"0x1": {id: "0x1", name: "harry"}},
 *    links:{"0x2": {source: "0x1", target: "0x2"}}
 * }
 * @param {*} graph
 */
export function transformToD3(graph) {
  if (graph.links) return graph;
  let new_graph;
  let nodes_orig = graph.nodes ? graph.nodes : {};
  let edges_orig = graph.edges ? graph.edges : {};
  let nodes_transformed = {};
  let edges_transformed = {};

  // unpack from data node for d3
  Object.keys(nodes_orig).forEach((key) => {
    if (nodes_orig[key] === undefined) {
      console.debug("GraphHelpers::Original node is undefined!! ", key);
    } else {
      nodes_transformed[key] = nodes_orig[key].data;
    }
  });

  // unpack from data node for d3, also a fresh copy is best for edges or the spread operator when merging with state d3GraphData
  // will copy the source and target node data into the edge
  Object.keys(edges_orig).forEach((key) => {
    if (edges_orig[key] === undefined) {
      console.debug("GraphHelpers::Original edge is undefined!! ", key);
    } else {
      edges_transformed[key] = _.cloneDeep(edges_orig[key].data);
    }
  });

  new_graph = {
    nodes: nodes_transformed,
    links: edges_transformed,
  };

  return new_graph;
}

// We have to clean the react-force-graph inserted properties from the graph
// so that when we load from cache the next time it won't be destructive
export function cleanD3Graph(d3GraphData) {
  const cleaned_graph = {
    nodes: {},
    edges: {},
  };

  d3GraphData.nodes.forEach((node) => {
    cleaned_graph.nodes[node.id] = {
      data: {
        id: node.id,
        ip: node.ip,
        name: node.name,
        expandable: node.expandable,
        classification: node.classification,
        host_type: node.host_type,
        range: node.range,
        risk: node.risk,
        display_info: node.display_info,
      },
    };
  });

  d3GraphData.links.forEach((edge) => {
    cleaned_graph.edges[edge.id] = {
      data: {
        id: edge.id,
        risk: edge.risk,
        source: edge.source.hasOwnProperty("id") ? edge.source.id : edge.source,
        target: edge.target.hasOwnProperty("id") ? edge.target.id : edge.target,
        topological: edge.topological,
      },
    };
  });

  return cleaned_graph;
}

/**
 * Toggle freezing nodes in graph.
 *
 * NOTE: This is a pure function and does not directly mutate any
 * object references from the original graph
 * @param {*} graph
 * @param {Boolean} freeze
 */
export function toggleSyncLock(graph, freeze) {
  graph.nodes.forEach((node) => {
    if (freeze) {
      // only lock nodes that haven't been locked by user
      if (node.fx === undefined) {
        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
        node.syncLocked = true;
      }
    } else {
      if (node.syncLocked !== undefined) {
        delete node.syncLocked;
        node.fx = undefined;
        node.fy = undefined;
        node.fz = undefined;

        // kill the velocity on nodes so they don't jerk when unfrozen
        node.vx = 0;
        node.vy = 0;
      }
    }
  });
}

function applyOperation(op, base, value) {
  if (value === undefined) return true;
  switch (op) {
    case FilterOperation.lt:
      return value < base;
    case FilterOperation.lte:
      return value <= base;
    case FilterOperation.eq:
      return value === base;
    case FilterOperation.gte:
      return value >= base;
    case FilterOperation.gt:
      return value > base;
    default:
      return true;
  }
}

/** Determines if a node is externally owned */
export const isExternal = (node) => [NodeClassification.EXTERNAL, NodeClassification.CLOUD_PROVIDER].includes(node.classification);

/**
 * Apply filter to current graph
 * @param {*} filter Filter with pre-evaluated key set (e.g. date ranges evaluated to flat date)
 * @param {*} d3GraphData Graph pulled directly from D3 datasource (arrays of nodes and links, not edges)
 */
export function filterGraph(filter, d3GraphData) {
  let processed_edges,
    excluded_edges = new Set();
  let processed_nodes,
    excluded_nodes = new Set();

  d3GraphData.links.forEach((edge) => {
    if (edge.topological) {
      return;
    }
    let source = edge.source;
    let target = edge.target;

    let filter_internal = filter[FilterKey.internal];

    // we need to preserve internal nodes if flag is set,
    // so determine whether nodes are internal or external
    let external,
      internal,
      node_list = [];
    if (isExternal(source)) {
      external = source;
      internal = [target];
    } else if (isExternal(target)) {
      external = target;
      internal = [source];
    } else internal = [source, target];
    if (filter_internal) {
      node_list = [...internal];
      if (external) node_list.push(external);
    } else if (external) {
      node_list = [external];
    }

    // Edge based filters
    let excluded_edge;
    let updated = filter[FilterKey.updated];
    if (updated) {
      if (!applyOperation(updated.op, updated.value, edge.updatedAt)) excluded_edge = true;
    }

    // Node based filters
    node_list = _.filter(node_list, (node) => {
      let included = false; // whether node is included in the node exclusion list

      // preserve hierarchy
      if (node.range !== NodeRange.ADDRESS) return false;

      let risk = filter[FilterKey.risk];
      if (risk) {
        if (!applyOperation(risk.op, risk.value, node.risk)) included = true;
      }

      let geo = filter[FilterKey.geo];
      if (geo) {
        switch (geo.value) {
          case "with":
            if (node.location === undefined) included = true;
            break;
          case "none":
            if (node.location !== undefined) included = true;
            break;
          default:
            break;
        }
      }

      return included;
    });

    // Exclude this edge if any connecting nodes were excluded
    if (node_list.length > 0) excluded_edge = true;
    node_list.forEach((node) => excluded_nodes.add(node.id));

    if (excluded_edge) {
      // cannot filter out edge if both nodes are internal and
      // internals are never filtered out
      if (!external && !filter_internal) return;
      excluded_edges.add(edge.id);
      // exclude nodes connected by edge
      if (external) excluded_nodes.add(external.id);
    }
  });

  excluded_nodes = Array.from(excluded_nodes);
  excluded_edges = Array.from(excluded_edges);
  processed_nodes = _.filter(d3GraphData.nodes, (filter_node) => !excluded_nodes.includes(filter_node.id));
  processed_edges = _.filter(d3GraphData.links, (filter_edge) => !excluded_edges.includes(filter_edge.id));

  const filtered_graph = {
    nodes: processed_nodes,
    links: processed_edges,
  };
  const to_cache = cleanD3Graph(filtered_graph);
  d3EdgeUnlink(filtered_graph);

  return {
    to_cache,
    to_set: filtered_graph,
  };
}

/**
 * Links the source and target IDs of a JSON edge to the respective nodes.
 * Should only be used when mocking D3 behavior
 *
 * NOTE: This function mutates the graph object.
 * @param {*} d3GraphData Expected format:
 * {
 *   nodes: {"0x0": {...}, ....},
 *   links: {"0x0": {source: "0x0", target: "0x1", ...}}
 * }
 */
export function d3EdgeLink(d3GraphData) {
  Object.values(d3GraphData.links).forEach((link) => {
    let source = d3GraphData.nodes[link.source];
    let target = d3GraphData.nodes[link.target];

    // If the node does not exist in the graph, then the graph is
    // invalid and the node must be reconstructed
    link.source = source
      ? source
      : {
          id: link.source,
          type: NodeClassification.MISSING,
          range: NodeRange.ADDRESS,
        };
    link.target = target
      ? target
      : {
          id: link.target,
          type: NodeClassification.MISSING,
          range: NodeRange.ADDRESS,
        };
  });
  return d3GraphData;
}

/**
 * To prevent D3-force from unlinking the nodes from edges on
 * graph merges, the references to nodes in the edges of the provided graph
 * should be ids, not the nodes themselves.
 *
 * NOTE: This function mutates the graph.
 * @param {*} d3GraphData Expected format:
 * {
 *   nodes: {"0x0": {...}, ....},
 *   links: {"0x0": {source: "0x0", target: "0x1", ...}}
 * }
 */
export function d3EdgeUnlink(d3GraphData) {
  let list = Object.values(d3GraphData.links);
  // shouldn't perform the same operation twice
  if (list.length > 0 && !list[0].source.hasOwnProperty("id")) return;
  list.forEach((link) => {
    link.source = link.source.id;
    link.target = link.target.id;
  });
}

/**
 * Given a graph, fill in missing nodes if needed so that we get a valid graph.
 * @param {*} graph graph to make valid
 */
export function makeValidD3Graph(graph) {
  console.debug(`GraphHelpers::getValidD3Graph `, graph);
  let graph_info = {};
  if (graph) {
    let graph_edges = graph.links ? graph.links : {};
    let graph_nodes = graph.nodes ? graph.nodes : {};

    // de-dupe edges, then get the nodes ids referenced by edges
    let edge_nodes_ids = new Set();
    Object.values(graph_edges).forEach((item) => {
      // get under data node if it is there
      if (item.source.id) {
        edge_nodes_ids.add(item.source.id);
        edge_nodes_ids.add(item.target.id);
      } else {
        edge_nodes_ids.add(item.source);
        edge_nodes_ids.add(item.target);
      }
    });

    // get the difference between the nodes ref'd by edges and the known graph nodes
    let missing_nodes = [...edge_nodes_ids].filter((x) => graph_nodes[x] === undefined);

    // go ahead and recreate this node; but note that it is missing (this is good for troubleshooting backend issues)
    if (missing_nodes && missing_nodes.length > 0) {
      // console.warn("There were nodes reference by edges that were not found: ", missing_nodes)
      missing_nodes.forEach((id) => {
        let missing_node = {
          data: {
            id: id,
            type: "missing",
            range: "missing",
            risk: 0,
            ip: "0.0.0.0",
          },
        };
        graph_nodes[id] = missing_node;
      });
    }

    graph_info.nodes = graph_nodes;
    graph_info.links = graph_edges;
    //console.debug(`GraphHelpers::getValidGraph cleaned graph_info=`, graph_info)
  }
  return graph_info;
}

/**
 * Get the points in a hexagon. Can be repurposed for use in SVG
 * @param {*} x
 * @param {*} y
 * @param {*} radius
 * @param {*} rot
 */
export function getHexagonPoints(x, y, radius, rot) {
  const a = Math.PI / 3;
  if (rot === undefined || rot === null) rot = Math.PI / 6;
  let points = [];

  for (var i = 0; i < 6; i++)
    points.push({
      x: x + radius * Math.cos(a * i + rot),
      y: y + radius * Math.sin(a * i + rot),
    });

  return points;
}

/**
 * Draw a hexagon to a canvas
 * @param {*} ctx Reference to Canvas context
 * @param {*} x position in canvas coords
 * @param {*} y position in canvas coords
 * @param {*} radius
 * @param {*} rot rotation along center (in radians)
 */
export function drawHexagon(ctx, x, y, radius, rot) {
  const points = getHexagonPoints(x, y, radius, rot);

  ctx.beginPath();
  for (var i = 0; i < 6; i++) ctx.lineTo(points[i].x, points[i].y);
  ctx.closePath();
  ctx.fill();
}
