// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import * as actions from "./actionTypes";

/** Add all monitoring dashboard data */
export const updateEndpointSummary = (data) => ({
  type: actions.UPDATE_MONITORING_SUMMARY,
  data,
});

export const toggleEndpointState = (endpointId) => ({ type: actions.TOGGLE_ENDPOINT_STATE, endpointId });
export const finishEndpointStateToggle = (endpointId) => ({ type: actions.FINISH_ENDPOINT_STATE_TOGGLE, endpointId });

/** Add display info for a selected host */
export const inspectEndpoint = (endpoint) => ({ type: actions.INSPECT_ENDPOINT, endpoint });

/** Initialize results asynchronous modification and deletions */
export const initEndpointOperation = (results) => ({
  results,
  type: actions.INIT_ENDPOINT_OPERATION,
});

export const failEndpointOp = (endpointId) => ({
  endpointId,
  type: actions.FAIL_ENDPOINT_OPERATION,
});
