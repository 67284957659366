// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import Switch from "react-switch";
import Tippy from "@tippyjs/react";

export default class StatusBar extends Component {
  render() {
    const { zoomIn, zoomOut, /*setZoom,*/ collapsed, autoSync, theme, toggleAutoSync, workerAvailable } = this.props;

    const autoSyncElem = (
      <div className="auto-sync">
        <Switch
          className={`toggle${workerAvailable ? "" : " disabled"}`}
          checked={autoSync}
          onChange={(event) => toggleAutoSync(event)}
          offColor={theme.background}
          onColor={theme.primary_dark}
          checkedIcon={false}
          uncheckedIcon={false}
          handleDiameter={16}
          height={20}
          width={44}
        />
        <label>Auto Sync</label>
      </div>
    );

    // const warning = (
    //   <Fragment>
    //     <i className="fas fa-exclamation-triangle" />
    //     &emsp; Warning: Will slow down display for massive graphs
    //   </Fragment>
    // );

    const disabledAlert = (
      <Fragment>
        <i className="fas fa-exclamation-triangle" />
        &emsp; Your browser does not support auto sync.
      </Fragment>
    );

    return (
      <div className={`status-bar${collapsed ? " collapsed" : ""}`}>
        {this.props.message}
        <div className="right actions">
          {!workerAvailable ? (
            <Tippy
              content={disabledAlert}
              animation="scale-subtle"
              theme="material"
              duration={global.gTTPDur}
              delay={[global.gTTPShow, 0]}
            >
              {autoSyncElem}
            </Tippy>
          ) : (
            <Fragment>{autoSyncElem}</Fragment>
          )}

          <div className="zoom">
            <div className="op" onClick={zoomOut}>
              <i className="fas fa-search-minus" />
            </div>
            {/* {setZoom ? <Slider onChange={setZoom}></Slider> : null} */}

            <div className="op" onClick={zoomIn}>
              <i className="fas fa-search-plus" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
