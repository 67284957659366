// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import _ from "lodash";

import { maxRisk, themes } from "../constants";
import { RiskLevel } from "../enumerations";

/**
 * @param {number} value of the risk level
 */
export function basicRiskColor(risk) {
  let color;
  switch (true) {
    case risk < RiskLevel.CAT1: {
      color = themes.Root.riskCatNone;
      break;
    }
    case risk >= RiskLevel.CAT1 && risk < RiskLevel.CAT2: {
      color = themes.Root.riskCat1;
      break;
    }
    case risk >= RiskLevel.CAT2 && risk < RiskLevel.CAT3: {
      color = themes.Root.riskCat2;
      break;
    }
    case risk >= RiskLevel.CAT3 && risk < RiskLevel.CAT4: {
      color = themes.Root.riskCat3;
      break;
    }
    default: {
      color = themes.Root.riskCat4;
      break;
    }
  }
  return color;
}
/**
 * @param {*} gradient The gradient, as defined as {
 *  stops: [
 *   {
 *     color: <hexidecimal>
 *     value: <number [0,1]>
 *   }
 *  ], ...
 * }
 * @param {number} value The index of the color within the gradient
 * @param {number} max The maximum the value can be. Any higher rounds
 *  to the highest valued stop.
 * @param {number} min The minimum the value can be. Any lower rounds
 *  lowest valued stop.
 */
export function evaluateGradient(gradient, value, max, min = 0) {
  let range = max - min;
  const norm = (value - min) / range;
  if (gradient.stops.length < 2) return gradient.stops[0];
  gradient.stops = _.orderBy(gradient.stops, ["value"], ["asc"]);
  // Lowest stop
  if (norm <= gradient.stops[0].value) {
    return gradient.stops[0].color;
  }

  for (let i = 0; i < gradient.stops.length - 1; i++) {
    if (norm >= gradient.stops[i].value && norm < gradient.stops[i + 1].value) {
      const cap = gradient.stops[i + 1].value - gradient.stops[i].value;
      const weight = (norm - gradient.stops[i].value) / cap;
      return blendColor(weight, gradient.stops[i].color, gradient.stops[i + 1].color);
    }
  }

  // Highest stop
  return gradient.stops[gradient.stops.length - 1].color;
}

/**
 * Determine the proper color to assign to a given risk value
 * @param {*} theme The current theme of the interface
 * @param {*} risk The value of risk to be evaluated
 * @param {Boolean} partial Whether to use the full range of colors or just the main few
 */
export function riskGradient(theme, risk, partial) {
  const mid = "#edd324"; // TODO: Remove hard coded hexcodes
  const gradient = {
    stops: partial
      ? [
          { color: "#afcd65", value: 0 },
          { color: mid, value: 0.22 },
          { color: theme.highlight_2, value: 0.6 },
          { color: theme.highlight_5, value: 1 },
        ]
      : [
          { color: theme.highlight_1, value: 0 },
          { color: theme.highlight_1, value: 0.16 },
          { color: mid, value: 0.42 },
          { color: theme.highlight_2, value: 0.7 },
          { color: theme.highlight_5, value: 1 },
        ],
  };

  return evaluateGradient(gradient, risk, maxRisk);
}

/**
 * Blend two Hex colors by the interpolation amount t
 * @param {number} t 0 == first color, 1 == second color.
 * @param {string} first The first color to lerp
 * @param {string} second The second color to lerp
 */
export function blendColor(t, first, second) {
  return lerpColor(t, 0, 1, hexToRgb(first), hexToRgb(second));
}

/**
 * linearly interpolates the RGB values of the two colors with relation to time
 * @param {Float} t the current time; if outside the parameters start and end, the value is constained automatically
 * @param {Float} start initial time
 * @param {Float} end maximum time
 * @param {*} first the beginning color
 * @param {} last the final color
 * @return the mixed color; if either color is null, the resulting color is the instantiated one.
 * If both are null, the resulting color is #FFFFFF
 */
export const lerpColor = (t, start, end, first, last) => {
  if (first === null || last === null) {
    if (first == null && last == null) return "#FFFFF";
    if (first == null) return last;
    return first;
  }

  //bounds validation
  if (t > end) t = end;
  if (t < start) t = start;
  const alpha = first.a !== undefined || last.a !== undefined;
  if (alpha) {
    if (last.a === undefined) last.a = 255;
    if (first.a === undefined) first.a = 255;
  }

  //slope calculation
  const mr = (last.r - first.r) / (end - start);
  const mg = (last.g - first.g) / (end - start);
  const mb = (last.b - first.b) / (end - start);
  let ma;
  if (alpha) ma = (last.a - first.a) / (end - start);

  //interpolation
  const res = {
    r: Math.round(mr * (t - start) + first.r),
    g: Math.round(mg * (t - start) + first.g),
    b: Math.round(mb * (t - start) + first.b),
  };

  if (alpha) res.a = Math.round(ma * (t - start) + first.a);

  return rgbToHex(res);
};

/**
 * Converts an RGB() color to Hexadecimal
 * @param {object} rgb object containing r, g, and b number values. alpha value is optional
 */
export function rgbToHex(rgb) {
  const alpha = rgb.a;
  if (alpha !== undefined) return rgba2Hex(rgb);
  return "#" + ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1);
}

export function rgba2Hex(rgba) {
  return "#" + rgba.r.toString(16) + rgba.g.toString(16) + rgba.b.toString(16) + rgba.a.toString(16).substring(0, 2);
}

/**
 * Converts a hexadecimal color to RGB()
 * @param {string} hex
 */
export function hexToRgb(hex) {
  if (hex === undefined)
    return {
      r: 0,
      g: 0,
      b: 0,
    };

  const alpha = hex.length === 9;
  if (hex.length !== 7 && !alpha) return null;

  let result = alpha
    ? /^#?([a-f\dA-F]{2})([a-f\dA-D]{2})([a-f\dA-D]{2})([a-f\dA-F]{2})$/i.exec(hex)
    : /^#?([a-f\dA-F]{2})([a-f\dA-F]{2})([a-f\dA-F]{2})$/i.exec(hex);

  if (result === null) return null;
  let color = {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };

  if (alpha) color.a = parseInt(result[4], 16);
  return color;
}

/**
 * Convert RGB colorspace to HSL colorspace
 * https://gist.github.com/mjackson/5311256
 * */
export function rgbToHsl(color) {
  color.r /= 255;
  color.g /= 255;
  color.b /= 255;

  let max = Math.max(color.r, color.g, color.b),
    min = Math.min(color.r, color.g, color.b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case color.r:
        h = (color.g - color.b) / d + (color.g < color.b ? 6 : 0);
        break;
      case color.g:
        h = (color.b - color.r) / d + 2;
        break;
      case color.b:
        h = (color.r - color.g) / d + 4;
        break;
      default:
        break;
    }

    h /= 6;
  }

  return { h, s, l };
}

/**
 * Convert HSL colorspace to RGB colorspace
 * https://gist.github.com/mjackson/5311256
 * */
export function hslToRgb(color) {
  let r, g, b;

  if (color.s === 0) {
    r = g = b = color.l; // achromatic
  } else {
    function hueToRgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }

    let q = color.l < 0.5 ? color.l * (1 + color.s) : color.l + color.s - color.l * color.s;
    let p = 2 * color.l - q;

    r = hueToRgb(p, q, color.h + 1 / 3);
    g = hueToRgb(p, q, color.h);
    b = hueToRgb(p, q, color.h - 1 / 3);
  }

  return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
}

/**
 * Generates a new color from a saturation percent of another.
 * @param {string} hex 6-char hex code.
 * @param {number} saturation positive number
 */
export function saturateColor(hex, saturation) {
  if (!/^#([0-9a-f]{6})$/i.test(hex)) {
    throw new Error("Unexpected color format; Expected 6 character hexcode: #abc123");
  }

  if (saturation < 0) {
    throw new Error("Saturation percent must be positive number.");
  }

  let rgbColor = hexToRgb(hex);
  let hslColor = rgbToHsl(rgbColor);

  hslColor.s *= saturation;
  return rgbToHex(hslToRgb(hslColor));
}
