// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import queryString from "query-string";

import CacheManager from "../../../shared/cache";
import { MyAPI, jsonpath, listToObj } from "../../../shared/functions/general";
import { decompress, stripDate } from "../../../shared/functions/formatting";
import * as NetworkActions from "../reducers/actions";
import { NodeRange, gateways } from "../../../shared/enumerations";
import { filterGraph, transformToD3, d3EdgeLink } from "../../../shared/functions/graphHelpers";
import { devConfig } from "../../../shared/constants";
import { evaluatePresetRange } from "../components/FilterBar";
import { FilterKey, FilterOperation } from "../components/FilterOption";

const cacheDisabled = global.gIsProd ? true : devConfig.cacheDisabled.graph;

/**
 * Act as a piechartized cache for the application's graph,
 * it is a smart cache that will fetch and expire the graph if necessary.
 */
class GraphCache {
  constructor() {
    this.cache = new CacheManager();
    this.graphKey = "saved_graphs";
    // the maximum number of graphs to store
    this.maxGraphs = 1;
    this.cacheTime = 10 * 60 * 1000; // 10 minutes
  }

  /**
   * Will call this from the app initialiation--we will use this like we are in a spring app!
   * NOTE: this seems to be unused since thre removal of the WebSocket
   */
  setDispatch(dispatch) {
    // console.debug('GraphCache::setDispatch ' + dispatch)
    this.dispatch = dispatch;
  }

  async getItem(userId, id, type, args, filter) {
    let data = null;
    const now = Date.now();
    let needToRetrieve = true;

    let cacheResponse = await this.cache.readData(userId, id);
    if (cacheResponse != null) {
      console.debug("GraphCache:: get Item: ", cacheResponse);
      let lastUpdated = cacheResponse.updatedAt;
      data = cacheResponse.data;
      needToRetrieve = now - lastUpdated >= this.cacheTime || cacheResponse.condition !== filter;
    } else {
      console.debug(`GraphCache:: item with key ${id} not in cache `);
    }

    if (needToRetrieve) {
      let response;
      if (global.gFromJSON) {
        // retrieve data via local file
        console.debug("GraphCache::getItem: per config, getting global dummy data!");
        //console.debug('response', JSON.stringify(global.gDataJSON))
        let path = `$..${type === NodeRange.FLOW ? "edges" : "nodes"}[?(@.data.id=='${id}')]`;
        let jsonpathRes = jsonpath.query(global.gDataJSON, path);
        //jsonpath returns array; make it look like api response
        data = jsonpathRes[0].data.display_info;
      } else {
        let url,
          query = {};

        switch (type) {
          case NodeRange.FLOW:
            delete args.hash_key;
            query = { ...args };
            url = `${gateways.sessionItem.replace(":id", id)}`;
            break;
          case NodeRange.NETWORK:
            url = `${gateways.networkItem.replace(":id", id)}`;
            break;
          case NodeRange.SUBNET:
            url = `${gateways.subnetItem.replace(":id", id)}`;
            break;
          case NodeRange.ADDRESS:
            url = `${gateways.addressItem.replace(":id", id)}`;
            break;
          case NodeRange.HOST:
            url = `${gateways.hostItem.replace(":id", id)}`;
            break;
          default:
            console.error("Node has an invalid range: ", type);
            throw new Error("Unknown error occured; status code 500");
        }

        if (FilterKey.updated in filter) {
          if (filter[FilterKey.updated].op === FilterOperation.between) {
            query.start = JSON.stringify(evaluatePresetRange(stripDate(filter[FilterKey.updated].value)));
            query.end = JSON.stringify(evaluatePresetRange(stripDate(filter[FilterKey.updated].secondary)));
            query.absolute = true;
          } else {
            query.start = JSON.stringify(evaluatePresetRange(filter[FilterKey.updated].value));
          }
        }

        if (Object.keys(query).length > 0) url += `?${queryString.stringify(query)}`;
        response = await MyAPI.get(url);
        const body = response.body;
        if (response.statusCode !== 200) throw new Error(`${body.message}; status code ${response.statusCode}`);
        data = Object.assign(body);
      }

      if (!cacheDisabled) this.cache.writeData(userId, id, data, filter);
    }

    return {
      display_info: data,
      cached: !needToRetrieve,
    };
  }

  /**
   * Fetches the graph data from the server side (or stub file)
   * @param {String} uuid The globally unique ID of the network
   * @param {boolean} preserve Whether to preserve existing nodes in graph rather than completely overwriting it
   * @param {Object} filter
   * @param {function} graphCallback Callback triggered when graph data has been returned
   * @returns Whether the operation was unsuccessful
   * @since 0.6.1
   * */
  async fetchNetworkGraph(uuid, preserve, filter, graphCallback) {
    let response;
    let cache = this;

    console.debug("GraphCache::getNetworkGraph calling networkfetcher api for uuid=" + uuid);
    if (global.gFromJSON) {
      // retrieve data via local file
      console.debug("GraphCache::getNetworkGraph per config, getting global dummy data!");
      response = global.gDataJSON[uuid];
      if (response) {
        console.debug("GraphCache::getNetworkGraph mimicking socket gen events so that refresh statuses set");
        this.dispatch(NetworkActions.initNetFetch(response.hierarchy, response.display_info));
        this.dispatch(NetworkActions.stopNetFetch());
        let rootGraph = response.graph; // the net call only returns the graph portion

        // in order to apply filter, need to mock the input graph as already coming from D3, not from JSON
        rootGraph.nodes = listToObj(rootGraph.nodes);
        rootGraph.edges = listToObj(rootGraph.edges);
        rootGraph = d3EdgeLink(transformToD3(rootGraph));
        let d3GraphData = {
          nodes: Object.values(rootGraph.nodes),
          links: Object.values(rootGraph.links),
        };

        // apply current filter to static graph
        response = filterGraph(filter, d3GraphData).to_cache;
      } else {
        // Network does not exist locally
        response = {
          hierarchy: [{ id: uuid, name: "Not Found" }],
          display_info: {},
        };

        this.dispatch(NetworkActions.initNetFetch(response.hierarchy, response.display_info));
        this.dispatch(NetworkActions.storedGraph(uuid));
        this.dispatch(NetworkActions.stopNetFetch());
      }

      graphCallback(response, preserve);

      return null;
    } else {
      console.debug("GraphCache::filter: ", filter);

      Object.keys(filter).forEach((key) => (filter[key] = JSON.stringify(filter[key])));
      const query = queryString.stringify(filter);
      const url = `${gateways.netFetch.replace(":id", uuid)}?${query}`;

      try {
        let response = await MyAPI.get(url);
        console.debug("Net fetch response: ", response);

        if (response.statusCode !== 200) {
          cache.dispatch(NetworkActions.stopNetFetch("Internal Server Error"));
        } else {
          decompress(response.body, (body) => {
            cache.dispatch(NetworkActions.initNetFetch(body.hierarchy, null));
            graphCallback(body.graph, preserve);
            cache.dispatch(NetworkActions.stopNetFetch());
          });
        }
      } catch (error) {
        console.error(error);
        cache.dispatch(NetworkActions.stopNetFetch("Unexpected Error"));
      }

      return null;
    }
  }

  invalidateItem(userId, id) {
    if (cacheDisabled) return;
    this.cache.removeData(userId, id);
  }
}

const graphCache = new GraphCache();
export default graphCache;
