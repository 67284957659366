// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withToastManager } from "react-toast-notifications";
import { Scrollbars } from "react-custom-scrollbars-2";
import Tippy from "@tippyjs/react";
import Modal from "react-modal";
import _ from "lodash";
import { ContextMenu, MenuItem } from "../../../shared/external/react-contextmenu";

import * as SysmapActions from "../reducers/actions";

import graphCache from "../graphcache";
import ThreatItem from "../components/ThreatItem";
import { gateways, NodeRange, pages, RiskSource, RiskSourceNames } from "../../../shared/enumerations";
import { formatPageTitle, formatMetric, getNodeLabel } from "../../../shared/functions/formatting";
import { requestErr, renderThumb, MyAPI, isNull } from "../../../shared/functions/general";
import "../styles/Threats.css";
import "../../../shared/styles/Dashboard.css";
import ResponsivePage from "../../../shared/containers/ResponsivePage";
import { riskGradient } from "../../../shared/functions/color";
import { maxRisk, modalStyle, ServerError } from "../../../shared/constants";
import { handleContextMenuClick } from "../../../shared/functions/ctxMenu";
import WebWorker from "../../../shared/threading/WebWorker";
import timer from "../../../shared/threading/timer";
import LoaderButton from "../../../shared/components/LoaderButton";
import { defaultFilter, formatFilterQuery } from "../components/FilterBar";

const POLL_INTERVAL = 600; // 10 minutes
// Whether Overall Threat and Suggested Actions column is displayed
const showRightColumn = false;

export const internalSources = [RiskSource.rejected_flow, RiskSource.cluster, RiskSource.unmanaged_addr, RiskSource.osint];
export const externalSources = [RiskSource.rejected_flow, RiskSource.cluster, RiskSource.osint, RiskSource.geopolitical];

export const ReportType = {
  unknown: -1,
  threat: 0,
};

class ThreatsView extends Component {
  constructor(props) {
    super(props);

    this.graphcache = graphCache;

    this.state = {
      loading: {
        active: false,
        failure: false,
      },

      initialized: false,
      lastFetchTime: null,
      processing: false,
      autoSync: false,

      reportOpen: false,
      reporting: false,
      reportUrl: false,

      resetOpen: null,
      resetting: false,
    };

    this.renderPage = this.renderPage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onShowContext = this.onShowContext.bind(this);
    this.finalizeFetch = this.finalizeFetch.bind(this);
    this.viewSysMap = this.viewSysMap.bind(this);
    this.viewHeatMap = this.viewHeatMap.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.refresh = this.refresh.bind(this);
    this.commitResetRisk = this.commitResetRisk.bind(this);
    this.onShowResetRiskModal = this.onShowResetRiskModal.bind(this);
  }

  componentDidMount() {
    this.requestThreatInfo();
  }

  pollTimerCallback() {
    console.debug("ThreatsView::poll timer starting network fetch");
    this.killPollTimer();

    if (this.props.isTimedOut) {
      console.debug("ThreatsView::poll rejected since user is idle");
      return;
    }

    this.requestThreatInfo();
  }

  startPollTimer() {
    if (!this.state.autoSync) return;
    console.debug("ThreatsView::poll Timer started");
    // Ensure no timer is already running
    this.killPollTimer();

    this.pollTimer = new WebWorker(timer);
    this.pollTimer.postMessage([POLL_INTERVAL]);
    this.pollTimer.addEventListener("message", this.pollTimerCallback);
  }

  async requestThreatInfo() {
    this.setState({
      initialized: true,
      loading: { active: true, failure: false },
    });

    try {
      let response = await MyAPI.get(gateways.threats);
      const body = response.body;

      console.debug("Threats API response:", body);
      if (parseInt(response.statusCode) !== 200) throw new Error(`${body.message}; status code ${response.statusCode}`);

      this.props.actions.setThreatList(global.gOrgLevelUID, {
        total_addres: body.risk_count,
        risky_addrs: body.risk_count,
        internal: body.internal_addresses,
        external: body.external_addresses,
      });

      this.finalizeFetch(false);
    } catch (error) {
      console.error(error);
      this.finalizeFetch(true);
    }
  }

  finalizeFetch(fail) {
    this.setState({
      processing: false,
      loading: {
        active: false,
        failure: fail,
      },
    });
  }

  refresh = () => {
    if (!this.state.loading.active) this.requestThreatInfo(this.state.lastFetchTime);
  };

  generateReport() {
    if (this.state.reporting) return;

    this.setState({ reporting: true, reportFailed: false, reportUrl: null });
    requestReport((response) => {
      const failed = response.statusCode !== 200;
      this.setState({ reporting: false, reportOpen: true, reportUrl: failed ? null : _.get(response.body, "url") });
    }, this.props.toastManager);
  }

  openModal(id) {
    this.setState({ [id]: true });
  }

  closeModal(event) {
    this.setState({ [event.target.id]: event.target.id === "resetOpen" ? null : false });
  }

  renderReportModal() {
    const style = _.cloneDeep(modalStyle);
    const { reportUrl } = this.state;
    const reportFailed = isNull(reportUrl);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];

    return (
      <Modal isOpen={this.state.reportOpen} style={style}>
        <div className="modal-pnl">
          <div className="header">Generating Report</div>
          <span className="info">
            {reportFailed
              ? "We could not process your request at this time. Please try again later."
              : "Your Threats Report has generated successfully. The link below will allow you to download the file for a limited time."}
          </span>

          <div className="actions center">
            <div className="btn quart" id="reportOpen" onClick={this.closeModal}>
              Close
            </div>

            {!reportFailed && (
              <a className="btn" href={reportUrl} id="reportOpen" onClick={this.closeModal}>
                Download
              </a>
            )}
            <div></div>
          </div>
        </div>
      </Modal>
    );
  }

  renderThreatTHead(hidden, sources) {
    const head = (
      <thead className={hidden ? "secondary" : ""}>
        <tr>
          <th style={{ maxWidth: "0px", paddingLeft: "10px" }}>Risk</th>
          <th style={{ maxWidth: "0px", paddingLeft: "24px" }}>Type</th>
          <th>IP Address</th>
          <th>Classification</th>
          {sources.map((src) => (
            <th key={src}>{RiskSourceNames[src]}</th>
          ))}
        </tr>
      </thead>
    );

    return (
      <Fragment>
        {/* { !hidden
          ? resHead
          : head
        } */}
        {head}
      </Fragment>
    );
  }

  onShowContext(node, event) {
    console.debug("ThreatsView::context click: ", node);
    const id = `threat-${node.uid}`;
    event.target.id = id;
    handleContextMenuClick(event, id, this.props);
  }

  /**
   * Redirect the page to display the node in the System Map
   * @param {*} node
   */
  viewSysMap(_, node) {
    // TODO: Replace with link to proposed Single Host flow view
    const { actions, toastManager, userId } = this.props;
    const id = node.uid;
    const query = formatFilterQuery(defaultFilter);

    // in linked page, highlight the referenced node
    graphCache
      .getItem(userId, id, NodeRange.ADDRESS, { hash_key: id, range_key: null }, toastManager)
      .then((response) => {
        actions.inspectItem({ id: id, display_info: response.display_info }, true);
      })
      .catch((error) => {
        this.props.toastManager.add(requestErr(error), {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });
      });

    query.network = id;
    const qs = queryString.stringify(query);
    this.props.navigate(`${pages.sysmap}?${qs}`);
    this.props.actions.viewNetwork({ id, name: getNodeLabel(node) });
  }

  viewHeatMap(_, node) {
    console.debug("ThreatsView::View Heat map: ", node);
    console.debug("Heat Map not yet implemented.");
  }

  onShowResetRiskModal(_, node) {
    console.debug("ThreatsView::Reset Node risk: ", node);
    this.setState({ resetOpen: node });
  }

  /** Reset risk for given node; refresh threats summary to reflect change */
  commitResetRisk() {
    const { toastManager } = this.props;
    const { resetOpen: node } = this.state;
    this.setState({ resetting: false });

    let url = gateways.riskItem.replace(":id", node.uid);
    MyAPI.get(url)
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({ resetting: false, resetOpen: null });

          this.props.toastManager.add(`Successfully reset risk for ${node.ip_address}`, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: global.gToastTimeout,
          });

          this.refresh();
        } else {
          throw new ServerError(response);
        }
      })
      .catch((error) => {
        this.setState({ resetting: false });
        toastManager.add(requestErr(error), {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });
      });
  }

  renderThreatContext(node) {
    return (
      <ContextMenu id={`ctx-threat-${node.uid}`}>
        <MenuItem tabIndex="0" data={node} onClick={this.viewSysMap}>
          <i className="fas fa-code-fork" />
          View in System Map
        </MenuItem>
        {/* <MenuItem data={node} onClick={this.viewHeatMap} disabled>
          <i title={global.gDemoMsg} className="fas fa-globe-americas" />
          View in Heat Map
        </MenuItem>
        <MenuItem data={node} disabled tabIndex="0">
          <i title={global.gDemoMsg} className="fas fa-cog" />
          Configure
        </MenuItem> */}
        {node.isInternal && (
          <MenuItem data={node} tabIndex="0" onClick={this.onShowResetRiskModal}>
            <i className="fas fa-undo" />
            Reset Risk
          </MenuItem>
        )}
      </ContextMenu>
    );
  }

  renderRiskResetModal() {
    const { resetOpen, resetting } = this.state;
    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];
    if (!resetOpen) return null;

    return (
      <Modal isOpen={true} style={style}>
        <div className="modal-pnl endpoint-modal">
          <div className="header">Reset Risk</div>
          <span className="info">
            Reset the risk for {resetOpen.ip_address}? All risk collected for this node up to this point will be considered as
            benign.
          </span>

          <div className="footer">
            <div className="actions center">
              <div className="btn quart" id="resetOpen" onClick={this.closeModal}>
                Cancel
              </div>
              <LoaderButton
                type="submit"
                text="Reset"
                loadingText="Resetting..."
                isLoading={resetting}
                onClick={this.commitResetRisk}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderThreatTable(nodeList, isInternal, active, size) {
    const sources = isInternal ? internalSources : externalSources;

    return (
      <div className="body">
        {/* inset style causes header to be overlayed by body
        TODO: limit Scrollbar to table body only
        Recorded as work item 134 in DevOps */}
        {/* <table className='dash-table threats-table'>
          { this.renderThreatTHead(false, sources) }
          <tbody/>
        </table> */}

        <Scrollbars
          renderThumbHorizontal={(_) => {
            return <div />;
          }}
          renderThumbVertical={(obj) => {
            return renderThumb(obj, this.props.theme);
          }}
          id={`threats-table-${sources.length}`}
          autoHideDuration={1000}
          style={{ height: "auto", inset: "0 0 52px", position: "absolute" }}
        >
          {Object.values(nodeList).length === 0 || active ? (
            <Fragment>
              <table className="dash-table threats-table">{this.renderThreatTHead(false, sources)}</table>

              <div className="dash-table-empty">
                {active ? (
                  <i>Loading data...</i>
                ) : (
                  <i>There are no {isInternal ? "in" : "ex"}ternal addresses with risk to display.</i>
                )}
              </div>
            </Fragment>
          ) : (
            <table className="dash-table threats-table">
              {this.renderThreatTHead(false, sources)}
              <tbody>
                {!active &&
                  Object.values(nodeList).map((node) => (
                    <ThreatItem
                      key={node.uid}
                      theme={this.props.theme}
                      node={node}
                      sources={sources}
                      onShowContext={this.onShowContext}
                      renderThreatContext={(node) => this.renderThreatContext(node)}
                    />
                  ))}
              </tbody>
            </table>
          )}
        </Scrollbars>
      </div>
    );
  }

  percentColor(value, total) {
    const { theme } = this.props;
    if (value === null || total === null) return theme.text;
    const perc = (value / total) * maxRisk;
    return riskGradient(theme, perc);
  }

  renderOverall() {
    return (
      <div className="third">
        <div className="overall card">
          <div className="heading">
            <div className="header">Threat Overview</div>
          </div>
          <div className="body"></div>
        </div>

        <div className="suggestions card">
          <div className="heading">
            <div className="header">Suggested Actions</div>
          </div>
          <div className="body"></div>
        </div>
      </div>
    );
  }

  renderPage(size) {
    const { internal, external, riskyAddresses, totalAddresses } = this.props;
    const active = this.state.loading.active;

    const addrColor = this.percentColor(riskyAddresses, totalAddresses);

    return (
      <Fragment>
        {formatPageTitle("Threats")}

        {this.renderReportModal()}
        {this.renderRiskResetModal()}

        <div className="title card">
          Threats Overview
          <div className="help right">
            {/* <i className="fas fa-question-circle" /> */}

            <Tippy
              content="Refresh data"
              animation="scale-subtle"
              theme="material"
              duration={global.gTTPDur}
              delay={[global.gTTPShow, 0]}
            >
              <i className={`${active ? "spin " : ""}fas fa-sync`} onClick={this.refresh} />
            </Tippy>

            {this.state.reporting ? (
              <div className="btn generator-btn" disabled>
                <i className="spin fas fa-sync" />
                Generating....
              </div>
            ) : (
              <div className="btn generator-btn quart" onClick={this.generateReport}>
                Generate Report
              </div>
            )}
          </div>
        </div>

        <div className="metrics">
          <div className="risk spark" style={{ borderBottomColor: addrColor }}>
            <div className="field">
              <div className="head">Addresses with Risk</div>
              <div className="metric">{riskyAddresses === null ? "--" : formatMetric(riskyAddresses)}</div>
            </div>
          </div>
        </div>

        <div className="columns locked">
          <div className="full">
            <div className="internal card">
              <div className="heading">
                <div className="header">Internal Addresses</div>
              </div>
              {this.renderThreatTable(internal, true, active, size)}
            </div>

            <div className="external card">
              <div className="heading">
                <div className="header">External Addresses</div>
              </div>

              {this.renderThreatTable(external, false, active, size)}
            </div>
          </div>

          {showRightColumn && this.renderOverall()}
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <main className="threats">
        <ResponsivePage renderPage={this.renderPage} />
      </main>
    );
  }
}

async function requestReport(callback, toaster) {
  try {
    const response = await MyAPI.post(gateways.reports, { type: ReportType.threat });
    console.debug("ThreatsView::Report response:", response);

    callback(response);
  } catch (error) {
    toaster.add(requestErr(error), {
      appearance: "error",
      autoDismiss: true,
      autoDismissTimeout: global.gToastTimeout,
    });

    callback(null);
  }
}

const mapState = (state) => ({
  hierarchy: state.sysmap.selector.hierarchy,
  networks: state.sysmap.networks.networkList,
  user: state.user,
  internal: state.threats.internal,
  external: state.threats.external,
  related_addrs: state.threats.related_addrs,
  riskyAddresses: state.threats.risky_addrs,
  totalAddresses: state.threats.total_addrs,
});

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(SysmapActions, dispatch),
});

export default connect(mapState, mapDispatch)(withToastManager(ThreatsView));
