// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import { Route, Routes } from "react-router-dom";
import React from "react";

import AuthenticatedRoute from "./AuthenticatedRoute";
import AuthorizedRoute from "./AuthorizedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";

/* For each new page you want to route to, 
import it at the top and then add an AuthenticatedRoute path like below */

import { pages, UserGroups } from "../enumerations";
import Login from "../../pages/home/containers/Login";
import Signup from "../../pages/home/containers/Signup";
import NotFound from "../containers/NotFound";
import SystemMapView from "../../pages/sysmap/containers/SystemMapView";
import ConsoleView from "../../pages/settings/containers/ConsoleView";
import BillingView from "../../pages/settings/containers/BillingView";
import ResetPasswordView from "../../pages/home/containers/ResetPasswordView";
import ThreatsView from "../../pages/sysmap/containers/ThreatsView";
import GeoMapView from "../../pages/geomap/containers/GeoMapView";
import MonitoringView from "../../pages/monitoring/containers/MonitoringView";

/**
 * This component uses this Routes component from React-Router that renders the first matching route that is defined within it.
 * In a route, it looks for / and renders the matching View.
 */
const Routing = ({ childProps }) => (
  <Routes>
    <Route path={pages.landing} element={<UnauthenticatedRoute component={Login} childProps={childProps} />} />
    <Route path={pages.login} element={<UnauthenticatedRoute component={Login} childProps={childProps} />} />
    <Route path={pages.sysmap} element={<AuthenticatedRoute component={SystemMapView} childProps={childProps} />} />
    <Route path={pages.threats} element={<AuthenticatedRoute component={ThreatsView} childProps={childProps} />} />
    <Route path={pages.monitor} element={<AuthenticatedRoute component={MonitoringView} childProps={childProps} />} />
    <Route path={pages.geomap} element={<AuthenticatedRoute component={GeoMapView} childProps={childProps} />} />
    <Route path={pages.configuration} element={<AuthenticatedRoute component={ConsoleView} childProps={childProps} />} />
    <Route
      path={pages.billing}
      element={<AuthorizedRoute component={BillingView} childProps={childProps} minCreds={UserGroups.Admin} />}
    />

    <Route path={pages.signup} element={<UnauthenticatedRoute component={Signup} childProps={childProps} />} />
    <Route path={pages.reset} element={<UnauthenticatedRoute component={ResetPasswordView} childProps={childProps} />} />

    {/* Finally, catch all unmatched routes */}
    <Route path="*" element={<NotFound childProps={childProps} />} />
  </Routes>
);

export default Routing;
