// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import * as actions from "./actionTypes";

/**
 * Sets the current list of networks to a new one. Primary use case for this event
 * is for sorting the list.
 * @param {ArrayLike} netList
 */
export const setNetworkList = (netList) => ({
  type: actions.SET_NETWORK_LIST,
  netList,
});

/**
 * Removes a network from the client
 * @since 0.2.2
 * @param {String} id
 */
export const removeNetwork = (id, parentId) => ({
  type: actions.REMOVE_NETWORK,
  id,
  parentId,
});

/**
 * Clears the current list of networks
 */
export const clearNetworks = () => ({
  type: actions.CLEAR_NETWORKS,
});

/**
 * Select a network from the list and view it's contents
 * @since 0.2.2
 * @param {} network the network object as pulled from the database
 * @param {boolean} preserve Whether to keep nodes already displaying in graph
 * loaded networks
 */
export const viewNetwork = (network, preserve) => ({
  type: actions.VIEW_NETWORK,
  network,
  preserve,
});

/** Initialize results asynchronous modification and deletions */
export const initNetworkManagementOperation = (results) => ({
  results,
  type: actions.INIT_NET_MANAGE_OPERATION,
});

export const failNetworkManagementOp = (id) => ({
  id,
  type: actions.FAIL_NET_MANAGE_OPERATION,
});

/**
 * Expand the network node in a graph and add to the current graph
 * @since 0.2.2
 * @param {} network the network object as pulled from the database
 * loaded networks
 */
export const expandItem = (network) => ({ type: actions.EXPAND_ITEM, network });

/**
 * Selects an item in the graph and sends it's information to the display window
 * @since 0.2.2
 * @param {*} data The data contained within the selected item
 * @param {Boolean} isNode Whether the item is a node or an edge
 * @param {*} source temporary field until displayInfo and basic node data are same field (i.e. displayInfo is preprocessed non-humanreadable)
 */
export const inspectItem = (data, isNode, source) => ({
  type: actions.INSPECT_ITEM,
  data,
  isNode,
  source,
});

/**
 * An event for when information in the network has been updated
 * @since 0.2.2
 * @param {*} index The index at which the network occurs in the list of
 * loaded networks
 * @param {*} data The data of the network
 */
export const updateNetwork = (index, data) => ({
  type: actions.UPDATE_NETWORK,
  index: index,
  data: data,
});

/**
 * updates the selector to include information on parents of the currently selected object
 * @param {ArrayLike} hierarchy A list of parent network objects
 */
export const updateHierarchy = (hierarchy) => ({
  type: actions.UPDATE_HIERARCHY,
  hierarchy,
});

/**
 * Handle response from server to start populating initial graph upon a network fetch request
 * @param {ArrayLike} hierarchy A list of parent network object
 * @param {object} display_info The display information of the root graph
 */
export const initNetFetch = (hierarchy, display_info) => ({
  type: actions.INIT_NET_FETCH,
  hierarchy,
  display_info,
});

/**
 * Start the loading process for fetching a network
 */
export const startNetFetch = () => ({
  type: actions.START_NET_FETCH,
});

/**
 * Force stop the loading process for fetching a network
 */
export const stopNetFetch = (error, errorCode) => ({
  type: actions.STOP_NET_FETCH,
  error,
  errorCode,
});

/**
 * Determine whether the graph is currently empty
 * @param {bool} isEmpty
 */
export const emptyCheck = (isEmpty) => ({
  type: actions.EMPTY_CHECK,
  isEmpty,
});

/**
 * In the state note the current graph stored in the cache
 * @param {string} network_id
 */
export const storedGraph = (network_id) => ({
  type: actions.STORED_GRAPH,
  network_id,
});

export const setFilter = (filter) => ({
  type: actions.SET_FILTER,
  filter,
});

/**
 * Save the report of identified threats
 * @param {object} threats Format: {
 *  internal: {<id>: {data: {...}}},
 *  external: (same as internal),
 *  ...
 * }
 */
export const setThreatList = (requestID, threats, callback) => ({
  type: actions.SET_THREATS_LIST,
  requestID,
  callback,
  ...threats,
});
