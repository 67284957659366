// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import Scrollbars from "react-custom-scrollbars-2";
import { Form, Col, FormGroup, FormControl } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import _ from "lodash";

import { gateways, UserGroupNames, UserGroups, UserStatus } from "../../../shared/enumerations";
import { ContextMenu, MenuItem } from "../../../shared/external/react-contextmenu";
import { ManagementResult, MyAPI, renderThumb, requestErr } from "../../../shared/functions/general";
import ResponsiveTableHeader from "../../../shared/components/ResponsiveTableHeader";
import Checkbox from "../../../shared/components/Checkbox";
import { handleContextMenuClick } from "../../../shared/functions/ctxMenu";
import { modalStyle } from "../../../shared/constants";
import UserItem from "./UserItem";
import LoaderButton from "../../../shared/components/LoaderButton";
import Dropdown from "../../../shared/components/Dropdown";

// TODO: Consolodate this with the global enumeration
export const AccountProvider = {
  aws: "AWS",
  az: "Azure",
  op: "On-Premises",
};

const reverseGroups = {};
Object.entries(UserGroupNames).forEach(([k, v]) => {
  reverseGroups[v] = k;
});

const MODIFY_TITLE = "Change User Role";
const CREATE_TITLE = "Invite User";

export default class UserTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // active fetch for user list
      usersLoading: false,
      // was fetch triggered by user and not page load
      refreshing: false,
      usersLoadingFailed: false,
      // height of the user list table as defined by responsive table omponent
      userTableHeight: 60,

      // following controls for user modal and operation results
      userModalOpen: false,
      removeModalOpen: false,
      resultsModalOpen: false,
      resultModalTitle: null,

      // following fields for adding or modifying a user
      editEmail: "",
      editFirst: "",
      editLast: "",
      editGroup: "",

      openGroup: null,
      // whether the user modal is on an existing user
      editModifying: null,
      // input validation errors for user
      editErrors: {},
      results: {},

      selectedUsers: [],
      // Active processes for resending user confirmation code
      resend: {},
    };

    this.addUserToSelection = this.addUserToSelection.bind(this);
    this.removeUserFromSelection = this.removeUserFromSelection.bind(this);
    this.toggleUserSelection = this.toggleUserSelection.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showUserCreationModal = this.showUserCreationModal.bind(this);
    this.showUserRemoveModal = this.showUserRemoveModal.bind(this);
    this.onContextClick = this.onContextClick.bind(this);
    this.renderUserContextMenu = this.renderUserContextMenu.bind(this);
    this.handleUserModalSubmit = this.handleUserModalSubmit.bind(this);
    this.handleUserModalDelete = this.handleUserModalDelete.bind(this);
    this.deleteModalTitle = this.deleteModalTitle.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    if (this.props.users.length === 0) {
      this.fetchUserList();
      this.props.actions.initManagementOperation({});
    }
  }

  refresh() {
    if (this.state.usersLoading) return;
    this.deselectAllUsers();
    this.fetchUserList(true);
  }

  fetchUserList(refreshing = false) {
    let ref = this;

    this.setState({ usersLoading: true, usersLoadingFailed: false, refreshing });
    MyAPI.get(gateways.users)
      .then((response) => {
        console.debug("User LIST response: ", response);
        let body = response.body;
        this.props.actions.addUsers(body.users);
        ref.setState({ usersLoading: false, refreshing: false });
      })
      .catch((error) => {
        ref.setState({ usersLoading: false, usersLoadingFailed: true, refreshing: false });
        console.error(error);
      });
  }

  onContextClick(event, id) {
    const idString = `ctx-${id}`;
    event.target.id = idString;
    handleContextMenuClick(event, idString, this.props);
  }

  resendInvite(user) {
    let { results } = this.state;
    let { toastManager } = this.props;

    results[user.user_name] = true;
    this.setState({ results });
    let url = gateways.userResend.replace(":id", user.user_name);

    MyAPI.get(url)
      .then((response) => {
        results = this.state.results;
        delete results[user._user_name];
        this.setState({ results });

        console.debug("Resend GET response: ", response);
        let body = response.body;
        if (response.statusCode === 200) {
          toastManager.add(`Successfully resent invite for ${user.email}`, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: global.gToastTimeout,
          });
        } else {
          console.error(body.message);

          toastManager.add(requestErr(body), {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: global.gToastTimeout,
          });
        }
      })
      .catch((error) => {
        console.error(error);

        toastManager.add(requestErr(error), {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });
      });
  }

  renderUserContextMenu(user) {
    let users = [user.user_name];
    if (this.state.selectedUsers.length > 0) users = _.clone(this.state.selectedUsers);

    const hoverEvent = (event) => event.preventDefault();

    const canDelete = user.user_name !== this.props.activeUser.id || users.length > 1;
    const deleteMenu = (
      <MenuItem
        disabled={!canDelete}
        onMouseMove={hoverEvent}
        onClick={(event) => {
          event.stopPropagation();
          this.showUserRemoveModal(users);
        }}
      >
        <i className="fas fa-trash" />
        Delete
      </MenuItem>
    );

    return (
      <ContextMenu id={`ctx-${user.user_name}`}>
        {/* Toggling user enabled is not yet available in backend */}
        {/* {user.enabled ? (
          <MenuItem onClick={() => console.debug("Disable user.....")}>
            <i className="fas fa-x" />
            Disable
          </MenuItem>
        ) : (
          <MenuItem onClick={() => console.debug("Enable user.....")}>
            <i className="fas fa-x" />
            Enable
          </MenuItem>
        )} */}

        {user.status === UserStatus.unconfirmed && (
          <MenuItem
            tabIndex="0"
            disabled={user.user_name in this.state.results}
            onMouseMove={hoverEvent}
            onClick={(event) => {
              event.stopPropagation();
              this.resendInvite(user);
            }}
          >
            <i className="fas fa-envelope" /> Resend Invite
          </MenuItem>
        )}

        <MenuItem
          tabIndex="1"
          onMouseMove={hoverEvent}
          onClick={(event) => {
            event.stopPropagation();
            this.showUserModifyModal(users);
          }}
        >
          <i className="fas fa-edit" /> Modify Role{users.length > 1 ? "s" : ""}
        </MenuItem>

        {/* Currently opting for hiding the delete option */}
        {/* { canDelete 
          ? <Tippy
              content="To delete yourself, please visit the Account Settings."
              animation="scale-subtle"
              theme="material"
              duration={global.gTTPDur}
              delay={[global.gTTPShow, 0]}
            >
            {deleteMenu}
          </Tippy>
          : deleteMenu
          }  */}
        {canDelete && deleteMenu}
      </ContextMenu>
    );
  }

  /**
   * Generic handler for hiding an open modal
   */
  closeModal(event) {
    this.setState({ [event.target.id]: false });
  }

  /**
   * Generic callback for storing the input value when its component
   * has been updated.
   * @param {*} event The event that was triggered
   */
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  /** Toggle the selection state of all users */
  toggleUserSelection() {
    if (this.state.selectedUsers.length !== this.props.users.length) {
      this.selectAllUsers();
    } else {
      this.deselectAllUsers();
    }
  }

  selectAllUsers() {
    const selection = new Set();
    Object.values(this.props.users).forEach((user) => {
      selection.add(user.user_name);
    });

    this.setState({ selectedUsers: Array.from(selection) });
  }

  deselectAllUsers() {
    this.setState({ selectedUsers: [] });
  }

  addUserToSelection(username) {
    const selection = _.cloneDeep(this.state.selectedUsers);
    selection.push(username);
    this.setState({ selectedUsers: selection });
  }

  /**
   * Removes the network at the given index from the selection
   * @param {*} username
   * @param {function} callback The function to callback when the item has been removed
   * @since 0.4.1
   */
  removeUserFromSelection(username, callback) {
    const selection = _.cloneDeep(this.state.selectedUsers);
    selection.splice(selection.indexOf(username), 1);
    this.setState({ selectedUsers: selection }, callback);
  }

  showUserCreationModal() {
    this.setState({
      userModalOpen: true,
      openGroup: null,
      editEmail: "",
      editFirst: "",
      editLast: "",
      editGroup: "",
      editFails: "",
      editModifying: null,
      editErrors: {},
    });
  }

  showUserRemoveModal(usersNames) {
    const { users: allUsers } = this.props;
    let users = _.filter(allUsers, (user) => {
      // A user cannot delete themselves this way; they must do so in another page
      if (user.user_name === this.props.activeUser.id) {
        return false;
      }

      return usersNames.includes(user.user_name);
    });

    if (users.length === 0) return;

    this.setState({
      removeModalOpen: true,
      openGroup: null,
      editModifying: users,
      editEmail: "",
    });
  }

  validateUserForm() {
    const { editEmail, editFirst, editLast, editGroup, editModifying } = this.state;
    const { users } = this.props;
    const errors = {};

    const usedEmails = [];
    users.forEach((user) => usedEmails.push(user.email));

    if (!editModifying) {
      if (editEmail.length === 0) {
        errors.email = "Email cannot be empty.";
      } else if (
        editEmail
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) === null
      ) {
        errors.editEmail = "Email must be in format: mail@domain.com.";
      } else if (usedEmails.includes(editEmail)) {
        errors.editEmail = "A user with this email address already exists.";
      }

      if (editFirst.length === 0) {
        errors.editFirst = "First Name cannot be empty.";
      } else if (editFirst.length > 250) {
        errors.editFirst = "First Name cannot be longer than 250 characters.";
      }

      if (editLast.length === 0) {
        errors.editLast = "Lasst Name cannot be empty.";
      } else if (editLast.length > 250) {
        errors.editLast = "Last Name cannot be longer than 250 characters.";
      }
    }

    if (editGroup === "") {
      errors.editGroup = "User must be assigned a role.";
    }

    return errors;
  }

  handleUserModalSubmit = () => {
    const { editModifying } = this.state;
    const modalTitle = editModifying ? MODIFY_TITLE : CREATE_TITLE;

    let errors = this.validateUserForm();
    this.setState({ editErrors: errors });

    if (Object.keys(errors).length === 0) {
      if (editModifying) {
        this.commitModifyUsers();
        this.setState({
          resultsModalOpen: true,
          userModalOpen: false,
          resultsModalTitle: modalTitle,
        });
      } else this.createUser();
    }
  };

  deleteModalTitle = () => `Remove User${this.state.selectedUsers.length > 1 ? "s" : ""} Confirmation`;

  handleUserModalDelete = () => {
    this.setState({
      resultsModalOpen: true,
      removeModalOpen: false,
      resultsModalTitle: this.deleteModalTitle(),
    });
    this.commitDeleteUsers();
  };

  renderUserCreationModal() {
    const { editModifying, editErrors, userModalOpen, editEmail, editFirst, editLast, editGroup, openGroup } = this.state;
    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];

    const isValid = editModifying
      ? editGroup !== "" && openGroup !== editGroup
      : editEmail.length > 0 && editFirst.length > 0 && editLast.length > 0 && editGroup !== "";
    const modalTitle = editModifying ? MODIFY_TITLE : CREATE_TITLE;

    return (
      <Modal isOpen={userModalOpen} style={style}>
        <div className="modal-pnl user-modal">
          <div className="header">{modalTitle}</div>

          {editModifying ? (
            <span className="info">
              Change the role for {editModifying.length > 1 ? "the selected users" : editModifying[0].email}.
            </span>
          ) : (
            <span className="info">
              The invited user will receive an email with a generated temporary password. They will be required to change the
              password when longing in for the first time.
            </span>
          )}

          <Form horizontal="true" className="modal-form">
            {!editModifying && (
              <Fragment>
                <FormGroup controlId="editFirst" className="modal-input">
                  <Col sm={4} style={{ paddingRight: "0" }}>
                    First name:
                  </Col>
                  <Col sm={12}>
                    <FormControl autoFocus type="text" value={editFirst} onChange={this.handleChange} placeholder="First Name" />
                  </Col>
                </FormGroup>
                {editErrors.editFirst && (
                  <Col className="modal-error">
                    <span className="err">{editErrors.editFirst}</span>
                  </Col>
                )}

                <FormGroup controlId="editLast" className="modal-input">
                  <Col sm={4} style={{ paddingRight: "0" }}>
                    Last name:
                  </Col>
                  <Col sm={12}>
                    <FormControl type="text" value={editLast} onChange={this.handleChange} placeholder="Last Name" />
                  </Col>
                </FormGroup>
                {editErrors.editLast && (
                  <Col className="modal-error">
                    <span className="err">{editErrors.editLast}</span>
                  </Col>
                )}

                <FormGroup controlId="editEmail" className="modal-input">
                  <Col sm={4} style={{ paddingRight: "0" }}>
                    Email:
                  </Col>
                  <Col sm={12}>
                    <FormControl type="email" value={editEmail} onChange={this.handleChange} placeholder="email@mail.com" />
                  </Col>
                </FormGroup>
                {editErrors.editEmail && (
                  <Col className="modal-error">
                    <span className="err">{editErrors.editEmail}</span>
                  </Col>
                )}
              </Fragment>
            )}

            <FormGroup controlId="editGroup" className="modal-input">
              <Col sm={4} style={{ paddingRight: "0" }}>
                Role:
              </Col>
              <Col sm={12}>
                <FormControl className="hidden" value={editGroup} onChange={this.handleChange} />
                <Dropdown
                  id="editGroup"
                  options={[UserGroupNames[UserGroups.Operator], UserGroupNames[UserGroups.Admin]]}
                  onChange={this.handleChange}
                  value={editGroup}
                />
              </Col>
            </FormGroup>
            {editErrors.editGroup && (
              <Col className="modal-error">
                <span className="err">{editErrors.editGroup}</span>
              </Col>
            )}
          </Form>

          <div className="footer">
            <div className="actions center">
              <div disabled={this.props.operationActive} className="btn quart" id="userModalOpen" onClick={this.closeModal}>
                Cancel
              </div>
              <LoaderButton
                disabled={!isValid}
                type="submit"
                text={editModifying ? "Save" : "Invite"}
                loadingText={editModifying ? "Saving..." : "Creating..."}
                isLoading={this.props.operationActive}
                onClick={this.handleUserModalSubmit}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  createUser() {
    const { editFirst, editLast, editEmail, editGroup } = this.state;
    const { toastManager, users, actions } = this.props;

    let payload = {
      first_name: editFirst,
      last_name: editLast,
      email: editEmail,
      group: reverseGroups[editGroup],
    };

    actions.initManagementOperation({});

    MyAPI.post(gateways.users, payload)
      .then((response) => {
        console.debug("CREATE User response: ", response);
        const body = response.body;

        if (response.statusCode === 200) {
          let newUser = {
            user_name: body.user_name,
            last_login: "Never",
            disabled: false,
            first_name: payload.first_name,
            last_name: payload.last_name,
            email: payload.email,
            groups: [payload.group],
            status: UserStatus.unconfirmed,
          };

          actions.addUsers([...users, newUser]);

          this.setState({ userModalOpen: false });
          toastManager.add(body.message, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: global.gToastTimeout,
          });
        } else {
          this.props.actions.failUserOp(null);
          this.props.toastManager.add(body.message, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: global.gToastTimeout,
          });
        }
      })
      .catch((error) => {
        this.props.actions.failUserOp(null);
        console.error(error);

        toastManager.add(requestErr(error), {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: global.gToastTimeout,
        });
      });
  }

  /** Show modal for modifying selected users. */
  showUserModifyModal(usersNames) {
    const { users: allUsers } = this.props;
    let users = _.filter(allUsers, (user) => usersNames.includes(user.user_name));

    let group = "";
    let selectedGroups = new Set();
    users.forEach((user) => {
      if (user.groups) user.groups.forEach((group) => selectedGroups.add(group));
      else selectedGroups.add(UserGroups.Ungrouped);
    });

    if (selectedGroups.size === 1) group = [...selectedGroups][0];

    let groupName = UserGroupNames[group];
    this.setState({
      userModalOpen: true,
      openGroup: groupName,
      editGroup: groupName,
      editModifying: users,
      results: {},
    });
  }

  commitModifyUsers() {
    const { editModifying, editGroup } = this.state;
    let results = {};
    editModifying.forEach((user) => (results[user.user_name] = ManagementResult(user.email)));

    this.props.actions.initManagementOperation(results);

    editModifying.forEach((user) => {
      let setGroups = [reverseGroups[editGroup]];
      let url = gateways.userItem.replace(":id", user.user_name);
      MyAPI.patch(url, { groups: setGroups })
        .then((response) => {
          console.debug("User PATCH response:", response);
          user.groups = setGroups;
          if (response.statusCode === 200) {
            this.props.actions.modifyUser(user);
          } else {
            this.props.actions.failUserOp(user.user_name);
          }
        })
        .catch((error) => {
          console.error(error);
          this.props.actions.failUserOp(user.user_name);
        });
    });
  }

  renderUserRemovalModal() {
    const deleteText = "delete";
    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];

    const modalTitle = this.deleteModalTitle();
    return (
      <Modal isOpen={this.state.removeModalOpen} style={style}>
        <div className="modal-pnl">
          <div className="header">{modalTitle}</div>

          <span className="info">Please confirm by entering '{deleteText}' below.</span>
          <Form onSubmit={this.removeSelected}>
            <FormGroup controlId="editEmail">
              <FormControl
                autoFocus
                type="text"
                value={this.state.editEmail}
                onChange={this.handleChange}
                placeholder={deleteText}
              />
            </FormGroup>
          </Form>

          <div className="footer">
            <div className="actions center">
              <div className="btn quart" id="deleteOpen" onClick={this.CloseModal}>
                Cancel
              </div>
              <LoaderButton
                disabled={this.state.editEmail !== deleteText}
                type="submit"
                text="Remove"
                loadingText="Removing..."
                isLoading={this.state.loadOp}
                onClick={this.handleUserModalDelete}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderResultsModal() {
    const { resultsModalOpen, resultsModalTitle } = this.state;
    const { results, operationActive } = this.props;

    const style = _.cloneDeep(modalStyle);
    style.content.backgroundColor = this.props.theme[modalStyle.content.backgroundColor];

    return (
      <Modal isOpen={resultsModalOpen} style={style}>
        <div className="modal-pnl user-modal">
          <div className="header">{resultsModalTitle}</div>

          <Form horizontal="true" className="modal-form">
            {Object.entries(results).map(([key, result]) => {
              let resultClass = "fas fa-ellipsis-h";
              let backingClass = "backing far fa-circle";
              if (result.failed) {
                resultClass = "fas fa-times error";
                backingClass += " error";
              } else if (!result.active) {
                resultClass = "fas fa-check success";
                backingClass += " success";
              }

              return (
                <div className="result-entry" key={key}>
                  <div className="indicator">
                    <i className={backingClass} />
                    <i className={`icon ${resultClass}`} />
                  </div>
                  <span>{result.text}</span>
                </div>
              );
            })}
          </Form>

          <div className="footer">
            <div className="actions center">
              <LoaderButton
                disabled={operationActive}
                type="submit"
                id="resultsModalOpen"
                text="Close"
                className="quart"
                loadingText="Saving..."
                isLoading={operationActive}
                onClick={this.closeModal}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  commitDeleteUsers() {
    const { editModifying } = this.state;
    const { actions } = this.props;
    let results = {};

    editModifying.forEach((user) => {
      results[user.user_name] = ManagementResult(user.email);
    });

    actions.initManagementOperation(results);
    this.setState({ selectedUsers: [] });

    editModifying.forEach((user) => {
      let url = gateways.userItem.replace(":id", user.user_name);
      MyAPI.delete(url)
        .then((response) => {
          console.debug("User DELETE response:", response);
          if (response.statusCode === 200) {
            actions.deleteUser(user.user_name);
          } else {
            actions.failUserOp(user.user_name);
          }
        })
        .catch((error) => {
          console.error(error);
          actions.failUserOp(user.user_name);
        });
    });
  }

  updateHeaderHeight(size, key) {
    if (this.state[key] === size.height) return;
    this.setState({ [key]: size.height });
  }

  /**
   * To ensure the table has the same column width despite having only the
   * body be scrollable, the header is drawn twice. The first is shown to user,
   * while the second is hidden and used only for positioning
   * @param {*} hidden
   */
  renderUserTHead(hidden) {
    const head = (
      <Fragment>
        <thead className={hidden ? "secondary" : ""}>
          <tr>
            <th width="20px">
              <Checkbox
                checked={this.props.users.length === 0 ? false : this.state.selectedUsers.length === this.props.users.length}
                onChange={this.toggleUserSelection}
              />
            </th>
            {/* <th width="20px"/> */}
            <th width="25%">Full Name</th>
            <th width="25%">Email</th>
            <th width="12%">Role</th>
            {/* <th width="8%">Status</th> */}
            <th width="calc(30% - 20px)">Last Activity</th>
          </tr>
        </thead>
      </Fragment>
    );

    return (
      <Fragment>
        {!hidden ? <ResponsiveTableHeader head={head} onSize={(size) => this.updateHeaderHeight(size, "userTableHight")} /> : head}
      </Fragment>
    );
  }

  renderUserList() {
    const userActions = {
      addUserToSelection: this.addUserToSelection,
      removeUserFromSelection: this.removeUserFromSelection,
    };

    const { usersLoading, selectedUsers, userTableHeight } = this.state;
    const { users, theme, pageSize } = this.props;
    const h = pageSize === undefined ? window.innerHeight : pageSize.height;
    const padding = 50;
    const minHeight = h - padding - 127 - userTableHeight;

    const sublist = _.filter(selectedUsers, (userName) => userName !== this.props.activeUser.id);
    const canDelete = sublist.length > 0 && selectedUsers.length > 0;

    return (
      <div className="user-list card" style={{ flexGrow: "2", position: "relative" }}>
        <div className="heading">
          <div className="header">Users</div>
          <div className="actions right">
            <Tippy
              content="Refresh list"
              animation="scale-subtle"
              theme="material"
              duration={global.gTTPDur}
              delay={[global.gTTPShow, 0]}
            >
              <div tabIndex="0" className="btn" style={{ backgroundColor: "transparent" }} onClick={this.refresh}>
                <i className={`fas fa-sync-alt${usersLoading ? " spin" : ""}`} />
              </div>
            </Tippy>

            <div tabIndex="0" className="btn" onClick={this.showUserCreationModal}>
              Invite User
            </div>

            <Tippy
              content={this.state.selectedUsers.length === 0 ? "Nothing is selected" : "Modify roles of selected users"}
              animation="scale-subtle"
              theme="material"
              duration={global.gTTPDur}
              delay={[global.gTTPShow, 0]}
            >
              <div>
                <div
                  tabIndex="0"
                  className="btn"
                  onClick={() => this.showUserModifyModal(selectedUsers)}
                  disabled={selectedUsers.length === 0}
                >
                  Change Role
                </div>
              </div>
            </Tippy>

            <Tippy
              content={
                this.state.selectedUsers.length === 0
                  ? "Nothing is selected"
                  : !canDelete
                  ? "Cannot delete active user"
                  : "Remove selected users"
              }
              animation="scale-subtle"
              theme="material"
              duration={global.gTTPDur}
              delay={[global.gTTPShow, 0]}
            >
              <div>
                <div
                  tabIndex="0"
                  className="btn"
                  onClick={() => {
                    this.showUserRemoveModal(selectedUsers);
                  }}
                  disabled={!canDelete}
                >
                  Remove
                </div>
              </div>
            </Tippy>
          </div>
        </div>

        {users.length === 0 && (
          <Fragment>
            {this.state.usersLoadingFailed ? (
              <div className="jumbo error">
                <div style={{ fontWeight: "600" }}>Error loading Users.</div>
                <div className="btn" onClick={this.refresh}>
                  Retry
                </div>
              </div>
            ) : (
              <div className="jumbo">
                <div>Loading users...</div>
                <div className="loading">
                  <i className="spin fas fa-sync-alt"></i>
                </div>
              </div>
            )}
          </Fragment>
        )}

        <div className="body">
          <Scrollbars
            renderThumbHorizontal={() => {
              return <div />;
            }}
            renderThumbVertical={(obj) => renderThumb(obj, theme)}
            id="user-list"
            autoHideDuration={1000}
            style={{ minHeight: `${minHeight}px` }}
            // autoHide
          >
            <table className="dash-table">
              {this.renderUserTHead(false)}
              <tbody>
                {users.map((user) => {
                  return (
                    <UserItem
                      user={user}
                      key={user.user_name}
                      selection={selectedUsers}
                      actions={userActions}
                      onContextClick={this.onContextClick}
                      renderUserContextMenu={this.renderUserContextMenu}
                    />
                  );
                })}
              </tbody>
            </table>
          </Scrollbars>
        </div>
      </div>
    );
  }

  /** Send API call to Observatory to generate a CloudFormation template, following input parameters */
  generateTemplate() {}

  render() {
    return (
      <Fragment>
        {this.renderUserCreationModal()}
        {this.renderUserRemovalModal()}
        {this.renderResultsModal()}
        {this.renderUserList()}
      </Fragment>
    );
  }
}
