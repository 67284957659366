// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import Tippy from "@tippyjs/react";

import Checkbox from "../../../shared/components/Checkbox";
import { properNoun } from "../../../shared/functions/formatting";

/**
 * Minor Component used for displaying an entry in the list of network
 * information in the management console
 * @since 0.4.1
 */
export default class NetItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };

    this.select = this.select.bind(this);
    this.toggleCaret = this.toggleCaret.bind(this);
  }

  // Commented out because could not detect removing subnets from network
  shouldComponentUpdate(nP, nS) {
    return (
      nP.name !== this.props.Name ||
      nP.status !== this.props.status ||
      nP.selection !== this.props.selection ||
      nS.open !== this.state.open ||
      nP.network.subnets.length !== this.props.network.subnets.length
    );
  }

  /**
   * Toggling wether this object is selected or not
   */
  select(item, callback, parentId) {
    const { selection, actions } = this.props;
    const isSelected = item.id in selection;
    if (isSelected) {
      actions.removeFromSelection(item, callback);
    } else {
      actions.addToSelection({ parentId, ...item });
    }
  }

  /**
   * OnClick handler for selecting Subnet entries
   * @param {object} subnet
   * @param {object} network
   */
  selectSubnet(subnet, network) {
    const { selection } = this.props;
    const isSelected = subnet.id in selection;
    const netSelected = network.id in selection;

    // Ensure the user cannot delete networks without removing Subnets first
    if (netSelected && isSelected)
      this.select(network, () => {
        this.select(subnet, null, network.id);
      });
    else this.select(subnet, null, network.id);
  }

  toggleCaret() {
    this.setState({ open: !this.state.open });
  }

  /**
   * Check if subnets all subnets are selected. Returns true if
   * network has no subnets, or if all subnets are selected.
   * Returns false otherwise.
   */
  checkSubnets() {
    const { selection, network } = this.props;
    let canSelect = true;

    for (let i = 0; i < network.subnets.length; i++) {
      let isSelected = network.subnets[i].id in selection;
      if (!isSelected) {
        canSelect = false;
        break;
      }
    }

    return canSelect;
  }

  render() {
    const { network, selection, onContextClick, renderItemContextMenu } = this.props;
    const isSelected = network.id in selection;
    const canSelect = this.checkSubnets();

    const checkbox = (
      <Checkbox
        checked={isSelected}
        onChange={() => {
          this.select(network);
        }}
        disabled={!canSelect}
      />
    );

    return (
      <Fragment>
        <tr
          id={`ctx-${network.id}`}
          onContextMenu={(event) => {
            event.persist();
            onContextClick(event, network, true);
          }}
        >
          {/* Checkbox for selecting network */}
          <td>
            {canSelect ? (
              checkbox
            ) : (
              <Tippy
                content="Cannot remove a network with subnets."
                animation="scale-subtle"
                theme="material"
                duration={global.gTTPDur}
                delay={[0, 0]}
              >
                <div>{checkbox}</div>
              </Tippy>
            )}
          </td>
          <td>
            {network.subnets.length > 0 && (
              <div tabIndex="0" onClick={this.toggleCaret} className={`indicator${this.state.open ? " open" : ""}`}>
                <i className="fas fa-caret-right" />
              </div>
            )}
            {network.name}
          </td>
          <td>{`${properNoun(network.status.toString())}`}</td>
          <td>{network.cidr}</td>
          <td className="ctx-ind">
            {network.tags}

            <div
              tabIndex="0"
              className="ctx-menu-btn"
              onClick={(event) => {
                event.persist();
                onContextClick(event, network, true);
              }}
            >
              <i className="fas fa-ellipsis-v" />
            </div>

            {renderItemContextMenu(network, null)}
          </td>
        </tr>

        {network.subnets.map((subnet) => (
          <Fragment key={`sub-frag-${subnet.id}`}>
            {this.state.open && (
              <tr
                className="subnet"
                id={`ctx-${subnet.id}`}
                onContextMenu={(event) => {
                  event.persist();
                  onContextClick(event, subnet, false);
                }}
              >
                <td>
                  <Checkbox
                    checked={subnet.id in selection}
                    onChange={() => {
                      this.selectSubnet(subnet, network);
                    }}
                  />
                </td>
                <td>
                  <span className="indent" />
                  {subnet.name !== undefined ? subnet.name : `${subnet.cidr}`}
                </td>
                <td>{subnet.status !== undefined ? properNoun(subnet.status.toString()) : "-"}</td>
                <td>{subnet.cidr}</td>
                <td className="ctx-ind">
                  {subnet.tags}

                  <div
                    tabIndex="0"
                    className="ctx-menu-btn"
                    onClick={(event) => {
                      event.persist();
                      onContextClick(event, subnet, true);
                    }}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </div>
                  {renderItemContextMenu(subnet, network.id)}
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </Fragment>
    );
  }
}
