// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import invert from "invert-color";

import { riskGradient } from "../../../shared/functions/color";
import { AddrClassNames, HostTypeNames, RiskSourceNames } from "../../../shared/enumerations";
import { hostComponents } from "../../../static/img/HostIcons";
import { pythonicRound } from "../../../shared/functions/formatting";

export default class ThreatItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };

    this.handleContextClick = this.handleContextClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter() {
    this.setState({ active: true });
  }

  onMouseLeave() {
    this.setState({ active: false });
  }

  handleContextClick(event) {
    this.props.onShowContext(this.props.node, event);
  }

  render() {
    const { node, sources, renderThreatContext, theme } = this.props;
    if (!node) return null;

    const filled = Object.keys(node.risk_detail).includes(RiskSourceNames[sources[0]]);

    let icon = hostComponents(node.host_type);
    const riskColor = riskGradient(theme, node.risk);

    const menu_button = (
      <Fragment>
        <div tabIndex="0" className="ctx-menu-btn" onClick={this.handleContextClick}>
          <i className="fas fa-ellipsis-v" />
        </div>

        {renderThreatContext(node)}
      </Fragment>
    );

    return (
      <tr onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onContextMenu={this.handleContextClick}>
        <td>
          <div
            className="risk-box"
            style={{
              backgroundColor: riskColor,
              color: invert(riskColor, true),
            }}
          >
            <div className="risk-cell">{pythonicRound(node.risk)}</div>
          </div>
        </td>
        <td>
          <div className="host" title={HostTypeNames[node.host_type]}>
            {icon}
          </div>
        </td>
        <td>{node.ip_address}</td>
        <td>{AddrClassNames[node.classification]}</td>
        {filled ? (
          <Fragment>
            {sources.map((src, i) => {
              let risk = node.risk_detail[RiskSourceNames[src]];
              // this default is for testing, though the risk value should not be null
              if (risk === undefined) risk = 0;
              return (
                <td key={src} className={`${i === sources.length - 1 ? "ctx-ind" : ""}`}>
                  {risk}
                  {i === sources.length - 1 ? <Fragment>{menu_button}</Fragment> : null}
                </td>
              );
            })}
          </Fragment>
        ) : (
          <Fragment>
            {sources.map((src, i) => {
              if (i === 0) return <td key={src}>...</td>;
              if (i === sources.length - 1)
                return (
                  <td key={src} className="risk-last">
                    {menu_button}
                  </td>
                );
              return <td key={src} />;
            })}
          </Fragment>
        )}
      </tr>
    );
  }
}
