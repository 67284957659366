// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import * as actions from "./actionTypes";
import { initialState } from "../constants";
import _ from "lodash";

/**
 * Handles storing of WebSocket updates to redux store
 * @since 0.2.2
 * @param {*} state
 * @param {*} action
 */
export default function globalReducer(state = initialState.global, action) {
  let newState = _.cloneDeep(state);
  switch (action.type) {
    case actions.SET_DISPATCHER:
      newState.dispatch = action.dispatch;
      return newState;
    default:
      return state;
  }
}
