// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";
import { Auth } from "@aws-amplify/auth";
import axios from "axios";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { config } from "../constants";
import { UserGroups } from "../enumerations";

export const jsonpath = require("jsonpath");

/**
 * Renders a custom scrollbar thumb, styled to matched the current theme
 * @param {object} param0 object passed from scrollbar
 * @param {string} theme the current user selected theme name
 */
export function renderThumb({ style, ...props }, theme) {
  style.borderRadius = "20px";
  const thumbStyle = {
    backgroundColor: theme.text,
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
}

export function randomFloat(min, max) {
  return Math.random() * (max - min) + min;
}

/**
 * allows creating cookies with only having to worry about its age
 * @param {number} age time in seconds
 */
export function cookie_settings(age) {
  const settings = Object.assign({}, global.gCookieSettings);
  settings.maxAge = age;
  return settings;
}

/** Returns true if value is null or undefined */
export const isNull = (value) => value === null || value === undefined;

/**
 * Determines if the collection is empty.
 * @param {object} obj
 */
export function isEmpty(obj) {
  if (isNull(obj)) return true;
  return Object.keys(obj).length === 0;
}

/**
 * Determines if string is numeric
 * https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
 */
export function isNumeric(str) {
  if (typeof str === "number") return true;
  // return str.split("").some((c) => !isNaN(c));

  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

/** Determines if string is a valid ISO 8601 format WITHOUT time zone */
export function isValidIsoDate(str) {
  if (
    !/^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ])?)?)?$/.test(
      str
    )
  )
    return false;
  return Date.parse(str) !== "NaN";
}

// IPv4 only
export function ValidateIPaddress(ipaddress) {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  )
    return true;
  return false;
}

/**
 * This small delay allows actions dispatched by Redux to dispatch new actions without throwing an error
 */
export function reduxDispatchDelay() {
  return new Promise((resolve, reject) => {
    try {
      resolve();
    } catch (error) {
      console.error(error);
      reject();
    }
  });
}

export function authorize(groups, minCreds) {
  if (minCreds === undefined) minCreds = UserGroups.Admin;

  if (groups === undefined || groups === null) groups = [];
  groups = groups.map((group) => UserGroups[group]);
  const level = groups.length === "0" ? UserGroups.Ungrouped : Math.min(...groups);
  return parseInt(level) <= parseInt(minCreds);
}

async function fetchToken() {
  return `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
}

function parseResponse(response) {
  return {
    statusCode: response.status,
    body: response.data,
    errorMessage: response.statusText,
  };
}

function parseError(error) {
  return _.isNil(error.response)
    ? {
        status: 500,
        data: {},
        statusText: error.message,
      }
    : error.response;
}

/**
 * Object that abstracts calls to te API gateway.
 */
export const MyAPI = {
  get: async (dest) => {
    let response;

    try {
      response = await axios({
        method: "GET",
        url: config.apiGateway.URL + dest,
        headers: {
          Authorization: await fetchToken(),
        },
      });
    } catch (error) {
      console.debug("GET response failed:", response);
      response = parseError(error);
    }

    return parseResponse(response);
  },

  post: async (dest, data) => {
    let response;

    try {
      response = await axios({
        method: "POST",
        url: config.apiGateway.URL + dest,
        headers: {
          Authorization: await fetchToken(),
        },
        data,
      });
    } catch (error) {
      response = parseError(error);
    }

    return parseResponse(response);
  },

  patch: async (dest, data) => {
    let response;

    try {
      response = await axios({
        method: "PATCH",
        url: config.apiGateway.URL + dest,
        headers: {
          Authorization: await fetchToken(),
        },
        data,
      });
    } catch (error) {
      response = parseError(error);
    }

    return parseResponse(response);
  },

  put: async (dest, data) => {
    let response;

    try {
      response = await axios({
        method: "PUT",
        url: config.apiGateway.URL + dest,
        headers: {
          Authorization: await fetchToken(),
        },
        data,
      });
    } catch (error) {
      response = _.isNil(error.response)
        ? {
            status: 500,
            data: {},
            statusText: error.message,
          }
        : error.response;
    }

    return parseResponse(response);
  },

  delete: async (dest) => {
    let response;

    try {
      response = await axios({
        method: "DELETE",
        url: config.apiGateway.URL + dest,
        headers: {
          Authorization: await fetchToken(),
        },
      });
    } catch (error) {
      response = parseError(error);
    }

    return parseResponse(response);
  },

  // POST to resources that don't need credentials
  // TODO: merge with regular POST method
  pre_post: async (dest, data) => {
    let response;

    try {
      response = await axios({
        method: "POST",
        url: config.apiGateway.URL + dest,
        data,
      });
    } catch (error) {
      response = parseError(error);
    }

    return response;
  },
};

/**
 * Removes an item from array at the given index. (why isn't this a default method in js???)
 * @param {ArrayLike} items
 * @param {number} i
 */
export function removeItem(items, i) {
  return items.slice(0, i - 1).concat(items.slice(i, items.length));
}

/**
 * Provides a more descriptive message for the recieved status code.
 * This function is necessary, as certain responses cannot be retreived
 * before an error is thrown.
 * @param {Error} err
 */
export function requestErr(err) {
  const code = stripCode(err);
  console.error(err);

  // Unknown Error!
  if (code === null) {
    return "An unexpected error occurred.";
  }

  switch (code) {
    case "400":
      return "A parameter was malformed.";
    case "401":
    case "403":
      // AWS Resource not created
      // OR authentication not included
      return "You are not authorized to access that resource.";
    case "404":
      // URL not found
      return "The requested resource was not found.";
    case "500":
      return `An unexpected server error occurred. (${code})`;
    case "502":
      // Internal Server Error
      return "There was an unexpected error on the server. Please try again later.";
    case "504":
      // Request took too long to resolve
      return "Request timed out. Please try again later.";
    default:
      return `An unexpected error occurred. (${code})`;
  }
}

/**
 * Strips the status code from an error message created by an HTML request
 * and returns it. Returns null if no status code could be found.
 * @param {Error} err
 */
export function stripCode(err) {
  const msg = err.message;
  if (msg === undefined) return null;
  if (msg.includes("status code")) {
    return msg.substr(msg.indexOf("status code") + "status code ".length);
  } else {
    // other error needs to be handled
    return null;
  }
}

export function copyText(text) {
  navigator.clipboard.writeText(text);
}

/**
 * Convert object list to a dictionary where the key is the id stored in its data
 * @param {*} obj
 */
export function listToObj(obj, key) {
  if (key === undefined) key = "data.id";
  // return _.mapValues(_.keyBy(obj, key))
  return _.keyBy(obj, key);
}

/**
 * Formats selected data into information that can be displayed in
 * the info panel
 * @since 0.3.1
 * @param {*} selectedData The data JSON object as passed from the graph
 */
export const getItemGroups = (selectedData) => {
  // nothing is selected
  if (selectedData === undefined) {
    return [];
  }

  // data from backend is improperly formatted
  if (selectedData.display_info === undefined) {
    return [];
  }

  return normalizeGroups(selectedData.display_info);
};

/**
 * Normalizes the display groups to be used in the
 * selection panel
 * @param {dictionary} display_info
 */
export const normalizeGroups = (display_info) => {
  let groups = _.pickBy(display_info, (_, k) => !k.startsWith("_"));

  return Object.entries(groups).map(([k, v]) => ({
    name: k,
    infos: normalizeInfos(v),
  }));
};

/**
 * Normalizes Info items from a dictionary to be displayed
 * in the selection panel
 * @param {dictionary} info
 */
const normalizeInfos = (info) => {
  return Object.entries(info).map(([k1, v1]) => ({
    name: k1,
    value: v1,
  }));
};

/**
 * Determine if asynchronous operation has finished.
 * Used in Redux store updates
 * @param {dictionary} results
 * @returns
 */
export function isOperationActive(results) {
  let active = 0;
  if (Object.keys(results).length === 0) return false;

  Object.values(results).forEach((entry) => {
    if (entry.active) active += 1;
  });

  return active > 0;
}

/**
 * The original react-router-dom function was removed from v6 in favor of hooks.
 * */
export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    const routedProps = { location, navigate, params, ...props };
    return <Component {...routedProps} />;
  }

  return ComponentWithRouterProp;
}

/**
 * @param {string} message The message to display if rule fails
 * @param {Function} resolver The function to determine validation. Should return true if value is valid
 * @param {string} prop The property that needs to be changed
 * @param {Function} conditional The function to determin if error should be counted
 */
export const ValidationRule = (message, resolver, prop, conditional) => ({
  msg: message,
  prop,
  resolver,
  conditional,
});

/**
 * An entry for storing responses of asynchronous PATCH, POST, DELETE requests
 * @param {string} text The text displayed in modal
 * @param {boolean} active Whether the client is still waiting for server to respond for this request
 * @param {boolean} failed Whether the server responded with an error code (!200)
 * */
export const ManagementResult = (text, active = true, failed = false) => ({
  text,
  active,
  failed,
});
