// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import * as actions from "./actionTypes";
import { initialState } from "../constants";
import _ from "lodash";

/**
 * Handles actions that modify the user
 * @since 0.2.2
 * @param {*} state
 * @param {*} action
 */
export default function userReducer(state = initialState.user, action) {
  let newState = _.cloneDeep(state);
  switch (action.type) {
    case actions.CHANGE_THEME:
      newState.theme = action.theme;
      return newState;
    case actions.CACHE_USER:
      newState = _.merge(newState, action.user);
      return newState;
    default:
      return state;
  }
}
