// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import { combineReducers } from "redux";

// Sub-reducers
import networksReducer from "./networks";
import selectorReducer from "./selector";
import fetchReducer from "./fetch";

/**
 * Main Reducer for handling the System Map
 */
const sysmapReducer = combineReducers({
  networks: networksReducer,
  selector: selectorReducer,
  fetch: fetchReducer,
});

export default sysmapReducer;
