// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import localforage from "localforage";

const CACHE_LIFE = 60 * 60 * 1000 * 2; // 2 Hrs

export default class CacheManager {
  constructor() {
    this.cacheTime = 10 * 60 * 1000; // 10 minutes
    // Clear the cache on login if the entire cache is older than the lifetime
    localforage.getItem("updatedAt").then((lastUpdated) => {
      const now = Date.now();
      let isExpired = now - lastUpdated >= CACHE_LIFE;

      if (isExpired) {
        localforage.clear();
      }
    });
  }

  writeData = (userId, key, data, condition) => {
    console.debug(`CacheManager::writeData (${key})`, data);

    // associate data with current user
    let fullKey = `${userId}.${key}`;
    const now = Date.now();

    localforage.setItem(fullKey, { updatedAt: now, data, condition });

    // use this timestamp for clearing cache on login
    return localforage.setItem("updatedAt", now);
  };

  readData = (userId, key) => {
    console.debug(`CacheManager::readData ${key}`);

    // associate data with current user
    let fullKey = `${userId}.${key}`;

    return localforage.getItem(fullKey);
  };

  removeData = (userId, key) => {
    console.debug(`CacheManager::Removing ${key}`);

    // associate data with current user
    let fullKey = `${userId}.${key}`;

    return localforage.removeItem(fullKey);
  };

  clear = () => localforage.clear();
}

// console.debug("CM: ", new CacheManager())
