// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";

/**
 * A message overlay displayed in the Graph Area
 * @param {*} message Text to display
 */
export default class GraphMessage extends React.Component {
  render() {
    return (
      <div className="graph-message">
        <div className="body">
          <p>{this.props.message}</p>
          {this.props.children}
        </div>
      </div>
    );
  }
}
