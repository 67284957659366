// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Tippy from "@tippyjs/react";
import queryString from "query-string";
import { withToastManager } from "react-toast-notifications";

import * as ConsoleActions from "../reducers/actions";
import * as SysmapActions from "../../sysmap/reducers/actions";
import * as UserActions from "../../../shared/reducers/actions";
import NetworksTab from "../components/NetworksTab";
import AccountTab from "../components/AccountTab";
import UserTab from "../components/UserTab";
import OrganizationTab from "../components/OrganizationTab";
import ResponsivePage from "../../../shared/containers/ResponsivePage";

import { pages } from "../../../shared/enumerations";
import { formatPageTitle } from "../../../shared/functions/formatting";
import { authorize } from "../../../shared/functions/general";
import "../styles/ConsoleView.css";

export const tabs = {
  acct: "account_info",
  org: "organization",
  usr: "users",
  net: "network_list",
};

const min_auth_tabs = [tabs.acct];

class ConsoleView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthorized: false,
    };

    this.renderAccount = this.renderAccount.bind(this);
    this.renderOrganization = this.renderOrganization.bind(this);
    this.renderUsers = this.renderUsers.bind(this);
    this.renderNetworks = this.renderNetworks.bind(this);
    this.onSwitchTab = this.onSwitchTab.bind(this);
  }

  async componentDidMount() {
    const { authGroups } = this.props;

    // collect information
    const isAuthorized = authorize(authGroups);
    this.setState({ authorized: isAuthorized });

    // select initial tab from QS
    let qs = queryString.parse(window.location.search);
    if (!qs.tab) {
      qs.tab = isAuthorized ? tabs.net : tabs.acct;
      this.props.navigate(`${pages.configuration}?${queryString.stringify(qs)}`);
    } else if (!isAuthorized && !min_auth_tabs.includes(qs.tab)) {
      this.props.navigate(`${pages.configuration}?${queryString.stringify(qs)}`);
    }
  }

  /**
   * Callback for when a tab has been switched
   */
  onSwitchTab(tab) {
    let qs = queryString.parse(window.location.search);
    qs.tab = tab;
    this.props.navigate(`${pages.configuration}?${queryString.stringify(qs)}`);
  }

  renderNetworks(size) {
    return (
      <NetworksTab
        networks={this.props.networks}
        results={this.props.netResults}
        // savingActive={this.props.netSavingActive} // TODO: Value is unused
        theme={this.props.theme}
        actions={this.props.sysActions}
        toastManager={this.props.toastManager}
        history={this.props.history}
        pageSize={size}
        navigate={this.props.navigate}
      />
    );
  }

  renderAccount() {
    return (
      <AccountTab
        user={this.props.user}
        theme={this.props.theme}
        actions={this.props.userActions}
        cookies={this.props.cookies}
        toastManager={this.props.toastManager}
        navigate={this.props.navigate}
      />
    );
  }

  renderUsers() {
    return (
      <UserTab
        loading={this.state.loadingUsers}
        users={this.props.users}
        activeUser={this.props.user}
        results={this.props.operationResults}
        operationActive={this.props.operationActive}
        theme={this.props.theme}
        actions={this.props.actions}
        toastManager={this.props.toastManager}
        navigate={this.props.navigate}
      />
    );
  }

  renderOrganization() {
    return (
      <OrganizationTab
        actions={this.props.actions}
        organization={this.props.organization}
        accounts={this.props.accounts}
        results={this.props.operationResults}
        operationActive={this.props.operationActive}
        templateUrls={this.props.templateUrls}
        theme={this.props.theme}
        toastManager={this.props.toastManager}
        navigate={this.props.navigate}
      />
    );
  }

  render() {
    let renderPage, title;
    const { authorized } = this.state;
    let qs_tab = queryString.parse(window.location.search).tab;
    if (!authorized && !min_auth_tabs.includes(qs_tab)) qs_tab = undefined;
    switch (qs_tab) {
      case tabs.acct:
        renderPage = this.renderAccount;
        title = "Account Settings";
        break;
      case tabs.org:
        title = "Organization Settings";
        renderPage = this.renderOrganization;
        break;
      case tabs.usr:
        title = "User Management";
        renderPage = this.renderUsers;
        break;
      case tabs.net:
        title = "Network Management";
        renderPage = this.renderNetworks;
        break;
      default:
        title = "Loading";
        renderPage = () => <div></div>;
        break;
    }

    return (
      <main className="console">
        {formatPageTitle(title)}
        <div className="tabs">
          <div className="group-stack">
            <div className="group">
              <Tab icon="fa-user" text="Account Settings" id={tabs.acct} click={this.onSwitchTab} />
              {authorized && (
                <Fragment>
                  <Tab icon="fa-users" text="Users" id={tabs.usr} click={this.onSwitchTab} />
                  <Tab icon="fa-users" text="Organization" id={tabs.org} click={this.onSwitchTab} />
                  <Tab icon="fa-network-wired" text="Networks" id={tabs.net} click={this.onSwitchTab} />
                </Fragment>
              )}
            </div>
            <div className="border"></div>
          </div>
        </div>

        <ResponsivePage renderPage={renderPage} />
      </main>
    );
  }
}

class Tab extends Component {
  render() {
    const { icon, text, id, click, disabled } = this.props;
    const active = queryString.parse(window.location.search).tab === id;

    const body = (
      <div
        tabIndex={disabled ? "-1" : "0"}
        className={`tab ${active ? "active" : "inactive"}`}
        disabled={disabled || this.props["data-demo"] === "true"}
        data-demo={`${this.props["data-demo"]}`}
        onClick={() => {
          if (click !== undefined) click(id);
        }}
      >
        <i className={`fas ${icon}`} />
        {text}
      </div>
    );

    return (
      <Fragment>
        {this.props["data-demo"] ? (
          <Tippy
            content={global.gDemoMsg}
            animation="scale-subtle"
            theme="material"
            duration={global.gTTPDur}
            delay={[global.gTTPShow, 0]}
            offset={[0, -8]}
          >
            <div>{body}</div>
          </Tippy>
        ) : (
          <Fragment>{body}</Fragment>
        )}
      </Fragment>
    );
  }
}

// ====== Redux action and property mapping

const mapState = (state) => {
  return {
    user: state.user,
    users: state.settings.users,
    operationActive: state.settings.operationActive,
    operationResults: state.settings.results,
    // netSavingActive: state.sysmap.networks.savingActive,
    netResults: state.sysmap.networks.results,
    networks: state.sysmap.networks.networkList,
    templateUrls: state.settings.templateUrls,

    accountResults: state.settings.accountResults,
    organization: state.settings.organization,
    accounts: state.settings.accounts,
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(ConsoleActions, dispatch),
  sysActions: bindActionCreators(SysmapActions, dispatch),
  userActions: bindActionCreators(UserActions, dispatch),
});

export default connect(mapState, mapDispatch)(withToastManager(ConsoleView));
