// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";
import dns, { ReactComponent as DnsServer } from "./icons/dns.svg";
import dir_server, { ReactComponent as DirServer } from "./icons/directory_server.svg";
import ftp_server, { ReactComponent as FtpServer } from "./icons/ftp_server.svg";
import ntp_server, { ReactComponent as NtpServer } from "./icons/ntp_server.svg";
import pen_tester, { ReactComponent as PenTester } from "./icons/pen_tester.svg";
import remote_desktop, { ReactComponent as RemoteDesktop } from "./icons/remote_desktop.svg";
import web_server, { ReactComponent as WebServer } from "./icons/web_server.svg";
import telnet_server, { ReactComponent as TelnetServer } from "./icons/telnet_server.svg";
import mail_server, { ReactComponent as MailServer } from "./icons/mail_server.svg";
import windows_server, { ReactComponent as WindowsServer } from "./icons/windows_server.svg";
import database_server, { ReactComponent as DatabaseServer } from "./icons/database_server.svg";
import tftp_server, { ReactComponent as TftpServer } from "./icons/tftp_server.svg";
import bgp_server, { ReactComponent as BgpServer } from "./icons/bgp_server.svg";
import irc_server, { ReactComponent as IrcServer } from "./icons/irc_server.svg";
import tor_server, { ReactComponent as TorServer } from "./icons/tor_server.svg";

import { HostType } from "../../shared/enumerations";

export const hostIcons = {};
Object.values(HostType).forEach((type) => {
  hostIcons[type] = new Image();
});

hostIcons[HostType.dns].src = dns;
hostIcons[HostType.dir_server].src = dir_server;
hostIcons[HostType.ftp_server].src = ftp_server;
hostIcons[HostType.ntp_server].src = ntp_server;
hostIcons[HostType.web_server].src = web_server;
hostIcons[HostType.pen_tester].src = pen_tester;
hostIcons[HostType.remote_desktop].src = remote_desktop;
hostIcons[HostType.telnet_server].src = telnet_server;
hostIcons[HostType.mail_server].src = mail_server;
hostIcons[HostType.windows_server].src = windows_server;
hostIcons[HostType.database_server].src = database_server;
hostIcons[HostType.tftp_server].src = tftp_server;
hostIcons[HostType.bgp_server].src = bgp_server;
hostIcons[HostType.irc_server].src = irc_server;
hostIcons[HostType.tor_server].src = tor_server;

/**
 * Draw a Host Icon as a React component with specified props
 * @param {number} type
 * @param {*} props
 */
export const hostComponents = (type, props) => {
  if (type === undefined || type === null) return null;

  props = props ? props : {};
  const components = {
    [HostType.dns]: <DnsServer className="host-ico" {...props} />,
    [HostType.dir_server]: <DirServer className="host-ico" {...props} />,
    [HostType.ftp_server]: <FtpServer className="host-ico" {...props} />,
    [HostType.ntp_server]: <NtpServer className="host-ico" {...props} />,
    [HostType.web_server]: <WebServer className="host-ico" {...props} />,
    [HostType.pen_tester]: <PenTester className="host-ico" {...props} />,
    [HostType.remote_desktop]: <RemoteDesktop className="host-ico" {...props} />,
    [HostType.telnet_server]: <TelnetServer className="host-ico" {...props} />,
    [HostType.mail_server]: <MailServer className="host-ico" {...props} />,
    [HostType.windows_server]: <WindowsServer className="host-ico" {...props} />,
    [HostType.database_server]: <DatabaseServer className="host-ico" {...props} />,
    [HostType.tftp_server]: <TftpServer className="host-ico" {...props} />,
    [HostType.bgp_server]: <BgpServer className="host-ico" {...props} />,
    [HostType.irc_server]: <IrcServer className="host-ico" {...props} />,
    [HostType.tor_server]: <TorServer className="host-ico" {...props} />,
  };

  return components[type.toString()];
};
