// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

/** Uesd for page title */
export const app_name = "Auspex Observatory";

/** The max level of risk values objects can have */
export const maxRisk = 10;

export const MAX_TIME_OFFSET = 7 * 60 * 24 - 1; // 7 Days in the past, in minutes. Timestream is not inclusive though

export const defaultUserPrefs = {
  min_alert_level: 1,
  reporting: 2,
  theme: 1,
  on_detection: false,
};

/** The initial state of the application */
export const initialState = {
  global: {
    dispatch: null,
  },

  // User details
  user: {
    id: 1234567,
    first_name: "Admin",
    last_name: "User",
    email: "...",
    phone: "Not set",
    organization: "...",
    preferences: defaultUserPrefs,
    cred: "settings",
  },

  threats: {
    requestID: null,
    loaded: -1,

    internal: {},
    external: {},

    // total number of addresses with risk
    risky_addrs: null,
    // links external addresses to an internal address for isolation
    related_addrs: null,
    // total number of addresses in entire environment
    total_addrs: null,
    // total number of connections with risk
    risk_edges: null,
    // total number of connections in environment
    total_edges: null,
  },

  geomap: {
    selector: {
      selectedRegion: null,
    },

    fetch: {},
  },

  sysmap: {
    // A list of network details, indexed by the ID of the network
    networks: {
      networkList: {},
      savingActive: false,
      results: {},
    },

    fetch: {
      // A message counter that allows us to update the data
      iteration: 0,

      // Whether the graph is currently empty
      isEmpty: false,

      // The exact history of chunks received from Server
      chunk_history: {},

      // The error received from server during fetch
      error: null,
    },

    selector: {
      // Data of currently viewed network
      selectedNetwork: {},

      filter: {},

      // Stack of objects visited by user,
      // Most recent is inserted at end. Root network is always
      // first if retrieved, else the the stack is empty
      hierarchy: [],

      // Information of currently viewed node/edge
      selectedItem: {
        data: [],
        isNode: false,
        // Temporary data until displayInfo is stored as pre-human readable processing
        source: null,
      },
    },
  },

  monitoring: {
    // List of monitored addresses
    endpoints: [],

    // Datapoints of average outage history for all monitored endpoints in past 30 days
    history: { datasets: [{ points: [] }] },

    // Top 10 endpoints with offending history
    topOutages: [],
    // Detailed information on top outages
    outageInfo: {},
    // Endpoint currently selected by User
    selectedEntry: {},
    // List of endpoints IDs User is currently toggling monitoring state for
    togglingEndpoints: [],
  },

  settings: {
    users: [],

    // asynchronous operation tracking
    results: {},
    operationActive: false,
    // Pre-signed URLs for downloading templates. Keyed by template option
    templateUrls: {},

    accounts: [],

    organization: null,

    billing: {
      report: {
        selected: 0,
        current: 0,
        previous: 0,
        due: 0,

        est_current: 0,
        est_previous: 0,
      },

      prices: {},

      // Datapoints object for Cost metrics
      metrics: {
        datasets: [],
      },

      services: {
        datasets: [],
      },

      logs: [],
    },
  },
};

/**
 * preset color theme configurations
 */
export const themes = {
  2: {
    // Light
    secondary: "#ffffff",
    secondary_mid: "#d9e0e7",
    secondary_dark: "#c2cad0",
    tertiary: "#434e58",
    background: "#e5e5ea",
    background_dark: "#d3d9de",
    background_gradient_first: "#ffffff",
    background_gradient_second: "#dadee2",
    background_gradient_type: "radial",
    text: "#000",
    disabled: "85%",
    list_transparency: "#cacaca40",
    list_transparency_dark: "#282c3026",
    list_transparency_hover: "#deedff52",
    console_transparency: "#00000091",
    check_box: "#ffffff",
    check_box_dark: "#cccccc",
    table_header: "#dadfe4",
    table_row_odd: "#33384200",
    dim: "125%",
    bright: "90%",
    highlight_1: "#72d2d2",
  },
  1: {
    // Night
    secondary: "#0E111B",
    secondary_mid: "#2e353c",
    secondary_dark: "#1b2132",
    tertiary: "#bdcad4",
    background: "#1d2438",
    background_dark: "#0E111B",
    background_gradient_first: "#1d2336",
    background_gradient_second: "#0E111B",
    background_gradient_type: "radial",
    text: "#ffffff",
    disabled: "50%",
    list_transparency: "#00000040",
    list_transparency_hover: "#0000001f",
    list_transparency_dark: "#1d2336ac",
    console_transparency: "#00000091",
    check_box: "#000000",
    check_box_dark: "#000000",
    table_header: "#191e2c",
    table_row_odd: "#00000026",
    bright: "125%",
    dim: "80%",
    tooltip_bg: "#323C4A",
  },
  Root: {
    // Theme containing values shared between other themes
    // Sizes used for node visualization
    large: 13.325,
    medium: 8.325,
    small: 5,

    primary: "#4e9bc1",
    primary_dark: "#1a72d8",

    highlight_1: "#02838d", // Turquoise
    highlight_2: "#bb5b00", // Orange
    highlight_3: "#ed6796", // Pink
    highlight_4: "#7ac89d", // Blue-Green
    highlight_5: "#d43e27", // Red
    highlight_6: "#9932CC", // Dark Orchid
    highlight_7: "#089f4c", // Green
    highlight_8: "#f1c900", // Yellow
    highlight_9: "#5b698d", // Gray for cloud node
    highlight_10: "#3a6fb6", // Blue

    // Coloring for the risk pie charts is ordered to match general available
    // risk sources per type of object
    pieChartColors: [
      "#089f4c", // Green,      Baseline
      "#d43d27", // Red         Rejected Flow
      "#9932CC", // Dark Orchid Unmanaged
      "#e68925", // Orange      Algorithm 1
      "#f1c900", // Yellow      Algorithm 2
      "#23b4b4", // Turquoise   Algorithm 3
    ],

    billingChartColors: [
      "#3a6fb6", // Blue
      "#f1c900", // Yellow
      "#23b4b4", // Turquoise
      "#089f4c", // Green
      "#9932CC", // Dark Orchid
      "#ed6796", // Pink
      "#7ac89d", // Blue-Green
    ],
    // Coloring for risk category
    riskCatNone: "#02838d", // Turquoise
    riskCat1: "#089f4c", // Green,
    riskCat2: "#f1c900", // Yellow
    riskCat3: "#e68925", // Orange
    riskCat4: "#d43d27", // Red
  },
};

/** Generic error message */
export const genericError = "Whoops! An unexpected error occurred. Please try again later.";

/**
 * Default style of modals
 */
export const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
    borderRadius: "10px",
    outline: "none",
    padding: "40px",
    border: 0,
    backgroundColor: "background_dark",
    boxShadow: "0 0 10px 3px #00000047",
  },
  overlay: {
    backgroundColor: "#000000D0",
  },
};

export function ServerError(response) {
  return Error(`${response.body.message} status code ${response.statusCode}`);
}

// static info for debugging ====================================

/* 
 as a best practice
 all global variables should be referenced via global. syntax
 and their names should always begin with g
*/
global.gIsProd = process.env.NODE_ENV !== "development";

// The UID of the Network representing the entire Organization
global.gOrgLevelUID = "-0x0";

const netFiles = {};
global.gDataJSON = {};
global.gHasJSONFiles = global.gIsProd ? false : loadFiles();

/**
 * Attempts to load test files into memory. Returns true on success, false on
 * failure.
 * This was created to help prevent builds from breaking without files that are
 * not synced through the git repo due to containing unwanted data. In other
 * words, debug data cannot be used if none of the required files are found.
 * @since 0.3.1
 */
function loadFiles() {
  try {
    global.gNetListJSON = require("../static/data/networkList.json");

    // Builds a list of network graphs, indexed by id
    let i, id, net;
    for (i = 0; i < global.gNetListJSON.networks.length; i++) {
      id = global.gNetListJSON.networks[i].id;
      net = require(`../static/data/network_${id}.json`);
      netFiles[id] = net;
    }
    for (i = 0; i < global.gNetListJSON.subs.length; i++) {
      id = global.gNetListJSON.subs[i].id;
      net = require(`../static/data/network_${id}.json`);
      netFiles[id] = net;
    }

    netFiles[global.gOrgLevelUID] = require("../static/data/full_graph.json");
    global.gBillingMetrics = require("../static/data/billing.json");
    global.gEndpointMonitoring = require("../static/data/endpoints.json");
    global.gDataJSON = netFiles;
  } catch (e) {
    console.warn(e);
    return false;
  }

  return true;
}

let local = {};

if (!global.gIsProd) {
  try {
    local.aws = require("./local_config.js").default;
    local.dev = require("./local_settings.js").default;
  } catch {
    // Local configuration is not available
    if (local.aws === undefined) console.warn("Missing local AWS configuration file!");
    if (local.dev === undefined) console.warn("Missing development configuration file!");
  }
}

export const config = global.gIsProd ? require("./amplify_config.js").default : local.aws;

export const devConfig = global.gIsProd ? undefined : local.dev;

/** ===============================================================

  Globals

===================================================================*/

// Disable graph option widget for prod environment
global.gOptionsDisabled = global.gIsProd ? false : devConfig.optionsDisabled;

// To test local values, set this value to true.
// NOTE: This will only work if all the necessary files are found on your machine.
global.gFromJSON = global.gHasJSONFiles && !global.gIsProd ? devConfig.fromJSON : false;

// Disable features that are not yet implemented
global.gDemo = true;
global.gDemoMsg = "This feature is currently unavailable.";

// The amount of time in milliseconds a toast message should
// stay on the screen
global.gToastTimeout = 10000;

// Tooltip Timing constants in ms
global.gTTPShow = 750;
global.gTTPDur = 200;

// The amount of time in milliseconds a custom scrollbar should
// remain visibile
global.gScrollTimeout = 1000;

// Default settings for a cookie, max age in seconds
global.gCookieSettings = {
  path: "/",
  sameSite: "strict",
  maxAge: 3600,
};

// Default account ID representing the org_key
global.gDefaultAccount = "Organization";

// Default request ID
// global.gInternalID = "00000000-0000-0000-0000-000000000000"
