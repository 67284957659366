// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import { combineReducers } from "redux";

import sysmap from "../../pages/sysmap/reducers/sysmap";
import monitoring from "../../pages/monitoring/reducers/monitoring";
import threats from "../../pages/sysmap/reducers/threats";
import user from "./user";
import settings from "../../pages/settings/reducers/settings";
import global from "./global";

const rootReducer = combineReducers({
  sysmap,
  user,
  settings,
  threats,
  monitoring,
  global,
});

export default rootReducer;
