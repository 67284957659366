// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import _ from "lodash";

import DirectoryItem from "./DirectoryItem";

/**
 * Contains the list of the networks in the system
 * @since 0.2.2
 * @param {*} networks
 * @param {number} selectedNetwork The index of the currently selected network
 * @param {*} actions The callback events that update the selector
 */
export default class Directory extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.hierarchy !== nextProps.hierarchy ||
      this.props.organization !== nextProps.organization ||
      this.props.fetcherStatus !== nextProps.fetcherStatus ||
      this.props.selectedNetwork !== nextProps.selectedNetwork
    );
  }

  render() {
    const { organization } = this.props;
    const orgName = organization ? organization : "Unnamed Organization";

    let { hierarchy } = this.props;
    hierarchy = _.uniqBy(hierarchy, "id");

    return (
      <div className="ribbon">
        <div style={{ position: "relative" }}>
          {this.props.selectedNetwork && (
            <Fragment>
              <div className="net-item" onClick={this.props.clearNet}>
                <div className="backing" />
                <i style={{ fontSize: "24px" }} className="fas fa-long-arrow-alt-left" />
                <div className="right-arrow"></div>
              </div>

              <div className="net-item static">
                <div className="backing" />
                {orgName}
                <div className="right-arrow"></div>
              </div>

              {/* Hierarchy Ribbon */}
              {hierarchy.map((network, index) => {
                let last = index === hierarchy.length - 1;
                return (
                  <DirectoryItem
                    actions={this.props.actions}
                    network={network}
                    last={last}
                    key={network.id}
                    updateLoading={this.props.updateLoading}
                    killPollTimer={this.props.killPollTimer}
                    requestNetworkInfo={this.props.requestNetworkInfo}
                    toastManager={this.props.toastManager}
                    fetcherStatus={this.props.fetcherStatus}
                    navigate={this.props.navigate}
                  />
                );
              })}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}
