// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Fragment } from "react";
import sizeMe from "react-sizeme";

/**
 * The sizeMe function creates a temporary <div/> element to get the initial
 * render size; Unfortunately, React creates an error for <div/> elements that
 * are immediate children of <table/> elements
 */
function _ResponsiveTableHeader({ head }) {
  return <Fragment>{head}</Fragment>;
}

const ResponsiveTableHeader = sizeMe({ monitorHeight: true })(_ResponsiveTableHeader);
export default ResponsiveTableHeader;
