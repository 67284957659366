// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
import { Link } from "react-router-dom";
import querystring from "query-string";

import "../styles/UserCard.css";
import { tabs } from "../../pages/settings/containers/ConsoleView";
import { pages } from "../enumerations";

/**
 * Display element for the user
 * @since 0.2.2
 */
export default class UserCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
  }

  /**
   * Opens the dropdown menu
   * @since 0.3.1
   */
  openMenu() {
    if (this.state.isOpen) return;
    this.setState({ isOpen: true });
  }

  /**
   * Closes the dropdown menu
   * @since 0.3.1
   */
  closeMenu() {
    if (!this.state.isOpen) return;
    this.setState({ isOpen: false });
  }

  componentDidUpdate() {
    setDOMVars(this.props.theme);
  }

  onClickLogout(event) {
    this.props.setUser({});
    this.props.handleLogout(event);
  }

  render() {
    let dropClass = "dropdown-content";
    dropClass += this.state.isOpen ? " open" : "";
    const acct_qa = querystring.stringify({ tab: tabs.acct });

    return (
      <div className="dropdown">
        <div
          className="dropbtn"
          //TODO: Menu should close when mouse leaves, find out why below was commented out
          //Recorded as work item 131 in DevOps
          // onMouseLeave={ this.closeMenu }
          onClick={this.openMenu}
        >
          {this.props.user.email} <i className="fa fa-user-circle" aria-hidden="true"></i>
          <i className="fa fa-caret-down"></i>
        </div>
        <div className="anchor">
          <div className={dropClass} onMouseLeave={this.closeMenu} onMouseEnter={this.openMenu}>
            <Link className="drop-entry" to={`${pages.configuration}?${acct_qa}`}>
              Account Settings
            </Link>

            <div className="drop-entry" onClick={this.onClickLogout}>
              Logout
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Update theme variables in document's CSS
 */
function setDOMVars(theme) {
  for (let col in theme) {
    document.documentElement.style.setProperty("--" + col, theme[col]);
  }

  return theme;
}
