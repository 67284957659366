// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import { applyMiddleware, createStore } from "redux";

import rootReducer from "./shared/reducers/index";
import { setDispatch } from "./shared/reducers/actions";
import { devConfig } from "./shared/constants";

const dispatchLoggingEnabled = global.gIsProd ? false : devConfig.dispatchLoggingEnabled;

// This will only be available during development
let composeTools;
try {
  composeTools = require("redux-devtools-extension").composeWithDevTools;
} catch {
  composeTools = null;
}

/* 
 Create the logging to the console of state
 */
const dispatchLogger = (store) => (next) => (action) => {
  console.group(`Action: ${action.type}`);
  console.info("dispatching", action);
  let result = next(action);
  console.log("next state", store.getState());
  console.groupEnd();
  return result;
};

/* Now configure the store */
export default function configureStore(preloadedState) {
  const middlewares = [];

  let enhancers = [];
  let middlewareEnhancer;

  if (dispatchLoggingEnabled) middlewares.push(dispatchLogger);

  if (middlewares.length > 0) {
    middlewareEnhancer = applyMiddleware(...middlewares);
    enhancers = [middlewareEnhancer];
  }

  let store;
  if (composeTools) {
    const composedEnhancers = composeTools(...enhancers);
    store = createStore(rootReducer, preloadedState, composedEnhancers);
  } else {
    store = createStore(rootReducer, preloadedState);
  }

  store.dispatch(setDispatch(store.dispatch, null));

  return store;
}

/**
 *  Custom middleware for formatting logger output. Adds timestamps to beginning of log message.
 *
 * NOTE: Doing this with current code changes the stack trace of the log entry to the custom lines.
 * Only enable if timing of logs are needed for debugging.
 */
export function configureLogger() {
  const { debug: oldDebug, log: oldLog, info: oldInfo, warn: oldWarn, error: oldError } = console;

  function debug(...args) {
    args[0] = `[${new Date().toISOString()}] ${args[0]}`;
    oldDebug(...args);
  }
  function log(...args) {
    args[0] = `[${new Date().toISOString()}] ${args[0]}`;
    oldLog(...args);
  }
  function info(...args) {
    args[0] = `[${new Date().toISOString()}] ${args[0]}`;
    oldInfo(...args);
  }
  function warn(...args) {
    args[0] = `[${new Date().toISOString()}] ${args[0]}`;
    oldWarn(...args);
  }
  function error(...args) {
    args[0] = `[${new Date().toISOString()}] ${args[0]}`;
    oldError(...args);
  }

  window.console.debug = debug;
  window.console.log = log;
  window.console.info = info;
  window.console.warn = warn;
  window.console.error = error;
}
