// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import { Auth } from "@aws-amplify/auth";
import { FormGroup, FormControl } from "react-bootstrap";
import Switch from "react-switch";
import Tippy from "@tippyjs/react";
import _ from "lodash";

import { processPassword } from "../../home/containers/Signup";
import Dropdown from "../../../shared/components/Dropdown";
import Password from "../../../shared/components/Password";
import LoaderButton from "../../../shared/components/LoaderButton";
import { ClientTheme, MinAlertLevel, ReportingSchedule, gateways, site_cookies } from "../../../shared/enumerations";
import { changeTheme } from "../../../shared/reducers/actions";
import { MyAPI, cookie_settings, isNull } from "../../../shared/functions/general";
import { defaultUserPrefs, initialState } from "../../../shared/constants";

export default class AccountTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // User is modifying information
      modifyingPersonal: false,
      sendingModifications: false,

      // If the user is changing passwords curently
      passOpen: false,
      // the new password
      oldPass: "",
      newPass: "",
      // Error message for changing password
      passErr: "",
      showPass: false,
      isLoadingPassword: false,
      // Password restriction fails
      fails: [],
      // User modification failures
      userFails: {},

      // Temporary modification values
      first_name: null,
      last_name: null,
      email: null,
      updatedPrefs: {
        reporting: null,
        on_detection: null,
        min_alert_level: null,
        theme: null,
      },
    };

    this.handleInfoChange = this.handleInfoChange.bind(this);
    this.handlePrefChange = this.handlePrefChange.bind(this);
    this.handleDropChange = this.handleDropChange.bind(this);
    this.saveModifications = this.saveModifications.bind(this);
    this.cancelModification = this.cancelModification.bind(this);
    this.cancelPassChange = this.cancelPassChange.bind(this);
    this.onChangeTheme = this.onChangeTheme.bind(this);
    this.cancelModification = this.cancelModification.bind(this);
    this.startPassChange = this.startPassChange.bind(this);
    this.startModification = this.startModification.bind(this);
  }

  componentDidUpdate(newProps) {
    // Reset the updated entries in notification preferences
    if (!_.isEqual(newProps.user, this.props.user)) {
      const { updatedPrefs } = this.state;
      Object.keys(updatedPrefs).forEach((key) => (updatedPrefs[key] = null));
      this.setState({ updatedPrefs });
    }
  }

  handleInfoChange(event) {
    this.setState(
      {
        [event.target.id]: event.target.value,
      },
      () => {
        this.setState({
          userFails: this.validChanges().fails,
        });
      }
    );
  }

  /**
   * @param {Boolean} value
   * @param {*} _ The triggered event
   * @param {String} id The name of the updated preference
   */
  handlePrefChange(value, _, id) {
    const prefs = this.state.updatedPrefs;
    prefs[id] = value;
    this.setState({ updatedPrefs: prefs });
  }

  handleDropChange = (event, enum_set) => {
    const enum_val = Object.keys(enum_set).find((key) => enum_set[key] === event.target.value);
    this.handlePrefChange(enum_val, event, event.target.id);
  };

  onChangeTheme = (event) => {
    changeTheme(this.props.theme);
    this.handleDropChange(event, ClientTheme);
  };

  handlePassChange = (event) => {
    this.handleChange(event, () => {
      this.setState({
        fails: processPassword(this.state.newPass, this.state.newPass),
      });
    });
  };

  handleChange = (event, callback) => {
    this.setState(
      {
        [event.target.id]: event.target.value,
      },
      callback
    );

    if (callback) callback();
  };

  toggleShowPass(_, value) {
    this.setState({ showPass: value });
  }

  validatePass() {
    return processPassword(this.state.newPass, this.state.newPass).length === 0;
  }

  validChanges() {
    const { first_name, last_name, email } = this.state;
    const fails = {};

    if (email.length === 0) {
      fails.email = "Must not be empty";
    } else if (email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm) === null) {
      fails.email = "Must be valid email";
    }

    if (first_name.length === 0) fails.first_name = "Must not be empty";
    if (last_name.length === 0) fails.last_name = "Must not be empty";

    return {
      valid: Object.keys(fails).length === 0,
      fails,
    };
  }

  handleSubmitPass = async (event) => {
    event.preventDefault();
    this.setState({ isLoadingPassword: true, passErr: "" });

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, this.state.oldPass, this.state.newPass);

      this.setState({
        isLoadingPassword: false,
        passOpen: false,
        oldPass: "",
        newPass: "",
      });
    } catch (err) {
      console.error(err);
    }
  };

  startPassChange() {
    this.setState({ passOpen: true });
  }

  cancelPassChange() {
    this.setState({
      passOpen: false,
      newPass: "",
      oldPass: "",
    });
  }

  startModification() {
    const { user } = this.props;

    const preferences = Object.assign({}, !isNull(user) ? user.preferences : defaultUserPrefs);

    this.setState({
      modifyingPersonal: true,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      updatedPrefs: preferences,
      userFails: {},
    });
  }

  cancelModification() {
    this.setState({ modifyingPersonal: false });
  }

  saveModifications() {
    const { user, cookies, actions, toastManager } = this.props;
    const { updatedPrefs, first_name, last_name, email } = this.state;
    this.setState({ sendingModifications: true });

    const updatedBody = { preferences: {} };
    const personal = { first_name, last_name, email };
    let modified = false;

    // only include values that were changed
    Object.entries(personal).forEach((entry, _) => {
      const [key, value] = entry;
      if (value !== user[key]) {
        updatedBody[key] = value;
        modified = true;
      }
    });
    Object.entries(updatedPrefs).forEach((entry, _) => {
      const [key, value] = entry;
      if (value !== user.preferences[key]) {
        updatedBody.preferences[key] = value;
        modified = true;
      }
    });

    if (!modified) {
      this.setState({ sendingModifications: false, modifyingPersonal: false });
      return;
    }

    let url = gateways.userItem.replace(":id", user.id);
    MyAPI.patch(url, updatedBody)
      .then((response) => {
        console.debug("AccountTab::Patch User response: ", response);
        if (response.statusCode !== 200) {
          this.setState({ sendingModifications: false });
          console.error("AccountTab::Patch User error: ", response.body.message);
          toastManager.add(response.body.message, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: global.gToastTimeout,
          });
        } else {
          this.setState({ sendingModifications: false, modifyingPersonal: false });
          toastManager.add("Successfully updated account.", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: global.gToastTimeout,
          });

          Object.entries(updatedBody.preferences).forEach((entry, _) => {
            const [key, value] = entry;
            user.preferences[key] = value;
          });
          if (updatedBody.first_name) user.first_name = updatedBody.first_name;
          if (updatedBody.last_name) user.last_name = updatedBody.last_name;
          if (updatedBody.email) user.email = updatedBody.email;

          actions.setUser(user);
          cookies.set(site_cookies.curUser, user, cookie_settings(300));
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ sendingModifications: false });
      });
  }

  renderPersonal() {
    const { user } = this.props;
    const { modifyingPersonal, userFails: fails } = this.state;

    const security = (
      <div className="security">
        <div className="pass">
          Password
          {this.state.passOpen ? (
            <form onSubmit={this.handleSubmitPass}>
              <FormGroup controlId="oldPass">
                {this.state.passErr.length > 0 && <div className="error">{this.state.passErr}</div>}

                <Password
                  value={this.state.oldPass}
                  onChange={this.handleChange}
                  onToggle={(_, value) => this.toggleShowPass(_, value)}
                  placeholder="Current Password"
                />
              </FormGroup>
              <FormGroup controlId="newPass">
                {this.state.fails.length > 0 && (
                  <ul className="error">
                    <p>Password Restrictions:</p>
                    {this.state.fails.map((message, i) => (
                      <li className="err" key={i}>
                        {message}
                      </li>
                    ))}
                  </ul>
                )}

                <Password
                  value={this.state.newPass}
                  onChange={this.handlePassChange}
                  placeholder="New Password"
                  visible={this.state.showPass}
                  hideToggle
                />
              </FormGroup>
              <div className="actions">
                <div className="btn" onClick={this.cancelPassChange}>
                  Cancel
                </div>
                <LoaderButton
                  className="second btn-block"
                  disabled={!this.validatePass()}
                  type="submit"
                  isLoadingPassword={this.state.isLoadingPassword}
                  text="Change"
                  loadingText="Changing…"
                />
              </div>
            </form>
          ) : (
            <div className="horiz-group">
              <div className="react-input">**********</div>
              <div tabIndex="0" className="btn second" onClick={this.startPassChange}>
                Change
              </div>
            </div>
          )}
        </div>
        <div className="mfa">
          Multi-Factor Authentication
          <div className="horiz-group">
            <div className="react-input">Not Set</div>

            <Tippy
              content={global.gDemoMsg}
              animation="scale-subtle"
              theme="material"
              duration={global.gTTPDur}
              delay={[global.gTTPShow, 0]}
            >
              <div>
                <div disabled tabIndex="0" className="btn second">
                  Configure
                </div>
              </div>
            </Tippy>
          </div>
        </div>
      </div>
    );

    if (modifyingPersonal)
      return (
        <Fragment>
          <div className="name">
            <FormGroup controlId="first_name">
              <label>First Name</label>
              <FormControl
                className={`react-input${fails.first_name ? " invalid" : ""}`}
                type="text"
                value={this.state.first_name}
                onChange={this.handleInfoChange}
              />
              {fails.first_name && <div className="field-error">{fails.first_name}</div>}
            </FormGroup>
            <FormGroup controlId="last_name">
              <label>Last Name</label>
              <FormControl
                className={`react-input${fails.last_name ? " invalid" : ""}`}
                type="text"
                value={this.state.last_name}
                onChange={this.handleInfoChange}
              />
              {fails.last_name && <div className="field-error">{fails.last_name}</div>}
            </FormGroup>
          </div>

          <div className="contact">
            <FormGroup controlId="email" className="input-email">
              <label>Email</label>
              <FormControl
                className={`react-input${fails.email ? " invalid" : ""}`}
                type="text"
                value={this.state.email}
                onChange={this.handleInfoChange}
              />
              {fails.email && <div className="field-error">{fails.email}</div>}
            </FormGroup>
          </div>
          {security}
        </Fragment>
      );

    return (
      <Fragment>
        <div className="name">
          <UserInput label="First Name" value={user.first_name} disabled={true} />
          <UserInput label="Last Name" value={user.last_name} disabled={true} />
        </div>
        <div className="contact">
          <UserInput label="Email" value={user.email} disabled={true} className="input-email" />
        </div>
        {security}
      </Fragment>
    );
  }

  renderNotificationPrefs(preferences, theme) {
    const { updatedPrefs, modifyingPersonal } = this.state;
    const prefs = {
      on_detection: {
        desc: "Email alerts as they're detected",
        value: preferences.on_detection,
      },
    };

    return (
      <Fragment>
        {Object.entries(prefs).map((entry, _) => {
          const [key, pref] = entry;
          return (
            <div className="op-tog" key={key}>
              <Switch
                className={`toggle${modifyingPersonal ? "" : " static"}`}
                checked={updatedPrefs[key] != null ? updatedPrefs[key] : isNull(pref.value) ? false : pref.value}
                onChange={modifyingPersonal ? this.handlePrefChange : () => {}}
                offColor={theme.background}
                onColor={theme.primary_dark}
                checkedIcon={false}
                uncheckedIcon={false}
                id={key}
              />
              <div className="desc">{pref.desc}</div>
            </div>
          );
        })}
      </Fragment>
    );
  }

  render() {
    const { user, theme } = this.props;
    const { modifyingPersonal, updatedPrefs, sendingModifications } = this.state;

    const themeOptions = [];
    const enabledThemes = [ClientTheme[1], ClientTheme[2]];
    Object.values(ClientTheme).forEach((value) => {
      themeOptions.push({
        text: value,
        enabled: enabledThemes.includes(value),
      });
    });

    const reportingOptions = [];
    Object.values(ReportingSchedule).forEach((value) => {
      reportingOptions.push({ text: value });
    });

    const alertOptions = [];
    Object.values(MinAlertLevel).forEach((value) => {
      alertOptions.push({ text: value });
    });

    return (
      <Fragment>
        <div className="title card">
          Account Settings
          <div className="actions right">
            {modifyingPersonal ? (
              <Fragment>
                {!sendingModifications && (
                  <div className="btn quart" onClick={this.cancelModification}>
                    Discard Changes
                  </div>
                )}

                <LoaderButton
                  className="second btn-block"
                  disabled={!this.validChanges().valid || user.email === initialState.user.email}
                  type="submit"
                  isLoading={sendingModifications}
                  text="Save Changes"
                  loadingText="Saving…"
                  onClick={this.saveModifications}
                />
              </Fragment>
            ) : (
              <div className="btn" onClick={this.startModification}>
                Update
              </div>
            )}
          </div>
        </div>
        <div className="card personal-info">
          <div className="heading">
            <div className="header">Personal Information</div>
          </div>
          <div className="body">
            <div className="prof-pic">
              <p>Profile Picture</p>
              <Tippy
                animation="scale-subtle"
                theme="material"
                duration={global.gTTPDur}
                delay={[global.gTTPShow, 0]}
                content={global.gDemoMsg}
              >
                <div className="image-region">
                  <UserPic user={user} />
                </div>
              </Tippy>
            </div>
            <div className="fields">{this.renderPersonal()}</div>
          </div>
        </div>

        <div className="card notifications">
          <div className="heading">
            <div className="header">Notification Settings</div>
          </div>

          <div className="body">
            <div className="options">
              <div className="lc">{this.renderNotificationPrefs(user.preferences, theme)}</div>
              <div className="rc">
                <div className="op-drop">
                  Activity Reporting frequency:
                  {modifyingPersonal ? (
                    <Dropdown
                      id="reporting"
                      value={ReportingSchedule[updatedPrefs.reporting]}
                      options={reportingOptions}
                      onChange={(event) => {
                        this.handleDropChange(event, ReportingSchedule);
                      }}
                    />
                  ) : (
                    <UserInput
                      dropdown
                      value={
                        isNull(_.get(user, "preferences.reporting"))
                          ? ReportingSchedule[defaultUserPrefs.reporting]
                          : ReportingSchedule[user.preferences.reporting]
                      }
                    />
                  )}
                </div>
                <div className="op-drop">
                  Minimum alert level notification:
                  {modifyingPersonal ? (
                    <Dropdown
                      id="min_alert_level"
                      value={MinAlertLevel[updatedPrefs.min_alert_level]}
                      options={alertOptions}
                      onChange={(event) => {
                        this.handleDropChange(event, MinAlertLevel);
                      }}
                    />
                  ) : (
                    <UserInput
                      dropdown
                      value={
                        isNull(_.get(user, "preferences.min_alert_level"))
                          ? MinAlertLevel[defaultUserPrefs.min_alert_level]
                          : MinAlertLevel[user.preferences.min_alert_level]
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card usability">
          <div className="heading">
            <div className="header">Usability</div>
          </div>

          <div className="body">
            <div className="usability horiz-group">
              {/* <div className="lc">
                Text Size
                <Tippy
                  animation="scale-subtle"
                  theme="material"
                  duration={global.gTTPDur}
                  delay={[global.gTTPShow, 0]}
                  content={global.gDemoMsg}
                >
                  <div className="usability-text-size-slider">
                    <Slider min={8} max={25} defaultValue={16} tipFormatter={(value) => value} disabled={true} />
                  </div>
                </Tippy>
              </div> */}

              <div className="rc">
                Theme:
                <div className="op-drop">
                  {modifyingPersonal ? (
                    <Dropdown
                      value={ClientTheme[updatedPrefs.theme]}
                      options={themeOptions}
                      onChange={this.onChangeTheme}
                      id="theme"
                      reverse
                    />
                  ) : (
                    <UserInput
                      dropdown
                      value={
                        isNull(_.get(user, "preferences.theme"))
                          ? ClientTheme[defaultUserPrefs.theme]
                          : ClientTheme[user.preferences.theme]
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

/** Minor component for displaying non modifyable values
 */
const UserInput = ({ className, label, value, dropdown }) => {
  let classes = className;
  if (dropdown) classes = classes ? classes + " drop-down" : "drop-down";

  return (
    <div className={classes}>
      <label>{label}</label>
      {dropdown ? (
        <div className="static react-input" tabIndex="0">
          {value}
        </div>
      ) : (
        <input className="static react-input form-control" tabIndex="0" value={value} onChange={() => {}} />
      )}
    </div>
  );
};

/** TODO: Convert to functional component */
export class UserPic extends Component {
  render() {
    const { user } = this.props;
    const initials = user.first_name.substr(0, 1) + user.last_name.substr(0, 1);
    const chg = (
      <div style={{ width: "100%", height: "100%" }}>
        <div className="chg-btn faa-pulse">
          <i className="fas fa-edit"></i>
          <input
            className="fake"
            type="file"
            id="prof-img-input"
            title=""
            onChange={() => {
              readURL("prof-img-input");
            }}
            accept="image/x-png,image/gif,image/jpeg"
          />
          <div className="plus"> </div>
        </div>
      </div>
    );

    return (
      <li className="chg-img">
        {user.profile_pic ? (
          <div
            id="prof_img"
            style={{ backgroundImage: `url(${user.profile_pic})` }}
            alt="Profile Image"
            className="rounded img set-bg faa-parent"
          >
            {chg}
          </div>
        ) : (
          <div className="color-bg">
            <div>{initials.toUpperCase()}</div>
            {/* { chg } */}
          </div>
        )}
      </li>
    );
  }
}

/** Allows user to change their profile picture. Imported from a
 * pure js project */
export function readURL(id) {
  const input = document.getElementById(id);
  if (input.files && input.files[0]) {
    let reader = new FileReader();

    reader.onload = function (e) {
      const ele = document.getElementById("prof_img");
      ele.css("background-image", "url(" + e.target.result + ")");
    };

    reader.readAsDataURL(input.files[0]);
  }
}
