// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React from "react";
import { SizeMe } from "react-sizeme";

export default function ResponsivePage({ renderPage, classes }) {
  let setClasses = `page${classes ? " " + classes : ""}`;

  return (
    <SizeMe monitorHeight={true}>
      {({ size }) => (
        // <div className="page">
        <div className={setClasses}>{renderPage(size)}</div>
      )}
    </SizeMe>
  );
}
