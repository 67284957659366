// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
import { FormControl, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

/** Callback applied to conform to React standards for forms, despite no callback being needed */
function voidCallback() {}

/**
 * Container class for inputting a password.
 * Includes a button to toggle visibility
 */
class Password extends Component {
  static propTypes = {
    className: PropTypes.string, // extra classes for CSS styling
    value: PropTypes.string, // current value
    onChange: PropTypes.func, // callback triggered when input has changed value
    placeholder: PropTypes.string, // value displayed when input is empty
    visible: PropTypes.bool, // whether input text is visible
    inputSM: PropTypes.number, // bootstrap property SM for main input
    hideSM: PropTypes.number, // bootstrap property SM for hide icon
  };

  static defaultProps = {
    inputSM: 12,
    hideSM: 1,
  };

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
    };

    this.toggleVisible = this.toggleVisible.bind(this);
  }

  toggleVisible = (event) => {
    this.setState(
      {
        show: !this.state.show,
      },
      () => {
        if (this.props.onToggle) {
          this.props.onToggle(event, this.state.show);
        }
      }
    );
  };

  render() {
    const { className, value, onChange, placeholder, hideToggle, visible, inputSM, hideSM, autoComplete, ...props } = this.props;

    const show = visible === undefined ? this.state.show : visible;
    const hide = hideToggle || visible !== undefined;
    const compiledProps = {
      className: `${className}`,
      value,
      onInput: onChange,
      onChange: voidCallback,
      type: show ? "text" : "password",
      placeholder,
      ...props,
    };

    if (autoComplete !== undefined) compiledProps.autoComplete = autoComplete;

    return (
      <Row className="password">
        <Col sm={inputSM}>
          <FormControl {...compiledProps} />
        </Col>

        {!hide && (
          <Col sm={hideSM} className="toggler">
            <i onClick={this.toggleVisible} className={`fas fa-eye${!show ? "-slash" : ""}`} />
          </Col>
        )}
      </Row>
    );
  }
}

export default Password;
