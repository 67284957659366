// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../reducers/actions";

import { ReactComponent as Logo } from "../../static/img/observatory_logo.svg";
import UserCard from "./UserCard";
import "../styles/NavBar.css";

/**
 * The navigation bar for the entire site.
 * @since 0.2.2
 */
const NavBar = ({ user, isAuthenticated, handleLogout, actions, theme }) => {
  actions = {
    handleLogout,
    ...actions,
  };

  return (
    <div className={`topnav${!isAuthenticated ? " home-nav" : ""}`}>
      <a href="https://www.auspex-labs.com">
        <Logo className="logo" />
        {/* <object className='logo' aria-labelledby='logo' type="image/svg+xml" data="https://static.auspex-labs.com/observatory_logo.svg" /> */}
      </a>

      {isAuthenticated ? (
        <Fragment>
          <UserCard user={user} theme={theme} {...actions} />
        </Fragment>
      ) : (
        <Fragment>{/*Login page should not navigation links per Issue 92 */}</Fragment>
      )}
    </div>
  );
};

// ====== Redux action and property mapping

const mapState = (state) => ({
  user: state.user,
});

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(mapState, mapDispatch)(NavBar);
