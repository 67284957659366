// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
import { FormControl, FormGroup } from "react-bootstrap";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import _ from "lodash";

import Dropdown from "../../../shared/components/Dropdown";
import { isNull } from "../../../shared/functions/general";

/** The comparison operation to perform on the value in a filter */
export const FilterOperation = {
  eq: "eq",
  gte: "gte",
  gt: "gt",
  lte: "lte",
  lt: "lt",
  between: "bt",
};

export const FilterOperationNames = {
  [FilterOperation.eq]: "=",
  [FilterOperation.gt]: ">",
  [FilterOperation.gte]: "≥",
  [FilterOperation.lt]: "<",
  [FilterOperation.lte]: "≤",
  [FilterOperation.between]: "between",
};

export const FilterKey = {
  risk: "risk",
  updated: "updated",
  geo: "geo",
  internal: "int",
  search: "s",

  //  TODO
  //Recorded as work item 125 in DevOps
  //  discovery: 'created',
  //  conn: 'connections',
  //  cls: 'classification',
};

export const ValueType = {
  number: 0,
  date: 1,
  string: 2,
};

/**
 * need to convert human readable operation strings to keys
 * @param {Object} dict Dictionary with keys to code opt
 * @param {Object} names Dictionary with keys to names; Can be undefined if names are code opts used in url
 * @param {*} val
 */
function readableToKey(dict, names, val) {
  let filter_func = names === undefined ? (i) => dict[i] === val : (i) => names[i] === val;

  let new_op = _.filter(Object.keys(dict), filter_func);
  if (new_op.length === 0) return undefined;
  return new_op[0];
}

class FilterOption extends Component {
  static propTypes = {
    classname: PropTypes.string,
    tooltip: PropTypes.string,
    field: PropTypes.string.isRequired, // the field in the filter for which the value represents
    name: PropTypes.string, // label to display beside input
    filter: PropTypes.object.isRequired, // the current state of the temporary filter
    range: PropTypes.shape({
      min: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
      max: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
    }), // range of values to clamp user input to. Values must match type of input
    onChange: PropTypes.func, // callback when value or operation has changed
    onError: PropTypes.func, // callback triggered when MUI error state changes
    options: PropTypes.array, // options if value must be chosen from a dropdown
    optionNames: PropTypes.arrayOf(PropTypes.string),
    operations: PropTypes.array, // FilterOperations valid for this value. Should only be one per group of FilterOption components
    defaultValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
    type: PropTypes.number, // if ValueType.date, values must be UTC ISO 8601 timestamp, without timezone
    secondary: PropTypes.bool, // Whether this input represents the second value of the filter
  };

  static defaultProps = {
    type: ValueType.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };

    this.determineValue = this.determineValue.bind(this);
    this.onError = this.onError.bind(this);
    this.onOperationChange = this.onOperationChange.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onDateTimeChange = this.onDateTimeChange.bind(this);
  }

  determineValue() {
    const { field, filter, defaultValue, options, secondary } = this.props;

    let value = null;
    let op = null;
    let op_name;

    if (filter[field] !== undefined) {
      value = filter[field][secondary ? "secondary" : "value"];
      if (options) value = options[value];
      op = filter[field].op;
      if (op !== undefined) op_name = FilterOperationNames[op];
    } else {
      if (options) value = options[defaultValue];
      else value = defaultValue;
    }

    return { value, op, op_name };
  }

  /**
   * @param {Date} newDate
   */
  onDateTimeChange(newDate) {
    const { field, range, onChange } = this.props;
    if (!onChange) return;

    // Comparisons are reversed since dates are not offsets in minutes
    if (newDate < range.max) {
      newDate = range.max;
    } else if (newDate > range.min) {
      newDate = range.min;
    }

    onChange(field, newDate);
  }

  onOperationChange(event) {
    const { field, onChange } = this.props;
    if (!onChange) return;

    const { value } = this.determineValue();

    let new_op = readableToKey(FilterOperation, FilterOperationNames, event.target.value);
    onChange(field, value, new_op);
  }

  onDropdownChange(event) {
    const { field, onChange, options } = this.props;
    if (!onChange) return;

    let val = event.target.value;
    if (options) {
      val = readableToKey(options, options, val);
    }
    onChange(field, val);
  }

  onInputChange(event) {
    const { field, filter, onChange, range, secondary, type } = this.props;

    if (!onChange) return;
    let val = event.target.value;
    try {
      if (type === ValueType.number) {
        val = parseFloat(val);
        if (isNaN(val)) throw new Error("New value is not a number.");
      }
    } catch (err) {
      // no need to log anything; the value simply won't change
      return;
    }

    // Clamp input value to range, if defined
    if (range !== undefined) {
      if (type === ValueType.date) {
        if (val > range.max) {
          val = range.max;
        } else if (val < range.min) {
          val = range.min;
        }
      } else if (type === ValueType.number) {
        val = Math.min(val, _.get(range, "max", Number.MAX_VALUE));
        val = Math.max(val, _.get(range, "min", Number.MIN_VALUE));
      }
    }

    if (secondary) {
      onChange(field, filter[field].value, undefined, val);
    } else {
      onChange(field, val);
    }
  }

  onError(failure) {
    const { onError, field, secondary } = this.props;
    let failed = !isNull(failure);

    if (failed) {
      this.setState({ errorMessage: "Value is invalid" });
    } else if (this.state.errorMessage) {
      this.setState({ errorMessage: null });
    }

    if (onError) {
      const id = `${field}.${secondary ? "secondary" : "value"}`;
      onError(id, failed);
    }
  }

  render() {
    const { name, optionNames, operations, tooltip, className, type, range } = this.props;
    const { errorMessage } = this.state;

    let { value, op_name } = this.determineValue();
    const desc = !isNull(name) ? <div className="desc">{name}:</div> : null;

    return (
      <div className={`filter-op${className ? " " + className : ""}`}>
        {tooltip && name ? (
          <Tippy content={tooltip} animation="scale-subtle" theme="material" duration={global.gTTPDur} delay={[global.gTTPShow, 0]}>
            {desc}
          </Tippy>
        ) : (
          desc
        )}

        {/* Operations (e.g. <, >=, etc) */}
        {operations && op_name && <Dropdown reverse options={operations} value={op_name} onChange={this.onOperationChange} />}

        {/* Primary value input */}
        {optionNames ? (
          <Dropdown reverse options={optionNames} value={value} onChange={this.onDropdownChange} />
        ) : (
          <FormGroup>
            {type === ValueType.date ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDateTimePicker
                  ampm={false}
                  className={errorMessage ? "MuiError" : undefined}
                  value={value}
                  onChange={this.onDateTimeChange}
                  onError={this.onError}
                  // localeText="UTC"
                  maxDateTime={_.get(range, "min", undefined)}
                  minDateTime={_.get(range, "max", undefined)}
                  viewRenderers={{
                    hours: null,
                    minutes: null,
                    seconds: null,
                  }}
                  disableFuture
                  closeOnSelect={false}
                  // timezone="UTC"
                />
              </LocalizationProvider>
            ) : (
              <FormControl
                value={value === null || value === undefined ? 0 : value}
                placeholder="value"
                type={type === ValueType.number ? "number" : "string"}
                onChange={this.onInputChange}
              />
            )}
          </FormGroup>
        )}
      </div>
    );
  }
}

export default FilterOption;
