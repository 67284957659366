// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component } from "react";
import PropTypes from "prop-types";
import { polyfill } from "react-lifecycles-compat";
import "../styles/Checkbox.css";

/** this class appears to be a rework of rc-checkbox */
class Checkbox extends Component {
  static propTypes = {
    prefixCls: PropTypes.string, // prefix class for input
    className: PropTypes.string, // extra classes for CSS styling
    style: PropTypes.object, // inline CSS styling
    name: PropTypes.string,
    id: PropTypes.string, // ID of component used when triggering callbacks
    type: PropTypes.string, // controls the type of input. defaults to checkbox
    defaultChecked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]), // whether box is checked initially
    checked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]), // whether box is currently checked
    disabled: PropTypes.bool, // disables all pointer events
    onFocus: PropTypes.func, // callback triggered when focus is gained
    onBlur: PropTypes.func, // callback triggered when focus is lost
    onChange: PropTypes.func, // callback triggered check as changed
    onClick: PropTypes.func, // callback triggered when box is clicked
    tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // property for tabbing into the component
    readOnly: PropTypes.bool, // value cannot be changed by clicking
    autoFocus: PropTypes.bool, // should ask for focus immediately when displayed
    value: PropTypes.any,
  };

  static defaultProps = {
    prefixCls: "rc-checkbox",
    className: "",
    style: {},
    type: "checkbox",
    defaultChecked: false,
    onFocus() {},
    onBlur() {},
    onChange() {},
  };

  constructor(props) {
    super(props);

    const checked = "checked" in props ? props.checked : props.defaultChecked;

    this.state = {
      checked,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if ("checked" in props) {
      return {
        ...state,
        checked: props.checked,
      };
    }
    return null;
  }

  focus() {
    this.input.focus();
  }

  blur() {
    this.input.blur();
  }

  handleChange = (e) => {
    const { disabled, onChange } = this.props;
    if (disabled) {
      return;
    }
    if (!("checked" in this.props)) {
      this.setState({
        checked: e.target.checked,
      });
    }
    if (onChange) {
      onChange({
        target: {
          ...this.props,
          checked: e.target.checked,
        },
        stopPropagation() {
          e.stopPropagation();
        },
        preventDefault() {
          e.preventDefault();
        },
        nativeEvent: e.nativeEvent,
      });
    }
  };

  saveInput = (node) => {
    this.input = node;
  };

  render() {
    const {
      prefixCls,
      className,
      style,
      name,
      id,
      type,
      disabled,
      readOnly,
      onClick,
      onFocus,
      onBlur,
      autoFocus,
      value,
      ...others
    } = this.props;

    const globalProps = Object.keys(others).reduce((prev, key) => {
      if (key.substring(0, 5) === "aria-" || key.substring(0, 5) === "data-" || key === "role") {
        prev[key] = others[key];
      }
      return prev;
    }, {});

    const { checked } = this.state;
    let { tabIndex: ti } = this.props;
    if (ti === undefined) ti = "0";

    return (
      <label className={`check-cont${this.props.disabled ? " disabled" : ""}`}>
        {this.props.children}
        <input
          onChange={this.handleChange}
          readOnly={readOnly}
          ref={this.saveInput}
          type={type}
          id={id}
          disabled={disabled}
          checked={!!checked}
        />
        <div
          name={name}
          style={style}
          disabled={disabled}
          className={`check-box ${prefixCls}-input`}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          autoFocus={autoFocus}
          value={value}
          tabIndex={disabled ? "-1" : ti}
          {...globalProps}
        />
      </label>
    );
  }
}

polyfill(Checkbox);

export default Checkbox;
