// This code is property of Auspex Labs Inc. and is protected by Trade Secret.

import React, { Component, Fragment } from "react";
import { Chart, Line, Doughnut, Bar } from "react-chartjs-2";
import _ from "lodash";

import { randomFloat } from "../functions/general";

const Chart_ = Chart;

// Register a plugin for hiding data tooltips for specified datasets
// code found here: https://stackoverflow.com/questions/43118408/how-to-disable-a-tooltip-for-a-specific-dataset-in-chartjs
// TODO: DevOps 290 - Revisit if this is necessary? Doesn't seem to be doing anything currently
Chart_.plugins.register({
  // need to manipulate tooltip visibility before its drawn (but after update)
  beforeDraw: function (chartInstance, easing) {
    // check and see if the plugin is active (its active if the option exists)
    if (chartInstance.config.options.tooltips.onlyShowForDatasetIndex) {
      // get the plugin configuration
      let tooltipsToDisplay = chartInstance.config.options.tooltips.onlyShowForDatasetIndex;

      // get the active tooltip (if there is one)
      let active = chartInstance.tooltip._active || [];

      // only manipulate the tooltip if its just about to be drawn
      if (active.length > 0) {
        // first check if the tooltip relates to a dataset index we don't want to show
        if (tooltipsToDisplay.indexOf(active[0]._datasetIndex) === -1) {
          // we don't want to show this tooltip so set it's opacity back to 0
          // which causes the tooltip draw method to do nothing
          chartInstance.tooltip._model.opacity = 0;
        }
      }
    }
  },
});

/**
 * The types of possible charts recognized by the tool
 */
export const chartTypes = {
  line: "line",
  doughnut: "doughnut",
  bar: "bar",
};

/**
 * A component containing a chartable graph
 */
class MyChart extends Component {
  constructor(props) {
    super(props);

    // Bind component functions
    this.normalizeChartData = this.normalizeChartData.bind(this);
    this.formatLineChartStyling = this.formatLineChartStyling.bind(this);
    this.formatPieChartStyling = this.formatPieChartStyling.bind(this);
    this.formatBarChartStyling = this.formatBarChartStyling.bind(this);
  }

  // TODO: DevOps 289 - Does this actually save renders?
  shouldComponentUpdate(newProps) {
    return !_.isEqual(this.props, newProps);
  }

  /**
   * renders an interactible ChartJS Line chart for the Item entry.
   * @since 0.3.2
   * @returns The ChartJS graph to be rendered to a DOM
   * @see https://www.chartjs.org/docs/latest/charts/line.html
   * @example //The following specifies expected/required information
   */
  renderAreaChart() {
    let min;
    const graph = this.props.data;
    // console.debug('service graph: ', graph)
    if (graph.datasets !== undefined) {
      const datasets = _.cloneDeep(graph.datasets);
      datasets.map((set) => dataMin(set.points));
      min = Math.min(...datasets);
    } else {
      min = dataMin(graph.data.points);
    }

    const { options: proptions } = this.props;

    let options = {
      responsive: false,
      mode: "label",
      legend: { display: false },
      title: { display: false },
      scales: {
        xAxes: [
          {
            offset: true,
            type: "linear",
            position: "bottom",
            gridLines: {
              display: false,
            },
            labels: {
              display: false,
            },
            ticks: {
              suggestedMin: min,
              suggestedMax: min,
              stepSize: 1,
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            gridLines: {
              display: false,
            },
          },
        ],
      },

      tooltips: {
        enabled: true,
        mode: "label",
        // TODO: DevOps 291 - Callbacks are causing the tooltips to disappear
        /*callbacks: {
          label: (item) => {
            let units = yAxisUnits;
            if (units === undefined) units = graph.yAxisUnits;
            if (units === undefined) units = "";
            return `${item.yLabel} ${units}`;
          },
          title: (item) => {
            let units = graph.data ? graph.data.xAxisUnits : null;
            if (units === undefined || units === null) {
              let scale = _.get(proptions, "scales.xAxes");
              if (scale !== undefined && scale[0].type === "time") {
                units = "UTC";
              } else {
                units = "";
              }
            }
            return `${item[0].xLabel} ${units}`;
          },
        },*/
      },
    };

    // let legend;

    options = _.merge(options, proptions);
    // options = this.props.options;

    // TODO: DevOps 292 - is this custom responsiveness code still needed?
    let height = undefined,
      width = undefined;
    if (this.props.sizeOverride !== undefined && this.props.sizeOverride !== null) {
      height = this.props.sizeOverride.height;
      width = this.props.sizeOverride.width;
    }

    return (
      <div className="chart-container">
        <Line
          options={options}
          data={this.formatLineChartStyling}
          id={this.props.uuid}
          legend={this.props.legend}
          width={width}
          height={height}
        />
      </div>
    );
  }

  /**
   * renders an interactable ChartJS bar chart
   * @since 0.4.1
   */
  renderBarChart() {
    // const label = this.props.label? this.props.label : (item) => item.value
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      title: { display: false },
      xAxes: [{ offset: true }],
      tooltips: {
        // TODO: DevOps 291 - custom functions cause visibility issue
        // callbacks:{
        //   label: label,
        // }
      },
    };

    options = _.merge(options, this.props.options);
    // console.debug(options)

    return (
      <div className="chart-container">
        <Bar options={options} data={this.formatBarChartStyling} id={this.props.uuid} />
      </div>
    );
  }

  /**
   * renders an interactible ChartJS Pie chart
   * @since 0.3.2
   */
  renderDougnutChart() {
    // const graph = this.props.data

    //const label = this.props.label ? this.props : (item) => item.value;

    const options = {
      responsive: false,
      legend: { display: false },
      title: { display: false },
      cutoutPercentage: 35,
      tooltips: {
        //callbacks: {
        // label: label,
        //},
      },
    };

    return (
      <div className="chart-container">
        <Doughnut options={options} data={this.formatPieChartStyling} id={this.props.uuid} />
      </div>
    );
  }

  /**
   * A callback to properly format the styling and data for a ChartJS bar chart
   * @param {*} canvas The canvas currently rendering the chart
   * @since 0.4.1
   */
  formatBarChartStyling = (canvas) => {
    let { /*barRatio, catRatio, hideCatSpacing,*/ minBarLen, width, height } = this.props;
    if (width !== undefined) canvas.width = width;
    if (height !== undefined) canvas.height = height;
    this.canvas = canvas;

    if (minBarLen === undefined) minBarLen = 3;
    const data = this.normalizeChartData(this.props.data, this.props.name);

    if (data.datasets) {
      data.datasets.forEach((set, _) => {
        const color = set.backgroundColor !== undefined ? set.backgroundColor : this.props.theme.primary_dark;
        const border = set.borderColor !== undefined ? set.borderColor : color;
        set.backgroundColor = color + (color.length === 7 ? "40" : "");
        set.borderColor = border;
        set.borderWidth = set.borderWidth !== undefined ? set.borderWidth : 1;

        // TODO: DevOps 293 - expose next two vars as params
        set.categoryPercentage = 1.0;
        set.barPercentage = 1.0;
        set.minBarLength = minBarLen;
      });
    }

    return data;
  };

  /**
   * A callback to properly format a ChartJS Line chart
   * @param {*} canvas The canvas currently rendering the chart
   * @since 0.3.2
   */
  formatLineChartStyling = (canvas) => {
    const { width, height } = this.props;
    if (width !== undefined) canvas.width = width;
    if (height !== undefined) canvas.height = height;
    this.canvas = canvas;

    let colorSet = this.props.colorSet !== undefined ? this.props.colorSet : this.props.theme.pieChartColors;

    const data = this.normalizeChartData(this.props.data, this.props.name);

    if (data.datasets) {
      data.datasets.forEach((set, i) => {
        const color = set.borderColor !== undefined ? set.borderColor : colorSet[Math.min(i, colorSet.length - 1)];
        set.borderColor = color;
        set.borderWidth = set.borderWidth !== undefined ? set.borderWidth : 2;
        set.backgroundColor = set.backgroundColor !== undefined ? set.backgroundColor : color + "40";
        set.lineTension = 0.15;
        set.pointRadius = 0.1;
        set.pointHitRadius = 10;
      });
    }

    return data;
  };

  /**
   * A callback to properly format a Chartjs Doughnut chart
   * @param {*} canvas The canvas currently rendering the chart
   * @since 0.3.2
   */
  formatPieChartStyling = (canvas) => {
    // TODO: DevOps 294 - Should the width always be set for pie chart?
    canvas.width = this.props.size !== undefined ? this.props.size : "200";
    canvas.height = canvas.width;
    this.canvas = canvas;
    let { colorSet } = this.props;
    if (colorSet === undefined) colorSet = this.props.theme.pieChartColors;
    const data = this.normalizeChartData(this.props.data, this.props.name);

    if (data.datasets) {
      data.datasets.forEach((set, i) => {
        set.backgroundColor = colorSet;
        set.borderWidth = 0;
      });
    }

    return data;
  };

  render() {
    let chart;
    switch (this.props.type) {
      case chartTypes.line:
        chart = this.renderAreaChart();
        break;
      case chartTypes.doughnut:
        chart = this.renderDougnutChart();
        break;
      case chartTypes.bar:
        chart = this.renderBarChart();
        break;
      default:
        // hide this error to logging
        chart = <Fragment>'{this.props.type}' is not a valid chart type.</Fragment>;
        break;
    }

    /**
     * Some error occurred trying to normalize the chart
     * data, so we are displaying something<
     */
    if (chart === undefined) chart = <div>Chart Error.</div>;

    return <Fragment>{chart}</Fragment>;
  }

  /**
   * Normalizes data into a chartjs friendly format
   * @param {*} graph Generic data received from the API gateway
   */
  normalizeChartData = (graph, name) => {
    // TODO: DevOps 295 - Reformat data from backend to be agnostic; don't include datasets object which is specific to Chartjs
    const multi = graph.datasets !== undefined;
    let datasets =
      multi && this.props.type !== chartTypes.doughnut
        ? graph.datasets
        : [
            {
              label: name,
              data: [],
            },
          ];
    const normalized = {
      labels: [],
      datasets,
    };

    try {
      switch (this.props.type) {
        case chartTypes.doughnut:
          let values = [],
            groups = [];

          _.get(graph, "datasets[0].points", []).forEach((point) => {
            groups.push(point.x);
            values.push(Math.round(point.y * 10) / 10);
          });

          // react-chartjs-2 has a quirk where it fails to render if two or more
          // groups have the exact same value
          values.forEach((value) => {
            let unique = value;
            if (value in normalized.datasets[0].data) {
              const epsilon = randomFloat(0.00001, 0.0001);
              unique = value + epsilon;
            }

            normalized.datasets[0].data.push(unique);
          });

          normalized.labels = groups;
          break;
        case chartTypes.bar:
          // console.debug('type:',this.props.type, '\nmulti: ', multi)
          if (!multi) {
            graph.data.forEach((value) => {
              normalized.datasets[0].data.push(value);
            });
          } else {
            // Flatten datasets into one list, obtain single
            // x-axis label list
            let arrays = _.map(datasets, (ed) => ed.data);
            let flattened = [].concat.apply([], arrays);
            normalized.labels = _.uniq(_.map(flattened, (ele) => ele.x));
          }

          break;
        case chartTypes.line:
          if (!multi) {
            graph.data.points = graph.data.points.sort(sortLinePoints);
            normalized.datasets[0].data = graph.data.points;
          } else {
            // Flatten datasets into one list, obtain single x-axis label list
            let arrays = _.map(datasets, (ed) => ed.points);
            let flattened = [].concat.apply([], arrays);
            normalized.labels = _.uniq(_.map(flattened, (ele) => ele.x));
            normalized.datasets.map((set) => {
              // for some reason reclassifying this field disables the chart; another fine quirk of react-chartjs-2
              // set.data = _.cloneDeep(set.points)
              // delete(set.points)
              set.data = set.points;
              return set;
            });
          }

          break;
        default:
          console.error(`Chart type '${this.props.type}' is invalid.`);
          return undefined;
      }

      return normalized;
    } catch (err) {
      console.error("Could not normalize chart data. Make sure the data is formatted properly.");
      console.error(err);
      return {};
    }
  };
}

const sortLinePoints = (a, b) => {
  let res = a.x - b.x;
  if (isNaN(res)) {
    let nameA = a.x.toUpperCase(); // ignore upper and lowercase
    let nameB = b.x.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  }
  return res;
};

export default MyChart;

function dataMin(points) {
  let min = Number.MAX_SAFE_INTEGER;

  points.forEach((point) => {
    min = point.x < min ? point.x : min;
  });

  return min;
}
